import React from 'react';
import ResourceGridView from './../../../components/grid/ResourceGridView';
import {useStore} from "../../../context";
import StandalonePopover from "../../../components/ui/StandalonePopover";
import {observer} from "mobx-react";
import ResourceUpdate from "./PartFamilyUpdate";
import ResourceCreate from "./PartFamilyCreate";

const ResourceGrid = observer(() => {

    const {genericResourceStore} = useStore();

    const canEdit = true;

    genericResourceStore.configure('/api/part_families', 'Part Family')


    return (
        <div className='dashboard'>
            {genericResourceStore.place === 'create' &&
            <StandalonePopover title={'Create'} closePopover={() => genericResourceStore.changePlace('grid')}>
                <ResourceCreate sharedGridResourceCollectionStore={genericResourceStore}/>
            </StandalonePopover>}
            {genericResourceStore.place === 'update' &&
            <StandalonePopover title={`${genericResourceStore.label} #${genericResourceStore.currentResourceId} update`}
                               closePopover={() => genericResourceStore.changePlace('grid')}>
                <ResourceUpdate canRemove={canEdit} sharedGridResourceCollectionStore={genericResourceStore}/>
            </StandalonePopover>}
            <ResourceGridView<any>
                columns={[
                    {
                        name: 'id',
                        value: (item) => item['@id'].split('/').reverse()[0],
                    },
                    {
                        name: 'label',
                        value: (item) => item.label,
                    },
                    {
                        name: 'manufacturer',
                        value: (item) => item.manufacturer ? item.manufacturer.name : 'N/A',
                    },
                    {
                        name: 'serviceType',
                        value: (item) => item.serviceType ? item.serviceType : 'N/A',
                    },
                ]}
                onRowClick={(item) => {
                    genericResourceStore.changePlace('update', item)
                }}
                onCreate={() => genericResourceStore.changePlace('create')}


                gridResource={genericResourceStore}
            />
        </div>
    );
})


export default ResourceGrid;
