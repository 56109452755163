import React, {useEffect} from 'react';
import {useStore} from "../../context";
import {observer} from "mobx-react";
import {useLocation, useRouteMatch} from "react-router";
import {CustomerNavigation} from "./Navigation/CustomerNavigation";
import CustomerDetailsForm from "./CustomerForm/CustomerDetailsForm";
import SystemForm from "../systems/SystemForm";
import {useQuery} from "../../utils/query";
import {Link} from "react-router-dom";
import {Icon} from "@mdi/react";
import {mdiChevronLeft, mdiTextSubject} from "@mdi/js";
import {Loader} from "../../components/ui";
import CustomerPageButtonList from "./CustomerPageButtonList";
import ProjectDetailsForm from "./ProjectDetailsForm";
import ContractDetailsForm from "./Сontract/ContractDetailsForm";
import PartTypeDetailsForm from "../parts/DetailsForm/PartTypeDetailsForm";
import {Media} from 'react-breakpoints'
import FloatButtons from "../../components/ui/FloatButtons";
import StandalonePopover from "../../components/ui/StandalonePopover";
import AssignPartModalInNavigationForm from "./Navigation/AssignPartModalInNavigationForm";

const CustomerPage = observer(() => {
    const {customerStore, authStore} = useStore();
    const {params} = useRouteMatch();
    const query = useQuery();
    const location = useLocation();

    useEffect(() => {
        customerStore.loadFromRouter(Number(params['customerId']), query);
    }, [location.search]);

    return (
        <div className={'center-form-container contract-navigation'}>
            {customerStore.isLoading && <Loader/>}
            {customerStore.contextPlace === 'parts_assigning' &&
            <StandalonePopover
                title={`Assign ${customerStore.selectedPartsToAssign.length} parts to ${customerStore.assignPartTo}`}
                closePopover={customerStore.resetPartsAssigning}>
                <AssignPartModalInNavigationForm/>
            </StandalonePopover>}
            <Media>
                {({breakpoints, currentBreakpoint}) => {
                    return breakpoints[currentBreakpoint] >= breakpoints.tablet && (
                        <div className='form-wrapper contract-navigation-section'>

                            <Link to={"/customers"} className="back"><Icon path={mdiChevronLeft}/>Back to
                                customers</Link>
                            <CustomerNavigation/>
                        </div>
                    )
                }}
            </Media>

            {!customerStore.isLoading && <div className='right-panel active-contract-data'>
                <div className={'center-form'}>
                    {customerStore.activeTab === 'customer' && <CustomerDetailsForm mode={"FIXED"}/>}
                    {customerStore.activeTab === 'contract' && <ContractDetailsForm mode={"FIXED"}/>}
                    {(customerStore.activeTab === 'project' || customerStore.activeTab === 'collapsed_project') &&
                    <ProjectDetailsForm
                        mode={"FIXED"}
                        customerStore={customerStore}/>
                    }
                    {customerStore.activeTab === 'system' && <SystemForm
                        canEdit={customerStore.canEdit}
                        canEditModelNumber={customerStore.canEditAdminUser}
                        mode={"FIXED"}
                        initialValues={customerStore.systemStore.currentSystem}
                        onSubmit={customerStore.updateSystem}
                        attachmentInterface={customerStore.systemStore}
                    />}
                    {customerStore.activeTab === 'collapsed_system' && <SystemForm
                        canEdit={false}
                        canEditModelNumber={false}
                        collapsed={true}
                        mode={"FIXED"}
                        initialValues={customerStore.systemStore.currentCollapsedSystem}
                        onSubmit={() => {
                        }}
                        attachmentInterface={customerStore.systemStore}
                    />}
                    {customerStore.activeTab === 'part' &&
                    <PartTypeDetailsForm
                        catalogueMode={false}
                        partStore={customerStore.partStore}
                        mode={"UPDATE"}
                        isGranted={!authStore.isCustomerOrPartner}
                        standalone={false}/>}
                    {customerStore.activeTab === 'collapsed_part' &&
                    <PartTypeDetailsForm
                        catalogueMode={false}
                        partStore={customerStore.partStore}
                        mode={"UPDATE"}
                        collapsed={true}
                        isGranted={!authStore.isCustomerOrPartner}
                        standalone={false}/>}
                </div>
                <div className="float-buttons">
                    <Media>
                        {({breakpoints, currentBreakpoint}) => {

                            return breakpoints[currentBreakpoint] < breakpoints.tablet && (
                                <FloatButtons type={"contract"} icon={mdiTextSubject}>
                                    <CustomerNavigation/></FloatButtons>
                            )
                        }}
                    </Media>
                    <CustomerPageButtonList/>
                </div>
            </div>}
        </div>);
});

export default CustomerPage;
