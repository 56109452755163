import {action, computed, observable, runInAction} from "mobx";
import remotedev from 'mobx-remotedev';
import ApiClient from "../ApiClient";
import {CollectionResponseType, GridResourceCollectionInterface} from "../interfaces/GridResourceCollectionInterface";
import {handleFormSubmit, hydrateInitialValues} from "../../utils/form/handleFormSubmit";
import {idFromEntity, idFromIri} from "../../utils/iri";
import {FileAttachmentsInterface} from "../interfaces/FileAttachmentsInterface";
import UiBus from "../service/UiBus";
import AttachmentManager from "../service/AttachmentManager";
import {SubmissionErrors} from "final-form";
import {WithExpensesInterface} from "../interfaces/WithExpensesInterface";
import {ContractPeriodListType, ContractTypeListType} from "../enum/contractEnumeration";
import {
    ContractFullModel,
    ContractShortModel,
    ExpenseUpdateInput,
    IAttachmentModel,
    IExpenseModel,
    Iri
} from "../../utils/models";
import moment from "moment";
import {RouterStore} from "mobx-react-router";

export type ContractTimeMinStep = 10 | 20 | 30 | 40 | 50 | 60;


interface ContractFormInterface {
    contractNumber: string | null,
    initialCost: string,
    totalValue: string | null,
    slaTime: string | null,
    startDate: string | null,
    endDate: string | null,
    customerPurchaseOrder: string | null,
    notes: string | null,
    salesPerson: string | null
}

export interface ContractMaintenanceFormInterface extends ContractFormInterface {
    period: ContractPeriodListType,
}

export interface ContractAdministrationFormInterface extends ContractFormInterface {
    ticketsNumber: number | null,
    hoursNumber: number | null,
    period: ContractPeriodListType,
}


export interface ContractPayAsYouGoFormInterface extends ContractFormInterface {
    rate: string,
    timeMinStep: ContractTimeMinStep | null,
}

export interface ContractBankOfHoursFormInterface extends ContractFormInterface {
    rate: string,
    hoursNumber: number | null,
    period: ContractPeriodListType,
    timeMinStep: ContractTimeMinStep | null,
}

export interface ContractBankOfTicketsFormInterface extends ContractFormInterface {
    rate: string,
    ticketsNumber: number | null,
    period: ContractPeriodListType,
    timeMinStep: ContractTimeMinStep | null,
}

type ContactStoreContextPlace = 'expenses' | 'duration' | 'history'

@remotedev({global: true})
export class ContractStore implements GridResourceCollectionInterface<ContractShortModel>, FileAttachmentsInterface, WithExpensesInterface {
    @observable contextPlace: ContactStoreContextPlace | null;
    @observable collectionResponse: CollectionResponseType<ContractShortModel> | null;
    @observable currentContract: ContractFullModel | null;
    @observable isLoading: boolean = false;
    private attachmentManager: AttachmentManager;
    @observable private _contractDraftType: ContractTypeListType | null;
    @observable childrenTree: { contractId: string, systemOrProjectsId: string, partId: string }

    constructor(private apiClient: ApiClient, private uiBus: UiBus, private routing?: RouterStore) {
        this.attachmentManager = new AttachmentManager(apiClient, this, uiBus);
    }

    getEntityName: 'Contract';

    @computed get contractType(): ContractTypeListType {
        if (this.currentContract) {
            return this.currentContract.type;
        }
        return this._contractDraftType;
    }

    @computed get fetchAttachments(): IAttachmentModel[] {
        return this.currentContract ? this.currentContract.attachments : [];
    }

    @computed get isExpired(): boolean {
        return this.currentContract.status === 'expired';
    }

    @computed get isFuture(): boolean {
        return this.currentContract.status === 'future';
    }

    @computed get isRenewableContractType(): boolean {
        return this.currentContract.type !== 'pay_as_you_go';
    }

    @computed get isReadyForRenew(): boolean {
        return !this.currentContract.endDate || moment(this.currentContract.endDate).diff(moment()) < 7776000000;
    }

    @computed get isActive(): boolean {
        return this.currentContract.status === 'active';
    }

    @computed get expensesTotal(): string {
        if (!this.currentContract) {
            return 'Not set';
        }
        return this.currentContract ? this.currentContract.expenses.reduce((prev, curr) => prev + Number(curr.cost), 0).toFixed(2) : ''
    }

    @computed get expenses(): IExpenseModel[] {
        return this.currentContract ? this.currentContract.expenses : [];
    }

    @computed get canEdit() {
        if (!this.currentContract) {
            return true;
        }
        return this.isActive || this.isExpired || this.isFuture;
    }

    /**
     * I need this getter (Kate)
     */
    @computed get canChangeTimeMinStep(): boolean {
        if (!this.canEdit) {
            return false;
        }
        if (!this.currentContract) {
            return true;
        }
        return this.currentContract.canChangeTimeMinStep;
    }

    @action updateContractDraftType = (type: ContractTypeListType): void => {
        this.currentContract = null;
        this._contractDraftType = type;
    };

    @action toggleLoading = (): void => {
        this.isLoading = !this.isLoading;
    };

    @action fetchItem = (id) => {
        this.toggleLoading();
        return this.apiClient.fetchContract(id).then(action("fetch contract ok", (data) => {
            this.currentContract = data;
            this.toggleLoading();
        }));
    };

    @action fetchCollection = (query): void => {
        this.isLoading = true;
        this.apiClient.contractCollectionFetch({params: query}).then(
            action("fetchCollection ok", response => {
                this.collectionResponse = response;
                this.isLoading = false;
            })
        ).catch(action("fetchCollection failed", e => {
            this.isLoading = false;
        }));
    };

    @action updateContract = async (values): Promise<SubmissionErrors | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.contractUpdate(idFromEntity(this.currentContract), hydrateInitialValues(this.currentContract, {
            ...values,
            type: this.contractType,
            ticketsNumber: values.ticketsNumber ? Number(values.ticketsNumber) : null,
            hoursNumber: values.hoursNumber ? Number(values.hoursNumber) : null,
            timeMinStep: values.timeMinStep ? Number(values.timeMinStep) : null,
        })));
        return runInAction("updateContract ok", () => {
            if (!errors) {
                this.currentContract = response;
                this.uiBus.notify("Contract updated", "success");
            }
            return errors;
        });
    };

    @action createContract = async (values): Promise<SubmissionErrors | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.contractCreate({
            ...values,
            ticketsNumber: values.ticketsNumber ? Number(values.ticketsNumber) : null,
            hoursNumber: values.hoursNumber ? Number(values.hoursNumber) : null,
            timeMinStep: values.timeMinStep ? Number(values.timeMinStep) : null,
            type: this.contractType
        }));
        return runInAction("createContract ok", () => {
            if (!errors) {
                this.currentContract = response;
                this.uiBus.notify("Contract created", "success");
            }
            return errors;
        });
    };

    @action renewContract = (e) => {
        this.uiBus.confirm("Create renewal quote", e).then(action("confirm ok", () => {
            this.isLoading = true;
            this.apiClient.contractRenew(this.currentContract['@id']).then(action("renewContract ok", (res) => {
                this.routing.history.push({pathname: `/opportunities/${idFromIri(res['@id'])}`});
            })).catch(action("renewContract failed", e => {
                this.uiBus.notify(e.response['data']['hydra:description'], "warning");
            })).finally(action("renewContract end", () => {
                this.isLoading = false;
            }));
        }))
    };

    @action disassembleContract = (e) => {
        this.uiBus.confirm("Disassemble contract?", e).then(action("Disassemble ok", () => {

            this.isLoading = true;
            this.apiClient.contractDisassemble(idFromEntity(this.currentContract)).then(action("disassembleContract ok", (res) => {
                this.currentContract = res;
                this.isLoading = false;
                this.uiBus.notify("Contract disassembled", "info");
            })).catch(action("Disassemble failed", e => {
                this.isLoading = false;
                this.uiBus.notify("Something went wrong", "warning");
            }));
        }))
    };

    @action saveAttachments = (files: FormData): void => {
        this.attachmentManager.persist(this.apiClient.contractsAttachmentsUpdate(this.currentContract.id, files)).then(action("saveAttachments ok", (res) => {
            this.currentContract.attachments = res;
        }))
    };

    canRemoveAttachment(attachment: IAttachmentModel): boolean {
        return this.canEdit;
    }

    @action removeAttachment = (item: IAttachmentModel): void => {
        this.attachmentManager.remove(item).then(action("removeAttachment ok", () => {
            this.currentContract.attachments = this.currentContract.attachments.filter(a => a['@id'] !== item['@id']);
        }));
    };

    @action saveExceededTicketsData = (values: ContractFullModel): void => {
        values.exceededTickets.forEach(async (ticket) => {
            if (ticket.additionalValue || ticket.takeFromNextPeriod) {
                await this.apiClient.contractUpdateExceededTicket(ticket["@id"], ticket);
            }
        });
        this.fetchItem(this.currentContract.id).then(() => {
            this.clearContextPlace();
            this.uiBus.notify(`Exceeded tickets data was updated`, "success");
        })
    }

    @action saveExceededHoursData = (values: ContractFullModel): void => {
        values.exceededHours.forEach(async (hour) => {
            if (hour.additionalValue || hour.takeFromNextPeriod) {
                await this.apiClient.contractUpdateExceededHour(hour["@id"], hour);
            }
        });
        this.fetchItem(this.currentContract.id).then(() => {
            this.clearContextPlace();
            this.uiBus.notify(`Exceeded hours data was updated`, "success");
        })
    }

    expireTime(endDate, durationInDays): number {
        if (!endDate) {
            return durationInDays
        }

        const date = new Date(endDate).getTime() - new Date().getTime();
        if (date <= 0) {
            return 0
        }

        return Math.floor(date / 1000 / 60 / 60 / 24);
    };

    durationStatus(endDate, durationInDays) {
        if (!endDate) {
            return 'green'
        }
        const timeLeft = this.expireTime(endDate, durationInDays);

        if (timeLeft >= 182) return 'green';
        else if (timeLeft < 182 && timeLeft > 70) return 'orange';
        else if (timeLeft <= 70) return 'red';
    }

    @action clearContextPlace = () => {
        this.contextPlace = null;
    };

    @action public changeContextPlace = (place: ContactStoreContextPlace) => {
        this.contextPlace = place;
    };

    @action addExpense = async (data): Promise<SubmissionErrors | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.expenseCreateForContract({
            ...data,
            contract: this.currentContract['@id']
        }));
        if (errors) {
            return errors
        } else {
            runInAction("addExpense ok", () => {
                this.currentContract.expenses.push(response);
                this.clearContextPlace();
                this.uiBus.notify(`Contact #${idFromEntity(this.currentContract)} updated`, "success");
            });
        }
    };

    @action expenseUpdate = async (iri: Iri, data: ExpenseUpdateInput): Promise<SubmissionErrors | null> => {
        const {errors} = await handleFormSubmit(this.apiClient.expenseUpdate(iri, data));
        if (errors) {
            return errors;
        } else {
            runInAction("expenseUpdate ok", () => {
                this.fetchItem(this.currentContract.id).then(() => {
                    this.clearContextPlace();
                    this.uiBus.notify(`Contact #${idFromEntity(this.currentContract)} updated`, "success");
                })
            });
        }
    }


    @action goAddExpense = () => {
        this.changeContextPlace('expenses');
    }

    @action removeExpense = async (iri: Iri) => {
        const {errors} = await handleFormSubmit(this.apiClient.expenseDelete(iri));
        if (errors) {
            return errors;
        } else {
            runInAction("expenseUpdate ok", () => {
                this.fetchItem(this.currentContract.id).then(() => {
                    this.uiBus.notify(`Contact #${idFromEntity(this.currentContract)} updated`, "success");
                })
            });
        }
    }
}
