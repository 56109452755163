import {action, computed, observable, runInAction} from "mobx";
import remotedev from 'mobx-remotedev';
import ApiClient from "../ApiClient";
import {CollectionResponseType, GridResourceCollectionInterface} from "../interfaces/GridResourceCollectionInterface";
import {handleFormSubmit, hydrateInitialValues} from "../../utils/form/handleFormSubmit";
import {FileAttachmentsInterface} from "../interfaces/FileAttachmentsInterface";
import {idFromEntity} from "../../utils/iri";
import UiBus from "../service/UiBus";
import AttachmentManager from "../service/AttachmentManager";
import {SubmissionErrors} from "final-form";
import {IAttachmentModel, CollapsedSystemModel, SystemFullModel} from "../../utils/models";


@remotedev({global: true})
export class SystemStore implements GridResourceCollectionInterface<SystemFullModel>, FileAttachmentsInterface {
    @observable collectionResponse: CollectionResponseType<SystemFullModel> | null;
    @observable isLoading: boolean = false;
    @observable currentSystem: SystemFullModel|null;
    @observable currentCollapsedSystem: CollapsedSystemModel|null;
    private attachmentManager: AttachmentManager;

    constructor(private apiClient: ApiClient, private uiBus: UiBus) {
        this.attachmentManager = new AttachmentManager(apiClient, this, uiBus);
    }
    getEntityName: 'System';
    @computed get canEdit() {
        if (!this.currentSystem || !this.currentSystem.contract) {
            return true;
        }
        return this.currentSystem.contract.status === 'active'
            || this.currentSystem.contract.status === 'expired'
            || this.currentSystem.contract.status === 'future';
    }

    @computed get fetchAttachments(): IAttachmentModel[] {
        return this.currentSystem ? this.currentSystem.attachments : [];
    }

    @action toggleLoading = (): void => {
        this.isLoading = !this.isLoading;
    };

    @action create = async (values): Promise<SubmissionErrors | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.systemCreate(values));
        return runInAction("handleCreate ok", () => {
            if (response) {
                this.currentSystem = response;
                this.uiBus.notify("System created", "success");
            }
            return errors;
        });

    };

    @action fetchCollection = (params): void => {
        this.isLoading = true;
        this.apiClient.systemCollectionFetch(params).then(
            action("fetchCollection ok", response => {
                this.collectionResponse = response;
                this.isLoading = false;
            })
        ).catch(action("fetchCollection failed", e => {
            this.isLoading = false;
        }));
    }

    @action update = async (values: Object): Promise<SubmissionErrors | null> => {
        let newValues = hydrateInitialValues(this.currentSystem, values);
        const oldContract = newValues['contract'];
        if (oldContract && typeof oldContract === 'object') {
            newValues['contract'] = oldContract['@id'];
        }
        const {response, errors} = await handleFormSubmit(this.apiClient.systemUpdate(idFromEntity(this.currentSystem), newValues));
        return runInAction("handleSystemUpdate ok", () => {
            if (!errors) {
                this.currentSystem = response;
                this.currentSystem.contract = oldContract;
                this.uiBus.notify("System info updated", "success")
            }
            return errors;
        });
    };

    @action updateSystemWithContract = async (id: number, contractId: string, values: Object): Promise<SubmissionErrors | null> => {
        values['contract'] = contractId;
        const {response, errors} = await handleFormSubmit(this.apiClient.systemUpdate(id, values));
        return runInAction("updateSystemWithContract ok", () => {
            if (!errors) {
                this.currentSystem = response;
                this.uiBus.notify("Contract system updated", "success")
            }
            return errors;
        });
    };

    @action fetchItem = (id) => {
        this.isLoading = true;
        return this.apiClient.systemItemFetch(id).then(
            action("fetchItem ok", response => {
                this.currentSystem = response;
                this.currentCollapsedSystem = null;
                this.isLoading = false;
            })
        ).catch(action("fetchItem failed", e => {
            this.isLoading = false;
        }));
    }

    @action fetchCollapsed = (id) => {
        this.isLoading = true;
        return this.apiClient.systemCollapsedItemFetch(id).then(
            action("fetchCollapsed ok", response => {
                this.currentCollapsedSystem = response;
                this.isLoading = false;
            })
        ).catch(action("fetchCollapsed failed", e => {
            this.isLoading = false;
        }));
    }

    @action saveAttachments = (files: FormData): void => {
        this.attachmentManager.persist(this.apiClient.systemsAttachmentsUpdate(idFromEntity(this.currentSystem), files)).then(action("saveAttachments ok", (res) => {
            this.currentSystem.attachments = res;
        }))
    }

    canRemoveAttachment(attachment: IAttachmentModel): boolean {
        return true;
    }

    @action removeAttachment = (item: IAttachmentModel): void => {
        this.attachmentManager.remove(item).then(action("removeAttachment ok", () => {
            this.currentSystem.attachments = this.currentSystem.attachments.filter(a => a['@id'] !== item['@id']);
        }));
    }
}
