import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {useStore} from "../../context";
import Icon from '@mdi/react';
import {mdiCancel, mdiClose} from "@mdi/js";
import {useHistory} from "react-router";
import {IFilterSettings,} from "../../utils/models";
import classNames from 'classnames';

const SavedFilterSegments = observer(() => {
    const {filterStore} = useStore();
    const history = useHistory();
    const [currentSegment, setCurrentSegment] = useState<IFilterSettings>({savedSegments: []});

    useEffect(() => {
        if (filterStore.savedFilterSegments) {
            setCurrentSegment(filterStore.savedFilterSegments[filterStore.getPageName])
        }
    }, [filterStore.savedFilterSegments]);

    return (
        <div className={'saved-segments'}>
            {(currentSegment && currentSegment.savedSegments.length > 0 ) && currentSegment.savedSegments.map((segment, index) => {
                const segmentItemClasses = classNames({
                    'saved-segments__item': true,
                    'active': filterStore.lastActiveSegment?.id === segment.id
                });
                return (
                    <div className={segmentItemClasses} key={segment.id} onClick={() => {
                        if (filterStore.lastActiveSegment && segment.id === filterStore.lastActiveSegment.id) {
                            filterStore.setContextPlace("update_segment");
                        }
                        filterStore.activateSelectedSegment(segment);
                        history.push({search: filterStore.lastActiveSegment.filter});
                    }}>
                        {segment.label}

                        <span onClick={(e) => {
                            e.stopPropagation();
                            filterStore.removeFilterSegment(index)
                        }}>
                             <Icon path={mdiClose}/>
                        </span>
                    </div>
                )
            })}

            {
                (currentSegment && currentSegment.savedSegments.length > 0) &&
                <span className={'secondaryBtn clear-filters'} onClick={() => {
                    history.push({search: ''})
                    filterStore.clearLastActiveSegment()
                }}>
                    Clear filter
                    <Icon path={mdiCancel}/>
                </span>
            }
        </div>
    );
});

export default SavedFilterSegments
