export type ActivityReadModelOutput = {
  id: string;
  username: string;
  messageDetails: string;
  messagePreview: string;
  createdAt: string;
  type: ActivityTypeEnum;
  isInternal: boolean;
  attachments: IncomingAttachmentItemDto[];
};

export enum ActivityTypeEnum {
  INFO = 'info',
  EDIT = 'edit',
  COMMENT = 'comment',
  ATTACHMENT = 'attachment',
  SUPPORT_REQUEST_MESSAGE = 'support_request_message',
}

export type IncomingAttachmentItemDto = {
  id: string;
  link: string;
  name: string;
  contentType: string;
};

export type SupportRequestCustomerOutput = {
  name: string | null;
  email: string | null;
  code: string | null;
  customerRef: string | null;
};

export type SupportRequestOutput = {
  id: string;
  subject: string;
  createdAt: string;
  customer: SupportRequestCustomerOutput;
  messages: ActivityReadModelOutput[];
  status: string;
  isImportant: boolean;
  ticketRef: string | null;
  relatedSystem: SupportRequestSystemOutput | null;
  relatedProject: SupportRequestProjectOutput | null;
  deadline: string | null;
  firstReplyAt: string | null;
};

export type SupportRequestProjectOutput = {
  id: string;
  name: string | null;
  contractStatus: string | null;
};

export type SupportRequestSystemOutput = {
  id: string;
  contractStatus: string | null;
  modelNumber: string | null;
};
