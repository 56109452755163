import React from 'react';
import Categories from './../../components/grid/Categories';
import {useStore} from "../../context";
import ResourceGridView from "../../components/grid/ResourceGridView";
import StandalonePopover from "../../components/ui/StandalonePopover";
import PartTypeSelectForm from "./PartTypeSelectForm";
import {observer} from "mobx-react";
import {mdiPlus} from "@mdi/js";
import PartTypeDetailsForm from "./DetailsForm/PartTypeDetailsForm";
import {useHistory} from "react-router";
import {idFromEntity} from "../../utils/iri";
import {stringify} from "querystring";

import {Media} from 'react-breakpoints'
import FloatButtons from "../../components/ui/FloatButtons";
import {PartFullModel} from "../../utils/models";

const PartsGrid = observer(() => {
    const {partStore} = useStore();
    const {push} = useHistory();

    return (
        <div className='dashboard part-grid'>
            {partStore.currentPlace === 'part_type_select' && <StandalonePopover
                classNames={'scrollable'}
                title={'Add product | select type'}
                closePopover={partStore.clearPlace}>
                <PartTypeSelectForm partStore={partStore}/>
            </StandalonePopover>}
            {partStore.currentPlace === 'part_second_step' && <StandalonePopover
                classNames={'scrollable'}
                title={`Create part | ${partStore.partType}`}
                closePopover={partStore.clearPlace}
                size={'ld'}
                columnCount={3}>
                <PartTypeDetailsForm isGranted={true} catalogueMode={true} partStore={partStore} mode={"CREATE"} standalone={true}/>
            </StandalonePopover>}

            <Media>
                {({breakpoints, currentBreakpoint}) => {
                    return breakpoints[currentBreakpoint] > breakpoints.desktop &&
                        <Categories/>
                }}
            </Media>

            <Media>
                {({breakpoints, currentBreakpoint}) => {
                    return breakpoints[currentBreakpoint] < breakpoints.desktop &&
                        <FloatButtons type={"normal"}> <Categories/></FloatButtons>
                }}
            </Media>

            <ResourceGridView<PartFullModel>
                title={'Products'}
                columns={[
                    {
                        name: 'Name',
                        value: (item) => (
                            <div>
                                <div className="title">{item.name}</div>
                                <div className="other-info">
                                    {item['@id'].split('/').reverse()[0]}
                                </div>
                            </div>
                        )
                    },
                    {
                        name: 'Model',
                        value: (item) => (
                            <div>
                                {item.modelNumber ? item.modelNumber : <span className={'not-available'}>N/A</span>}
                            </div>
                        )
                    },
                    {
                        name: 'Part number',
                        value: (item) => item.partNumber
                    }
                ]}
                filterKeys={{
                    modelNumber: 'Model number',
                    partNumber: 'Part number',
                    type: 'Type',
                    status: 'Status',
                    'partRealizations.serialNumber': 'Serial number'
                }}
                onRowClick={(item) => {
                    if (!item.customerId) {
                        partStore.goUpdate(item, true)
                    } else {
                        push({
                            pathname: `/customers/${item.customerId}`,
                            search: stringify({partId: idFromEntity(item)})
                        })
                    }

                }}
                panels={() => {
                    return [{
                        onClick: partStore.goCreate,
                        label: "Create",
                        classes: "tertiaryBtn",
                        icon: mdiPlus
                    }]
                }}
                gridResource={partStore}
                tableClass={'products'}
            />
        </div>
    );
});

export default PartsGrid
