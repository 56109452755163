import React from 'react';
import {Field, Form} from 'react-final-form'
import {Input} from './../../../components/ui';
import {FinalButtonSet} from "../../../components/ui/form";

type ManufacturerFormProps = { initialValues, onSubmit }
const ManufacturerForm = ({initialValues = {}, onSubmit}: ManufacturerFormProps) => {
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
        >
            {({handleSubmit, pristine, submitting}) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        name='name'
                        label='Name'
                        component={Input}
                    />

                    <FinalButtonSet submitting={submitting} pristine={pristine} mode={"RELATIVE"}/>
                </form>
            )}
        </Form>
    )
}


export default ManufacturerForm;
