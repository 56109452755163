import React, {useEffect} from "react";
import {ActivitiesLogSupportInterface} from "../activity_log/ActivitiesLogInterface";
import {Field, Form} from "react-final-form";
import {ActivityLogMessages} from "../activity_log/ActivityLogMessages";
import {sortActivities} from "../activity_log/formatter";
import {useStore} from "../../context";
import {observer} from "mobx-react";
import {Button, Textarea} from "../../components/ui";
import UserAvatar from "../../components/ui/UserAvatar";
import {Intent, Spinner} from "@blueprintjs/core";

type ActivityLogType = {
    activitiesLog: ActivitiesLogSupportInterface,
    canEdit?: boolean
    showTitle?: boolean
}

export const ActivityLogSupportRequest = observer((props: ActivityLogType) => {
    const {activitiesLog, canEdit = true, showTitle = false} = props;
    const {authStore} = useStore();

    useEffect(() => {
        activitiesLog?.fetchActivities();
    }, [activitiesLog.idForActivityLogParent]);

    return (
        <div className={'activity-log'}>
            {showTitle && <h2>Activity log</h2>}
            <div className={'support-grid'}>
                <div className={'activity-log support'}>
                    {/*{canEdit &&*/}
                    {/*<Form onSubmit={form => activitiesLog.createActivities({body: form.body, isInternal: false})}>*/}
                    {/*    {({handleSubmit, form, submitting, values}) =>*/}
                    {/*        <>*/}
                    {/*            <div className={'activity-log-send'}>*/}
                    {/*                <UserAvatar name={authStore.username} full={false}/>*/}
                    {/*                <div style={{fontSize: '22px'}}>Reply to client</div>*/}
                    {/*            </div>*/}
                    {/*            <form onSubmit={handleSubmit} className={'add-comment-form support'}>*/}
                    {/*                <Field*/}
                    {/*                    name={'body'}*/}
                    {/*                    placeholder={"Add reply"}*/}
                    {/*                    component={Textarea}*/}
                    {/*                />*/}
                    {/*                <Button type={'submit'} onClick={async (e) => {*/}
                    {/*                    if (!submitting && values.body?.length > 0) {*/}
                    {/*                        e.preventDefault();*/}
                    {/*                        return new UiBus().confirm("Are you sure you want to send a reply to the client? This reply can not be retracted once sent", e).then(() => form.submit().then(() => form.change('body', '')));*/}
                    {/*                    }*/}
                    {/*                }}>Send</Button>*/}
                    {/*            </form>*/}
                    {/*        </>*/}
                    {/*    }*/}
                    {/*</Form>*/}
                    {/*}*/}
                    <div className="activity-list">
                        {activitiesLog.loaderReply ? <Spinner intent={Intent.PRIMARY} size={20}/> :
                        <ActivityLogMessages
                            isSupportRequest={true}
                            activities={sortActivities(activitiesLog.activitiesMessagesReply)}/>
                        }
                    </div>
                </div>
                <div className={'activity-log support'}>
                    {canEdit &&
                    <Form onSubmit={form => activitiesLog.createActivities({body: form.body, isInternal: true})}
                          keepDirtyOnReinitialize={true}>
                        {({handleSubmit, form, submitting, values}) =>
                            <>
                                <div className={'activity-log-send'}>
                                    <UserAvatar name={authStore.username} full={false}/>
                                    <div style={{fontSize: '22px'}}>Notes</div>
                                </div>
                                <form className={'add-comment-form support'} onSubmit={handleSubmit}>
                                    <Field
                                        name={'body'}
                                        placeholder={"Add note"}
                                        component={Textarea}
                                    />
                                    <Button type={'submit'} onClick={async (e) => {
                                        if (!submitting && values.body?.length > 0) {
                                            e.preventDefault();
                                            form.submit().then(() => form.change('body', ''));
                                        }
                                    }}>Submit</Button>
                                </form>
                            </>
                        }
                    </Form>
                    }
                    <div className="activity-list">
                        {activitiesLog.loaderInternal ? <Spinner intent={Intent.PRIMARY} size={20}/> :
                        <ActivityLogMessages
                            forceActivityVisibleMode={'internal'}
                            isSupportRequest={true}
                            activities={sortActivities(activitiesLog.activitiesMessagesInternal)}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
})
