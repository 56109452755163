import React from 'react';

import {Field, Form} from "react-final-form";
import useDataListFetch from "../../utils/form/useDataListFetch";
import AutoSave from "../../utils/form/AutoSave";
import SelectButtons from "../../components/ui/form/SelectButtons";


type OpportunityAssignToSelectFormProps = {
    onFormSubmit,
    initialValues: { salesAgent }
}

const OpportunityAssignToSelectForm = ({onFormSubmit, initialValues}: OpportunityAssignToSelectFormProps) => {
    const usersDL = useDataListFetch('sales_agents+admins')

    return (
        <Form
            onSubmit={onFormSubmit}
            initialValues={initialValues}
        >
            {() => (
                <div>
                    <AutoSave save={onFormSubmit}/>
                    <Field
                        name='salesAgent'
                        {...usersDL}
                        component={SelectButtons}
                    />
                </div>
            )}
        </Form>
    );
}


export default OpportunityAssignToSelectForm;
