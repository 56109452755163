import React from 'react';
import ResourceGridView from "../../components/grid/ResourceGridView";
import {useStore} from "../../context";
import {useHistory} from "react-router-dom";
import {stringify} from "querystring";
import {idFromEntity} from "../../utils/iri";
import {ContractShortModel} from "../../utils/models";

const Contracts = ({children, ...props}) => {
    const {contractStore, authStore} = useStore();
    const history = useHistory();
    return (
        <div className='dashboard'>
            <ResourceGridView<ContractShortModel>
                onRowClick={(item) => {
                    history.push({
                        pathname: `/customers/${idFromEntity(item.customer)}`,
                        search: stringify({
                            contractId: idFromEntity(item),
                        }),
                    });
                }}
                filterKeys={{
                    id: 'Id',
                    customerPurchaseOrder: 'PO',
                    contractNumber: 'Contract number',
                    'salesPerson.id': 'Account manager',
                    customer: 'Company name'
                }}
                gridResource={contractStore}
                columns={[
                    {
                        name: 'companyName',
                        label: 'Company name',
                        value: (item) => (
                            <div>
                                <div className="title">
                                    {item.customer ? item.customer.name : ''}
                                </div>
                                <div className="other-info">
                                    {item['@id'].split('/').reverse()[0]}
                                    {item.contractNumber ? <span className={'dot'}>•</span> : ''}
                                    {item.contractNumber}
                                </div>
                            </div>
                        ),
                    },
                    {
                        name: 'Total value',
                        value: (item) => (<div>{item.totalValue ? parseFloat(item.totalValue).toFixed(2) :
                            <span className={'not-available'}>N/A</span>}</div>),
                        visible: authStore.isGranted('ROLE_SHOW_CONTRACT_VALUE'),
                    },
                    {
                        name: 'Start date',
                        value: (item) => (<div>{item.startDate ? new Date(item.startDate).toDateString() :
                            <span className={'not-available'}>N/A</span>}</div>),
                    },
                    {
                        name: 'End date',
                        value: (item) => (<div>{item.endDate ? new Date(item.endDate).toDateString() :
                            <span className={'not-available'}>N/A</span>}</div>),
                    },
                    {
                        name: 'Customer PO',
                        value: (item) => (<div>{item.customerPurchaseOrder ? item.customerPurchaseOrder :
                            <span className={'not-available'}>N/A</span>}</div>),
                    },
                ]}
                tableClass={'contracts'}
            />
        </div>
    );
};

export default Contracts;
