import {action, computed, observable} from "mobx";
import {RouterStore} from "mobx-react-router";
import {clearFinalFormValues} from "../../utils/clearFinalFormValues";
import {serializeFilters} from "../../utils/filters";
import {AuthStore} from "./AuthStore";
import ApiClient from "../ApiClient";
import {IFilterSettings, SavedSegmentsProps} from "../../utils/models";
import _ from "underscore";
import {formatDatePickerStrings} from "../../utils/formatDatePickerStrings";
import {v4 as uuid} from 'uuid';
import {parse} from "querystring";
import UiBus from "../service/UiBus";

type PageIntersection = 'opportunities' |
    'tickets' |
    'customers' |
    'contracts' |
    'system' |
    'projects' |
    'products' |
    'inventory' |
    'sales' |
    'purchase' |
    'suppliers' |
    'contact_profit'

type ContextPlaceTypes = 'filters_form' | 'grid' | 'update_segment'

export class FilterStore {
    @observable savedFilterSegments: IFilterSettings | null;
    @observable lastActiveSegment: SavedSegmentsProps;
    @observable contextPlace: ContextPlaceTypes = 'grid';
    @observable query = parse(this.routerStore.location.search.slice(1));

    public currentTab = 'all';
    private dateFields: Array<string> = ['createdAt', 'updatedAt', 'startDate', 'endDate', 'closeAt', 'lastActivityTime'];
    private tabs = {
        'opportunities': {
            all: {label: 'All', additionalQuery: {}},
            quoted: {label: 'Working', additionalQuery: {status: 'working'}},
            converted: {label: 'Successful', additionalQuery: {status: 'successful'}},
            refused: {label: 'Unsuccessful', additionalQuery: {status: 'unsuccessful'}},
        },
        'systems': {
            all: {label: 'All', additionalQuery: {}},
            assigned: {label: 'Assigned', additionalQuery: {is_null_contract: false}},
            unassigned: {label: 'Unassigned', additionalQuery: {is_null_contract: true}}
        }
    };

    private userId: number = this.authStore.userId;

    constructor(private apiClient: ApiClient, private authStore: AuthStore, private routerStore: RouterStore, private uiBus:UiBus) {
    }

    load() {
        this.apiClient.updateSavedSegments(this.authStore.userId, {}).then(action((res) => {
            this.savedFilterSegments = res;
        }))

        if (localStorage.getItem('lastActiveSegment')) {
            this.setLastActiveSegment(JSON.parse(localStorage.getItem('lastActiveSegment')))
        }
    }

    @action updateStoreQuery = () => {
        this.query = parse(this.routerStore.location.search.slice(1));
    };

    @action setContextPlace = (value: ContextPlaceTypes) => {
        this.contextPlace = value;
    };

    @action setLastActiveSegment = (segment: SavedSegmentsProps | any): void => {
        this.lastActiveSegment = {
            ...segment,
            page: this.getPageName
        };
        if(segment) {
            localStorage.setItem('lastActiveSegment', JSON.stringify(this.lastActiveSegment));
        }
    };

    @action clearLastActiveSegment = () => {
        this.lastActiveSegment = null
        localStorage.removeItem('lastActiveSegment')
    };

    @action setCurrentTab = (tab) => {
        this.currentTab = tab
    };

    @action addNewFilterSegment = (name: string, obj): void => {
        const filterStr = this.handleTransformationFilters(obj);
        const id = uuid();

        const newSegment = {
            ...this.savedFilterSegments,
            [this.getPageName]: {
                savedSegments: this.savedFilterSegments[this.getPageName]?.savedSegments ? [
                    ...this.savedFilterSegments[this.getPageName]?.savedSegments,
                    {
                        id: id,
                        label: name,
                        filter: serializeFilters(clearFinalFormValues(filterStr))
                    }
                ] : [
                    {
                        id: id,
                        label: name,
                        filter: serializeFilters(clearFinalFormValues(filterStr))
                    }
                ]
            }
        };
        this.apiClient.updateSavedSegments(this.userId, {filterSettings: newSegment}).then(action((res) => {
            this.savedFilterSegments = res;

            this.setLastActiveSegment({
                label: name,
                filter: serializeFilters(filterStr),
                id: id
            })

            this.routerStore.history.push({search: this.lastActiveSegment.filter})
        }));
    };

    @action removeFilterSegment = (index): void => {
        const newSegment = {
            ...this.savedFilterSegments,
            [this.getPageName]: {
                ...this.savedFilterSegments[this.getPageName],
                savedSegments: this.savedFilterSegments[this.getPageName].savedSegments
                    .filter((segment, segmentIndex) => segmentIndex !== index ? segment : null),
            }
        };

        this.uiBus.confirm('Remove filters segment?').then(action('removefilterSegments', () => {
            this.apiClient.updateSavedSegments(this.userId, {filterSettings: newSegment}).then(action((res) => {
                this.savedFilterSegments = res
            }));
        }))
    };

    @action updateFilterSegment = (filters) => {
        const newQ = this.handleTransformationFilters(filters);
        const oldFilteredSegments = this.savedFilterSegments[this.getPageName]
            .savedSegments.filter((item: SavedSegmentsProps) => item.id !== this.lastActiveSegment.id);

        const newUpdatedSegments = {
            ...this.savedFilterSegments,
            [this.getPageName]: {
                savedSegments: [
                    ...oldFilteredSegments,
                    {
                        id: this.lastActiveSegment.id,
                        filter: serializeFilters(newQ),
                        label: this.lastActiveSegment.label
                    }
                ]
            }
        };

        this.apiClient.updateSavedSegments(this.userId, {filterSettings: newUpdatedSegments}).then(action((res) => {
            this.savedFilterSegments = res;

            const segment:SavedSegmentsProps = {
                label: this.lastActiveSegment.label,
                filter: serializeFilters(newQ),
                id: this.lastActiveSegment.id
            };
            this.setLastActiveSegment(segment);
            this.routerStore.history.push({search: this.lastActiveSegment.filter})
        }));
    };

    @action activateSelectedSegment = (segment: SavedSegmentsProps) => {
        const pageWithTabs = Object.keys(this.tabs);
        if (!pageWithTabs.includes(this.getPageName)) {
            this.routerStore.history.push({search: segment.filter});
            this.setLastActiveSegment(segment)
        } else {
            const serialize = serializeFilters({
                activeTab: this.currentTab,
                ...this.tabs[this.getPageName][this.currentTab]?.additionalQuery,
            });
            this.routerStore.history.push({search: serialize});
            this.setLastActiveSegment({...segment, filter: serialize + '&' + segment.filter})
        }
    };

    @action applyFilter = (segment) => {
        const newQ = this.handleTransformationFilters(segment);
        const pageWithTabs = Object.keys(this.tabs);
        if (pageWithTabs.includes(this.getPageName)) {
            const serialize = serializeFilters({
                activeTab: this.currentTab,
                ...this.tabs[this.getPageName][this.currentTab]?.additionalQuery,
                ...newQ
            });
            this.routerStore.history.push({search: serialize});
            this.setLastActiveSegment({label: 'unsaved', filter: serialize, id: 0})
        } else {
            this.routerStore.history.push({search: serializeFilters(newQ)});
            this.setLastActiveSegment({label: 'unsaved', filter: serializeFilters(newQ), id: 0})
        }
    };

    @action handleTransformationFilters = (values) => {
        let cleanValues: any;
        cleanValues = _.keys(values).filter(key => values[key]).reduce((prev, key) => {
            const filterKey = values[key];

            if (this.dateFields.includes(key) && !filterKey.before && !filterKey.after) {
                if (typeof filterKey === 'object') {
                    const keys = Object.keys(filterKey);
                    return {
                        ...prev,
                        [key + `[${keys}]`]: 'true'
                    };
                }
                return {
                    ...prev,
                    [key + `[${formatDatePickerStrings(filterKey)}]`]: 'true'
                };
            }

            if (this.dateFields.includes(key) && typeof filterKey !== 'string') {
                return {
                    ...prev,
                    [key + '[before]']: filterKey.before,
                    [key + '[after]']: filterKey.after,
                };
            } else if (key === 'rate') {

                return {
                    ...prev,
                    [key + '[lte]']: filterKey.lte,
                    [key + '[gte]']: filterKey.gte,
                };
            } else if (typeof values[key] === 'object') {
                const foundDateField = Object.keys(values[key]).find(innerKey => this.dateFields.includes(innerKey));
                if (foundDateField && values[key][foundDateField]) {
                    values[key][foundDateField + '[before]'] = values[key][foundDateField].before;
                    values[key][foundDateField + '[after]'] = values[key][foundDateField].after;
                    values[key][foundDateField] = undefined;
                }
            }
            return {
                ...prev,
                [key]: filterKey,
            };

        }, {});
        return cleanValues;
    };

    @action onSubmitFilterForm = (filters) => {
        if (this.contextPlace === 'filters_form') {
            this.applyFilter(filters)
        } else if (this.contextPlace === 'update_segment') {
            this.updateFilterSegment(filters)
        }
        this.setContextPlace('grid');
    };

    @computed get activeTab(): string | null {
        if (this.query['activeTab']) {
            return this.query['activeTab'] as string;
        }
        return this.tabs[this.getPageName] ? Object.keys(this.tabs[this.getPageName])[0] : null;
    }

    @computed get tabsListPerPage() {
        return this.tabs[this.getPageName];
    }

    @computed get isUnsavedActiveFilters() {
        const lastActiveSegmentObj = JSON.parse(localStorage.getItem('lastActiveSegment'));
        return lastActiveSegmentObj?.label === 'unsaved';
    }

    @computed get getPageName(): PageIntersection {
        return this.routerStore.location.pathname.split('/')[1] as PageIntersection
    }
}
