import React from 'react';


import {observer} from "mobx-react";
import {useStore} from "../../context";
import {Input, Select, Textarea} from "../../components/ui";
import {Field, Form} from "react-final-form";
import FileGallery from "../../components/ui/widgets/FileGallery";
import useDataListFetch from "../../utils/form/useDataListFetch";
import {FinalAutocomplete, FinalButtonSet} from "../../components/ui/form";
import {CustomerFullModel, IItemOpportunityModel} from "../../utils/models";


const OpportunityDetailsForm = observer(() => {
    const {opportunityStore} = useStore();
    const opportunityTypes = useDataListFetch("opportunity_types");
    const behalfDL = useDataListFetch("partners");

    return <Form<IItemOpportunityModel> onSubmit={opportunityStore.updateOrCreate}
                                        initialValues={opportunityStore.currentOpportunity}
                                        render={({handleSubmit, submitting, pristine, form, values}) => {
                                            return (
                                                <React.Fragment>
                                                    <form className={'opportunity-form'}
                                                          id={"opportunities-details-form"}
                                                          onSubmit={handleSubmit}>
                                                        <Field
                                                            name="title"
                                                            label="Title"
                                                            canEdit={opportunityStore.canEdit}
                                                            component={Input}
                                                        />
                                                        <div>
                                                            <h3>Company details</h3>
                                                            <Field
                                                                name="customerName"
                                                                label="Customer name"
                                                                required
                                                                canEdit={opportunityStore.canEdit}
                                                                clearOnComplete={false}
                                                                fieldName={"name"}
                                                                scope={"customers"}
                                                                component={FinalAutocomplete}
                                                                onComplete={(item: CustomerFullModel | string) => {
                                                                    if (typeof item === 'object') {
                                                                        form.change("customerName", item.name);
                                                                        form.change("customerEmail", item.email);
                                                                        form.change("contactName", item.contactName);
                                                                        form.change("onBehalf", item.onBehalf ? item.onBehalf['@id'] : null);
                                                                        form.change("customer", item['@id']);
                                                                    } else {
                                                                        form.change("customerName", item);
                                                                    }
                                                                }}
                                                            />
                                                            <Field
                                                                name="customerEmail"
                                                                label="Customer email"
                                                                required
                                                                type={"email"}
                                                                canEdit={opportunityStore.canEdit && !values.customer}
                                                                component={Input}
                                                            />
                                                            <Field
                                                                name="contactName"
                                                                label="Contact name"
                                                                canEdit={opportunityStore.canEdit && !values.customer}
                                                                component={Input}
                                                            />
                                                            <Field
                                                                name="onBehalf"
                                                                label="On behalf"
                                                                canEdit={opportunityStore.canEdit && !values.customer}
                                                                component={Select}
                                                                {...behalfDL}
                                                            />
                                                        </div>
                                                        <div>
                                                            <h3>Opportunity details</h3>
                                                            <Field
                                                                name="source"
                                                                label="Source"
                                                                canEdit={opportunityStore.canEdit}
                                                                component={Input}
                                                            />
                                                            <Field
                                                                name="type"
                                                                label="Type"
                                                                component={Select}
                                                                canEdit={opportunityStore.canEdit}
                                                                {...opportunityTypes}
                                                            />
                                                            <Field
                                                                name="description"
                                                                label="Description"
                                                                component={Textarea}
                                                                canEdit={opportunityStore.canEdit}
                                                            />
                                                        </div>
                                                        <FinalButtonSet pristine={pristine} submitting={submitting}
                                                                        mode={opportunityStore.currentPlace === 'create' ? "RELATIVE" : "FIXED"}
                                                                        reset={form.reset}/>
                                                    </form>
                                                    {(opportunityStore.canEdit && opportunityStore.fetchAttachments.length) > 0 && (
                                                        <div className='custom-field'>
                                                            <label>Attachments: </label>
                                                            <FileGallery attachmentInterface={opportunityStore}/>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )
                                        }}/>
});


export default OpportunityDetailsForm;
