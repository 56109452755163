import React from 'react';
import ResourceGridView from './../../components/grid/ResourceGridView';
import * as cells from './../../components/ui/cells';

import {useStore} from "../../context";
import {observer} from "mobx-react";
import UserAvatar from "../../components/ui/UserAvatar";
import StandalonePopover from "../../components/ui/StandalonePopover";
import CreateTicketForm from "./CreateTicketForm";
import {TicketShortModel} from "../../utils/models";

const TicketsGrid = observer(() => {
    const {ticketStore} = useStore();


    return (
        <div className='dashboard'>
            {ticketStore.place === 'create' &&
            <StandalonePopover columnCount={1} title={"Create ticket"}
                               closePopover={ticketStore.clearPlace}
                               size={'bd'}>
                <CreateTicketForm createTicket={ticketStore.createTicket}/>
            </StandalonePopover>}
            <ResourceGridView<TicketShortModel>
                onRowClick={(item) => {
                    ticketStore.goUpdate(item.id)
                }}
                onCreate={() => {
                    ticketStore.goCreate()
                }}
                filterKeys={{
                    keyword: 'Keyword',
                    status: 'Status',
                    uuid: 'ID',
                    'system.modelNumber': 'System model',
                    'system.serialNumber': 'System',
                    'engineer.username': 'Engineer',
                    'ticket_customer': 'Customer name',
                    createdAt: 'Created at',
                    'name_partner': 'Partner',
                }}

                title={"Tickets"}
                columns={[
                    {
                        name: 'title',
                        label: 'Title',
                        value: (item) => (

                            <div key={item.id}>
                                <div className="title">
                                    {item.title}
                                </div>
                                <div className="other-info grid">
                                    {cells.statusCell(item)}
                                    <div className="status-col">
                                        {item['@id'].split('/').reverse()[0]}
                                    </div>
                                    <div className="grid-date">
                                        {item.createdAt ? <span className={'dot'}>•</span> : ''}
                                        {item.createdAt ? cells.dateAndTimeCell(item.createdAt) : ''}
                                    </div>
                                </div>
                            </div>
                        ),
                    },
                    {
                        name: 'companyName',
                        label: 'Company',
                        value: (item) => {
                            let companyName = 'N/A';
                            if (item.contract && item.contract.customer) {
                                companyName = item.contract.customer.name;
                            }
                            if (item.collapsedSystem && item.collapsedSystem.contract && item.collapsedSystem.contract.customer) {
                                companyName = item.collapsedSystem.contract.customer.name;
                            }
                            if (item.collapsedProject && item.collapsedProject.contract && item.collapsedProject.contract.customer) {
                                companyName = item.collapsedProject.contract.customer.name;
                            }
                            return (
                                <div><span className={companyName === 'N/A' ? 'not-available' : ''}>{companyName}</span>
                                </div>
                            );
                        },
                    },
                    {
                        name: 'system',
                        label: 'System model',
                        value: (item) => {
                            let modelNumber = '';
                            if (item.system) {
                                modelNumber = item.system.modelNumber
                            } else if (item.collapsedSystem) {
                                modelNumber = item.collapsedSystem.modelNumber
                            }
                            return (
                                <div>{modelNumber ? modelNumber : <span className={'not-available'}>N/A</span>}</div>
                            );
                        },
                    },
                    {
                        name: 'category',
                        label: 'Category',
                        value: (item) => {
                            return (<div>{item.category ? cells.categoryCell(item.category.name) :
                                <span className={'not-available'}>N/A</span>}</div>)
                        },
                    },
                    {
                        name: 'engineer',
                        label: 'Engineer name',
                        value: item => {
                            return (<div className={'engineer'}>

                                {item.engineer && item.engineer.username ?
                                    <UserAvatar name={item.engineer.username} full={true}/>
                                    :
                                    <span className={'not-available'}>N/A</span>}
                            </div>);
                        },
                    },
                ]}
                gridResource={ticketStore}
                tableClass={'tickets'}/>
        </div>
    );
});


export default TicketsGrid;
