import * as _ from 'underscore';


// Collapses all objects that contains @id
export const collapseObjectsWithId = (value: any, except: any = [], exceptedArray: boolean = false): any => {

    if (_.isArray(value)) {

        return value.reduce((prev: any, item: any) => {
            if (_.isDate(item)) {
                return [
                    ...prev,
                    item
                ];
            } else if (item['@id'] && !exceptedArray) {
                return [
                    ...prev,
                    item['@id']
                ];
            } else {
                return [
                    ...prev,
                    collapseObjectsWithId(item, except, _.isArray(item))
                ];
            }
        }, []);

    } else if (_.isObject(value)) {

        const keys = Object.keys(value);
        return keys.reduce((prev, key) => {
            if (_.isDate(value[key])) {
                return {
                    ...prev,
                    [key]: value[key]
                };
            } else if (value[key] && value[key]['@id'] && !except.includes(key)) {
                return {
                    ...prev,
                    [key]: value[key]['@id']
                };
            } else {
                return {
                    ...prev,
                    [key]: collapseObjectsWithId(value[key], except, (_.isArray(value[key]) && except.includes(key)))
                };
            }
        }, {});
    } else {
        return value;
    }
}

// Clear object from @id
export const clearObjectFromId = (value: any, except: any = [], excepted: boolean = false): any => {

    if (_.isArray(value)) {
        return (value as Array<any>).reduce((prev: Array<any>, item: any) => {

            if (_.isObject(item)) {
                return [
                    ...prev,
                    clearObjectFromId(item, except, excepted)
                ]
            } else {
                return [
                    ...prev,
                    item
                ]
            }

        }, []);
    } else {
        const keys = Object.keys(value);
        return keys.reduce((prev, key) => {

            if (key.indexOf('@') >= 0 && !(except.includes(key) || excepted)) {
                return {...prev};
            }

            if (_.isObject(value[key])) {
                return {
                    ...prev,
                    [key]: clearObjectFromId(value[key], except, except.includes(key))
                }
            } else {
                return {
                    ...prev,
                    [key]: value[key]
                }
            }

        }, {});
    }
}
