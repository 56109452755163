import React, {useState} from 'react';
import {QuoteProjectInfoModel} from "../../../utils/models";
import {FieldArrayRenderProps} from "react-final-form-arrays";
import {Button} from "@blueprintjs/core";
import Icon from '@mdi/react';
import {mdiClose} from "@mdi/js";

type QuoteProjectTimeEntryProps = {
    item: Partial<QuoteProjectInfoModel>,
    fields: FieldArrayRenderProps<Partial<QuoteProjectInfoModel>, any>,
    index: number
}

const QuoteProjectTimeEntry = ({
                                   fields,
                                   index,
                                   item,
                               }: QuoteProjectTimeEntryProps) => {
    const [numberValue, setNumberValue] = useState('');
    const [titleValue, setTitleValue] = useState('');
    const [spentTimeType, setSpentTimeType] = useState('remote');

    const handleSpentTimeToggle = (type) => {
        setSpentTimeType(type);
    };

    return (<div className={'project-time-entry'}>
        <input
            type={'number'}
            placeholder={'Time in minutes'}
            value={numberValue}
            onChange={(e) => {
                setNumberValue(e.target.value);
            }}
        />
        <input
            type={'text'}
            placeholder={'Ticket title'}
            value={titleValue}
            onChange={(e) => {
                setTitleValue(e.target.value);
            }}
        />

        <div className="three-columns">
            <button onClick={() => {
                handleSpentTimeToggle('remote');
            }} type="button" className={`tertiaryBtn ${'remote' === spentTimeType ? 'active' : ''}`}>Remote
            </button>
            <button onClick={() => {
                handleSpentTimeToggle('on_site');
            }} type="button" className={`tertiaryBtn ${'on_site' === spentTimeType ? 'active' : ''}`}>On site
            </button>
        </div>

        <Button className={'secondaryBtn'} onClick={(e) => {
            e.preventDefault();
            if (numberValue && titleValue) {
                let previousEntries = item.quoteProjectTimeEntries;
                previousEntries.push({
                    ticketTitle: titleValue,
                    hours: Math.floor(Number(numberValue) / 60),
                    minutes: Math.round(Number(numberValue) % 60),
                    spentTimeType: spentTimeType
                });
                fields.fields.update(index, {...item, quoteProjectTimeEntries: previousEntries});
                setNumberValue('');
                setTitleValue('');
                setSpentTimeType('remote');
            }
        }}>
            Add new time entry
        </Button>
        {item.quoteProjectTimeEntries && item.quoteProjectTimeEntries.map((timeEntry, timeEntryIndex) => {
            return (<div className={'other-info'} key={timeEntryIndex}>
                <span className={'name'}>{timeEntry.ticketTitle}</span>
                <span className={'dot'}>•</span>
                <span>{timeEntry.hours}h {timeEntry.minutes}m</span>

                <span className={'del'} onClick={() => {
                    let previousEntries = item.quoteProjectTimeEntries;
                    previousEntries.filter((entry, eIndex) => eIndex !== timeEntryIndex);
                    fields.fields.update(index, {...item, quoteProjectTimeEntries: previousEntries});
                }}>
                     <Icon path={mdiClose}/>
                </span>
            </div>);
        })}
        <div>
        </div>
    </div>);
};

export default QuoteProjectTimeEntry;
