import React, {useState} from 'react';
import ResourceGridView from './../../components/grid/ResourceGridView';
import * as cells from './../../components/ui/cells';
import {formatCurrency} from "../opportunities/OpportunityHelper";
import {useStore} from "../../context";
import StandalonePopover from "../../components/ui/StandalonePopover";
import SaleInfo from "./SaleInfo";
import {IShortSaleModel} from "../../utils/models";

function Sales() {
    const {salesStore} = useStore();
    const [popoverItem, setPopoverItem] = useState<IShortSaleModel | null>(null)

    return (
        <div className='dashboard'>

            {popoverItem && <StandalonePopover
                title={`Sale info (${popoverItem.type})`}
                closePopover={() => setPopoverItem(null)}>
                <SaleInfo shortSale={popoverItem}/>
            </StandalonePopover>}

            <ResourceGridView<IShortSaleModel>
                columns={[
                    {
                        name: 'Opportunity',
                        value: (item) => (
                            <div>
                                <div className="title">{item.title}</div>
                                <div className="other-info">
                                    {item['@id'].split('/').reverse()[0]}
                                    {item.customer ? <span className={'dot'}>•</span> : ''}
                                    {item.customer ? item.customer.name : ''}
                                    {item.source ? <span className={'dot'}>•</span> : ''}
                                    {item.source}
                                </div>
                            </div>
                        ),
                    },
                    {
                        name: 'value',
                        label: 'Total value',
                        value: (item) => (
                            <div>{item.totalValue ? formatCurrency(parseFloat(item.totalValue)) :
                                <span className={'not-available'}>N/A</span>}</div>
                        )
                    },
                    {
                        name: 'invoiceNumber',
                        label: 'Invoice number',
                        value: (item) => (<div>
                            <div className="title">{item.invoiceNumber}</div>
                            <div className="other-info">
                                {item.isInvoiced ? 'invoiced' : 'not invoiced'}
                            </div>
                        </div>)
                    },
                    {
                        name: 'createdAt',
                        label: 'Closing date',
                        value: (item) => (item.createdAt
                            ? cells.dateAndTimeCell(item.createdAt)
                            : ''),
                    },
                    {
                        name: 'type',
                        label: 'Sale type',
                        value: (item) => (
                            <div>{item.type ? item.type : <span className={'not-available'}>N/A</span>}</div>
                        )
                    },

                    {
                        name: 'Sales agent',
                        value: (item) => (
                            <div>{item.agent ? item.agent.username : <span className={'not-available'}>N/A</span>}</div>
                        ),
                    },
                ]}
                gridResource={salesStore}

                onRowClick={(item, e) => {
                    setPopoverItem(item)
                }}
                filterKeys={{
                    createdAt: 'Closing date',
                    'contracts.startDate': 'Contract Start',
                    'contracts.endDate': 'Contract End',
                    agent: 'Sales Agent',
                    customer: 'Customer/Partner',
                    description: 'Description',
                    isInvoiced: 'Is invoiced',
                    invoiceNumber: 'Invoice number',
                    'quote.subcontractorsInvoiceNumber': 'Partner\'s invoice',
                }}
                trProps={(item) => {
                    if (!item.approved) {
                        return {className: 'not-approved'};
                    }
                }}

                tableClass={'sales'}
            />
        </div>
    );
};


export default Sales;
