import React from 'react';

import {confirm as uiconfirm} from "../../components/ui/dialogs";
import {store} from 'react-notifications-component';

class UiBus {
    public notify(message: string, type: "warning" | "success" | "info" | "danger" = "info", title?: string) {
        store.addNotification({
            title: title,
            message: message,
            type: type,
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: type !== "danger" ? 3000 : 7000,
                onScreen: type === "warning"
            }
        });
    }

    public confirm(title = "Are you sure?", e?: React.MouseEvent, message: string = '') {
        const position = e ? {
            x: e.clientX,
            y: e.clientY,
        } : {x: 100, y: 100};
        return uiconfirm(position, title, message);
    }

    public convertQuote(title = "Are you sure?", e?: React.MouseEvent, message: string = '') {
        const position = e ? {
            x: e.clientX,
            y: e.clientY,
        } : {x: 100, y: 100};
        return uiconfirm(position, title, message, true);
    }
}

export default UiBus;
