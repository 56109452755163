import React from 'react';
import {FinalAutocomplete, FinalButtonSet, Input, Select} from '../../components/ui/form';
import {LinkButton, NumberInput} from '../../components/ui';
import {useStore} from "../../context";
import {Field, FieldRenderProps, Form, FormSpy} from "react-final-form";
import {observer} from "mobx-react";
import {Icon} from "@mdi/react";
import {mdiClose} from "@mdi/js";
import useDataListFetch from "../../utils/form/useDataListFetch";
import {AddSerialsToPurchaseBatchInput, PartFullModel} from "../../utils/models";

const HardwareAutocompleteAdapter = observer(({meta, input, change, canEdit, ...rest}: FieldRenderProps<PartFullModel, any> & { change, canEdit }) => {
    const {purchaseStore} = useStore();

    return <FinalAutocomplete<PartFullModel>
        fieldName={"partNumber"}
        scope={"parts"}
        canEdit={canEdit}
        label={"Part Number"}
        additionalQuery={{isStock: true, type: "hardware"}}
        onComplete={(item) => {
            if (typeof item === 'object') {
                change('name', item.name);
                change('modelNumber', item.modelNumber);
                change('partNumber', item.partNumber);
                change('manufacturer', item.manufacturer);
            }
            purchaseStore.acceptPartFromAutocomplete(item)
        }
        }
        input={input} meta={meta}  {...rest}/>
});

const SystemAutocompleteAdapter = observer(({meta, input, change, canEdit, ...rest}: FieldRenderProps<PartFullModel, any> & { change, canEdit }) => {
    const {purchaseStore} = useStore();

    return <FinalAutocomplete<PartFullModel>
        fieldName={"modelNumber"}
        scope={"parts"}
        canEdit={canEdit}
        label={"Model Number"}
        additionalQuery={{isStock: true, type: "system"}}
        onComplete={(item) => {
            if (typeof item === 'object') {
                change('manufacturer', item.manufacturer);
            }
            purchaseStore.acceptPartFromAutocomplete(item)
        }
        }
        input={input} meta={meta}  {...rest}/>
});

const SoftwareAutocompleteAdapter = observer(({meta, input, change, canEdit, ...rest}: FieldRenderProps<PartFullModel, any> & { change, canEdit }) => {
    const {purchaseStore} = useStore();

    return <FinalAutocomplete<PartFullModel>
        fieldName={"modelNumber"}
        scope={"parts"}
        canEdit={canEdit}
        label={"Model number"}
        additionalQuery={{isStock: true, type: "software"}}
        onComplete={(item) => {
            if (typeof item === 'object') {
                change('name', item.name);
                change('manufacturer', item.manufacturer);
            }
            purchaseStore.acceptPartFromAutocomplete(item)
        }
        }
        input={input} meta={meta}  {...rest}/>
});

const Serials = observer(() => {
    const {purchaseStore} = useStore();

    return (
        <div className={'serials-number'}>
            {purchaseStore.newSerials.map((serialNumber, sKey) =>
                <div className={'serials-key'} key={serialNumber}>
                    <p>{serialNumber}</p>
                    <LinkButton onClick={() => purchaseStore.removeNewSerialNumber(sKey)}>
                        <Icon path={mdiClose}/>
                    </LinkButton>
                </div>)}
        </div>
    )
});

const PurchaseItemForm = observer(() => {
    const {purchaseStore} = useStore();
    const manufacturerDataList = useDataListFetch('manufacturers');

    const handleNewSerialNumber = (e, change) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            purchaseStore.addNewSerialNumber(e.target.value);
            change('serialNumber', null)
        }
    };

    const canEdit = !purchaseStore.onlyNewSerials;
    const canSave = purchaseStore.canSaveCreatePurchaseItemForm;
    const isHardware = purchaseStore.partDraftType === 'hardware';
    const isSoftware = purchaseStore.partDraftType === 'software';
    const isSystem = purchaseStore.partDraftType === 'system';

    return <Form<AddSerialsToPurchaseBatchInput>
        onSubmit={purchaseStore.createPurchaseItem}
        render={({handleSubmit, form, pristine, submitting}) => {
            return (<form onSubmit={handleSubmit}>
                {isHardware && <Field
                    name={"partNumber"}
                    label={"Part Number"}
                    component={HardwareAutocompleteAdapter}
                    change={form.change}
                    canEdit={canEdit}
                    required
                />}

                {isHardware && <Field
                    placeholder='Model number'
                    name="modelNumber"
                    label="Model Number"
                    component={Input}
                    canEdit={canEdit}
                    required
                />}
                {isSoftware && <Field
                    name={"modelNumber"}
                    label={"License number"}
                    component={SoftwareAutocompleteAdapter}
                    change={form.change}
                    canEdit={canEdit}
                    required
                />}
                {isSystem && <Field
                    name={"modelNumber"}
                    label={"Model Number"}
                    component={SystemAutocompleteAdapter}
                    change={form.change}
                    canEdit={canEdit}
                    required
                />}
                <Field
                    placeholder='Price'
                    name="price"
                    label="Price"
                    component={Input}
                    required
                />
                <Field
                    placeholder='Warranty'
                    name="warranty"
                    label="Warranty"
                    component={NumberInput}
                    canEdit={true}
                    required
                />
                <Field
                    placeholder='Manufacturer'
                    name="manufacturer"
                    label="Manufacturer"
                    required
                    component={Select}
                    {...manufacturerDataList}
                    canEdit={canEdit}
                />
                {!isSystem && <Field
                    placeholder='Name'
                    name="name"
                    label="Name"
                    component={Input}
                    canEdit={canEdit}
                    required={isHardware}
                />}
                {isSoftware && <Field
                    name="quantity"
                    label="Quantity"
                    component={NumberInput}
                    canEdit={true}
                    required={true}
                />}
                {!isSoftware && <Field
                    placeholder={'Serial Number'}
                    label={'Serial Number'}
                    name={'serialNumber'}
                    component={Input}

                    onKeyDown={(e) => {
                        handleNewSerialNumber(e, form.change);
                    }}/>}
                <Serials/>
                <FinalButtonSet pristine={pristine || !canSave} submitting={submitting} reset={form.reset}
                                label={"Create"}/>
                <FormSpy onChange={state => purchaseStore.updatePartDraftData(state.values)}/>
            </form>)
        }}>
    </Form>
});


export default PurchaseItemForm;
