import React, {useState} from "react";
import {Field, Form} from "react-final-form";
import {Checkbox} from "../../../components/ui/form";
import moment from "moment";
import {FieldArray} from 'react-final-form-arrays'
import {Button, NumberInput} from "../../../components/ui";
import arrayMutators from 'final-form-arrays'
import {ContractStore} from "../../../store/mobx/ContractStore";

type ContractHistoryListType = {
    contractStore: ContractStore
}

const ContractHoursHistoryListForm = ({contractStore}: ContractHistoryListType) => {
    const [touchedFields, setTouchedFields] = useState({});

    return <Form
        onSubmit={contractStore.saveExceededHoursData}
        mutators={{
            // potentially other mutators could be merged here
            ...arrayMutators
        }}
        initialValues={contractStore.currentContract}
        render={({values, handleSubmit, pristine}) => (
            <form onSubmit={handleSubmit}>
                <FieldArray name={"exceededHours"}>
                    {({fields}) => (
                        <div>
                            {Object.keys(contractStore.currentContract.ticketsPerPeriodWithExceeded)
                                .sort((a, b) => moment(a).diff(moment(b)))
                                .map(period => {
                                    return (<div key={period} className="contract-history-item">
                                        <div>
                                            <p className="pre-text">Tickets list from {period} period</p>
                                            <br/>
                                            <br/>
                                        </div>
                                        {contractStore.currentContract.ticketsPerPeriodWithExceeded[period].tickets
                                            .map(ticket => {
                                                return (<div key={ticket.ticket["@id"]}
                                                             className={'contract-history-item__heading'}>
                                                    <p className="contract-history-item__title">
                                                        {moment(ticket.ticket.createdAt).format("LLLL")} -
                                                        ticket "{ticket.ticket.title}" <br/>spent {ticket.ticket.spentHours}h {ticket.ticket.spentMinutes === 0 ? '00' : ticket.ticket.spentMinutes}m
                                                    </p>

                                                </div>);
                                            })}
                                    </div>)
                                })}
                            {fields.map((name, index) => {
                                    const item = contractStore.currentContract;
                                    const excH = item.exceededHours[index];
                                    const status = excH.additionalValue || excH.takeFromNextPeriod;

                                    return (<div key={excH["@id"]} className="contract-history-item">
                                        <div className={'contract-history-item__heading'}>
                                            <p className="contract-history-item__title">
                                                Outstanding {Math.floor(excH.minutesNumber / 60)}h {Math.round(excH.minutesNumber % 60)}m
                                            </p>
                                            <div className={status ? 'true' : 'false'}>{status ? 'True' : 'False'}</div>
                                        </div>

                                        {!status && <div className={'boxes'}>
                                            <div className="boxes-item">
                                                <p className="boxes-item__title">Outstanding settlement</p>
                                                <Field name={`${name}.additionalValue`}
                                                       component={NumberInput}
                                                       disabled={touchedFields[name] && touchedFields[name] === 'from_next'}
                                                       onChange={(val) => {
                                                           if (val) {
                                                               setTouchedFields({...touchedFields, ...{[name]: 'additional'}})
                                                           } else {
                                                               setTouchedFields({...touchedFields, ...{[name]: null}})
                                                           }
                                                       }}
                                                />
                                            </div>
                                            {item.period !== 'all' && <span>or</span>}
                                            {item.period !== 'all' && <div className="boxes-item">
                                                <p className="boxes-item__title">Take from next period</p>
                                                <Field name={`${name}.takeFromNextPeriod`}
                                                       component={Checkbox}
                                                       disabled={touchedFields[name] && touchedFields[name] === 'additional'}
                                                       type={'checkbox'}
                                                       onChange={(val) => {
                                                           if (val) {
                                                               setTouchedFields({...touchedFields, ...{[name]: 'from_next'}})
                                                           } else {
                                                               setTouchedFields({...touchedFields, ...{[name]: null}})
                                                           }
                                                       }}
                                                       label={'Take from next period'}/>
                                            </div>}
                                        </div>}
                                        {Number(excH.additionalValue) !== 0 && <div>
                                            <p className={'pre-text'}>Outstanding settlement:</p>
                                            <p>£{excH.additionalValue}</p>
                                        </div>}
                                        {excH.takeFromNextPeriod && <div>
                                            <p className={'pre-text'}>Taken from next period: </p>
                                            <p>From {moment(excH.nextPeriodStartDate).format("LLLL")} to {moment(excH.nextPeriodEndDate).format("LLLL")}</p>
                                        </div>}
                                    </div>);
                                }
                            )}
                            <div className={"relative-buttons"}>
                                {!pristine && <Button color={"blue"}>
                                    Save exceeded hours data
                                </Button>}
                            </div>

                        </div>
                    )}
                </FieldArray>
            </form>
        )}/>
};
// const ContractTicketHistoryListForm = ({item}: ContractHistoryListType) => {
//     return (
//         <React.Fragment>
//             {item.exceededHours.map((excH, excTIndex) => {
//                 const status = excH.additionalValue || excH.takeFromNextPeriod;
//                 return (<div key={excH["@id"]} className="contract-history-item">
//                     <div className={'contract-history-item__heading'}>
//                         <p className="contract-history-item__title">
//                             Outstanding {Math.floor(excH.minutesNumber / 60)}h {Math.round(excH.minutesNumber % 60)}m
//                         </p>
//                         <div className={status ? 'true' : 'false'}>{status ? 'True' : 'False'}</div>
//                     </div>
//
//                     {!status && <div className={'boxes'}>
//                         <div className="boxes-item">
//                             <p className="boxes-item__title">Outstanding settlement</p>
//                             <Field name={`exceededHours[${excTIndex}][additionalValue]`}
//                                    component={Input}
//                             />
//                         </div>
//                         {item.period !== 'all' && <span>or</span>}
//                         {item.period !== 'all' && <div className="boxes-item">
//                             <p className="boxes-item__title">Take from next period</p>
//                             <Field name={`exceededHours[${excTIndex}][takeFromNextPeriod]`}
//                                    component={Checkbox}
//                                    type={'checkbox'}
//                                    label={'Take from next period'}/>
//                         </div>}
//                     </div>}
//                     {Number(excH.additionalValue) !== 0 && <div>
//                         <p className={'pre-text'}>Outstanding settlement:</p>
//                         <p>£{excH.additionalValue}</p>
//                     </div>}
//                     {excH.takeFromNextPeriod && <div>
//                         <p className={'pre-text'}>Taken from next period: </p>
//                         <p>From {moment(excH.nextPeriodStartDate).format("LLLL")} to {moment(excH.nextPeriodEndDate).format("LLLL")}</p>
//                     </div>}
//                 </div>)
//             })}
//         </React.Fragment>
//     )
// };

export default ContractHoursHistoryListForm;
