export const ticketTypeList = ['remote', 'on_site'] as const;
export const ticketStatusList = ['floating', 'inProgress', 'assigned', 'hold', 'complete', 'archived'] as const;
export const manuallySettingTicketStatusList = ['inProgress', 'hold', 'complete', 'archived'] as const;
export type TicketStatusType = typeof ticketStatusList[number];
export type TicketTypeType = typeof ticketTypeList[number];

export function formatTicketStatus(status: TicketStatusType): string {
    if (status === 'inProgress') {
        return 'In Progress';
    }
    return status.charAt(0).toUpperCase() + status.slice(1);
}

export function formatTicketType(type: TicketTypeType): string {
    if (type === 'on_site') {
        return 'On site';
    }
    return type.charAt(0).toUpperCase() + type.slice(1);
}
