import React from "react";

type UserAvatarProps = {
    name: string,
    full: boolean
}
const UserAvatar = ({name, full}: UserAvatarProps) => {
    let colors = ['#339CFF', '#D470F8', '#FF9A23', '#FB6666', '#4AEA8A', '#B0EC30'];
    function hashCode(str) {
        return str.split('').reduce((prevHash, currVal) =>
            (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);
    }
    const detectColor = (name: string): string => {
        const hash = hashCode(name);
        return colors[hash % colors.length];
    };

    const getInitials = (name) => {
        let initials = name.split(' ');

        if(initials.length > 1) {
            initials = initials.shift().charAt(0) + initials.pop().charAt(0);
        } else {
            initials = name.substring(0, 2);
        }

        return initials.toUpperCase();
    }

    return (
        <div>
            <span style={{backgroundColor: detectColor(name)}} className="avatar">
                {getInitials(name)}
            </span>
            {full ? name : null}
        </div>
    )
};

export default UserAvatar