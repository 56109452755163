import React from 'react';
import {render} from 'react-dom';
import 'mobx-react-lite/batchingForReactDom';
import {Provider} from 'mobx-react';
import RootStore from "./store/mobx";
import ApiClient from "./store/ApiClient";
import axios from 'axios';
import {Router} from "react-router-dom";
import './css/style.scss';
import {IntlProvider} from "react-intl";
import App from "./App";
import {createBrowserHistory} from 'history';
import {RouterStore, syncHistoryWithStore} from 'mobx-react-router';
import {StoreContext} from './context';
import UiBus from "./store/service/UiBus";
import SimpleReactLightbox from 'simple-react-lightbox';

const browserHistory = createBrowserHistory();

const routingStore = new RouterStore();
const history = syncHistoryWithStore(browserHistory, routingStore);

const rootEl = document.getElementById('root');

const renderApp = (mobxStore: RootStore) => {
    render(
        <StoreContext.Provider value={mobxStore}>
            <Provider rootStore={mobxStore}>
                <IntlProvider locale={"en"}>
                    <Router history={history}>
                        <SimpleReactLightbox>
                            <App/>
                        </SimpleReactLightbox>
                    </Router>
                </IntlProvider>
            </Provider>
        </StoreContext.Provider>,
        rootEl
    )
};

const mobxStore = new RootStore(new ApiClient(axios, routingStore), routingStore, new UiBus());

if (process.env.SENTRY_DSN && window['Raven']) {
    window['Raven'].config(process.env.SENTRY_DSN).install();
    window['Raven'].context(function () {
        renderApp(mobxStore);
    });
} else {
    renderApp(mobxStore);
}
