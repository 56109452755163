import * as React from 'react';
import {useEffect} from 'react';
import * as _ from 'underscore';
import {useStore} from "../../context";
import {observer} from "mobx-react";
import {ContractFullModel, Iri, IShortSaleModel, PartFullModel} from "../../utils/models";
import {idFromEntity, idFromIri} from "../../utils/iri";
import {Checkbox, DateInput, Input, Link} from "../../components/ui";
import {Form, FormRenderProps, Field} from 'react-final-form';

const SaleInfo = observer(({shortSale}: { shortSale: IShortSaleModel }) => {
    const {salesStore} = useStore();

    useEffect(() => {
        if (shortSale) {
            salesStore.fetchItem(shortSale["@id"].split('/').reverse()[0])
        }
    }, [shortSale])

    const renderPartsRow = (item: PartFullModel, index: number) => {
        return (
            <div className='entity-info__row__part' key={index}>
                <p className='entity-info__row__part__title'>
                    {item.name + (item.partNumber ? ' #' + item.partNumber : '')} x {item.configParts}
                </p>
                <ul>
                    {item.partRealizations.map((real, realIndex: number) => {
                        return (<li key={real["@id"]}>{real.serialNumber}</li>);
                    })}
                </ul>
            </div>
        );
    };

    const renderContractsRow = (item: ContractFullModel, index: number, customerId: Iri) => {
        return (
            <div className='entity-info__row__part' key={index}>
                <p className='entity-info__row__part__title'>
                    <Link to={`/customers/${idFromIri(customerId)}?contractId=${idFromEntity(item)}`}>
                        Contract # {idFromEntity(item)}
                    </Link>
                </p>
                <ul>
                    {item.systems.map((systemId, systemIndex) => {
                        return (<li key={systemId} className='entity-info__row__part__serial'>
                            <Link to={`/customers/${idFromIri(customerId)}?systemId=${idFromEntity(item)}`}>
                                System # {idFromEntity(item)}
                            </Link>
                        </li>);
                    })}
                </ul>
            </div>
        );
    };

    // const renderSystemsRow = (item: any, index: number) => {
    //     return (
    //         <div className='entity-info__row__part' key={'systems-' + index}>
    //             <p className='entity-info__row__part__title'>
    //                 {'System #' + item['modelNumber']}
    //             </p>
    //         </div>
    //     );
    // };

    const renderRows = () => {
        return (
            <React.Fragment>
                <div key={_.uniqueId()} className='entity-info__row'>
                    <label>Title: </label> {salesStore.currentSale.title}
                </div>
                {salesStore.currentSale.source && <div key={_.uniqueId()} className='entity-info__row'>
                    <label>Source: </label> {salesStore.currentSale.source}
                </div>}
                <div key={_.uniqueId()} className='entity-info__row'>
                    <label>Customer: </label>
                    <Link to={`/customers/${idFromIri(salesStore.currentSale.customer['@id'])}`}>
                        {salesStore.currentSale.customer.name}
                    </Link>
                </div>
                {salesStore.currentSale.type === 'parts' ? (
                    <div key={_.uniqueId()} className='entity-info__row'>
                        <label>Parts: </label>
                        <div className='entity-info__row__parts'>
                            {salesStore.currentSale.parts.map(renderPartsRow)}
                        </div>
                    </div>
                ) : (
                    <div key={_.uniqueId()} className='entity-info__row'>
                        <label>Contracts: </label>
                        <div className='entity-info__row__parts'>
                            {salesStore.currentSale.contracts.map(
                                (item, index) => renderContractsRow(item, index, salesStore.currentSale.customer['@id']))}
                        </div>
                    </div>
                )}

                {/*{salesStore.currentSale.opportunity.quotes.length > 0*/}
                {/*&& ((salesStore.currentSale.type === 'parts' && !salesStore.currentSale.contractSibling) || (salesStore.currentSale.type === 'contracts')) ? (*/}
                {/*    <div key={_.uniqueId()} className='entity-info__row'>*/}
                {/*        <label>Systems: </label>*/}
                {/*        <div className='entity-info__row__parts'>*/}
                {/*            {salesStore.currentSale.opportunity.quotes.every(quote=>quote.quoteSystemInfos.map(*/}
                {/*                (item, index) => renderSystemsRow(item, index)))}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*) : null}*/}
            </React.Fragment>
        );
    }

    return (
        <div className='entity-info sale-info'>
            {salesStore.currentSale ? renderRows() : null}

            <Form onSubmit={(values) => salesStore.updateSales(values, idFromEntity(shortSale))}
                  render={({handleSubmit}: FormRenderProps) => {
                      return (
                          <form onSubmit={handleSubmit}>
                              <Field name={'invoiceNumber'}
                                     defaultValue={salesStore.currentSale && salesStore.currentSale.invoiceNumber}
                                     label={'Invoice number'}
                                     component={Input}/>

                              <Field name={'isInvoiced'}
                                     defaultValue={salesStore.currentSale && salesStore.currentSale.isInvoiced}
                                     label={'Is invoiced'}
                                     component={Checkbox}/>

                              <Field name={'invoicedAt'}
                                     defaultValue={salesStore.currentSale && salesStore.currentSale.invoicedAt}
                                     label={'Invoice date'}
                                     component={DateInput}/>

                              <button type={'submit'} className={'tertiaryBtn'}>Submit</button>
                          </form>
                      )
                  }}
            />

        </div>
    );
})

export default SaleInfo;
