import React from 'react';
import {FileAttachmentsInterface} from "../../../store/interfaces/FileAttachmentsInterface";
import {observer} from "mobx-react";

import {Icon} from '@mdi/react';
import {mdiDownload, mdiFileImage, mdiFilePdf, mdiFileTableOutline, mdiTrashCanOutline} from "@mdi/js";
import {IAttachmentModel} from "../../../utils/models";

type FileGalleryProps = {
    attachmentInterface: FileAttachmentsInterface,
    extraClassName?
}
const MEDIA_PREFIX = process.env['REACT_APP_MEDIA_BASE_HOST'] ? process.env['REACT_APP_MEDIA_BASE_HOST'] : process.env['REACT_APP_API_HOST'];

@observer
class FileGallery extends React.Component<FileGalleryProps, { activeRowIndex }> {
    private gridClass;
    private filesInRow;
    private cellClass;
    private mainContainer;

    constructor(props) {
        super(props);

        this.filesInRow = props.filesInRow ? props.filesInRow : 4;
        this.gridClass = (this.filesInRow === 5 ? 'custom-grid-10' : 'custom-grid');
        this.cellClass = (this.filesInRow !== 5 ? ('cell-' + 12 / this.filesInRow) : 'cell-2');
        this.state = {
            activeRowIndex: 0
        }
    }

    getOffsetValue = () => {
        if (!this.mainContainer) {
            return 0;
        }
        return this.state.activeRowIndex * this.mainContainer.offsetHeight;
    };

    getFileSize = (value) => {

        if (value >= 1000) {
            return `${Math.floor(value / 1000)} KB`;
        }
        if (value >= 1000000) {
            return `${Math.floor(value / 1e+6)} MB`;
        }

        return `${value} B`;
    };

    getFileExtensions = (extension) => {
        const imageExtensions = ['png', 'jpg', 'jpeg', 'svg'];

        if (imageExtensions.includes(extension)) {
            return <Icon path={mdiFileImage}/>
        } else if (extension === 'pdf') {
            return <Icon path={mdiFilePdf}/>
        }
        return <Icon path={mdiFileTableOutline}/>
    };

    concatText = (str) => {
        if (str.length > 20) {
            return str.substr(0, 18) + '...';
        }
        return str;
    };

    renderFile = (item: IAttachmentModel, index: number) => {

        return (
            <div className={this.cellClass} key={index}>
                <div className="file">
                    <a href={`${MEDIA_PREFIX}${item.uri}`}
                       target='_blank'
                       title={item.name}
                       className="file-preview">
                        <p className={'file-preview-title'}> {this.concatText(item.name)} </p>
                        <span className="file-info">
                                <span className="icon">
                                    <Icon className={'download'} path={mdiDownload}/>
                                    {this.getFileExtensions(item.extension)}
                                </span>
                                 <p className={'format'}> {item.extension} </p>
                                 <span className={'dot'}>•</span>
                                <p className="size">{this.getFileSize(Math.round(item.size))}</p>
                            </span>
                        {this.props.attachmentInterface.canRemoveAttachment(item) && <span
                            className="file-delete"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.props.attachmentInterface.removeAttachment(item)
                            }}>
                            <Icon path={mdiTrashCanOutline}/>
                        </span>}
                    </a>
                </div>
            </div>
        );
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.filesInRow && newProps.filesInRow !== this.filesInRow) {
            this.filesInRow = newProps.filesInRow;
            this.gridClass = (this.filesInRow === 5 ? 'custom-grid-10' : 'custom-grid');
            this.cellClass = (this.filesInRow !== 5 ? ('cell-' + 12 / this.filesInRow) : 'cell-2');
        }
    }

    render() {
        if (!this.props.attachmentInterface.fetchAttachments.length) {
            return null;
        }

        return (
            <div
                className={'file-gallery ' + (this.props.extraClassName ? this.props.extraClassName : '')}
                ref={node => (this.mainContainer = node)}>
                <div
                    className={this.gridClass + ' file-wrapper'}
                    style={{top: -this.getOffsetValue()}}>
                    {this.props.attachmentInterface.fetchAttachments.map(this.renderFile)}
                </div>
            </div>
        );
    }
}


export default FileGallery;
