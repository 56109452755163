import {action, runInAction} from "mobx";
import {downloadAsBlob} from "../../utils/downloadAsBlob";
import {toCSV} from "react-csv/lib/core";
import {
    CollectionResponseType,
    GridResourceCollectionInterface,
    GridResourceType
} from "../interfaces/GridResourceCollectionInterface";
import UiBus from "./UiBus";
import {IReportTrackModel} from "../../utils/models";
import ApiClient from "../ApiClient";


const MAX_ITEM_COUNT = 1000;

class ReportDownloader {

    constructor(private apiClient: ApiClient){}
    download<T>(entityName: GridResourceType, gridResource: GridResourceCollectionInterface<T>, request: Promise<CollectionResponseType<T>>, itemProcessorClb: (item: T) => Object, reportProcessorClb?: (items: Object[]) => Object[], totalValuesClb?: (items: Object[]) => Object) {
        if (gridResource.collectionResponse["hydra:totalItems"] > MAX_ITEM_COUNT) {
            (new UiBus()).notify(`Total items should be < ${MAX_ITEM_COUNT} items. Actual: ${gridResource.collectionResponse["hydra:totalItems"]}`);
            return;
        }
        gridResource.toggleLoading();

        request.then(action("fetchCollectionReport ok", response => {
            gridResource.toggleLoading();
            const items = response['hydra:member'];
            const csv = items.map(itemProcessorClb);
            if(totalValuesClb) {
                csv.push(totalValuesClb(items))
            }
            const final = reportProcessorClb ? reportProcessorClb(csv) : csv;
            const finalCsv = toCSV(final)
            downloadAsBlob(finalCsv);
            const reportJournal: IReportTrackModel = {
                tier: entityName,
                reportContent: finalCsv,
                reportFileName: entityName+`.csv`
            };
            this.apiClient.sendReportToJournal(reportJournal).then(()=>{
                // eslint-disable-next-line
                console.log("sendReportToJournal ok");
            });
        })).catch(e => {
            runInAction("fetchCollectionReport failed", () => {
                gridResource.toggleLoading();
            })
        })
    }
}

export default ReportDownloader;
