import React from "react";
import StandalonePopover from "../../components/ui/StandalonePopover";
import FileUploader from "../../components/ui/widgets/FileUploader";

import {Icon} from "@mdi/react";
import {
    mdiAccountArrowLeftOutline,
    mdiAccountPlusOutline,
    mdiAlertOutline,
    mdiAttachment,
    mdiCalendarPlus,
    mdiCalendarRemoveOutline,
    mdiFileExcelOutline,
    mdiStarOutline
} from '@mdi/js';
import {observer} from "mobx-react";
import {useStore} from "../../context";
import OpportunityAssignToSelectForm from "./OpportunityAssignToSelectForm";
import OpportunityInvitedSelectForm from "./OpportunityInvitedSelectForm";
import OpportunityAppointmentForm from "./OpportunityAppointment";
import OpportunityCloseDateForm from "./OpportunityCloseDateForm";
import OpportunityRateForm from "./OpportunityRateForm";
import AlertForm from "../../components/ui/AlertForm";

const OpportunityDetailsButtonList = observer(() => {
    const {opportunityStore} = useStore();

    return (
        <div className="buttons" onClick={event => {
            event.stopPropagation();
        }}>
            {opportunityStore.currentContextPlace === 'assign' &&
            <StandalonePopover title={"Assign to"} closePopover={opportunityStore.clearContextPlace}>
                <OpportunityAssignToSelectForm
                    initialValues={{salesAgent: opportunityStore.currentOpportunity.salesAgent}}
                    onFormSubmit={opportunityStore.assignUser}/>
            </StandalonePopover>}

            {opportunityStore.currentContextPlace === 'alert' &&
            <StandalonePopover title={"Alert"} closePopover={opportunityStore.clearContextPlace}>
                <AlertForm currentItemAlert={opportunityStore.currentOpportunity}
                           onSubmit={opportunityStore.mutateAlert}
                           removeAlert={opportunityStore.removeAlert}
                           values={opportunityStore.currentOpportunity.alert}/>
            </StandalonePopover>}

            {opportunityStore.currentContextPlace === 'attachment' &&
            <StandalonePopover title={"Attachments"} size={"bd"} closePopover={opportunityStore.clearContextPlace}>
                <FileUploader attachmentStore={opportunityStore}/>
            </StandalonePopover>}
            {opportunityStore.currentContextPlace === 'invite' &&
            <StandalonePopover title={"Invite"} closePopover={opportunityStore.clearContextPlace}>
                <OpportunityInvitedSelectForm/>
            </StandalonePopover>}

            {opportunityStore.currentContextPlace === 'set_appointment' &&
            <StandalonePopover columnCount={2} title={"Appointment"} closePopover={opportunityStore.clearContextPlace}>
                <OpportunityAppointmentForm/>
            </StandalonePopover>}

            {opportunityStore.currentContextPlace === 'close_date' &&
            <StandalonePopover title={"Set close date"} closePopover={opportunityStore.clearContextPlace}>
                <OpportunityCloseDateForm/>
            </StandalonePopover>}
            {opportunityStore.currentContextPlace === 'rate' &&
            <StandalonePopover title={"Rate"} closePopover={opportunityStore.clearContextPlace}>
                <OpportunityRateForm/>
            </StandalonePopover>}

            {opportunityStore.canAssign &&
            <div className="buttons-item" onClick={() => opportunityStore.changeContextPlace('assign')}>
                <Icon path={mdiAccountArrowLeftOutline}/>Assign to
            </div>}

            {opportunityStore.canEdit &&
            <React.Fragment>
                <div className="buttons-item" onClick={() => opportunityStore.changeContextPlace('attachment')}>
                    <Icon path={mdiAttachment}/>Attachment
                </div>

                <div className="buttons-item" onClick={() => opportunityStore.changeContextPlace('alert')}>
                    <Icon path={mdiAlertOutline}/>Alert
                </div>

                <div className="buttons-item" onClick={() => opportunityStore.changeContextPlace('set_appointment')}>
                    <Icon path={mdiCalendarPlus}/> Set appointment
                </div>

                <div className="buttons-item" onClick={() => opportunityStore.changeContextPlace('invite')}>
                    <Icon path={mdiAccountPlusOutline}/>Invite
                </div>

                <div className="buttons-item" onClick={() => opportunityStore.goExportOpportunity()}>
                    <Icon path={mdiFileExcelOutline}/>Export to Excel
                </div>

                <div className="buttons-item" onClick={() => opportunityStore.changeContextPlace('close_date')}>
                    <Icon path={mdiCalendarRemoveOutline}/>Close date
                </div>
                <div className="buttons-item" onClick={() => opportunityStore.changeContextPlace('rate')}>
                    <Icon path={mdiStarOutline}/> Rate
                </div>
            </React.Fragment>
            }


        </div>
    );
})

export default OpportunityDetailsButtonList;
