import * as React from 'react';
import {Field} from 'react-final-form'
import {DateInput, FinalButtonSetMode, Input, Select, Textarea} from "../../../components/ui/form";
import {observer} from "mobx-react";
import {useStore} from "../../../context";
import ContractMaintenanceForm from "./ContractMaintenanceForm";
import ContractAdministrationForm from "./ContractAdministrationForm";
import useDataListFetch from "../../../utils/form/useDataListFetch";
import ContractPayAsYouGoForm from "./ContractPayAsYouGoForm";
import ContractBankOfHoursForm from "./ContractBankOfHoursForm";

import {Icon} from "@mdi/react";
import {mdiClockOutline} from "@mdi/js";
import ExpensesDetailsWidget from "../../../components/ui/widgets/ExpensesDetailsWidget";
import ContractBankOfTicketsForm from "./ContractBankOfTicketsForm";
import ContractExceededHistory from "./ContractExceededHistory";
import FileGallery from "../../../components/ui/widgets/FileGallery";

export const ContractDetailsFormSharedFieldsSet = observer(() => {
    const {customerStore, authStore} = useStore();
    const salesAgentDataList = useDataListFetch('sales_agents+admins');
    const slaTimeDataList = useDataListFetch('sla_times');

    const renderExceededHistory = customerStore.contextPlace !== 'create_contract'
        && customerStore.contractStore.currentContract
        && customerStore.contractStore.currentContract.period
        && (customerStore.contractStore.currentContract.ticketsNumber || customerStore.contractStore.currentContract.hoursNumber);
    const canSeeNotes = authStore.isGranted('ROLE_ADMIN') || authStore.isGranted('ROLE_ENGINEER') || authStore.isGranted('ROLE_SALE');

    return (
        <React.Fragment>
            {renderExceededHistory ? <ContractExceededHistory contractStore={customerStore.contractStore}/> : null}


            <Field
                type='text'
                name='contractNumber'
                label={'Contract number'}
                component={Input}
                canEdit={customerStore.canEdit}
                required
            />

            <Field
                name='slaTime'
                label={'SLA'}
                component={Select}
                {...slaTimeDataList}
                canEdit={customerStore.canEdit}
                required
            />

            {customerStore.canSeeContractCosts && <Field
                name='initialCost'
                label='Initial cost'
                component={Input}
                canEdit={customerStore.canEdit}
            />}

            {customerStore.canSeeContractCosts && <Field
                label={'Total value'}
                name='totalValue'
                component={Input}
                canEdit={customerStore.canEdit}
            />}

            <Field
                type='text'
                name='startDate'
                label={'Start date'}
                component={DateInput}
                canEdit={customerStore.canEdit}
            />

            <Field
                type='text'
                name='endDate'
                label={'End date'}
                component={DateInput}
                canEdit={customerStore.canEdit}
            />

            <Field
                label={'Customer PO'}
                name='customerPurchaseOrder'
                component={Input}
                canEdit={customerStore.canEdit}
            />

            <Field
                type='text'
                name='salesPerson'
                label={'Sales person'}
                component={Select}
                {...salesAgentDataList}
                canEdit={customerStore.canEdit}
            />

            { canSeeNotes && <Field
                type='text'
                name='notes'
                label={'Notes'}
                component={Textarea}
                canEdit={customerStore.canEdit}
            />}
            {customerStore.contractStore.fetchAttachments
            && customerStore.contractStore.fetchAttachments.length > 0 && (
                <div className='custom-field'>
                    <label>Attachments: </label>
                    <FileGallery
                        attachmentInterface={customerStore.contractStore}/>
                </div>
            )}

        </React.Fragment>
    );
});

export const TimeMinStepFieldAdapter = ({input, meta, canEdit}) => {
    return (<Select input={input} meta={meta} label={"Time Min Step"} canEdit={canEdit} options={
        {
            '10': 10,
            '20': 20,
            '30': 30,
            '40': 40,
            '60': 60
        }
    }/>)
};

type ContractDetailsFormProps = {
    mode: FinalButtonSetMode
}

const ContractDetailsForm = observer(({mode}: ContractDetailsFormProps) => {
    const {customerStore} = useStore();
    let form;
    if (customerStore.contractStore.contractType === 'maintenance') {
        form = <ContractMaintenanceForm mode={mode}/>
    } else if (customerStore.contractStore.contractType === 'administration_saas') {
        form = <ContractAdministrationForm mode={mode}/>
    } else if (customerStore.contractStore.contractType === 'pay_as_you_go') {
        form = <ContractPayAsYouGoForm mode={mode}/>
    } else if (customerStore.contractStore.contractType === 'bank_of_hours') {
        form = <ContractBankOfHoursForm mode={mode}/>
    } else if (customerStore.contractStore.contractType === 'bank_of_tickets') {
        form = <ContractBankOfTicketsForm mode={mode}/>
    }
    return (<>
        {customerStore.contextPlace !== 'create_contract' ?
        <div className="tile">
            {customerStore.canEdit && <ExpensesDetailsWidget withExpenses={customerStore.contractStore}/>}
            <div className="tile-item">
                <Icon path={mdiClockOutline}/>
                <div className="tile-item__info">
                    <p className={'tile-item__info-title'}>Duration:</p>
                    <span> {customerStore.contractStore.currentContract
                        ? <p>{customerStore.contractStore.currentContract.durationInDays} days</p>
                        : <p>Not set</p>}</span>
                </div>
            </div>
        </div> : null}
        {form}
    </>);
});

export default ContractDetailsForm;
