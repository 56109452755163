import React from "react";
import {TicketStore} from "../../../store/mobx/TicketStore";
import {OpportunityStore} from "../../../store/mobx/OpportunityStore";
import {Icon} from "@mdi/react";
import {mdiClockOutline} from "@mdi/js";
import Timer from "./Timer";

type TopInfoBlockProps = {
    store: TicketStore | OpportunityStore,
    forExpired?: boolean
}

const choseColor = (status) => {
    switch (status) {
        case 'pristine':
        case 'floating':
        case 'archived':
            return 'bg-grey';
        case 'working':
        case 'inProgress':
            return 'bg-cyan';
        case 'hold':
        case 'expired':
            return 'bg-orange ';
        case 'assigned':
            return 'bg-purple';
        case 'successful':
        case 'complete':
            return 'bg-green';
        case 'unsuccessful':
        case 'failed':
            return 'bg-red';
    }
};

export const StatusColorBlock = ({status, resolvedStatus}) => {
    return (<div className={'status ' + choseColor(status)}>
        {resolvedStatus}
    </div>)
}

const choseSrStatusColor = (status) => {
    switch (status) {
        case 'floating':
            return 'bg-cyan';
        case 'converted':
            return 'bg-green';
        default:
            return 'bg-grey';
    }
};

export const SupportRequestStatusColorBlock = ({status}) => {
    return (<div className={'status ' + choseSrStatusColor(status)}>
        {status}
    </div>)
}

const TopInfoBlock = ({store, forExpired = false}: TopInfoBlockProps) => {
    return (
        <React.Fragment>
            <div className="top-info">
                <StatusColorBlock resolvedStatus={store.resolvedStatus} status={store.status}/>
                {forExpired &&
                <StatusColorBlock resolvedStatus={'Expired contract'} status={'expired'}/>}
                {store.showTimer && <div className={'time'}>
                    <div className={`time ${store.isThereTimeLeft ? 'green' : 'red'}`}>
                        <Icon path={mdiClockOutline}/>
                        <p>SLA:</p>
                        <Timer
                            updateOffset={1000}
                            workMode={store.isThereTimeLeft ? 'reverse' : 'straight'}
                            targetDate={store.timeLeft}
                        />
                    </div>
                </div>}
                {store.showSlaSpent && <div className={"time"}>
                    <Icon path={mdiClockOutline}/>
                    <p>SLA:</p>
                    {store.formattedSlaSpent}
                </div>}
            </div>
            <div className="title">
                {store.title}
            </div>
        </React.Fragment>
    )
}

export default TopInfoBlock;
