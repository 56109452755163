import React from "react";

const ViolationMessage = (props) => {
    if (!props.message) {
        return null;
    }

    return (
        <div className={'violationMessage'}>{props.message}</div>
    )
};

export default ViolationMessage
