import {Icon} from "@mdi/react";
import {mdiPlus} from "@mdi/js";
import React from "react";
import {observer} from "mobx-react";
import {Loader} from "../../components/ui";

const TicketAssignPartsList = observer(({ticketStore}) => {

    if (ticketStore.assignedPartsLoading) {
        return <Loader/>
    }
    if (ticketStore.assignedParts.length !== 0) {
        return ticketStore.assignedParts.map((elem, i) => {
                return (

                    <div key={elem['@id']} className={'parts-item'}>
                        <div className="parts-item__iterate">
                            {i + 1}
                        </div>
                        <div className="parts-item-info">
                            {ticketStore.canEdit && <span className={'remove'} onClick={(e) => {
                                ticketStore.removeAssignedPart(elem, e)
                            }}><Icon path={mdiPlus}/></span>}
                            <div className="parts-item__serial">
                                s/n {elem.serialNumber}
                            </div>
                            <div className="parts-item__name">
                                <b>Name:</b> {elem.part.name}
                            </div>
                            <div className="parts-item__part-number">
                                <b>Part number:</b> {elem.part.partNumber}
                            </div>
                        </div>
                    </div>
                )
            }
        )
    } else {
        return (
            <div className={'no-items'}>
                <div className="attachment-list">
                    <p>No assigned parts</p>
                    {ticketStore.canEdit && <button onClick={() => ticketStore.changeContextPlace('assign_parts')}
                                                    className={'tertiaryBtn'}>
                        <Icon path={mdiPlus}/>
                        Add part
                    </button>}
                </div>
            </div>
        )
    }
});

export default TicketAssignPartsList;
