import {useStore} from "../../context";

const useApiClient = () => {
    const {_apiClient} = useStore()
    return {client: _apiClient};
};

export default useApiClient;


