export const partTypeList = ['hardware', 'software', 'system'] as const;
export const partRealizationStatusList = ['draft', 'n_a', 'stock', 'spare', 'assigned', 'faulty', 'sold'] as const;
export const partServiceTypeList = ['storage', 'server', 'network'] as const;
export type PartRealizationStatusType = typeof partRealizationStatusList[number];
export type PartTypeListType = typeof partTypeList[number];
export type PartServiceType = typeof partServiceTypeList[number];

export function formatPartTypeListStatus(status: PartTypeListType): string {
    switch (status) {
        case "hardware":
            return 'Hardware';
        case 'software':
            return 'Software';
        case 'system':
            return 'System';
    }
}

export function formatPartRealizationStatus(status: PartRealizationStatusType): string {
    switch (status) {
        case "n_a":
            return 'N/A';
        case 'draft':
            return 'Draft';
        case 'stock':
            return 'Stock';
        case 'spare':
            return 'Spare';
        case 'assigned':
            return 'Assigned';
        case 'faulty':
            return 'Faulty';
        case 'sold':
            return 'Sold';
    }
}

export function formatPartServiceType(type: PartServiceType) {
    switch (type) {
        case "server":
            return "Server";
        case "storage":
            return "Storage";
        case "network":
            return "Network";
    }
}