/* eslint-disable no-script-url */
import React from 'react';

import {LinkButton, Loader} from '../ui';
import {clearObjectFromId, collapseObjectsWithId} from '../../utils/apiHelper';
import {RouteComponentProps, withRouter} from "react-router";
import {stringify} from "querystring";
import {inject, observer} from "mobx-react";
import RootStore from "../../store/mobx";

import {Icon} from '@mdi/react';
import {mdiClose, mdiPlus, mdiSquareEditOutline} from "@mdi/js";
import {ICategoryModel} from "../../utils/models";

type IProps = RouteComponentProps

@inject("rootStore") @observer
class Categories extends React.Component<IProps & Partial<{ rootStore: RootStore }>, { activeRow, activeItemIndex, openCategories: any[], categoryInEditMode, inputValue, draggingOver }> {
    categoriesContainer;

    constructor(props: IProps) {
        super(props);
        const query = new URLSearchParams(props.location.search);
        this.state = {
            activeItemIndex: query['category'] ? query['category'] : '',
            openCategories: [],
            categoryInEditMode: '',
            inputValue: '',
            draggingOver: '',
            activeRow: null
        }
    }

    handleKeyBoardEventOnEdit = (event: any, path, item) => {
        switch (event.keyCode) {
            //ENTER
            case 13:
                event.preventDefault();
                event.stopPropagation();
                this.handleInputBlur(item);
                break;
            //ESCAPE
            case 27:
                event.preventDefault();
                event.stopPropagation();
                this.setState({
                    categoryInEditMode: '',
                    inputValue: ''
                });
                break;
            default:
                break;
        }
    }
    categoriesList = (): ICategoryModel[] => {
        return this.props.rootStore.partStore.categoriesList ? this.props.rootStore.partStore.categoriesList['hydra:member'] : [];
    }
    handleInputBlur = (category) => {

        if (this.state.inputValue.length) {
            this.props.rootStore.partStore.partCategoriesUpdate(category.id, {
                ...clearObjectFromId(category),
                children: collapseObjectsWithId(category['children']),
                name: this.state.inputValue
            }).then(() => {
                this.setState({
                    categoryInEditMode: '',
                    inputValue: ''
                })
            })
        }
    }

    handleAddCategory = (insertTo?: ICategoryModel) => {
        this.props.rootStore.partStore.partCategoriesCreate({
            name: 'new category',
            parent: insertTo ? insertTo['@id'] : null
        }).then(newCategory => {
            this.setState({
                categoryInEditMode: newCategory.id,
                inputValue: newCategory['name'],
                openCategories: [...this.state.openCategories, insertTo],
            })
        })
    }

    pushLocation = (event: any, categories: Array<number>) => {
        event.stopPropagation();
        this.props.history.push({
            search: stringify({
                'category': categories
            })
        })
    };
    query = () => {
        return new URLSearchParams(this.props.location.search);
    }

    renderCategory(item: ICategoryModel, index: number, prefix = '', offset: number = 1, floor = 1) {
        const key = prefix + index;
        const query = this.query();
        const rowClass = (Number(query['category']) === item.id ? ' active' : '');
        const isEditMode = item.id === this.state.categoryInEditMode;
        const canEditCategory = this.props.rootStore.authStore.isGranted('ROLE_ADMIN') || this.props.rootStore.authStore.isGranted('ROLE_ENGINEER');

        return (
            <li key={key}>
                <div
                    className={['category-row', rowClass, this.state.activeRow === item.id ? 'active' : ''].join(' ')}
                    onClick={(e) => {
                        this.setState({
                            activeRow: item.id
                        });
                        if (this.state.activeRow === item.id) {
                            this.setState({
                                activeRow: null
                            });
                        }
                        this.pushLocation(e, [item.id, ...this.getChildrenList(item)])
                    }}>
                    <span className="list-dot"></span>
                    {isEditMode ? (
                        <input
                            type="text"
                            autoFocus={true}
                            value={this.state.inputValue}
                            onChange={(e: any) => this.setState({inputValue: e.target.value})}
                            onClick={e => e.stopPropagation()}
                            onBlur={() => this.handleInputBlur(item)}
                            onFocus={(e: any) => e.target.setSelectionRange(0, e.target.value.length)}
                            onKeyDown={(e: any) => this.handleKeyBoardEventOnEdit(e, item.id, item)}
                        />
                    ) : (
                        <span className={'categories-name'}>
                                {item['name']}
                            </span>
                    )}
                    {isEditMode ? (
                        <p className="controls">
                            <span
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.setState({
                                        categoryInEditMode: '',
                                        inputValue: ''
                                    })
                                }}>
                               <Icon path={mdiClose}/>
                            </span>
                        </p>
                    ) : canEditCategory && (
                        <div className="controls">
                            <span
                                onClick={e => {
                                    e.stopPropagation();
                                    this.handleAddCategory(item);
                                }}>
                                <Icon path={mdiPlus}/>
                            </span>
                            <span
                                onClick={e => {
                                    e.stopPropagation();
                                    this.setState({
                                        inputValue: item['name'],
                                        categoryInEditMode: item.id
                                    });
                                }}>
                                <Icon path={mdiSquareEditOutline}/>
                            </span>
                            <span
                                onClick={e => {
                                    e.stopPropagation();
                                    this.props.rootStore.partStore.partCategoriesDelete(item.id);
                                }}>
                               <Icon path={mdiClose}/>
                            </span>
                        </div>
                    )}
                </div>
                <ul className={`step-${floor}`}>
                    {item.children.map((item, index: number) => this.renderCategory(item, index, key + '-', offset + 1, floor + 1))}
                </ul>
            </li>
        );
    }

    render() {
        const canEditCategory = this.props.rootStore.authStore.isGranted('ROLE_ADMIN') || this.props.rootStore.authStore.isGranted('ROLE_ENGINEER');

        return (
            <div ref={node => this.categoriesContainer = node} className="categories">
                {this.props.rootStore.partStore.categoriesListLoading ? (
                    <Loader/>
                ) : (<div className="categories-list-wrap">
                        <div className="categories-list-heading">
                            <p className="categories-title">Categories</p>
                            {canEditCategory &&
                            <LinkButton className={'roundButton  tertiaryBtn'}
                                        onClick={(event) => this.handleAddCategory()}>
                                <Icon path={mdiPlus}/>
                                Add category
                            </LinkButton>
                            }
                        </div>

                        <ul>
                            {this.categoriesList().map((item, index: number) => this.renderCategory(item, index))}
                        </ul>
                    </div>
                )}

            </div>
        );
    }

    componentDidMount() {

        this.props.rootStore.partStore.partCategoriesFetch().then(() => {
            if (this.query()['category']) {
                const categoryID = Number(this.query()['category']);
                const category = this.findObjectByID(categoryID, this.categoriesList());
                if (category) {
                    this.setState({
                        ...{openCategories: this.getParentList(category)}
                    })
                }
            }
        }).catch(error => {
            throw new Error(error);
        });

    }

    findObjectByID = (id: number, categoriesList: Array<ICategoryModel>) => {
        for (let i = 0; i < categoriesList.length; i++) {
            const category = categoriesList[i];
            if (category.id === id) {
                return category;
            } else if (category['children'].length) {
                return this.findObjectByID(id, category['children']);
            }
        }
    }

    getParentList = (category, result: Array<string> = []) => {
        if (category['parent']) {
            result.push(category['parent']);
            const parentObject = this.findObjectByID(category['parent'], this.categoriesList());

            if (parentObject) {
                return this.getParentList(parentObject, result);
            }

            return this.getParentList({}, result);
        }
        return result;
    }

    getChildrenList = (category): Array<number> => {
        if (category['children'].length) {
            return category.children.reduce((prev: Array<string>, current) => {
                let res: Array<number> = [];
                if (current['children'].length) {
                    res = [...this.getChildrenList(current)]
                }
                return [...prev, ...res, current.id];
            }, []);
        } else {
            return [];
        }
    }
}


export default withRouter(Categories);
