import * as React from 'react';
import ResourceGridView from './../../components/grid/ResourceGridView';
import * as cells from './../../components/ui/cells';
import {formatCurrency} from "./OpportunityHelper";
import {useStore} from "../../context";
import {observer} from "mobx-react";
import StandalonePopover from "../../components/ui/StandalonePopover";
import OpportunityDetailsForm from "./OpportunityDetailsForm";
import {IListOpportunityModel} from "../../utils/models";
import moment from "moment";

const Opportunities = observer(() => {
    const {opportunityStore} = useStore();

    return (
        <div className='dashboard'>
            {opportunityStore.currentPlace === 'create' &&
            <StandalonePopover classNames={'scrollable'} columnCount={2} title={'Create opportunity'} closePopover={() => {
                opportunityStore.clearPlace();
            }}>
                <OpportunityDetailsForm/>
            </StandalonePopover>}
            <ResourceGridView<IListOpportunityModel>
                filterKeys={{
                    id: 'Id',
                    salesAgent: 'Assigned to',
                    customerName: 'Customer/Partner',
                    rate: 'Rating',
                    invited: 'Invited',
                    description: 'Description',
                    closeAt: 'Close date',
                    keyword: 'Keyword',
                    'quotes.type': 'Contract type',
                    'quotes.quotePartInfos.part.partNumber': 'Part number',
                    'quotes.quoteSystemInfos.modelNumber': 'System model number',
                    'quotes.quotePartInfos.part.name': 'Software name',
                    lastActivityTime: 'Last activity',
                    'quotes.quoteSystemInfos.serialNumber': 'System serial number',
                }}
                gridResource={opportunityStore}
                onCreate={() => {
                    opportunityStore.goCreate();
                }}
                onRowClick={(item) => {
                    opportunityStore.goUpdate(item['@id']);
                }}
                columns={[
                    {
                        name: 'Opportunity',
                        value: (item) => (
                            <div>
                                <div className="title">
                                    {item.title}
                                </div>
                                <div className="other-info">
                                    {/*{cells.statusCell(item)}*/}
                                    {/*<span className={'dot'}>•</span>*/}
                                    {cells.dateAndTimeCell(item.createdAt)}
                                </div>
                            </div>
                        ),
                    },
                    {
                        name: 'Value',
                        label: 'Total value',
                        value: (item) => item.totalValue ? formatCurrency(item.totalValue) : null
                    },
                    {
                        name: 'Customer',
                        label: 'Customer',
                        value: (item) => item.customerName
                    },
                    {
                        name: 'Close data',
                        label: 'Close date',
                        value: (item) => (
                            <div>{item.closeAt ? cells.dateTimeCell(item.closeAt) :
                                <span className={'not-available'}>N/A</span>}</div>
                        ),
                    },
                    {
                        name: 'Rate',
                        value: (item) => (
                            <div>{item.rate ? item.rate + '%' : <span className={'not-available'}>N/A</span>}</div>
                        ),
                    },
                    {
                        name: 'Alert',
                        value: (item) => (
                            <div>
                                {item.alert ? (
                                    <>
                                        <p> {item.alert.title}</p>
                                        {cells.dateAndTimeCell(item.alert.date)}
                                    </>
                                ) : <span className={'not-available'}>N/A</span>}
                            </div>
                        ),
                    },
                    {
                        name: 'Sales agent',
                        value: (item) => (
                            <div>{item.salesAgent ? item.salesAgent.username :
                                <span className={'not-available'}>N/A</span>}</div>
                        ),
                    },
                    {
                        name: 'Last activity',
                        value: (item) => <div>
                            {moment(item.lastActivity.createdAt).format('DD MMM \'YY HH:mm')}<br/>
                            <div className="other-info"><span
                                style={{whiteSpace: 'normal'}}>{item.lastActivity.username} {item.lastActivity.messageDetails}</span></div>
                        </div>
                    }
                ]}
                tableClass={'opportunities'}
            />
        </div>
    );
});


export default Opportunities;
