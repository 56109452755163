import React, {useState} from 'react';
import ResourceGridView from './../../components/grid/ResourceGridView';
import * as cells from './../../components/ui/cells';
import {useStore} from "../../context";
import StandalonePopover from "../../components/ui/StandalonePopover";
import SystemCreate from "./SystemCreate";
import EntityInfo from "../../components/ui/widgets/EntityInfo";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import {stringify} from "querystring";
import {SystemFullModel} from "../../utils/models";

const Systems = observer(() => {
    const {systemStore, authStore} = useStore();
    const history = useHistory();
    const [showCreate, setShowCreate] = useState(false)
    const [showInfoItem, setShowInfoItem] = useState<SystemFullModel | null>(null)
    const canEdit = authStore.isGranted('ROLE_ADMIN') || authStore.isGranted('ROLE_ENGINEER');


    return (
        <div className='dashboard'>
            {showCreate && <StandalonePopover classNames={'scrollable'} columnCount={2} closePopover={() => {
                setShowCreate(false)
            }}>
                <SystemCreate/>
            </StandalonePopover>}
            {showInfoItem && <StandalonePopover title={"System info"} closePopover={() => {
                setShowInfoItem(null)
            }}>
                <EntityInfo info={
                    [
                        {label: 'Model number', value: showInfoItem.modelNumber},
                        {label: 'Serial number', value: showInfoItem.serialNumber},
                        {label: 'Location', value: showInfoItem.location ? showInfoItem.location.searchText : null},
                        {label: 'System notes', value: showInfoItem.notes}
                    ]
                }/>
            </StandalonePopover>}
            <ResourceGridView<SystemFullModel>
                filterKeys={{
                    serialNumber: 'Serial number',
                    modelNumber: 'Model number',
                    'contract.customer.contactName': 'Customer name',
                    'contract.customer.name': 'Company name',
                }}
                gridResource={systemStore}

                columns={
                    [
                        {
                            name: 'Model',
                            value: (item) => (
                                <div>
                                    <div className="title">{item.modelNumber}</div>
                                    <div className="other-info">
                                        {item['@id'].split('/').reverse()[0]}
                                        {item.serialNumber ? <span className={'dot'}>•</span> : ''}
                                        {item.serialNumber}
                                    </div>
                                </div>
                            ),
                        },
                        {
                            name: 'Company/End user',
                            value: (item) => (
                                <div>
                                    {item.contract && item.contract.customer ?
                                        item.contract.customer['name'] : <span className={'not-available'}>N/A</span>}
                                </div>),
                        },
                        {
                            name: 'Partner',
                            value: (item) => {
                                let customerName = '';

                                if (item.contract &&
                                    item.contract.customer &&
                                    item.contract.customer.onBehalf &&
                                    item.contract.customer.onBehalf['name']) {
                                    customerName = item.contract.customer.onBehalf['name']
                                }

                                return (
                                    <div>
                                        {customerName ? customerName : <span className={'not-available'}>N/A</span>}
                                    </div>
                                )
                            },

                        },
                        {
                            name: 'SLA',
                            value: (item) => (
                                <div>
                                    {item.slaTime && item.slaTime.label ? item.slaTime.label :
                                        <span className={'not-available'}>N/A</span>}
                                </div>
                            ),
                        },
                        {
                            name: 'Contract due date',
                            value: (item) => {
                                if (item.contract && item.contract['endDate']) {
                                    return (
                                        <div>
                                            {item.contract['endDate'] ? cells.dateTimeCell(item.contract['endDate']) :
                                                <span className={'not-available'}>N/A</span>}
                                        </div>
                                    );
                                }
                            },
                        },

                    ]
                }
                onRowClick={(item) => {
                    if (item.contract) {
                        history.push({
                            pathname: item.contract.customer['@id'].replace('/api', ''),
                            search: stringify({systemId: item['@id'].split('/').reverse()[0]})
                        });

                        return;
                    }

                    setShowInfoItem(item);
                }}
                onCreate={canEdit ? () => {
                    setShowCreate(true)
                } : null}
                tableClass={'systems'}
            />
        </div>
    );
});


export default Systems;
