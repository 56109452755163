/* eslint-disable react/no-array-index-key */
import React, {useEffect, useState} from 'react';
import {Button, Link, Loader} from '../ui';
import NoResults from './NoResults';
import UltimatePaginationBasic from './Pagination';
import {Icon} from '@mdi/react';
import {mdiCloudDownloadOutline, mdiDotsHorizontal, mdiEyeOutline, mdiPlus} from '@mdi/js';
import ActiveFilters from "./ActiveFilter";
import {
    FlatActiveFiltersType,
    GridResourceCollectionInterface
} from "../../store/interfaces/GridResourceCollectionInterface";
import Filters from "./Filters";
import {observer} from "mobx-react";
import GridTab, {GridTabType} from "./GridTab";
import StandalonePopover from "../ui/StandalonePopover";
import {useHistory, useLocation} from "react-router-dom";
import {useQuery} from "../../utils/query";
import {stringify} from "querystring";
import SavedFilterSegments from "./SavedFilterSegments";
import {useStore} from "../../context";

export type ChildGridType<Resource> = {
    title: string,
    getChildren: (item: Resource) => any[]
    onRowClick: (item, e: React.MouseEvent) => any
    getColumns: (resource: Resource) => Array<GridColumnType<any>>,
}
export type GridPanelCustomType = {
    label: string,
    icon: string,
    classes: string,
    onClick: (e: React.MouseEvent) => void;
}

type GridOptionItem<Resource> = {
    color: string,
    icon: string,
    label: string,
    onClick: (item: Resource, e: React.MouseEvent) => void
}

export type GridColumnType<Resource> = {
    name: string,
    label?: ((items: Resource[]) => any) | string,
    value: (item: Resource) => any | string | null,
    visible?: boolean,
} | string

export type GridProps<Resource> = {
    title?: string,
    columns: Array<GridColumnType<Resource>>,
    panels?: (activeTab: string | null) => GridPanelCustomType[]
    gridResource: GridResourceCollectionInterface<Resource>,
    trProps?: (item: Resource, index: number) => any,
    onRowClick?: (item: Resource, e: React.MouseEvent) => any,
    onCreate?: (e: React.MouseEvent) => any,
    childGrids?: ChildGridType<Resource>[],
    filterKeys?: FlatActiveFiltersType,
    options?: Array<GridOptionItem<Resource>>
    tabs?: GridTabType,
    filtersUpdateClb?: (filters) => any,
    tableClass?
}

export default observer(function ResourceGridView<Resource>(props: GridProps<Resource>) {
    const location = useLocation();
    const history = useHistory();
    const query = useQuery();
    const {filterStore} = useStore();

    const [renderOptionsPopover, setRenderOptionsPopover] = useState(false);

    useEffect(() => {
        filterStore.load();
        if (filterStore.lastActiveSegment && !query['_page'] && !query['showGridEl'] && history.length === 0) {
            history.push({search: filterStore.lastActiveSegment.filter})
        }
        filterStore.updateStoreQuery();
        props.gridResource.fetchCollection(fetchCollectionFilters());
        if (props.filtersUpdateClb) {
            props.filtersUpdateClb(fetchCollectionFilters())
        }
    }, [location.search]);

    useEffect(() => {

        return () => {
            filterStore.clearLastActiveSegment()
        }
    }, [])

    const fetchCollectionFilters = () => {
        if (filterStore.tabsListPerPage?.tabs) {
            return {...query, ...filterStore.tabsListPerPage[filterStore.activeTab].additionalQuery}
        }
        return query;
    };

    let rowElements: any = {};

    const getModuleName = () => {
        if (props.title) {
            return props.title;
        }
        let path = location.pathname.replace(new RegExp(/\/index|\/$/), '/home');
        let r = new RegExp(/\/([a-z_]+)($|\/[0-9]+$)/);
        return path.match(r) && path.match(r).length > 1 ? path.match(r)[1].split('_').join(' ') : '';
    };

    const renderHeader = (columns, index) => {
        let th = [];
        let sort = [];
        let StyledLink = Link;

        if (props.gridResource.collectionResponse["hydra:search"]) {
            props.gridResource.collectionResponse["hydra:search"]['hydra:mapping'].forEach(searchItem => {
                if (searchItem.variable.indexOf('_order') >= 0) {
                    sort.push(searchItem.property);
                }
            });
        }

        columns.forEach((item, i) => {
            let name = item.name;
            let thProps = {key: `grid-th-${i}`};

            let value = item.name;
            if (typeof item.label === "function") {
                value = item.label(props.gridResource.collectionResponse["hydra:member"]);
            } else if (item.label) {
                value = item.label;
            }

            let href;

            const cleanQuery = (query) => {
                let res = {};
                Object.keys(query).forEach(key => {
                    if (key.indexOf('_order') === -1) {
                        res[key] = query[key];
                    }
                })

                return res;
            };

            if (sort.length > 0 && sort.indexOf(name) >= 0) {
                if (Object.keys(query).indexOf(('_order[' + name + ']')) >= 0) {
                    let orderDirection = query['_order[' + name + ']'] === 'desc' ? 'asc' : 'desc';
                    href = {
                        ...location,
                        query: {
                            ...cleanQuery(query),
                            ['_order[' + name + ']']: orderDirection
                        }
                    }
                } else {
                    href = {
                        ...location,
                        query: {
                            ...cleanQuery(query),
                            ['_order[' + name + ']']: 'desc'
                        }
                    }
                }
            }

            let content;

            if (sort.length > 0 && sort.indexOf(name) >= 0) {
                let linkClass = Object.keys(query).indexOf(('_order[' + name + ']')) >= 0 ? query['_order[' + name + ']'] : '';
                content = <StyledLink className={'sort-link ' + linkClass} to={href}>{value}</StyledLink>;
            } else {
                content = <span>{value}</span>;
            }

            th.push(<div {...thProps}>{content}</div>);
        });
        if (props.options && props.options.length > 0) {
            th.push(<div key={index}></div>);
        }

        return th;
    };

    const getHydratedColumns = (columns: GridColumnType<Resource>[]) => {
        let res: typeof columns = [];
        columns.forEach((item) => {
            if (typeof item === 'string') {
                res.push({name: item, label: item, value: null});
            } else {
                if (item.visible === undefined) {
                    res.push(item);
                } else {
                    let isVisible = item.visible;

                    if (isVisible) {
                        res.push(item);
                    }
                }
            }
        });
        return res;
    };

    const renderRows = (rows: Resource[] | any, hydratedColumns, noChild: boolean = false, onRowClick: (item: Resource, e) => Function) => {
        const rowPropsClb = props.trProps;
        let tr = [];

        rows.forEach((item, ii) => {
            let td = [];
            let rowProps = {key: `grid-tr-${ii}`};

            const id = item['@id'] ? item['@id'].split('/').reverse()[0] : ii;
            hydratedColumns.forEach((column, ci) => {
                let dataProps = {key: `grid-td-${ci}-${ii}`};

                if (column.dataProps) {
                    const additionalDataProps = typeof column.dataProps === 'object' ? column.dataProps : column.dataProps(item, ci);
                    dataProps = {...dataProps, ...additionalDataProps};
                }

                let value = item[column.name];

                if (column.value) {
                    value = column.value(item, ci) || <span className={'not-available'}>N/A</span>;
                }

                if (typeof value === 'string') {
                    value = value.trim();
                }
                const tdProps = (column.tdProps ? column.tdProps : {});
                td.push(<div className={'table-row__item'} {...tdProps} {...dataProps}>
                    {value ? value : (
                        <span className="not-available">N/A</span>)}
                    {props.options && renderOptionsPopover &&
                    <StandalonePopover title={null} closePopover={() => setRenderOptionsPopover(false)}>
                        <div className={'dropdown-helpers'}>
                            {props.options.map((elem, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <span key={index} onClick={(e) => elem.onClick(item, item)}>
                                    <Icon path={elem.icon}/>
                                    {elem.label}
                                </span>))}
                        </div>
                    </StandalonePopover>}
                </div>);
            });

            //TABLE OPTIONS
            if (props.options && props.options.length > 0 && !noChild) {
                td.push(<div className={'dropdown'} onClick={e => e.stopPropagation}
                             key={rowProps.key + '-options'}>
                    <span> <Icon path={mdiDotsHorizontal}/> </span>
                    <div className={'dropdown-helpers'} onClick={
                        (e) => {
                            e.stopPropagation();
                        }}>
                    <span onClick={(e) => {
                        e.stopPropagation();
                        history.push({search: stringify({...query, showGridEl: ii})})
                        if (query['showGridEl'] === String(ii)) {
                            history.push({search: stringify({...query, showGridEl: undefined})})
                        }
                    }}>
                        <Icon path={mdiEyeOutline}/>
                        {query['showGridEl'] === String(ii) ? 'Hide' : 'Show'}
                    </span>
                        {props.options.map((elem, index) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <span key={index} onClick={(e) => elem.onClick(item, e)}>
                                         <Icon path={elem.icon}/>
                                    {elem.label}
                                    </span>
                            )
                        })}
                    </div>
                </div>)
            }

            if (rowPropsClb) {
                const additionalRowProps = typeof rowPropsClb === 'object' ? rowPropsClb : rowPropsClb(item, ii);
                rowProps = {...rowProps, ...additionalRowProps};
            }
            tr.push(
                <div ref={node => rowElements[id] = node}
                     onClick={(e) => {

                         onRowClick && onRowClick(item, e)
                     }}{...rowProps}
                     className={query['showGridEl'] === String(ii) && !noChild ? 'table-row active' : 'table-row'}>
                    {td}
                </div>
            );

            if (!noChild && props.childGrids) {
                if (query['showGridEl'] === String(ii)) {
                    props.childGrids.forEach((childitem, childIndex) => {
                        const grid = renderChildGrid(childitem, item, hydratedColumns.length, ii + '-' + childIndex);
                        if (grid) {
                            tr.push(grid);
                        }
                    })
                }
            }
        });

        return tr;
    };

    const renderChildGrid = (child: ChildGridType<Resource>, parent: Resource, parentColsLength: number, index: string) => {
        const items = child.getChildren(parent);
        if (!items.length) {
            return null;
        }
        const hydratedColumns = getHydratedColumns(child.getColumns(parent));
        return (
            <div key={index} className="child-table nestable">
                <div className={'table-heading'}>
                    <div className={'table-row heading'}>
                        <div className="title"><i>{child.title}</i></div>
                    </div>
                    <div className={'table-row'}>{renderHeader(hydratedColumns, index)}</div>
                </div>
                <div className={'table-body'}>
                    {renderRows(items, hydratedColumns, true, child.onRowClick)}
                </div>
            </div>
        );
    };

    const renderGrid = () => {
        if (!props.gridResource.collectionResponse) {
            return null;
        }
        const hydrated = getHydratedColumns(props.columns);
        return (
            // <ReactCSSTransitionGroup
            //     transitionName="fade"
            //     transitionAppear={true}
            //     transitionAppearTimeout={250}
            //     transitionEnterTimeout={250}
            //     transitionLeaveTimeout={200}>
            <>
                <div className={'table-wrap'}>

                    <div className={`main-table table-${props.tableClass}`}>
                        <div className={'table-head'}>
                            <div className={'table-row heading'}>{renderHeader(hydrated, 'main')}</div>
                        </div>
                        <div className={'table-body'}>
                            {renderRows(
                                props.gridResource.collectionResponse["hydra:member"],
                                hydrated,
                                false,
                                props.onRowClick
                            )}
                        </div>
                    </div>
                    {renderPagination()}
                </div>
                {props.gridResource.collectionResponse["hydra:member"].length === 0 && (<NoResults/>)}
            </>
            // </ReactCSSTransitionGroup>
        );
    }

    const renderPagination = () => {
        if (props.gridResource.collectionResponse["hydra:view"]) {
            let viewMeta = props.gridResource.collectionResponse["hydra:view"];
            let reg = new RegExp(/_page=([0-9]+)/);

            let currentMatch = viewMeta['@id'].match(reg);

            if (!currentMatch) return;
            let currentPage = currentMatch[1];

            let pages = viewMeta['hydra:last'].match(reg)[1];
            if (pages > 1) {
                return (
                    <UltimatePaginationBasic
                        currentPage={parseInt(currentPage)}
                        totalPages={parseInt(pages)}
                        onChange={(page) =>
                            history.push({
                                search: stringify({
                                    ...query,
                                    _page: page
                                })
                            })
                        }
                    />
                )
            }
        }
    };
    const downloadCsv = async () => {
        if (!props.gridResource.fetchCollectionReport) {
            return;
        }
        props.gridResource.fetchCollectionReport(fetchCollectionFilters());
    };
    const renderPanelButtons = () => {
        if (!props.panels) {
            return null;
        }
        const panels = props.panels(filterStore.activeTab);
        if (!panels || !panels.length) {
            return null;
        }
        return panels.map((panel, i) => <Button
            key={i}
            classes={panel.classes}
            icon={panel.icon}
            onClick={panel.onClick}
            disabled={props.gridResource.isLoading}>
            {panel.label}
        </Button>)
    }
    return (
        <div className='grid-wrap'>
            <div className="grid">
                <div className="user-bar">

                    <div className="grid-head">
                        <p className='header-title'>{getModuleName()}</p>

                        <SavedFilterSegments/>

                        <div className={'control-buttons'}>

                            {props.gridResource.fetchCollectionReport && <Button
                                classes={'secondaryBtn'}
                                icon={mdiCloudDownloadOutline}
                                onClick={downloadCsv}
                                disabled={props.gridResource.isLoading}>
                                Download csv
                            </Button>}
                            {props.onCreate && <Button
                                classes={'tertiaryBtn'}
                                icon={mdiPlus}
                                onClick={props.onCreate}
                                disabled={props.gridResource.isLoading}>
                                Create {getModuleName()}
                            </Button>}
                            {renderPanelButtons()}
                        </div>

                    </div>

                    {filterStore.tabsListPerPage &&
                    <GridTab tabs={filterStore.tabsListPerPage} active={filterStore.activeTab}
                             handleTabChange={(tab => {
                                 let newQuery = {...query, activeTab: tab, ...filterStore.tabsListPerPage[tab].additionalQuery};
                                 filterStore.setCurrentTab(tab);
                                 history.push({search: stringify(newQuery)});
                             })}/>}

                    <div className="filters">
                        {props.filterKeys && Object.keys(props.filterKeys).length ? <div className="filters-list">
                            <ActiveFilters filterKeys={props.filterKeys}/>
                        </div> : null}

                        <div className="filters-button">
                            {props.filterKeys && Object.keys(props.filterKeys).length ?
                                <Button classes={"transparent filters"} icon={mdiPlus}
                                        onClick={() => {
                                            filterStore.setContextPlace("filters_form")
                                        }}>
                                    Filters
                                </Button> : null}

                        </div>
                    </div>

                </div>
                {(filterStore.contextPlace === 'filters_form' || filterStore.contextPlace === 'update_segment') &&
                <StandalonePopover
                    title={null}
                    closePopover={() => {
                        filterStore.setContextPlace('grid');
                    }}
                    size={'ld'}
                    darken={false}>

                    <Filters filterKeys={props.filterKeys}/>

                </StandalonePopover>}
                {props.gridResource.isLoading && (<Loader/>)}
                {!props.gridResource.isLoading && renderGrid()}
            </div>
        </div>
    );

});
