import React from 'react';
import {Link} from '../../components/ui';
import {inject, observer} from "mobx-react";
import RootStore from "../../store/mobx";
import Icon from '@mdi/react'
import {mdiSettingsOutline} from '@mdi/js'

@inject("rootStore") @observer
class Menu extends React.Component<{ items, isGranted } & Partial<{ rootStore: RootStore }>, { activeRow }> {
    private toggleTimer;
    constructor(props) {
        super(props);
        this.toggleTimer = -1;
        this.state = {
            activeRow: null
        };
    }

    renderEmbeddedList = (item, index) => {

        return (
            <div
                className={'sidebar-item embedded' + (item.reverseDirection ? ' up' : '')}
                key={index}>
                <p>
                    {item.icon && <img alt={'img'} className={'fa fa-' + item.icon}/>}
                    <span className="sidebar-item-label">{item.label}</span>
                </p>
                <ul>
                    {item.items.map((emItem, ii) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index + '_' + ii}>
                            <Link className={'sidebar-item'} to={emItem.path}>
                                <p>
                                    {emItem.icon && <i className={'fa fa-' + emItem.icon}/>}
                                    <span className="sidebar-item-label">{emItem.label}</span>
                                </p>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    render() {
        const isOpened = this.props.rootStore.uiStore.isOpenMenu;

        return (
            <React.Fragment>
                {isOpened && <div onClick={this.props.rootStore.uiStore.hideMenu} className="overlay"> </div>}
                <div className={`sidebar ${isOpened ? 'full' : ''}`}>

                    {this.props.items.map(
                        (item, i) => {
                            if ((!item.path && !item.items) || !item.isGranted) {
                                return null;
                            }
                            return item.path ? (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={i}
                                     onClick={() => {
                                         this.setState({
                                             activeRow: i,
                                         });

                                         if (this.state.activeRow === i) {
                                             this.setState({
                                                 activeRow: null,
                                             })
                                         }
                                         this.props.rootStore.uiStore.hideMenu()
                                     }}
                                     className={`sidebar-item ${this.state.activeRow === i ? 'active' : ''}`}
                                >
                                    {/* eslint-disable-next-line react/no-array-index-key */}
                                    <Link key={i} to={item.path}>
                                        <div className="sidebar-item-ico">
                                            {item.icon && <Icon path={item.icon}/>}
                                        </div>
                                        <span className="sidebar-item-label">{item.label}</span>
                                    </Link>
                                </div>
                            ) : item.items ? this.renderEmbeddedList(item, i) : null;
                        })
                    }
                    {this.props.isGranted('ROLE_ADMIN') && (
                        <div className={'sidebar-config'} onClick={() => {
                            this.setState({
                                activeRow: 'settings',
                            })
                        }}>
                            <Link
                                className={`sidebar-item  sidebar-item-ico ${this.state.activeRow === 'settings' ? 'active' : ''}`}
                                to={"/settings"}>
                                <Icon path={mdiSettingsOutline}/>

                            </Link>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}


export default Menu;
