import React, {useEffect} from 'react';
import {useStore} from "../../context";
import {observer, Observer} from "mobx-react";
import {useParams} from "react-router";
import {Link, Loader} from "../../components/ui";
import StandalonePopover from "../../components/ui/StandalonePopover";
import {Icon} from "@mdi/react";
import {
    mdiAccount,
    mdiAccountConvert,
    mdiCheckCircleOutline,
    mdiChevronLeft, mdiClipboardOutline, mdiClockOutline,
    mdiServer
} from "@mdi/js";
import {Media} from 'react-breakpoints'
import {SupportRequestStatusColorBlock} from "../../components/ui/widgets/TopInfoBlock";
import {SupportRequestSelectSystemForm} from "./SupportRequestSelectSystemForm";
import {SupportRequestSelectProjectForm} from "./SupportRequestSelectProjectForm";
import CreateTicketWithSelectedForm from "../tickets/CreateTicketWithSelectedForm";
import {SupportRequestSelectCustomerForm} from "./SupportRequestSelectCustomerForm";
import Timer from "../../components/ui/widgets/Timer";
import {ActivityLogSupportRequest} from "./ActivityLogSupportRequest";

const SupportRequestItem = observer(() => {
    const {supportRequestStore} = useStore();
    let { requestId } = useParams();

    useEffect(() => {
        supportRequestStore.loadCurrent(requestId);
    }, [requestId]);
    if (!supportRequestStore.current) {
        return <Loader/>
    }



    const isCustomerSelected = supportRequestStore.current.customer.customerRef;
    const currentSystemsList = supportRequestStore.currentSystemsList;
    const currentProjectsList = supportRequestStore.currentProjectsList;
    const systemOrProjectSelected = supportRequestStore.current.relatedSystem || supportRequestStore.current.relatedProject;
    const isThereSystemsInList = currentSystemsList?.state === 'fulfilled' && currentSystemsList?.value.length > 0;
    const isThereOnlyOneSystemInList = currentSystemsList?.state === 'fulfilled' && currentSystemsList?.value.length === 1;
    const isThereProjectsInList = currentProjectsList?.state === 'fulfilled' && currentProjectsList?.value.length > 0;
    const isThereOnlyOneProjectInList = currentProjectsList?.state === 'fulfilled' && currentProjectsList?.value.length === 1;
    const notRenderSystemsButton = supportRequestStore.current.ticketRef || (isThereOnlyOneSystemInList && systemOrProjectSelected && !isThereProjectsInList);
    const notRenderProjectsButton = supportRequestStore.current.ticketRef || (isThereOnlyOneProjectInList && systemOrProjectSelected && !isThereSystemsInList);

    const convertButtonColor = systemOrProjectSelected ? 'black' : 'grey';

    return (<div className='center-form-container'>
        {supportRequestStore.contextPlace === 'convert' && !systemOrProjectSelected &&
        <StandalonePopover title={`Convert to ticket`}
                           size={'bd'}
                           closePopover={supportRequestStore.clearContextPlace}>
            <div>{isCustomerSelected ? 'You should select project or system first' : 'You should select customer first'}</div>
        </StandalonePopover>}
        {supportRequestStore.contextPlace === 'convert' && systemOrProjectSelected &&
        <StandalonePopover title={`Convert to ticket`}
                           size={'bd'}
                           closePopover={supportRequestStore.clearContextPlace}>
            <CreateTicketWithSelectedForm
                title={supportRequestStore.title}
                system={supportRequestStore.current.relatedSystem}
                project={supportRequestStore.current.relatedProject}
                createTicket={supportRequestStore.createTicket}
            />
        </StandalonePopover>}
        {supportRequestStore.contextPlace === 'select_customer' &&
        <StandalonePopover title={`Select customer`}
                           closePopover={supportRequestStore.clearContextPlace}>
            <SupportRequestSelectCustomerForm/>
        </StandalonePopover>}
        {supportRequestStore.contextPlace === 'select_system' &&
        <StandalonePopover title={`Select system for ticket`}
                           closePopover={supportRequestStore.clearContextPlace}>
            <SupportRequestSelectSystemForm/>
        </StandalonePopover>}
        {supportRequestStore.contextPlace === 'select_project' &&
        <StandalonePopover title={`Select project for ticket`}
                           closePopover={supportRequestStore.clearContextPlace}>
            <SupportRequestSelectProjectForm/>
        </StandalonePopover>}

        <Media>
            {({breakpoints, currentBreakpoint}) => {
                return (<Observer>
                    {() => {
                        return breakpoints[currentBreakpoint] > breakpoints.desktop &&
                            (<div className={'left-panel'}>
                                <Link to={"/support_requests"} className="back"><Icon path={mdiChevronLeft}/>
                                    Back to support requests list
                                </Link>
                                <div className={'info-block'}>
                                    <div className={'label'}>Status</div>
                                    <div className={'value'}>
                                        <SupportRequestStatusColorBlock status={supportRequestStore.current.status} />
                                    </div>
                                    <div className={'button'} onClick={supportRequestStore.goChangeStatus}>
                                        <Icon path={mdiCheckCircleOutline}/> Change
                                    </div>
                                </div>
                                {isCustomerSelected && <div className={'info-block'}>
                                    <div className={'label'}>Assigned Client</div>
                                    <div className={'value'}>
                                        {supportRequestStore.current.customer.name}</div>
                                    {!supportRequestStore.current.ticketRef &&
                                    <div className={'button'} onClick={supportRequestStore.goSelectCustomer}>
                                        <Icon path={mdiAccount}/> Change
                                    </div>}
                                </div>}
                                {supportRequestStore.current.relatedSystem && <div className={'info-block'}>
                                    <div className={'label'}>System</div>
                                    <div className={'value'}>
                                        {supportRequestStore.current.relatedSystem.modelNumber}
                                    </div>
                                    <div className={'button'} onClick={supportRequestStore.goSelectSystem}>
                                        <Icon path={mdiServer}/> Change
                                    </div>
                                </div>}
                                {supportRequestStore.current.relatedProject && <div className={'info-block'}>
                                    <div className={'label'}>Project</div>
                                    <div className={'value'}>
                                        {supportRequestStore.current.relatedProject.name}
                                    </div>
                                    <div className={'button'} onClick={supportRequestStore.goSelectProject}>
                                        <Icon path={mdiClipboardOutline}/> Change
                                    </div>
                                </div>}
                                {supportRequestStore.ticketDataEngineerName && <div className={'info-block'}>
                                    <div className={'label'}>Assigned Engineer</div>
                                    <div className={'value'}>
                                        {supportRequestStore.ticketDataEngineerName}
                                    </div>
                                </div>}
                                <div className={'buttons'}>
                                    {!isCustomerSelected &&
                                    <div className="buttons-item" onClick={supportRequestStore.goSelectCustomer}>
                                        <Icon path={mdiAccount}/>
                                        Select customer
                                    </div>}
                                    {isThereSystemsInList && !notRenderSystemsButton && !supportRequestStore.current.relatedSystem &&
                                    <div className="buttons-item" onClick={supportRequestStore.goSelectSystem}>
                                        <Icon path={mdiServer}/>
                                        Select system
                                    </div>}
                                    {isThereProjectsInList && !notRenderProjectsButton && !supportRequestStore.current.relatedProject &&
                                    <div className="buttons-item" onClick={supportRequestStore.goSelectProject}>
                                        <Icon path={mdiClipboardOutline}/>
                                        Select project
                                    </div>}

                                    {supportRequestStore.current.ticketRef === null &&
                                    <div className="buttons-item" onClick={supportRequestStore.goConvert}>
                                        <Icon style={{color: convertButtonColor}} path={mdiAccountConvert}/>
                                        <span style={{color: convertButtonColor}}>Convert request to ticket</span>
                                    </div>}
                                </div>
                            </div>)
                    }}
                </Observer>);
            }}
        </Media>
        <div className={'form-wrapper'}>
            <div className="top-info">
                <div className="title">
                    {supportRequestStore.title}
                </div>
                {supportRequestStore.showTimer &&
                <div className={`time ${supportRequestStore.isThereTimeLeft ? 'green' : 'red'}`}>
                    <Icon path={mdiClockOutline}/>
                    <p>SLA:</p>
                    <Timer
                        updateOffset={1000}
                        workMode={supportRequestStore.isThereTimeLeft ? 'reverse' : 'straight'}
                        targetDate={supportRequestStore.timeLeft}
                    />
                </div>}
                {supportRequestStore.showSlaSpent && <div className={"time"}>
                    <Icon path={mdiClockOutline}/>
                    <p>SLA:</p>
                    {supportRequestStore.formattedSlaSpent}
                </div>}
            </div>

            <div className="top-info">
                {supportRequestStore.current.ticketRef !== null &&
                <Link to={`/tickets/${supportRequestStore.current.ticketRef}`}>Ticket
                    #{supportRequestStore.current.ticketRef}</Link>}
            </div>

            <ActivityLogSupportRequest activitiesLog={supportRequestStore} showTitle={false}/>

        </div>
    </div>)
})


export default SupportRequestItem;