import * as React from 'react';
import {Field, Form} from 'react-final-form'
import {Input} from "../../../components/ui";
import {useStore} from "../../../context";
import {observer} from "mobx-react";
import {ContractDetailsFormSharedFieldsSet, TimeMinStepFieldAdapter} from "./ContractDetailsForm";
import {ContractPayAsYouGoFormInterface} from "../../../store/mobx/ContractStore";
import {FinalButtonSet} from "../../../components/ui/form";


const ContractPayAsYouGoForm = observer(({mode}) => {
    const {customerStore} = useStore();

    return <Form<ContractPayAsYouGoFormInterface> onSubmit={customerStore.updateOrCreateContract}
                                                  initialValues={customerStore.contractStore.currentContract}>
        {({handleSubmit, pristine, submitting, form}) => (
            <form onSubmit={handleSubmit}>
                <ContractDetailsFormSharedFieldsSet/>

                <Field name='rate' label={"Rate per period"} component={Input} canEdit={customerStore.canEdit}/>
                <Field name={"timeMinStep"} component={TimeMinStepFieldAdapter}
                       canEdit={customerStore.contractStore.canChangeTimeMinStep}/>

                <FinalButtonSet pristine={pristine} reset={form.reset} submitting={submitting} mode={mode}/>
            </form>
        )}
    </Form>
});

export default ContractPayAsYouGoForm;
