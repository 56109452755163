import * as React from 'react';
import {Field, Form} from "react-final-form";
import {Input} from "../../components/ui";
import {FinalButtonSet, SwitchToggler} from "../../components/ui/form";


function ContractProfitCostForm({handleSubmit, initialValues}) {
    return (
        <Form onSubmit={handleSubmit}
              initialValues={initialValues}>
            {({handleSubmit, pristine, submitting}) =>
                <form onSubmit={handleSubmit}>
                    <Field
                        name={'sharedCost'}
                        label={'Shared Cost'}
                        component={Input}
                    />
                    <Field
                        name={'remove'}
                        label={'Remove in hour'}
                        component={SwitchToggler}
                    />
                    <FinalButtonSet submitting={submitting} pristine={pristine} label={"Set"} mode={"RELATIVE"}/>
                </form>
            }
        </Form>

    );
}

export default ContractProfitCostForm
