import React from 'react';
import ResourceGridView from "../../components/grid/ResourceGridView";
import {useStore} from "../../context";
import CustomerDetailsForm from "./CustomerForm/CustomerDetailsForm";
import {observer} from "mobx-react";
import {CustomerFullModel} from "../../utils/models";
import StandalonePopover from "../../components/ui/StandalonePopover";

const CustomersGrid = observer(() => {
    const {customerStore} = useStore();
    return <div className='dashboard'>
        {customerStore.currentPlace === 'create' &&
        <StandalonePopover columnCount={3} title={"Create customer"} closePopover={customerStore.clearPlace} classNames={'scrollable'}>
            <CustomerDetailsForm mode={"RELATIVE"}/>
        </StandalonePopover>}
        <ResourceGridView<CustomerFullModel>
            gridResource={customerStore}

            filterKeys={{
                name: 'Company name',
                type: 'Type',
                onBehalf: 'Partner',
            }}
            columns={[
                {
                    name: 'name',
                    label: 'Name',
                    value: (item) => (
                        <div>
                            <div className="title">
                                <div>{item.name} (<b>{item.code}</b>)</div>
                            </div>
                            <div className="other-info">
                                {item['@id'].split('/').reverse()[0]}
                                {/*<span className={'dot'}>•</span>*/}
                            </div>
                        </div>
                    ),
                },
                {
                    name: 'type',
                    label: 'Type',
                    value: (item) => (
                        <div>{item.type ? item.type : <span className={'not-available'}>N/A</span>}</div>),
                },
                {
                    name: 'contact',
                    label: 'Contact person',
                    value: (item) => (<div>{item.contactName ? item.contactName :
                        <span className={'not-available'}>N/A</span>}</div>),
                },
                {
                    name: 'phone',
                    label: 'Phone',
                    value: (item) => (<div>{item.contactNumber ? item.contactNumber :
                        <span className={'not-available'}>N/A</span>}</div>),
                },
            ]}
            onCreate={customerStore.goCreate}
            onRowClick={(item) => customerStore.goUpdate(item)}
            tableClass={'customers'}/>
    </div>
});


export default CustomersGrid;
