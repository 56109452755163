import React, {useEffect} from 'react';
import Icon from '@mdi/react'
import {mdiClose, mdiLogout, mdiMenu} from '@mdi/js';
import {Link} from "react-router-dom";
import Logo from "../../media/logo.png";
import {useStore} from "../../context";
import {observer} from "mobx-react";
import {Notification} from "../notification/Notification";

const Header = observer((props) => {
    const {username} = props;
    const {uiStore, notificationStore} = useStore();

    const isOpened = uiStore.isOpenMenu;
    useEffect(() => {
        notificationStore.fetchCollection();
        const intId = setInterval(notificationStore.fetchCollection, 20000);
        return () => clearInterval(intId);
    }, []);
    return (
        <div className='header'>
            <div className="logo">
                <div className="logo-item">
                    <img src={Logo} alt="logotype" className="logo__img"/>
                </div>
            </div>

            <div onClick={uiStore.toggleMenu} className="burger-menu">
                {!isOpened ? <Icon path={mdiMenu}/> :
                    <Icon path={mdiClose}/>}
            </div>

            <Notification/>

            <div className='header-right'>
                <div className='user'>
                    <div className='user-avatar'></div>
                    <div className='user-info'> {username}  </div>
                    <Link to='/logout' className={'logout'}> <Icon path={mdiLogout}/> </Link>
                </div>
            </div>
        </div>
    );
});

export default Header;
