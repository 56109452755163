import React from 'react';

import ResourceForm from './CancellationReasonForm';
import {confirm} from "../../../components/ui/dialogs";
import {GenericResourceStore} from "../../../store/mobx/GenericResourceStore";
import {observer} from "mobx-react";
import {Icon} from "@mdi/react";
import {mdiTrashCanOutline} from "@mdi/js";

type ResourceUpdateProps = {
    sharedGridResourceCollectionStore: GenericResourceStore,
    canRemove: boolean,
}
const ResourceUpdate = observer(({sharedGridResourceCollectionStore, canRemove}: ResourceUpdateProps) => {

    const handleDelete = (e) => {
        const position = {
            x: e.clientX,
            y: e.clientY,
        };
        confirm(position, 'Confirm delete action',
            'A you sure you want to delete this resource?').then(() => {
            sharedGridResourceCollectionStore.removeResource()
        });
    };

    return (
        <React.Fragment>
            <div className='remove' onClick={handleDelete}>
                <Icon path={mdiTrashCanOutline}/>
<p> Delete </p>
            </div>

            {sharedGridResourceCollectionStore.currentResource && <ResourceForm
                initialValues={sharedGridResourceCollectionStore.currentResource}
                onSubmit={sharedGridResourceCollectionStore.updateResource}
            />}
        </React.Fragment>
    );
});


export default ResourceUpdate;
