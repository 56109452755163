import React from 'react';
import {Field, Form} from 'react-final-form'
import {DateInput, Input} from './index';
import {FinalButtonSet, TimeInput} from "./form";

const AlertForm = ({currentItemAlert, onSubmit, values, removeAlert}) => {
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={values}
        >
            {({handleSubmit, pristine, submitting, error,submitError}) => (
                <React.Fragment>

                    {currentItemAlert && <div className='controls'>
                        {<a className="fa fa-trash red" onClick={removeAlert}/>}
                    </div>}
                    <form onSubmit={handleSubmit}>
                        <Field
                            label='Title'
                            name='title'
                            component={Input}
                            required={true}
                        />
                        <div className='custom-grid'>
                            <div className='cell-8'>
                                <div style={{marginTop: '20px'}}>
                                    <Field
                                        label='Date'
                                        name='dateAndTime.date'
                                        placeholder='Select date'
                                        component={DateInput}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className='cell-4'>
                                <TimeInput hoursName={'dateAndTime.hours'}
                                           minutesName={'dateAndTime.minutes'}
                                           label={'Time'}
                                           desc={'You will be notified 5 minutes prior'}/>
                            </div>

                            <FinalButtonSet submitting={submitting} pristine={pristine} mode={"RELATIVE"}/>
                        </div>
                    </form>
                </React.Fragment>
            )}
        </Form>

    )
};


export default AlertForm;
