import {action, computed, observable, runInAction} from "mobx";
import remotedev from 'mobx-remotedev';
import ApiClient from "../ApiClient";
import {handleFormSubmit, hydrateInitialValues} from "../../utils/form/handleFormSubmit";
import {CollectionResponseType, GridResourceCollectionInterface} from "../interfaces/GridResourceCollectionInterface";
import {SystemStore} from "./SystemStore";
import {AuthStore} from "./AuthStore";
import {RouterStore} from 'mobx-react-router';
import {idFromEntity, idFromIri} from "../../utils/iri";
import {FileAttachmentsInterface} from "../interfaces/FileAttachmentsInterface";
import {PartStore} from "./PartStore";
import {ContractStore} from "./ContractStore";
import UiBus from "../service/UiBus";
import AttachmentManager from "../service/AttachmentManager";
import {ProjectStore} from "./ProjectStore";
import {stringify} from "querystring";
import {SubmissionErrors} from "final-form";
import {
    ContractNavigationModel,
    CreatePartForMasterInput,
    CustomerFullModel, CustomerNavigationModel,
    IAttachmentModel, Iri,
    PartFullModel,
    ProjectNavigationModel, SystemNavigationModel,
    ReassignContractToCustomerInput,
    TicketFullModel
} from "../../utils/models";
import {ContractStatusType, ContractTypeListType} from "../enum/contractEnumeration";
import {formatCustomerContractName} from "../formatters";
import { v4 as uuidv4 } from 'uuid';

export type CustomerNavPlaceType =
    'contract'
    | 'system'
    | 'project'
    | 'part'
    | 'customer'
    | 'collapsed_system'
    | 'collapsed_part'
    | 'collapsed_project';

export enum CustomerTypeEnum {
    client = 'Client',
    partner = 'Partner'
}

type CustomerStoreContextPlace =
    'attachments'
    | 'add_requester'
    | 'expenses'
    | 'create_contract'
    | 'create_contract_select_type'
    | 'create_system'
    | 'create_project'
    | 'part_type_select'
    | 'part_second_step'
    | 'part_third_step'
    | 'parts_assigning'
    | 'create_ticket'
    | 'contract_reassign'
    | 'create_project_with_network_support';
type CustomerStorePlace = 'grid' | 'update' | 'create';
type AssignPartToType = "system" | "contract" | "project";

export interface ContractWrapper {
    contractId: number,
    contractNumber: string,
    type: ContractTypeListType,
    status: ContractStatusType,
    durationInDays: number,
    endDate: string | null,
    isRenewedContract: boolean,
    isActive: boolean,
    isActiveParent: boolean,
    isParent: boolean,
    isLast: boolean,
    wrappedSystems: SystemWrapper[],
    wrappedCollapsedSystems: SystemWrapper[],
    wrappedProjects: ProjectWrapper[],
    wrappedCollapsedProject: ProjectWrapper[],
    wrappedParts: PartWrapper[],
    wrappedCollapsedParts: PartWrapper[],
}

export interface SystemWrapper {
    systemId: number,
    serialNumber: string,
    modelNumber: string,
    name: string,
    isCollapsed: boolean,
    isActive: boolean,
    isActiveParent: boolean,
    isLast: boolean,
    isParent: boolean,
    wrappedParts: PartWrapper[],
    wrappedCollapsedParts: PartWrapper[],
}

export interface ProjectWrapper {
    projectId: number,
    name: string,
    isActive: boolean,
    isActiveParent: boolean,
    isParent: boolean,
    isLast: boolean,
    isCollapsed: boolean,
    wrappedParts: PartWrapper[],
    wrappedCollapsedParts: PartWrapper[],
}

export interface PartWrapper {
    partId: number,
    partNumber: string,
    isActive: boolean,
    isLast: boolean,
    isCollapsed: boolean,
    hasSpare: boolean,
    hasStock: boolean
}

@remotedev({global: true})
export class CustomerStore implements GridResourceCollectionInterface<CustomerFullModel>, FileAttachmentsInterface {
    public readonly contractStore: ContractStore;

    public readonly systemStore: SystemStore;
    public readonly partStore: PartStore;
    public readonly projectStore: ProjectStore;
    @observable customerNavigation: CustomerNavigationModel;
    @observable customer: CustomerFullModel | null;
    @observable selectedPartsToAssign: number[] = [];
    @observable assignPartTo: AssignPartToType | null;
    @observable collectionResponse: CollectionResponseType<CustomerFullModel> | null;
    @observable currentPlace: CustomerStorePlace = 'grid';
    @observable draftPart: Partial<CreatePartForMasterInput> | null;
    @observable stockPartToAssign: Iri | null;

    private readonly attachmentManager: AttachmentManager;

    constructor(private apiClient: ApiClient, private authStore: AuthStore, private uiBus: UiBus, private routing?: RouterStore) {
        this.systemStore = new SystemStore(apiClient, uiBus);
        this.partStore = new PartStore(apiClient, authStore, uiBus);
        this.contractStore = new ContractStore(apiClient, uiBus, routing);
        this.attachmentManager = new AttachmentManager(apiClient, this, uiBus);
        this.projectStore = new ProjectStore(apiClient, this.uiBus);
    }

    @observable private _isLoading: boolean = false;

    @computed get isLoading(): boolean {
        return this._isLoading || this.systemStore.isLoading || this.partStore.isLoading || this.contractStore.isLoading || this.projectStore.isLoading;
    }

    getEntityName: 'Customer';
    @observable _contextPlace: CustomerStoreContextPlace | null = null;

    @computed get contextPlace(): CustomerStoreContextPlace {
        if (this.partStore.currentPlace === 'part_second_step'
            || this.partStore.currentPlace === 'part_type_select'
            || this.partStore.currentPlace === 'part_third_step'
        ) {
            return this.partStore.currentPlace;
        }

        return this._contextPlace;
    }

    @computed get customerToUpdate() {
        if (!this.customer) {
            return null;
        }
        return {
            name: this.customer.name,
            type: this.customer.type,
            address: this.customer.addresses && this.customer.addresses.length ? this.customer.addresses[0].searchText : null,
            requesterCustomers: this.customer.requesterCustomers ? this.customer.requesterCustomers : [],
            email: this.customer.email,
            plainPassword: this.customer.plainPassword,
            contactNumber: this.customer.contactNumber,
            contactName: this.customer.contactName,
            onBehalf: this.customer.onBehalf,
            notes: this.customer.notes,
            code: this.customer.code,
        }
    }

    @observable private _activeTab: CustomerNavPlaceType = 'customer';

    @computed get activeTab(): CustomerNavPlaceType | null {
        return this._activeTab;
    }

    @computed get canUnassign(): boolean {
        return this.activeTab === 'system' && (this.authStore.hasRole('ROLE_ADMIN') || this.authStore.hasRole('ROLE_ENGINEER'));
    }

    @computed get canAddSystem(): boolean {
        return this.activeTab === 'contract' && this.contractStore.isActive && (this.authStore.hasRole('ROLE_ADMIN') || this.authStore.hasRole('ROLE_ENGINEER') || this.authStore.hasRole('ROLE_SALE'));
    }

    @computed get canAddPart(): boolean {
        return (this.activeTab === 'system' || this.activeTab === 'contract') && (this.authStore.hasRole('ROLE_ADMIN') || this.authStore.hasRole('ROLE_ENGINEER') || this.authStore.hasRole('ROLE_SALE'));
    }

    @computed get canRemovePart(): boolean {
        return this.activeTab === 'part' && (this.authStore.hasRole('ROLE_ADMIN') || this.authStore.hasRole('ROLE_ENGINEER') || this.authStore.hasRole('ROLE_SALE'));
    }

    @computed get canAddRequester(): boolean {
        return this.activeTab === 'customer' && !this.customer.parentCustomer;
    }

    @computed get canAddTicket(): boolean {
        return this.activeTab === 'system' || this.activeTab === 'project';
    }

    @computed get canShowExpense(): boolean {
        return this.activeTab === 'contract' && this.contractStore.isActive && (this.authStore.hasRole('ROLE_ADMIN') || this.authStore.hasRole('ROLE_ENGINEER') || this.authStore.hasRole('ROLE_SALE'));
    }

    @computed get canAddContract(): boolean {
        return this.activeTab === 'customer' && (this.authStore.hasRole('ROLE_ADMIN') || this.authStore.hasRole('ROLE_ENGINEER'));
    }

    @computed get canAddProject(): boolean {
        return this.activeTab === 'contract' && this.contractStore.isActive && (this.authStore.hasRole('ROLE_ADMIN') || this.authStore.hasRole('ROLE_ENGINEER') || this.authStore.hasRole('ROLE_SALE'));
    }

    @computed get canDisassembleContract(): boolean {
        return this.activeTab === 'contract' && this.contractStore.isExpired && (this.authStore.hasRole('ROLE_ADMIN'));
    }

    @computed get canReassignContract(): boolean {
        return this.activeTab === 'contract' && this.contractStore.isActive && this.authStore.hasRole('ROLE_ADMIN');
    }

    @computed get canRenewContract(): boolean {
        return this.activeTab === 'contract' && this.contractStore.isRenewableContractType
            && (this.authStore.hasRole('ROLE_ADMIN') || this.authStore.hasRole('ROLE_SALE'));
    }

    @computed get canEdit(): boolean {
        if (this.activeTab === 'collapsed_project' || this.activeTab === 'collapsed_system' || this._activeTab === 'collapsed_part') {
            return false;
        }
        const grantedRoles = ['ROLE_ADMIN', 'ROLE_ENGINEER', 'ROLE_SALE', 'ROLE_ALL_ACCOUNT']
        const can = this.authStore.roles.find(role => grantedRoles.includes(role)) !== undefined;
        if (this.activeTab === 'contract') {
            return can && this.contractStore.canEdit;
        } else if (this.activeTab === 'system') {
            return can && this.systemStore.canEdit;
        } else if (this.activeTab === 'project') {
            return can && this.projectStore.canEdit;
        } else {
            return can;
        }
    }

    @computed get canEditAdminUser(): boolean {
        return this.canEdit && this.authStore.roles.find(role => role === 'ROLE_ADMIN') !== undefined;
    }

    @computed get canSeeContractCosts(): boolean {
        return this.authStore.isGranted('ROLE_SHOW_CONTRACT_VALUE') || this.authStore.isGranted('ROLE_ALL_ACCOUNT');
    }

    @computed get renderContextMenu(): boolean {
        if (this.activeTab === 'collapsed_project' || this.activeTab === 'collapsed_system' || this.activeTab === 'collapsed_part') {
            return false;
        }
        const grantedRoles = ['ROLE_ADMIN', 'ROLE_ENGINEER', 'ROLE_SALE', 'ROLE_ALL_ACCOUNT'];
        const can = this.authStore.roles.find(role => grantedRoles.includes(role)) !== undefined;
        if (this.activeTab === 'contract') {
            return can && this.contractStore.canEdit;
        } else if (this.activeTab === 'system') {
            return this.systemStore.canEdit;
        } else if (this.activeTab === 'project') {
            return this.projectStore.canEdit;
        } else {
            return can;
        }
    }

    @computed get sortedContracts() {
        function sort(a: ContractNavigationModel, b: ContractNavigationModel) {
            if (a.status === b.status) {
                return 0;
            } else {
                if (a.status === 'active') {
                    return -1;
                }
                if (b.status === 'active') {
                    return 1;
                }
                if (a.status === 'closed') {
                    return -1;
                }
                if (b.status === 'closed') {
                    return 1;
                }
            }
        }

        return this.customerNavigation ? this.customerNavigation.contracts ? this.customerNavigation.contracts.slice().sort(sort) : [] : [];
    }

    @computed get wrappedContracts(): ContractWrapper[] {
        let wrappedContracts: ContractWrapper[] = [];
        this.sortedContracts.forEach((contract, contractIndex) => {
            let isActive = this.activeTab === 'contract' && this.contractStore.currentContract
                && this.contractStore.currentContract.id === contract.id;
            let isContractActiveParent = false;
            let isContractParent = false;


            let wrappedContractParts: PartWrapper[] = [];
            contract.parts.forEach((part, partIndex) => {
                isContractParent = true;
                let isPartActive = this.activeTab === 'part' && this.partStore.currentPart
                    && this.partStore.currentPart.id === part.id;
                if (isPartActive) {
                    isContractActiveParent = true;
                }
                wrappedContractParts.push({
                    partId: part.id,
                    partNumber: part.partNumber,
                    isActive: isPartActive,
                    hasSpare: part.hasSpare,
                    hasStock: part.hasStock,
                    isLast: partIndex + 1 === contract.parts.length
                        && contract.collapsedParts.length === 0
                        && contract.systems.length === 0
                        && contract.projects.length === 0
                        && contract.collapsedSystems.length === 0
                        && contract.collapsedProjects.length === 0,
                    isCollapsed: false
                })
            });
            let wrappedContractCollapsedParts: PartWrapper[] = [];
            contract.collapsedParts.forEach((collapsedPart, collapsedPartIndex) => {
                isContractParent = true;
                let isPartActive = this.activeTab === 'collapsed_part' && this.partStore.currentCollapsedPart
                    && this.partStore.currentCollapsedPart.id === collapsedPart.id;
                if (isPartActive) {
                    isContractActiveParent = true;
                }
                wrappedContractCollapsedParts.push({
                    partId: collapsedPart.id,
                    partNumber: collapsedPart.partNumber,
                    isActive: isPartActive,
                    hasSpare: collapsedPart.hasSpare,
                    hasStock: collapsedPart.hasStock,
                    isLast: collapsedPartIndex + 1 === contract.collapsedParts.length
                        && contract.systems.length === 0
                        && contract.projects.length === 0
                        && contract.collapsedSystems.length === 0
                        && contract.collapsedProjects.length === 0,
                    isCollapsed: true
                })
            });

            let wrappedSystems: SystemWrapper[] = [];
            contract.systems.forEach((system, systemIndex) => {
                let isSystemActive = this.activeTab === 'system' && this.systemStore.currentSystem
                    && this.systemStore.currentSystem.id === system.id;
                if (isSystemActive) {
                    isContractActiveParent = true;
                }
                isContractParent = true;

                let isSystemParent = false;
                let isSystemActiveParent = false;
                let wrappedSystemParts: PartWrapper[] = [];
                system.parts.forEach((part, partIndex) => {
                    isSystemParent = true;
                    let isPartActive = this.activeTab === 'part' && this.partStore.currentPart
                        && this.partStore.currentPart.id === part.id;
                    if (isPartActive) {
                        isSystemActiveParent = true;
                        isContractActiveParent = true;
                    }
                    wrappedSystemParts.push({
                        partId: part.id,
                        partNumber: part.partNumber,
                        hasSpare: part.hasSpare,
                        hasStock: part.hasStock,
                        isActive: isPartActive,
                        isLast: partIndex + 1 === system.parts.length && system.collapsedParts.length === 0,
                        isCollapsed: false
                    })
                });
                let wrappedSystemCollapsedParts: PartWrapper[] = [];
                system.collapsedParts.forEach((collapsedPart, collapsedPartIndex) => {
                    isSystemParent = true;
                    let isPartActive = this.activeTab === 'collapsed_part' && this.partStore.currentCollapsedPart
                        && this.partStore.currentCollapsedPart.id === collapsedPart.id;
                    if (isPartActive) {
                        isSystemActiveParent = true;
                        isContractActiveParent = true;
                    }
                    wrappedSystemCollapsedParts.push({
                        partId: collapsedPart.id,
                        partNumber: collapsedPart.partNumber,
                        hasSpare: collapsedPart.hasSpare,
                        hasStock: collapsedPart.hasStock,
                        isActive: isPartActive,
                        isLast: collapsedPartIndex + 1 === system.collapsedParts.length,
                        isCollapsed: true
                    })
                })
                wrappedSystems.push({
                    systemId: system.id,
                    serialNumber: system.serialNumber,
                    modelNumber: system.modelNumber,
                    name: system.name,
                    isLast: systemIndex + 1 === contract.systems.length
                        && contract.projects.length === 0
                        && contract.collapsedSystems.length === 0
                        && contract.collapsedProjects.length === 0,
                    isActive: isSystemActive,
                    isActiveParent: isSystemActiveParent,
                    isParent: isSystemParent,
                    isCollapsed: false,
                    wrappedParts: wrappedSystemParts,
                    wrappedCollapsedParts: wrappedSystemCollapsedParts,
                })
            });

            let wrappedProjects: ProjectWrapper[] = [];
            contract.projects.forEach((project, projectIndex) => {
                isContractParent = true;
                let isProjectActive = this.activeTab === 'project' && this.projectStore.currentProject
                    && this.projectStore.currentProject.id === project.id;
                if (isProjectActive) {
                    isContractActiveParent = true;
                }
                let isProjectParent = false;
                let isProjectActiveParent = false;
                let wrappedProjectParts: PartWrapper[] = [];
                project.parts.forEach((part, partIndex) => {
                    isProjectParent = true;
                    let isPartActive = this.activeTab === 'part' && this.partStore.currentPart
                        && this.partStore.currentPart.id === part.id;
                    if (isPartActive) {
                        isProjectParent = true;
                        isContractActiveParent = true;
                    }
                    wrappedProjectParts.push({
                        partId: part.id,
                        partNumber: part.partNumber,
                        isActive: isPartActive,
                        hasSpare: part.hasSpare,
                        hasStock: part.hasStock,
                        isLast: partIndex + 1 === project.parts.length && project.collapsedParts.length === 0,
                        isCollapsed: false
                    })
                })
                let wrappedProjectCollapsedParts: PartWrapper[] = [];
                project.collapsedParts.forEach((collapsedPart, collapsedPartIndex) => {
                    isProjectParent = true;
                    let isPartActive = this.activeTab === 'collapsed_part' && this.partStore.currentCollapsedPart
                        && this.partStore.currentCollapsedPart.id === collapsedPart.id;
                    if (isPartActive) {
                        isProjectParent = true;
                        isContractActiveParent = true;
                    }
                    wrappedProjectCollapsedParts.push({
                        partId: collapsedPart.id,
                        partNumber: collapsedPart.partNumber,
                        isActive: isPartActive,
                        hasSpare: collapsedPart.hasSpare,
                        hasStock: collapsedPart.hasStock,
                        isLast: collapsedPartIndex + 1 === project.collapsedParts.length,
                        isCollapsed: true
                    })
                })

                wrappedProjects.push({
                    projectId: project.id,
                    name: project.name,
                    isLast: projectIndex + 1 === contract.projects.length
                        && contract.collapsedSystems.length === 0
                        && contract.collapsedProjects.length === 0,
                    isActive: isProjectActive,
                    isActiveParent: isProjectActiveParent,
                    isParent: isProjectParent,
                    isCollapsed: false,
                    wrappedParts: wrappedProjectParts,
                    wrappedCollapsedParts: wrappedProjectCollapsedParts,
                })
            })

            let wrappedCollapsedSystems: SystemWrapper[] = [];
            contract.collapsedSystems.forEach((collapsedSystem, collapsedSystemIndex) => {
                let isSystemActive = this.activeTab === 'collapsed_system' && this.systemStore.currentCollapsedSystem
                    && this.systemStore.currentCollapsedSystem.id === collapsedSystem.id;
                if (isSystemActive) {
                    isContractActiveParent = true;
                }
                isContractParent = true;

                let isSystemParent = false;
                let isSystemActiveParent = false;
                let wrappedCollapsedSystemParts: PartWrapper[] = [];
                collapsedSystem.parts.forEach((collapsedPart, collapsedPartIndex) => {
                    isSystemParent = true;
                    let isPartActive = this.activeTab === 'collapsed_part' && this.partStore.currentCollapsedPart
                        && this.partStore.currentCollapsedPart.id === collapsedPart.id;
                    if (isPartActive) {
                        isSystemActiveParent = true;
                        isContractActiveParent = true;
                    }
                    wrappedCollapsedSystemParts.push({
                        partId: collapsedPart.id,
                        partNumber: collapsedPart.partNumber,
                        hasSpare: collapsedPart.hasSpare,
                        hasStock: collapsedPart.hasStock,
                        isActive: isPartActive,
                        isLast: collapsedPartIndex + 1 === collapsedSystem.parts.length,
                        isCollapsed: true
                    })
                })
                wrappedCollapsedSystems.push({
                    systemId: collapsedSystem.id,
                    name: collapsedSystem.name,
                    serialNumber: collapsedSystem.serialNumber,
                    modelNumber: collapsedSystem.modelNumber,
                    isLast: collapsedSystemIndex + 1 === contract.collapsedSystems.length
                        && contract.collapsedProjects.length === 0,
                    isActive: isSystemActive,
                    isActiveParent: isSystemActiveParent,
                    isParent: isSystemParent,
                    isCollapsed: true,
                    wrappedParts: [],
                    wrappedCollapsedParts: wrappedCollapsedSystemParts,
                })
            })

            let wrappedCollapsedProjects: ProjectWrapper[] = [];
            contract.collapsedProjects.forEach((collapsedProject, collapsedProjectIndex) => {
                isContractParent = true;
                let isProjectActive = this.activeTab === 'collapsed_project' && this.projectStore.currentCollapsedProject
                    && this.projectStore.currentCollapsedProject.id === collapsedProject.id;
                if (isProjectActive) {
                    isContractActiveParent = true;
                }
                let isProjectParent = false;
                let isProjectActiveParent = false;

                let wrappedProjectCollapsedParts: PartWrapper[] = [];
                collapsedProject.parts.forEach((collapsedPart, collapsedPartIndex) => {
                    isProjectParent = true;
                    let isPartActive = this.activeTab === 'collapsed_part' && this.partStore.currentCollapsedPart
                        && this.partStore.currentCollapsedPart.id === collapsedPart.id;
                    if (isPartActive) {
                        isProjectParent = true;
                        isContractActiveParent = true;
                    }
                    wrappedProjectCollapsedParts.push({
                        hasSpare: collapsedPart.hasSpare,
                        partId: collapsedPart.id,
                        partNumber: collapsedPart.partNumber,
                        hasStock: collapsedPart.hasStock,
                        isActive: isPartActive,
                        isLast: collapsedPartIndex === collapsedProject.parts.length,
                        isCollapsed: true
                    })
                })
                wrappedProjects.push({
                    projectId: collapsedProject.id,
                    name: collapsedProject.name,
                    isLast: collapsedProjectIndex + 1 === contract.collapsedProjects.length,
                    isActive: isProjectActive,
                    isActiveParent: isProjectActiveParent,
                    isParent: isProjectParent,
                    isCollapsed: true,
                    wrappedParts: [],
                    wrappedCollapsedParts: wrappedProjectCollapsedParts,
                })
            })
            wrappedContracts.push({
                contractId: contract.id,
                contractNumber: contract.contractNumber,
                type: contract.type,
                status: contract.status,
                durationInDays: contract.durationInDays,
                endDate: contract.endDate,
                isRenewedContract: contract.isRenewedContract,
                isActive: isActive,
                isParent: isContractParent,
                isLast: contractIndex + 1 === this.sortedContracts.length,
                isActiveParent: isContractActiveParent,
                wrappedSystems: wrappedSystems,
                wrappedCollapsedSystems: wrappedCollapsedSystems,
                wrappedProjects: wrappedProjects,
                wrappedCollapsedProject: wrappedCollapsedProjects,
                wrappedParts: wrappedContractParts,
                wrappedCollapsedParts: wrappedContractCollapsedParts
            })
        });
        return wrappedContracts;
    }


    @computed get attachmentStore(): FileAttachmentsInterface {
        if (this._activeTab === 'system') {
            return this.systemStore;
        } else if (this._activeTab === 'part') {
            return this.partStore;
        } else if (this._activeTab === 'contract') {
            return this.contractStore;
        } else {
            return this;
        }
    }

    @computed get fetchAttachments(): IAttachmentModel[] {
        if (!this.customer) {
            return [];
        }
        if (!this.customer.attachments) {
            return [];
        }
        return this.customer.attachments;
    }

    @action public changeContextPlace = (place: CustomerStoreContextPlace) => {
        this._contextPlace = place;
    }

    @action public setContractDraftType = ({type}: { type: ContractTypeListType }): void => {
        this.contractStore.updateContractDraftType(type);
        this.changeContextPlace('create_contract');
    }

    @action public clearPlace = () => {
        this.customer = null;
        this.currentPlace = 'grid';
    }

    @action public goCreate = () => {
        this.customer = null;
        this.currentPlace = 'create';
    }

    @action public goUpdate = (item: CustomerFullModel) => {
        if (this.routing) {
            this.routing.push({pathname: `/customers/${idFromEntity(item)}`, search: ''});
        }
    }

    @action public toggleLoading = (): void => {
        this._isLoading = !this._isLoading;
    }

    @action public clearContextPlace = (): void => {
        this._contextPlace = null;
        this.partStore.clearPlace();
    }

    @action
    public fetchCollection(query): void {
        this._isLoading = true;
        this.apiClient.customersFetch({params: query}).then(
            action("customer fetchCollection ok", response => {
                this.collectionResponse = response;
                this._isLoading = false;
            })
        ).catch(action("customer fetchCollection failed", e => {
            this._isLoading = false;
        }));
    }

    @action public loadFromRouter = (id: number, query: { contractId?, systemId?, projectId?, partId?, isCollapsed? }) => {
        this._isLoading = true;
        this.apiClient.customerNavigationFetch(id).then(action("fetch customerNavigation ok", (data) => {
            if (query['contractId']) {
                this.contractStore.fetchItem(query['contractId']).then(action("contractStore itemFetch ok", () => {
                    this._activeTab = 'contract';
                }))
            } else if (query['systemId']) {
                if (Number(query.isCollapsed)) {
                    this.systemStore.fetchCollapsed(query['systemId']).then(action("contractStore itemFetch ok", () => {
                        this._activeTab = 'collapsed_system';
                    }))
                } else {
                    this.systemStore.fetchItem(query['systemId']).then(action("contractStore itemFetch ok", () => {
                        this._activeTab = 'system';
                    }))
                }
            } else if (query['projectId']) {
                if (Number(query.isCollapsed)) {
                    this.projectStore.fetchCollapsed(query['projectId']).then(action("contractStore itemFetch ok", () => {
                        this._activeTab = 'collapsed_project';
                    }))
                } else {
                    this.projectStore.fetchItem(query['projectId']).then(action("contractStore itemFetch ok", () => {
                        this._activeTab = 'project';
                    }))
                }
            } else if (query['partId']) {
                if (Number(query.isCollapsed)) {
                    this.partStore.fetchCollapsed(query['partId']).then(action("contractStore itemCollapsedFetch ok", () => {
                        this._activeTab = 'collapsed_part';
                    }))
                } else {
                    this.partStore.fetchItem(query['partId']).then(action("contractStore itemFetch ok", () => {
                        this._activeTab = 'part';
                    }))
                }
            } else {
                this.apiClient.customerFetch(id).then(action("fetch customer ok", (cust) => {
                    this.customer = cust;
                    this._activeTab = 'customer';
                }));
            }
            this._isLoading = false;
            this.currentPlace = 'update';
            this.customerNavigation = data;
        }));
    };

    @action updateOrCreateContract = async (values): Promise<SubmissionErrors | null> => {

        if (this.contextPlace === 'create_contract') {
            return this.contractStore.createContract({...values, customer: this.customer['@id']}).then(errors => {
                    if (!errors) {
                        this.clearContextPlace();
                        this.routing.history.push({search: stringify({contractId: this.contractStore.currentContract.id})});
                    }
                    return errors;
                }
            );
        } else {
            return this.contractStore.updateContract({...values, rate: values['rate'] ? String(values['rate']) : null});
        }
    };

    @action public updateOrCreateCustomer = async (_data): Promise<SubmissionErrors | null> => {
        let req: Promise<CustomerFullModel>;
        const address = _data.address;
        delete _data.address;
        const data = {..._data, addresses: [{searchText: address}]}
        if (this.currentPlace === 'update') {
            req = this.apiClient.customerUpdate(idFromEntity(this.customer), hydrateInitialValues(this.customerToUpdate, data))
        }
        if (this.currentPlace === 'create') {
            req = this.apiClient.customerCreate(data)
        }
        const {response, errors} = await handleFormSubmit(req);
        return runInAction("updateOrCreateCustomer ok", () => {
            if (!errors) {
                this.customer = response;
                if (this.currentPlace === 'create') {
                    this.goUpdate(this.customer);
                } else {
                    this.uiBus.notify(`Customer #${this.customer.id} updated`)
                }

            }
            return errors;
        });
    };

    @action public createRequester = async ({email, name, code, plainPassword}): Promise<SubmissionErrors | null> => {
        const {errors} = await handleFormSubmit(this.apiClient.customerRequesterCreate({
            email,
            name,
            code,
            plainPassword,
            parentCustomer: this.customer['@id']
        }));
        return runInAction("createRequester", () => {
            if (!errors) {
                this.clearContextPlace();
                this.routing.history.push({search: ''});
            }
            return errors;
        })

    };

    @action public resetAllTabs = (withRedirect = false) => {
        this._activeTab = 'customer';
        if (withRedirect) {
            this.routing.push({pathname: `/customers/${idFromEntity(this.customer)}`, search: ''});
        }
    }

    @action public updateSystem = (values) => {
        let updatedValues = values;
        if (values['manufacturer'] && values['manufacturer']['@id']) {
            updatedValues['manufacturer'] = values['manufacturer']['@id'];
        }
        if (values['slaTime'] && values['slaTime']['@id']) {
            updatedValues['slaTime'] = values['slaTime']['@id'];
        }
        return this.systemStore.update(updatedValues);
    }

    @action public assignPartFromStock = (part: PartFullModel) => {
        this.stockPartToAssign = part['@id'];
    }

    @action public createDraftPart = (partNumber: string) => {
        this.draftPart = {
            "partNumber": partNumber,
            "type": this.partStore.partType,
        }
        if (this.activeTab === 'system') {
            this.draftPart["system"] = this.systemStore.currentSystem['@id'];
        }
        if (this.activeTab === 'contract') {
            this.draftPart["contract"] = this.contractStore.currentContract['@id'];
        }
        if (this.activeTab === 'project') {
            this.draftPart["project"] = this.projectStore.currentProject['@id'];
        }
    }

    @action public goToNextAddPartStep = async () => {
        let partId;
        if (this.stockPartToAssign) {
            if (this.activeTab === 'system') {
                partId = await this.partStore.assignToSystem(this.stockPartToAssign, this.systemStore.currentSystem['@id'])
            }
            if (this.activeTab === 'project') {
                partId = await this.partStore.assignToProject(this.stockPartToAssign, this.projectStore.currentProject['@id'])
            }
            if (this.activeTab === 'contract') {
                partId = await this.partStore.assignToContract(this.stockPartToAssign, this.contractStore.currentContract['@id'])
            }
            if (partId) {
                this.clearContextPlace();
                this.stockPartToAssign = null;
                this.routing.history.push({
                    search: stringify({partId: partId})
                });
            }
        } else {
            this.partStore.goToThirdStepOfCreate();
        }
    }

    @action public addDraftPart = async (values) => {
        if (!this.draftPart) {
            throw new Error("Draft part should be set!");
        }
        const errors = await this.partStore.createForMaster({
            type: this.draftPart.type,
            partNumber: values.partNumber,
            name: values.name,
            manufacturer: values.manufacturer,
            modelNumber: values.modelNumber,
            system: this.draftPart.system,
            contract: this.draftPart.contract,
            project: this.draftPart.project
        });
        let partId = null;
        if (this.partStore.currentPart) {
            partId = idFromEntity(this.partStore.currentPart);
        }
        return runInAction("addDraftPart ok", () => {
            if (!errors) {
                this.clearContextPlace();
                this.draftPart = null;
                this.routing.history.push({
                    search: stringify({partId: partId})
                });
            }
        })

    };

    @action public removePart = (e) => {
        if (this.partStore.currentPart.system) {
            const systemId = idFromIri(this.partStore.currentPart.system);
            return this.partStore.remove(e).then(() => {
                this.routing.history.push({
                    search: stringify({systemId: systemId})
                });
            });
        }
        if (this.partStore.currentPart.contract) {
            const contractId = idFromIri(this.partStore.currentPart.contract);
            return this.partStore.remove(e).then(() => {
                this.routing.history.push({
                    search: stringify({contractId: contractId})
                });
            });
        }
        if (this.partStore.currentPart.project) {
            const projectId = idFromIri(this.partStore.currentPart.project);
            return this.partStore.remove(e).then(() => {
                this.routing.history.push({
                    search: stringify({projectId: projectId})
                });
            });
        }
    };

    @action public saveAttachments = (files: FormData): void => {
        this.attachmentManager.persist(this.apiClient.customerAttachmentsUpdate(idFromEntity(this.customer), files)).then(action("saveAttachments ok", (res) => {
            this.customer.attachments = res;
        }))
    }

    canRemoveAttachment(attachment: IAttachmentModel): boolean {
        return this.canEdit;
    }

    @action public removeAttachment = (item: IAttachmentModel): void => {
        this.attachmentManager.remove(item).then(action("removeAttachment ok", () => {
            this.customer.attachments = this.customer.attachments.filter(a => a['@id'] !== item['@id']);
        }));
    }

    @action public updateOrAddProject = async (body): Promise<SubmissionErrors | null> => {
        let errors = null
        if (this.contextPlace === 'create_project_with_network_support' || this.contextPlace === 'create_project') {
            errors = await this.projectStore.create({
                contract: this.contractStore.currentContract['@id'],
                ...body
            }, this.contextPlace === 'create_project_with_network_support');
        } else {
            errors = await this.projectStore.update(body);
        }
        return runInAction(() => {
            if (!errors) {
                this.clearContextPlace();
                this.routing.history.push({search: stringify({projectId: idFromEntity(this.projectStore.currentProject)})})
            } else {
                return errors;
            }
        })

    }

    @computed get systemList() {
        if (!this.customerNavigation) {
            return [];
        }
        let data: Array<SystemNavigationModel> = [];
        for (let contract of this.customerNavigation.contracts) {
            for (let system of contract.systems) {
                data.push(system);
            }
        }
        return data.reduce(
            (prev, item) => ({...prev, [`/api/systems/${item.id}`]: item.serialNumber}), {});
    }

    @computed get contractList() {
        if (!this.customerNavigation) {
            return [];
        }
        let data: Array<ContractNavigationModel> = [];
        for (let contract of this.customerNavigation.contracts) {
            data.push(contract);
        }
        return data.reduce(
            (prev, item) => ({
                ...prev,
                [`/api/contracts/${item.id}`]: formatCustomerContractName(item.type, item.contractNumber, item.status)
            }), {});
    }

    @computed get projectList() {
        if (!this.customerNavigation) {
            return [];
        }
        let data: Array<ProjectNavigationModel> = [];
        for (let contract of this.customerNavigation.contracts) {
            for (let project of contract.projects) {
                data.push(project);
            }
        }
        return data.reduce(
            (prev, item) => ({...prev, [`/api/projects/${item.id}`]: item.name}), {});
    }

    @action public addSystem = async (body): Promise<SubmissionErrors | null> => {
        let errors;
        if (body.id) {
            if (body.manufacturer && typeof body.manufacturer === 'object') {
                body['manufacturer'] = body.manufacturer['@id']
            }
            errors = await this.systemStore.updateSystemWithContract(Number(body.id), this.contractStore.currentContract['@id'], body);
        } else {
            errors = await this.systemStore.create({
                contract: this.contractStore.currentContract['@id'],
                ...body
            });
        }
        return runInAction(() => {
            if (!errors) {
                this.clearContextPlace();
                this.routing.history.push({search: stringify({systemId: idFromEntity(this.systemStore.currentSystem)})})
            } else {
                return errors;
            }
        })
    }

    @action createTicket = async (values): Promise<SubmissionErrors | null> => {
        let context = {};
        if (this.activeTab === 'system') {
            context = {system: this.systemStore.currentSystem['@id'], id: uuidv4()}
            const {errors, response} = await handleFormSubmit<TicketFullModel>(this.apiClient.ticketCreateForSystem({...values, ...context}));
            return runInAction("createTicket ok", () => {
                if (!errors) {
                    this.clearContextPlace();
                    this.routing.history.push({pathname: `/tickets/${response.id}`});
                } else {
                    return errors;
                }
            })
        } else {
            context = {project: this.projectStore.currentProject['@id'], id: uuidv4()}
            const {errors, response} = await handleFormSubmit<TicketFullModel>(this.apiClient.ticketCreateForProject({...values, ...context}));
            return runInAction("createTicket ok", () => {
                if (!errors) {
                    this.clearContextPlace();
                    this.routing.history.push({pathname: `/tickets/${response.id}`});
                } else {
                    return errors;
                }
            })
        }


    }
    @action setSelectedPartsToAssign = (ids: number[]) => {
        this.selectedPartsToAssign = ids;
    }

    @action setAssignedPartTo = (type: AssignPartToType) => {
        this.assignPartTo = type;
        this.changeContextPlace('parts_assigning');
    }

    @action resetPartsAssigning = () => {
        this.selectedPartsToAssign = [];
        this.assignPartTo = null;
    }

    @action public addContractExpense = async (values): Promise<SubmissionErrors | null> => {
        const errors = await this.contractStore.addExpense(values);
        if (!errors) {
            this.clearContextPlace();
        }
        return errors;
    }

    @action unassignSystem = (e): void => {
        this.uiBus.confirm("Unassign system?", e).then(() => {
            this.toggleLoading();
            this.apiClient.systemUnassign(this.systemStore.currentSystem.id).then(action("unassignSystem ok", () => {
                this.toggleLoading();
                this.uiBus.notify("System unassigned", "info");
                this.routing.push({search: ''})
            }));
        })
    };

    @action public assignParts = async (values: { system?, contract?, project? }) => {
        this.toggleLoading();
        this.clearContextPlace();
        let redirectTo = {};
        if (values['system']) {
            for (let partId of this.selectedPartsToAssign) {
                await handleFormSubmit(this.apiClient.reassignPartToSystem(partId, {
                    system: values['system']
                }));
            }
            redirectTo = {systemId: idFromIri(values['system'])};
        }
        if (values['project']) {
            for (let partId of this.selectedPartsToAssign) {
                await handleFormSubmit(this.apiClient.reassignPartToProject(partId, {
                    project: values['project']
                }));
            }
            redirectTo = {projectId: idFromIri(values['project'])};
        }
        if (values['contract']) {
            for (let partId of this.selectedPartsToAssign) {
                await handleFormSubmit(this.apiClient.reassignPartToContract(partId, {
                    contract: values['contract']
                }));
            }
            redirectTo = {contractId: idFromIri(values['contract'])};
        }
        runInAction("assignTo ok", () => {
            this.toggleLoading();
            this.uiBus.notify(`(${this.selectedPartsToAssign.length}) parts assigned`, 'success');
            this.resetPartsAssigning();
            if (this.routing) {
                this.routing.history.push({search: stringify(redirectTo)});
            }
        })
    };

    @action reassignContract = (data: ReassignContractToCustomerInput) => {
        this.toggleLoading();
        const contractId = idFromIri(this.contractStore.currentContract['@id']);
        this.apiClient.contractReassign(contractId, data).then(action("reassignContract ok", () => {
            const customerId = idFromIri(data.customer);
            this.clearContextPlace();
            this.routing.push({pathname: `/customers/${customerId}`, search: `contractId=${contractId}`});
            this.loadFromRouter(customerId, {contractId: contractId})
        })).catch(action("reassignContract failed", e => {
            this.uiBus.notify(e.response['data']['hydra:description'], "warning");
        }));
    }

}
