/* eslint-disable-next-line react/no-array-index-key */
import React from 'react';
import _ from 'underscore';
import {LinkButton} from "../index";
import {inject, observer} from "mobx-react";
import RootStore from "../../../store/mobx";
import {Icon} from '@mdi/react';
import {IApiEntity} from "../../../utils/models";


export interface AutocompleteInputProps<T> {
    onComplete: (item: T) => any,
    fieldName: 'partNumber' | 'name' | 'serialNumber' | 'part.partNumber' | 'modelNumber' | "name_and_part_number",
    scope: 'parts' | 'systems' | 'customers',
    canEdit: boolean,
    unique?: 'modelNumber',
    onChange?,
    onFocus?,
    onBlur?,
    additionalQuery?,
    placeholder?,
    clearOnComplete?,
    required?,
    input?,
    ico?
}

interface AutocompleteInputState<T> {
    dataList: T[],
    showList: boolean,
    activeItemIndex: number,
    isFetching: any
}

@inject("rootStore") @observer
class AutocompleteInput<T extends IApiEntity> extends React.Component<AutocompleteInputProps<T> & Partial<{ rootStore: RootStore }>, AutocompleteInputState<T>> {
    private items;
    private isInitialized;
    private inputElement;
    private container;
    private itemsContainer;

    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            showList: false,
            activeItemIndex: -1,
            isFetching: false
        };
        this.items = [];
        this.isInitialized = false;
    }


    handleUserInput = (e) => {
        if (!this.props.canEdit) {
            return;
        }
        switch (e.keyCode) {
            //ARROW DOWN
            case 40:
                if (!this.state.showList)
                    return;
                this.setState({
                    activeItemIndex: (this.state.activeItemIndex >= this.state.dataList.length - 1 ? 0 : this.state.activeItemIndex + 1)
                });
                break;
            //ARROW UP
            case 38:
                if (!this.state.showList)
                    return;
                this.setState({
                    activeItemIndex: (this.state.activeItemIndex <= 0 ? this.state.dataList.length - 1 : this.state.activeItemIndex - 1)
                });
                break;
            //ENTER
            case 13:
                e.preventDefault();
                e.stopPropagation();
                if (this.state.activeItemIndex >= 0) {
                    this.handleComplete(this.state.dataList[this.state.activeItemIndex]);
                } else {
                    this.inputElement.value && this.handleComplete(this.inputElement.value);
                }
                break;
            default:
        }
    };

    handleMouseEnter = (e, index) => {
        this.setState({activeItemIndex: index});
    };

    handleDocumentClick = (e) => {
        if (!this.container.contains(e.target)) {
            this.setState({showList: false});
            if (this.state.dataList.length === 1 && this.inputElement.value === this.state.dataList[0][this.props.fieldName]) {
                this.handleComplete(this.state.dataList[0]);
            } else {
                this.handleComplete(this.inputElement.value);
            }
        }
    };

    handleChange = () => {
        if (!this.inputElement) {
            return;
        }
        if (this.inputElement.value) {
            let query = {[this.props.fieldName]: this.inputElement.value};
            if (this.props.additionalQuery) {
                query = {...query, ...this.props.additionalQuery}
            }
            // if (this.props.stockParts) {
            //     query['realization_status'] = 'stock';
            // }
            //
            // // it means stock part (not realization)
            // if (this.props.isNullSystem) {
            //     query['isStock'] = this.props.isNullSystem;
            // }
            //
            // if (this.props.stockSystems) {
            //     query['is_null_contract'] = this.props.stockSystems
            // }
            //
            // if (this.props.type) {
            //     query['type'] = this.props.type;
            // }


            this.setState({
                isFetching: true
            });
            const client = this.props.rootStore._apiClient;


            client.autocomplete<T>(`/api/${this.props.scope}`, query).then(value => {
                let dataList = [...value['hydra:member']];
                if (this.props.unique) {
                    let groupedDataList = {};
                    dataList.forEach(item => groupedDataList[item[this.props.unique]] = item);
                    dataList = Object.values(groupedDataList);
                }
                this.setState({showList: true, dataList: dataList, activeItemIndex: -1})
                this.setState({
                    isFetching: false
                });
            }).catch(e => {
                this.setState({
                    isFetching: false
                });
            });
        } else {
            this.setState({showList: false, dataList: []});
            this.setState({
                isFetching: false
            });
        }
    };

    handleComplete = (item) => {
        document.removeEventListener('click', this.handleDocumentClick, false);
        this.setState({showList: false, dataList: [], activeItemIndex: -1});
        this.props.onComplete && this.props.onComplete(item);
        this.props.onBlur && this.props.onBlur();

        const res = item[this.props.fieldName] ? item[this.props.fieldName] : item;
        this.inputElement && (this.inputElement.value = this.props.clearOnComplete ? '' : res);
    };

    render() {
        const {fieldName, input, onChange, placeholder, onFocus, required, canEdit} = this.props;
        const isListVisible = this.state.dataList.length > 0 && this.state.showList;
        const containerClass = 'autocomplete-input' + (isListVisible ? ' list' : '');
        const dOnChange = _.debounce((e) => {
            if (canEdit) {
                onChange && onChange(e);
                this.handleChange();
            }
        }, 200);
        return (
            <div ref={node => (this.container = node)}
                 className={[containerClass, this.props.ico ? 'withico' : ''].join(' ')}>
                {this.state.isFetching ? <div className="loading-dots">
                    <div className="loading-dots--dot"> </div>
                    <div className="loading-dots--dot"> </div>
                    <div className="loading-dots--dot"> </div>
                </div> : null}
                {this.props.ico && <Icon path={this.props.ico}/>}
                <input
                    ref={node => (this.inputElement = node)}
                    type="input"
                    autoComplete="off"
                    onChange={dOnChange}
                    onKeyDown={this.handleUserInput}
                    required={required}
                    disabled={!canEdit}
                    onFocus={() => {
                        onFocus && onFocus();
                        document.addEventListener('click', this.handleDocumentClick, false);
                    }}
                    defaultValue={input ? input.value : ''}
                    placeholder={placeholder ? placeholder : fieldName
                        ? (fieldName.substr(0, 1).toUpperCase() + fieldName.substr(1).toLowerCase().replace('_', ' '))
                        : ''
                    }
                />
                {isListVisible && (
                    <ul className={'select'} ref={node => (this.itemsContainer = node)}>

                        {this.state.dataList.map((item, index) => (
                            /* eslint-disable react/no-array-index-key */
                            <li
                                key={index}
                                ref={node => this.items.push(node)}
                                className={this.state.activeItemIndex === index ? ' active' : ''}
                                onMouseEnter={(event) => this.handleMouseEnter(event, index)}
                                onClick={() => this.handleComplete(item)}>
                                <LinkButton>
                                    {fieldName === 'name_and_part_number'
                                        ? item['partNumber'] ? `${item['partNumber']} (${item['name']})` : item['name']
                                        : item[fieldName]}
                                </LinkButton>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.dataList.length > 0 && this.itemsContainer && this.state.activeItemIndex >= 0) {
            const containerHeight = this.itemsContainer.offsetHeight;
            const scrollAmount = this.itemsContainer.scrollTop;
            const itemOffset = this.items[this.state.activeItemIndex] ? this.items[this.state.activeItemIndex].offsetTop : 0;
            const itemHeight = this.items[this.state.activeItemIndex] ? this.items[this.state.activeItemIndex].offsetHeight : 0;
            const destinationScroll = itemOffset + itemHeight;

            if (itemOffset < scrollAmount || destinationScroll > scrollAmount + containerHeight) {
                this.itemsContainer.scrollTop = destinationScroll - containerHeight;
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocumentClick, false);
    }
}


export default AutocompleteInput;
