import React from 'react';

import ForceTwoFactorForm from "./ForceTwoFactorForm";
import {useHistory} from "react-router";
import {observer} from "mobx-react";
import {useStore} from "../../context";

const ForceTwoFactor = observer(() => {
    const {authStore} = useStore();
    const history = useHistory();
    const returnToLoginForm = () => {
        localStorage.removeItem('token')
        const logoutLocation = {
            pathname: '/'
        };
        history.push(logoutLocation);
        window.location.reload();
    };

    return (
        <div className='login'>
            <div className="form-side two-factor">
                <div className={'row-1'}>
                    <div className={'back-to-login'}>
                        <span style={{cursor: "pointer"}} onClick={returnToLoginForm}>&lt; back to login form </span>
                    </div>
                    {authStore.twoFactorSecret && <div>
                        <p className="mediumGridVie-text">Scan QR code</p>
                        <p className="small-text"> Open authenticator, tap on icon “+” and scan QR code below.</p>
                        <div className={'qr-code-wrap'}>
                            <img alt={"code"} src={authStore.twoFactorCodeUrl}/>
                        </div>
                        <p className='small-text'>Also you can insert this secret manualy in authenticator mobile
                            application:</p>
                        <span className={'secret-code'}>{authStore.twoFactorSecret}</span>
                        <p className='medium-text'>Enter security key generated in authenticator app</p>
                        <p className="small-text">Enter the code from two-factor app on your mobile device</p>
                    </div>}
                </div>
                <div className={'row-2'}>
                    <ForceTwoFactorForm onSubmit={authStore.twoFactorStep}/>
                </div>

                {!authStore.twoFactorSecret && <div className={'two-factor-one-col-wrap'}>
                    <p className={'small-text'}>Contact administrator if you lost your device or can't login</p>
                    {/*<p className={'small-text'}><LinkButton className={'small-text_blue'} onClick={''}>Contact  </LinkButton> administrator if you lost your device or can't login</p>*/}
                </div>}
            </div>
        </div>
    );
})


export default ForceTwoFactor;
