import {RoleType} from "../store/mobx/AuthStore";

export function isTokenExpired(token): boolean {
    const date = getTokenExpirationDate(token)
    const offsetSeconds = 0
    if (date.getTime() - new Date().getTime() < 0) {
        return true
    }
    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000)
}

export function parseJwt(token: string): { 'exp', 'roles': RoleType[], username: string, '2factor_passes', 'user_id', '2factor_secret', '2factor_qr_code_url', '2factor_route' } {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

function getTokenExpirationDate(token) {
    const result = new Date(0) // The 0 here is the key, which sets the date to the epoch
    if (!token) return result
    try {
        const decoded = parseJwt(token)
        if (!decoded.exp) {
            return result
        }
        result.setUTCSeconds(decoded.exp)
        return result
    } catch (e) {
        return new Date('01-01-1970');
    }
}
