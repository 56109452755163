import React from 'react';

import {FinalButtonSet, Input, Select} from '../../components/ui/form';
import {Loader, NumberInput} from '../../components/ui';

import {useStore} from "../../context";
import {Field, Form} from "react-final-form";
import {observer} from "mobx-react";
import useDataListFetch from "../../utils/form/useDataListFetch";
import {PurchaseItemModel} from "../../utils/models";

const PurchaseItemWithSerialsUpdateForm = observer(() => {
    const {purchaseStore} = useStore();
    const manufacturerDataList = useDataListFetch('manufacturers');
    if (purchaseStore.isLoading) {
        return <Loader/>
    }

    const isHardware = purchaseStore.purchaseItemToEdit.partRealization && purchaseStore.purchaseItemToEdit.partRealization.part.type === 'hardware';
    const isSystem = purchaseStore.purchaseItemToEdit.partRealization && purchaseStore.purchaseItemToEdit.partRealization.part.type === 'system';

    return <Form<PurchaseItemModel>
        onSubmit={purchaseStore.updatePurchaseItemWithSerial}
        validate={(values) => {
            let errors = {};
            if (!values.warranty) {
                errors['warranty'] = "Required"
            }
            if (!values.price) {
                errors['price'] = "Required"
            } else if (!(Number(values.price) > 0)) {
                errors['price'] = "Price should be greater than 0"
            }
            if (!values.partRealization.serialNumber) {
                errors['partRealization.serialNumber'] = "Required"
            }
            return errors
        }}
        initialValues={purchaseStore.purchaseItemToEdit}
        render={
            ({handleSubmit, pristine, submitting}) => {
                return (<form onSubmit={handleSubmit}>
                    {isHardware && <Field
                        disabled
                        name={"partNumber"}
                        label={"Part Number"}
                        component={Input}
                    />}
                    {!isSystem &&
                    <Field
                        placeholder='Name'
                        name="partName"
                        label="Name"
                        component={Input}
                        disabled
                    />}
                    <Field
                        name="modelNumber"
                        label={"Model Number"}
                        component={Input}
                        disabled
                    />
                    <Field
                        placeholder={'Manufacturer'}
                        name="manufacturer"
                        label="Manufacturer"
                        component={Select}
                        {...manufacturerDataList}
                        disabled
                    />
                    <Field
                        placeholder='Price'
                        name="price"
                        label="Price"
                        component={Input}
                    />
                    <Field
                        placeholder='Warranty'
                        name="warranty"
                        label="Warranty"
                        component={NumberInput}
                        canEdit={true}
                    />
                    <div>
                        <Field
                            placeholder={'Serial Number'}
                            label={'Serial Number'}
                            name={'partRealization.serialNumber'}
                            component={Input}/>
                    </div>
                    <FinalButtonSet pristine={pristine} submitting={submitting}/>
                </form>)
            }}>
            </Form>
        });


export default PurchaseItemWithSerialsUpdateForm;
