import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {RouteComponentProps, useRouteMatch} from "react-router";
import {useStore} from "../../context";
import TicketDetailsForm from "./TicketDetailsForm";
import {Icon} from '@mdi/react'
import {mdiChevronLeft, mdiPlus} from '@mdi/js';
import StandalonePopover from "../../components/ui/StandalonePopover";
import TicketAssignPartForm from "./TicketAssignPartForm";
import {Loader} from "../../components/ui";
import {Link} from "react-router-dom";
import TicketDetailsButtonList from "./TicketDetailsButtonList";
import TicketDetailsInfoPanel from "./TicketDetailsInfoPanel";
import ActivityLogWidget from "../activity_log/ActivityLogWidget";
import FloatButtons from "../../components/ui/FloatButtons";
import TicketAssignPartsList from './TicketAssignPartsList'
import {Media} from 'react-breakpoints'

const TicketDetailsLeftPanel = observer(() => {
    const {ticketStore} = useStore();
    return <div className='left-panel'>
        <Link to={"/tickets"} className="back"><Icon path={mdiChevronLeft}/>Back to tickets</Link>
        {ticketStore.isLoading && (<Loader/>)}

        {ticketStore.currentTicket && <TicketDetailsButtonList/>}
    </div>
})

const TicketDetailsRightPanel = observer(() => {
    const {ticketStore} = useStore();
    return <div className='right-panel'>
        <div className="right-panel__title">
            <p>Assigned parts</p>

            {ticketStore.canEdit && ticketStore.assignedParts.length ?
                <button onClick={() => ticketStore.changeContextPlace('assign_parts')}
                        className={'tertiaryBtn'}>
                    <Icon path={mdiPlus}/>
                    Add part
                </button> : null}
        </div>

        <div className="right-panel-content">
            <TicketAssignPartsList ticketStore={ticketStore}/>
        </div>
    </div>
})
const TicketDetails = observer(({history, location, match}: RouteComponentProps) => {
    const {ticketStore} = useStore();
    const {params} = useRouteMatch();
    useEffect(() => {
        ticketStore.loadCurrentTicket(params['ticketId'])
    }, [params]);

    return (

        <div className='center-form-container'>
            {ticketStore.contextPlace === 'assign_parts' &&
            <StandalonePopover classNames={'assign-part'} title={"Assign part"}
                               closePopover={ticketStore.clearContextPlace}
                               size={'bd'}>
                <TicketAssignPartForm
                    system={ticketStore.currentTicket.system ? ticketStore.currentTicket.system['@id'] : null}
                    project={ticketStore.currentTicket.project ? ticketStore.currentTicket.project['@id'] : null}
                    closePopover={ticketStore.clearContextPlace}
                    handleAssign={({chosenRealizationsList}) => {
                        ticketStore.assignParts(chosenRealizationsList).then(ticketStore.clearContextPlace);
                    }}/>
            </StandalonePopover>}

            <Media>
                {({breakpoints, currentBreakpoint}) => {
                    return breakpoints[currentBreakpoint] > breakpoints.desktop &&
                        <TicketDetailsLeftPanel/>
                }
                }
            </Media>

            <div className='form-wrapper'>
                <Media>
                    {({breakpoints, currentBreakpoint}) => {
                        return breakpoints[currentBreakpoint] < breakpoints.tablet &&
                            <Link to={"/tickets"} className="back"><Icon path={mdiChevronLeft}/>Back to tickets</Link>
                    }
                    }
                </Media>
                <div className="section-block">
                    {ticketStore.currentTicket && <TicketDetailsInfoPanel canEdit={ticketStore.canEdit}/>}

                    {ticketStore.currentTicket && <TicketDetailsForm
                        onSubmit={ticketStore.updateTicket}
                        canEdit={ticketStore.canEdit}
                        initialValues={ticketStore.currentTicket}
                        ticketStore={ticketStore}
                    />}


                    <Media>
                        {({breakpoints, currentBreakpoint}) => {
                            return breakpoints[currentBreakpoint] < breakpoints.tablet &&
                                <div className='right-panel'>
                                    <div className="right-panel__title">
                                        <p>Assigned parts</p>

                                        {ticketStore.canEdit && ticketStore.assignedParts.length ?
                                            <button onClick={() => ticketStore.changeContextPlace('assign_parts')}
                                                    className={'tertiaryBtn'}>
                                                <Icon path={mdiPlus}/>
                                                Add part
                                            </button> : null}
                                    </div>

                                    <div className="right-panel-content">
                                        <TicketAssignPartsList ticketStore={ticketStore}/>
                                    </div>
                                </div>
                        }
                        }
                    </Media>

                    {ticketStore.currentTicket &&
                    <ActivityLogWidget activitiesLog={ticketStore} canEdit={ticketStore.canEdit}/>}

                </div>
            </div>

            <Media>
                {({breakpoints, currentBreakpoint}) => {
                    return breakpoints[currentBreakpoint] > breakpoints.mobileLandscape &&
                        <TicketDetailsRightPanel/>
                }}
            </Media>

            <div className="float-buttons">

                <Media>
                    {({breakpoints, currentBreakpoint}) =>

                        breakpoints[currentBreakpoint] < breakpoints.desktopLarge &&
                        <FloatButtons type={"normal"}><TicketDetailsButtonList/></FloatButtons>
                    }
                </Media>
            </div>
        </div>)
});

export default TicketDetails;
