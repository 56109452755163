import {ActiveFiltersType, FlatActiveFiltersType} from "../store/interfaces/GridResourceCollectionInterface";
import * as queryString from 'qs';
import _ from 'lodash';

export const serializeFilters = function (obj, prefix = '') {
    let str = [],
        p;
    for (p in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
            let v = obj[p];
            if (v instanceof Array) {
                for (let subV of v) {
                    const k = getK(p, prefix)
                    str.push(encodeURIComponent(k) + "=" + encodeURIComponent(subV))
                }
            } else {
                const k = getK(p, prefix);
                str.push((v !== null && typeof v === "object") ?
                    serializeFilters(v, k) :
                    encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
        }
    }
    return str.filter(v => v).join("&");
};

const getK = function(p, prefix) {
    return prefix ? prefix + '.' + p : p;
}

export function activeFilters(filterKeys: { [key: string]: string }, onlyWithValue: boolean, query): ActiveFiltersType[] {

    const q = queryString.parse(query, {ignoreQueryPrefix: true});

    return Object.keys(filterKeys).filter(key => {
        if (onlyWithValue) {
            return q[key] !== undefined
        } else {
            return true;
        }
    }).map(key => {
        return {
            key: key,
            value: q[key],
            label: filterKeys[key]
        }
    });

};

export function flatActiveFilters(filterKeys: { [key: string]: string }, onlyWithValue: boolean, query): FlatActiveFiltersType {
    let res = {};
    for (let item of activeFilters(filterKeys, onlyWithValue, query)) {
        _.set(res, item.key, item.value);
        // res = {...res, [item.key]: item.value}
    }
    return res;
};
