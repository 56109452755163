import {action} from "mobx";
import UiBus from "./UiBus";
import {FileAttachmentsInterface} from "../interfaces/FileAttachmentsInterface";
import ApiClient from "../ApiClient";
import {IAttachmentModel} from "../../utils/models";


class AttachmentManager {

    constructor(private apiClient: ApiClient, private store: FileAttachmentsInterface, private uiBus: UiBus) {

    }

    persist = (request: Promise<IAttachmentModel[]>): PromiseLike<IAttachmentModel[]> => {
        this.store.toggleLoading();

        return request.then((res) => {
            this.store.toggleLoading()
            this.uiBus.notify("Attachment created", "success");
            if (this.store.clearContextPlace) {
                this.store.clearContextPlace();
            }
            return res;
        }).catch(action("fetchTickets failed", e => {
            this.store.toggleLoading();
            this.uiBus.notify("Something went wrong", "warning");
            throw e;
        }));
    }

    remove = (item: IAttachmentModel): PromiseLike<void> => {
        return this.uiBus.confirm("Remove attachment?").then(() => {

            this.store.toggleLoading()
            return this.apiClient.attachmentRemove(item.id).then((res) => {
                this.store.toggleLoading()
                this.uiBus.notify("Attachment removed", "info");
            }).catch(e => {
                this.store.toggleLoading()
                this.uiBus.notify("Something went wrong", "warning");
                throw e;
            });
        })
    }

}

export default AttachmentManager;
