import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import {Button} from './..';
import {Field, Form} from 'react-final-form';
import {DateInput} from "../../../components/ui/form";

function getPositionObject(position) {
    const windowSize = {
        x: window.innerWidth,
        y: window.innerHeight
    }
    switch (position) {
        case 'CENTER':
            return {
                x: windowSize.x / 2,
                y: windowSize.y / 2
            }
        default:
            return {x: 0, y: 0}
    }
}

const Confirm = ({...props}) => {
    const position = _.isString(props.position) ? getPositionObject(props.position) : props.position;
    return (
        <div className='dialog'>
            <div className='dialog-back' style={{left: position.x + 'px', top: position.y + 'px'}}/>
            <div className='dialog-container'>
                <p className='dialog-title'>{props.title}</p>
                <p className='dialog-message'>{props.message}</p>

                {props.withDate && props.children}

                {!props.withDate && <div className='dialog-buttons'>
                    <Button color='orange' onClick={props.onSuccess}>Confirm</Button>
                    <Button onClick={props.onAbort}>Cancel</Button>
                </div>}
            </div>
        </div>
    );
}

const ConfirmForm = ({...props}) => {

    return (
        <Form onSubmit={(data) => {
            props.resolve(data);
            props.wrapRemove();
        }}>
            {({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Field label={'Start date'} name={'startDate'} component={DateInput}/>

                    <div className='dialog-buttons'>
                        <Button color='orange'>Convert</Button>
                        <Button onClick={props.wrapRemove}>Cancel</Button>
                    </div>
                </form>
            )}
        </Form>
    );
}

export const confirm = (position: { x, y }, title: string, message?, withDate?) => {
    return new Promise((resolve, reject) => {
       if (document.body) {
           const wrapper = document.body.appendChild(document.createElement('div'));
           wrapper.setAttribute('class', 'dialog-wrapper');
            ReactDOM.render(<Confirm
                position={position}
                title={title}
                message={message}
                onSuccess={(data) => {
                    wrapper.remove();
                    resolve(data);
                }}
                withDate={withDate}
                onAbort={(data) => {
                    wrapper.remove();
                    reject(data);
                }}
            > {withDate && <ConfirmForm wrapRemove={() => wrapper.remove()} resolve={resolve}/>}</Confirm>, wrapper);
        } else {
            reject('No body element !');
        }
    });
}
