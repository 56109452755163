import React, {useEffect, useState} from "react";
import {Field, Form} from 'react-final-form';
import {useStore} from "../../../context";
import {observer} from "mobx-react";
import {FinalButtonSet, Input, MultiSelect, NumberInput, Select, Textarea} from "../../../components/ui/form";
import {PickOpportunityTypeAdapter} from "./OpportunityQuoteDetailsForm";
import {useParams} from "react-router";
import arrayMutators from "final-form-arrays";
import {FieldArray} from 'react-final-form-arrays'
import AutocompleteInput from "../../../components/ui/form/AutocompleteInput";
import {Button, Loader} from "../../../components/ui";
import {Icon} from '@mdi/react';
import {
    mdiAttachment,
    mdiChevronLeft,
    mdiMagnify,
    mdiTrashCanOutline,
    mdiFormatListBulletedSquare
} from "@mdi/js";
import StandalonePopover from "../../../components/ui/StandalonePopover";
import useDataListFetch from "../../../utils/form/useDataListFetch";
import {Link} from 'react-router-dom';
import {idFromEntity} from "../../../utils/iri";

import {Media} from 'react-breakpoints'
import QuoteItemHeader from "./QuoteItemHeader";
import {
    PartDraftModel,
    PartFullModel,
    QuotePartInfoModel, QuoteProjectInfoModel,
    QuoteSystemInfoRead
} from "../../../utils/models";
import FileGallery from "../../../components/ui/widgets/FileGallery";
import FileUploader from "../../../components/ui/widgets/FileUploader";
import {formatCurrency} from "../OpportunityHelper";
import {contractPeriodList, formatContractPeriod} from "../../../store/enum/contractEnumeration";
import ActivityLogWidget from "../../activity_log/ActivityLogWidget";
import QuotePartInfoTable from "./QuotePartInfoTable";
import QuoteSystemInfoTable from "./QuoteSystemInfoTable";
import QuoteProjectInfoTable from "./QuoteProjectInfoTable";

export const RemoveComponentButton = ({fieldArrayProps, index}) => {
    return (<div className={'remove'} onClick={() => fieldArrayProps.fields.remove(index)}>
        <Icon path={mdiTrashCanOutline}/>
    </div>);
};

const ComponentButton = ({label, onClick, selected}) => {
    return (<button type={"button"} onClick={onClick}
                    className={`tertiaryBtn ${selected ? 'active' : ''}`}>
        {label}
    </button>)
};

export type ComponentTabType = "hardware" | "software" | "system" | "project";

const QuoteDetailsForm = observer(({form}) => {
    const termsAndConditionDataList = useDataListFetch('terms_and_conditions');
    const slaDataList = useDataListFetch('sla_times');
    const partnersDataList = useDataListFetch('partners', {}, true);

    const {opportunityQuoteStore} = useStore();

    return (<div className="form-wrapper">
        <div className={'additional-info'}>
            {opportunityQuoteStore.currentQuote && <QuoteItemHeader
                mode={"SINGLE"} item={opportunityQuoteStore.currentQuote}/>}

            <p className="step-title">
                <span>Total value: </span>
                {formatCurrency(opportunityQuoteStore.currentQuote.totalValue)}
            </p>
            <Field name={'description'}
                   label={'Description'}
                   canEdit={opportunityQuoteStore.canEdit}
                   component={Textarea}/>

            <p className="step-title">
                Additional information
            </p>
            <div className={`two-columns subcontractor`}>
                <Field name={'purchaseOrder'}
                       label={'Purchase order'}
                       canEdit={opportunityQuoteStore.canEdit}
                       component={Input}
                />
                <Field name={'subcontractor'}
                       label={'Subcontractor'}
                       canEdit={opportunityQuoteStore.canEdit}
                       component={Select}
                       {...partnersDataList}
                />

                {opportunityQuoteStore.currentQuote.subcontractor ?
                    <Field name={'primenetsCost'}
                           label={'Primenets cost'}
                           initialValue={opportunityQuoteStore.currentQuote.primenetsCost}
                           component={Input}/>
                    : null}

                {opportunityQuoteStore.currentQuote.subcontractor ?
                    <Field name={'subcontractorsInvoiceNumber'}
                           label={'Subcontractor\'s invoice number'}
                           initialValue={opportunityQuoteStore.currentQuote.subcontractorsInvoiceNumber}
                           component={Input}/>
                    : null}
            </div>

            {(opportunityQuoteStore.currentQuote && opportunityQuoteStore.currentQuote.type !== 'pay_as_you_go')
            && <div className="two-columns">
                <Field name={'slaTime'}
                       label={'SLA'}
                       component={Select}
                       canEdit={opportunityQuoteStore.canEdit}
                       {...slaDataList}
                />
                <Field name={'duration'}
                       label={'Duration in months'}
                       component={NumberInput}
                       canEdit={opportunityQuoteStore.canEdit}
                />
            </div>}

            {(opportunityQuoteStore.currentQuote
                && (opportunityQuoteStore.currentQuote.type === 'administration_saas'
                    || opportunityQuoteStore.currentQuote.type === 'maintenance'))
            && <Field name='period'
                      label={"Period"}
                      component={Select}
                      options={contractPeriodList}
                      formatOptions={formatContractPeriod}
                      canEdit={opportunityQuoteStore.canEdit}
            />}

            <Field name={'termsAndConditions'}
                   label={'Terms & Conditions'}
                   component={MultiSelect}
                   canEdit={opportunityQuoteStore.canEdit}
                   {...termsAndConditionDataList}
            />

            <Field name={'notes'}
                   label={'Notes'}
                   canEdit={opportunityQuoteStore.canEdit}
                   component={Textarea}/>

            <Button type={"button"} onClick={() => {
                opportunityQuoteStore.goAttachments();
            }}><Icon path={mdiAttachment}/>Upload attachments</Button>
        </div>

        {opportunityQuoteStore.fetchAttachments.length > 0 && (
            <div className='custom-field'>
                <label>Attachments: </label>
                <FileGallery attachmentInterface={opportunityQuoteStore}/>
            </div>
        )}
    </div>);
});

const OpportunityQuoteDetails = observer(() => {
    const {opportunityQuoteStore, partStore} = useStore();
    const params = useParams<{ opportunityId, quoteId }>();
    useEffect(() => {
        opportunityQuoteStore.fetchItem(Number(params.quoteId)).then();
    }, [params]);
    const [componentTab, setComponentTab] = useState<ComponentTabType>("system");


    if (!opportunityQuoteStore.currentOpportunity || !opportunityQuoteStore.currentQuote || opportunityQuoteStore.isLoading) {
        return <Loader/>
    }


    return (

        <div className={'center-form-container quote-details'}>
            {opportunityQuoteStore.currentContextPlace === 'attachments' &&
            <StandalonePopover title={"Attachments"} size={"bd"} closePopover={opportunityQuoteStore.clearContextPlace}>
                <FileUploader attachmentStore={opportunityQuoteStore}/>
            </StandalonePopover>}

            {opportunityQuoteStore.currentContextPlace === 'activity_log' &&
            <StandalonePopover classNames={'activity-log-popup'} title={"Activity log"} size={"ld"}
                               closePopover={opportunityQuoteStore.clearContextPlace}>
                <ActivityLogWidget showTitle={false}
                                   activitiesLog={opportunityQuoteStore}
                                   canEdit={opportunityQuoteStore.canEdit}/>
            </StandalonePopover>}


            {<Form<typeof opportunityQuoteStore.normalized>
                mutators={{
                    ...arrayMutators
                }}
                onSubmit={opportunityQuoteStore.updateQuote}
                initialValues={opportunityQuoteStore.normalized}>
                {({handleSubmit, pristine, submitting, form, values}) => {
                    const renderPartsAndSystems = values.type !== 'bank_of_hours' && values.type !== 'bank_of_tickets';
                    const renderBankFields = values.type === 'bank_of_hours' || values.type === 'bank_of_tickets';

                    return (
                        <form onSubmit={handleSubmit}>
                            <div className={'right-panel'}>
                                <Link className={'back'}
                                      to={`/opportunities/${idFromEntity(opportunityQuoteStore.currentOpportunity)}`}>
                                    <Icon path={mdiChevronLeft}/> Back to opportunity
                                    #{idFromEntity(opportunityQuoteStore.currentOpportunity)}
                                </Link>

                                <div onClick={opportunityQuoteStore.goActivityLog}
                                     className={'activity-log-button secondaryBtn'}>
                                    <Icon path={mdiFormatListBulletedSquare}/>
                                    <p>Activity log</p>
                                </div>


                                <div>
                                    <Field name={"type"}
                                           canEdit={opportunityQuoteStore.canEdit}
                                           component={PickOpportunityTypeAdapter}
                                           mode={"EMBEDDED"}/>
                                </div>

                                <Media>
                                    {({breakpoints, currentBreakpoint}) => {
                                        return breakpoints[currentBreakpoint] < breakpoints.tablet && (
                                            <QuoteDetailsForm form={form}/>
                                        )
                                    }}
                                </Media>
                                {renderPartsAndSystems && <div>
                                    <div className="right-panel-heading">
                                        <p className="step-title">
                                            Components
                                        </p>
                                    </div>
                                    <div className={'three-columns'}>
                                        {(values.type === 'pay_as_you_go' || values.type === 'administration_saas')
                                        && <ComponentButton selected={componentTab === 'project'} label={"Project"}
                                                            onClick={() => {
                                                                setComponentTab("project")
                                                            }}/>}
                                        <ComponentButton selected={componentTab === 'system'} label={"System"}
                                                         onClick={() => {
                                                             setComponentTab("system")
                                                         }}/>
                                        <ComponentButton selected={componentTab === 'software'} label={"Software"}
                                                         onClick={() => {
                                                             setComponentTab("software")
                                                         }}/>
                                        <ComponentButton selected={componentTab === 'hardware'} label={"Hardware"}
                                                         onClick={() => {
                                                             setComponentTab("hardware")
                                                         }}/>
                                    </div>
                                    {componentTab === 'hardware' &&
                                    <FieldArray<Partial<QuotePartInfoModel>> name="quotePartHardwareInfos">
                                        {(fieldArrayProps) => {

                                            return (<div>

                                                {opportunityQuoteStore.canEdit && <AutocompleteInput<PartFullModel>
                                                    placeholder={"Search for hardware"}
                                                    scope={"parts"}
                                                    additionalQuery={{isStock: true, type: componentTab}}
                                                    clearOnComplete={true}
                                                    fieldName={"name_and_part_number"}
                                                    canEdit={opportunityQuoteStore.canEdit}
                                                    ico={mdiMagnify}
                                                    onComplete={(item) => {
                                                        if (typeof item === 'object') {
                                                            fieldArrayProps.fields.push({
                                                                part: item,
                                                                price: "0",
                                                                quantity: 1,
                                                                quoteRealizationInfos: []
                                                            });
                                                        }
                                                    }}/>}
                                                <QuotePartInfoTable componentTab={componentTab}
                                                                    quoteType={values.type}
                                                                    canEdit={opportunityQuoteStore.canEdit}
                                                                    values={values["quotePartHardwareInfos"]}
                                                                    fieldArrayProps={fieldArrayProps}/>
                                            </div>)
                                        }}
                                    </FieldArray>}
                                    {componentTab === 'software' &&
                                    <FieldArray<Partial<QuotePartInfoModel>> name="quotePartSoftwareInfos">
                                        {(fieldArrayProps) => {

                                            return (<div>

                                                {opportunityQuoteStore.canEdit && <AutocompleteInput<PartFullModel>
                                                    placeholder={"Search for software"}
                                                    scope={"parts"}
                                                    additionalQuery={{isStock: true, type: componentTab}}
                                                    canEdit={opportunityQuoteStore.canEdit}
                                                    clearOnComplete={true}
                                                    ico={mdiMagnify}
                                                    onComplete={async (item: PartDraftModel | string) => {
                                                        if (typeof item === 'object') {
                                                            fieldArrayProps.fields.push({
                                                                part: item,
                                                                price: "0",
                                                                quantity: 1,
                                                                quoteRealizationInfos: []
                                                            });
                                                        } else if (typeof item === 'string' && item.length > 2) {
                                                            const part = await partStore.createSoftwarePartForQuote({name: item});
                                                            fieldArrayProps.fields.push({
                                                                part: part,
                                                                quantity: 1,
                                                                price: "0"
                                                            });
                                                        }
                                                    }}
                                                    fieldName={"name_and_part_number"}
                                                />}
                                                <QuotePartInfoTable componentTab={componentTab}
                                                                    quoteType={values.type}
                                                                    canEdit={opportunityQuoteStore.canEdit}
                                                                    values={values["quotePartSoftwareInfos"]}
                                                                    fieldArrayProps={fieldArrayProps}/>
                                            </div>)
                                        }}
                                    </FieldArray>}
                                    {componentTab === 'system' &&
                                    <FieldArray<Partial<QuoteSystemInfoRead>> name="quoteSystemInfos">
                                        {(fieldArrayProps) => {
                                            return (
                                                <div className={'systems'}>
                                                    {(opportunityQuoteStore.canEdit)
                                                        && (<AutocompleteInput<PartFullModel>
                                                            placeholder={"Search for systems"}
                                                            scope={"parts"}
                                                            additionalQuery={{'part_is_system': true}}
                                                            canEdit={opportunityQuoteStore.canEdit}
                                                            clearOnComplete={true}
                                                            ico={mdiMagnify}
                                                            onBlur={() => {
                                                            }}
                                                            onComplete={async (item: PartDraftModel | string) => {
                                                                if (typeof item === 'object') {
                                                                    fieldArrayProps.fields.push({
                                                                        systemAsProduct: item,
                                                                        systemAsExample: null,
                                                                        modelNumber: item.modelNumber,
                                                                        quantity: 1,
                                                                        price: "0"
                                                                    });
                                                                } else if (typeof item === 'string' && item.length > 2) {
                                                                    const part = await partStore.createSystemPartForQuote({partNumber: item});
                                                                    fieldArrayProps.fields.push({
                                                                        systemAsProduct: part,
                                                                        systemAsExample: null,
                                                                        modelNumber: part.modelNumber,
                                                                        quantity: 1,
                                                                        price: "0"
                                                                    });
                                                                }
                                                            }}
                                                            fieldName={"modelNumber"}
                                                        />)}
                                                    <QuoteSystemInfoTable
                                                        customerName={opportunityQuoteStore.currentOpportunity.customerName}
                                                        quoteType={values.type}
                                                        values={values["quoteSystemInfos"]}
                                                        isRenewalQuote={opportunityQuoteStore.currentOpportunity.contractToRenew !== null}
                                                        fieldArrayProps={fieldArrayProps}
                                                        canEdit={opportunityQuoteStore.canEdit}
                                                    />
                                                </div>);
                                        }}
                                    </FieldArray>}
                                    {componentTab === "project" &&
                                    <FieldArray<Partial<QuoteProjectInfoModel>> name="quoteProjectInfos">
                                        {(fieldArrayProps) => {
                                            const handleNewProject = (e) => {
                                                e.preventDefault();
                                                fieldArrayProps.fields.push({
                                                    name: ''
                                                });
                                            };
                                            return (<div className={'project'}>
                                                <Button onClick={handleNewProject}>
                                                    Add new project
                                                </Button>
                                                <QuoteProjectInfoTable
                                                    quoteType={values.type}
                                                    values={values["quoteProjectInfos"]}
                                                    fieldArrayProps={fieldArrayProps}
                                                    canEdit={opportunityQuoteStore.canEdit}
                                                />
                                            </div>)
                                        }}
                                    </FieldArray>}
                                </div>}
                                {renderBankFields && <div>
                                    <p className="step-title">
                                        Contract details
                                    </p>
                                    <div>
                                        {values.type === 'bank_of_tickets' &&
                                        <Field name={'ticketsNumber'}
                                               label={'Number of tickets'}
                                               component={NumberInput}
                                               canEdit={opportunityQuoteStore.canEdit}
                                        />}
                                        {values.type === 'bank_of_hours' &&
                                        <Field name={'hoursNumber'}
                                               label={'Number of hours'}
                                               component={NumberInput}
                                               canEdit={opportunityQuoteStore.canEdit}
                                        />}
                                        <Field name={'period'}
                                               label={'Period'}
                                               component={Select}
                                               canEdit={opportunityQuoteStore.canEdit}
                                               options={contractPeriodList}
                                               formatOptions={formatContractPeriod}
                                        />

                                        <Field name={'rate'}
                                               label={'Rate'}
                                               component={Input}
                                               canEdit={opportunityQuoteStore.canEdit}
                                        />
                                        <Field name={'timeMinStep'}
                                               label={'Time Min Step'}
                                               component={Select}
                                               options={
                                                   {
                                                       '10': 10,
                                                       '20': 20,
                                                       '30': 30,
                                                       '40': 40,
                                                       '60': 60
                                                   }
                                               }
                                               canEdit={opportunityQuoteStore.canEdit}
                                        />
                                    </div>
                                </div>}

                            </div>

                            <Media>
                                {({breakpoints, currentBreakpoint}) => {
                                    return breakpoints[currentBreakpoint] >= breakpoints.tablet && (
                                        <QuoteDetailsForm form={form}/>
                                    )
                                }}
                            </Media>
                            <FinalButtonSet pristine={pristine} submitting={submitting} mode={"FIXED"}
                                            reset={form.reset}/>
                        </form>
                    )
                }}
            </Form>}
        </div>
    )
});

export default OpportunityQuoteDetails
