import React from 'react';
import ResourceGridView from './../../../components/grid/ResourceGridView';
import {useStore} from "../../../context";

import ResourceUpdate from "./CourierCompanyUpdate";
import ResourceCreate from "./CourierCompanyCreate";
import {observer} from "mobx-react";
import StandalonePopover from "../../../components/ui/StandalonePopover";

const ResourceGrid = observer(() => {

    const {genericResourceStore} = useStore();
    const canEdit = true;
    genericResourceStore.configure('/api/courier_companies', 'Courier company')

    return (
        <div className='dashboard'>
            {genericResourceStore.place === 'create' &&
            <StandalonePopover title={'Create'} closePopover={() => genericResourceStore.changePlace('grid')}>
                <ResourceCreate sharedGridResourceCollectionStore={genericResourceStore}/>
            </StandalonePopover>}
            {genericResourceStore.place === 'update' &&
            <StandalonePopover size={"bd"}
                               title={`${genericResourceStore.label} #${genericResourceStore.currentResourceId} update`}
                               closePopover={() => genericResourceStore.changePlace('grid')}>
                <ResourceUpdate canRemove={canEdit} sharedGridResourceCollectionStore={genericResourceStore}/>
            </StandalonePopover>}
            <ResourceGridView<any>
                columns={[
                    {
                        name: 'id',
                        value: (item) => item['@id'].split('/').reverse()[0],
                    },
                    {
                        name: 'name',
                        value: (item) => item.name,
                    },
                ]}
                onRowClick={(item) => {
                    genericResourceStore.changePlace('update', item)
                }}
                onCreate={() => genericResourceStore.changePlace('create')}

                gridResource={genericResourceStore}
            />
        </div>
    );
})


export default ResourceGrid;
