import React from 'react';
import moment from 'moment';

import {Icon} from '@mdi/react';
import {mdiCashUsdOutline, mdiHeadset, mdiMapMarker,} from "@mdi/js";

export const dateTimeCell = (date) => {
    const momentObj = moment(date);
    if (!momentObj.isValid()) {
        return (<div></div>);
    }
    return (
        <div>
            {momentObj.format('DD MMM \'YY')}
        </div>
    );
}

export const dateAndTimeCell = (date) => {
    const momentObj = moment(date);
    if (!momentObj.isValid()) {
        return (<div></div>);
    }
    return (
        <div className={'date'}>
            <span>{momentObj.format('DD MMM \'YY')}, </span>
            <span>{momentObj.format('HH:mm')}</span>
        </div>
    );
}

export const categoryCell = (item) => {
    let categoryIcon;
    switch (item) {
        case 'Remote':
            categoryIcon = mdiHeadset;
            break;
        case 'Onsite':
            categoryIcon = mdiMapMarker;
            break;
        default:
            categoryIcon = mdiCashUsdOutline
    }

    return (
        <div className={'category'}>
            <Icon path={categoryIcon}/>
            <p>{item}</p>
        </div>
    )
};


export const statusCell = (item) => {
    let apprStatus = 'status-ico ';
    // let apprIcon;
    let label;
    switch (item.status) {
        case 'floating':
            apprStatus += 'grey';
            // apprIcon = mdiAccountQuestionOutline;
            label = 'Floating';
            break;
        case 'inProgress':
            apprStatus += 'blue';
            // apprIcon = mdiClockOutline;
            label = 'In Progress';
            break;
        case 'hold':
            apprStatus += 'orange';
            // apprIcon = mdiPauseCircleOutline;
            label = 'Hold';

            break;
        case 'complete':
        case 'completed':
            apprStatus += 'green';
            // apprIcon = mdiCheckCircleOutline;
            label = 'Complete';
            break;
        case 'failed':
            apprStatus += 'red';
            // apprIcon = mdiAlertOutline;
            label = 'Failed';

            break;
        case 'archived':
            apprStatus += 'red';
            // apprIcon = mdiAlertOutline;
            label = 'Archived';

            break;
        case 'assigned':
            apprStatus += 'purp';
            // apprIcon = mdiAccountCheckOutline;
            label = 'Assigned';
            break;
        default:
            apprStatus += 'orange';
            // apprIcon = mdiAlertOutline;
            label = 'Unexpected status';

            break;
    }


    return (
        <div className="status-cell">
            <div className="status-col">
                <div className={apprStatus}>
                    {label}
                </div>
            </div>
        </div>
    );
};

