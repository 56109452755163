import {ContractShortModel, INotificationsModel} from "../../utils/models";
import {formatContractStatus, formatContractType} from "../enum/contractEnumeration";
import {idFromIri, stringIdFromIri, uuidFromIri} from "../../utils/iri";
import {formatTicketStatus} from "../enum/ticketEnumeration";


export const formatCustomerContractName = (contractType, contractNumber, contractStatus) => {
    return `${formatContractType(contractType)} #${contractNumber ? contractNumber : '(No name)'} ${formatContractStatus(contractStatus)}`;
}

export const formatShortContractName = (contract: ContractShortModel) => {
    return contract.contractNumber ? `${contract.contractNumber}` : null;
}

export const formatNotification = (notification: INotificationsModel): { title, notes } | null => {
    switch (notification.type) {
        case "alert":
            return {title: `Alert #${idFromIri(notification.alert)}`, notes: notification.notes};
        case "appointment":
            return {title: `Appointment #${idFromIri(notification.appointment)}`, notes: notification.notes};
        case "assigned":
            if (notification.opportunity) {
                return {title: `Opportunity #${idFromIri(notification.opportunity)}`, notes: 'You were assigned'};
            } else if (notification.ticket) {
                return {title: `Ticket #${idFromIri(notification.ticket)}`, notes: 'You were assigned'};
            }
            break;
        case "manual_notification":
            return {title: notification.notes, notes: null};
        case "unassigned":
            if (notification.opportunity) {
                return {title: `Opportunity #${idFromIri(notification.opportunity)}`, notes: 'You were unassigned'};
            } else if (notification.ticket) {
                return {title: `Ticket #${idFromIri(notification.ticket)}`, notes: 'You were unassigned'};
            }
            break;
        case "invited":
            if (notification.opportunity) {
                return {title: `Opportunity #${idFromIri(notification.opportunity)}`, notes: 'You were invited'};
            } else if (notification.ticket) {
                return {title: `Ticket #${uuidFromIri(notification.ticket)}`, notes: 'You were invited'};
            }
            break;
        case "expired_at":
            if (notification.contract) {
                const inDays = (notification.payload as { days }).days;
                return {
                    title: `Contract #${idFromIri(notification.contract)}`,
                    notes: `Contract will expire in ${inDays} days`
                };
            }
            break;
        case "status_changed":

            if (notification.ticket) {
                const oldStatus = formatTicketStatus((notification.payload as { old_status, new_status }).old_status);
                const newStatus = (notification.payload as { old_status, new_status }).new_status;
                return {
                    title: `Ticket #${idFromIri(notification.ticket)}`,
                    notes: `Ticket status has changed from ${oldStatus} to ${newStatus}`
                };
            }
            break;
        case "support_request":
            if (notification.supportRequest) {
                return {
                    title: `New message in support request ${stringIdFromIri(notification.supportRequest)}`,
                    notes: notification.payload?.preview.slice(0, 40) + '...'
                };
            }
            break;
        default:
            return null;
    }

}


