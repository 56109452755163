import remotedev from "mobx-remotedev";
import {CollectionResponseType, GridResourceCollectionInterface} from "../interfaces/GridResourceCollectionInterface";
import ApiClient from "../ApiClient";
import {action, computed, observable, runInAction} from "mobx";
import {handleFormSubmit, hydrateInitialValues} from "../../utils/form/handleFormSubmit";
import {idFromEntity, idFromIri} from "../../utils/iri";
import {FileAttachmentsInterface} from "../interfaces/FileAttachmentsInterface";
import UiBus from "../service/UiBus";
import AttachmentManager from "../service/AttachmentManager";
import {SubmissionErrors} from "final-form";
import {RouterStore} from 'mobx-react-router';
import {AuthStore} from "./AuthStore";
import {
    CollapsedPartModel, CreatePartForMasterInput, CreateSoftwarePartForQuoteInput, CreateSystemPartForQuoteInput,
    IAttachmentModel,
    ICategoryModel,
    Iri,
    PartFullModel,
    PartRealizationShortModel
} from "../../utils/models";
import {PartRealizationStatusType, PartTypeListType} from "../enum/partEnumeration";
import moment from "moment";

type PartStorePlace = 'grid' | 'part_type_select' | 'part_second_step' | 'part_third_step' | 'part_update';
type PartStoreContextPlace = 'attachment';

@remotedev({global: true})
export class PartStore implements GridResourceCollectionInterface<PartFullModel>, FileAttachmentsInterface {
    @observable categoriesList: CollectionResponseType<ICategoryModel> | null;
    @observable categoriesListLoading: boolean = false
    @observable collectionResponse: CollectionResponseType<PartFullModel> | null;
    @observable isLoading: boolean = false;
    @observable currentPart: PartFullModel | null;
    @observable currentCollapsedPart: CollapsedPartModel | null;
    @observable stockPart: PartFullModel | null;
    @observable currentPlace: PartStorePlace = 'grid';
    @observable currentContextPlace: PartStoreContextPlace | null;
    private attachmentManager: AttachmentManager;
    @observable private _creatingPartType: PartTypeListType | null = null;

    constructor(private apiClient: ApiClient, private authStore: AuthStore, private uiBus: UiBus, private routing?: RouterStore) {
        this.attachmentManager = new AttachmentManager(apiClient, this, uiBus);
    }

    @computed get partType(): PartTypeListType {
        if (this.currentPart) {
            return this.currentPart.type;
        } else {
            return this._creatingPartType;
        }
    }

    getEntityName: 'Part';

    @computed get canEdit() {
        return !this.authStore.isCustomerOrPartner;
    }

    @computed get isHardware() {
        return this.partType === 'hardware';
    }

    @computed get isSoftware() {
        return this.partType === 'software';
    }

    @computed get isSystem() {
        return this.partType === 'system';
    }

    @computed get fetchAttachments(): IAttachmentModel[] {
        return (this.currentPart && this.currentPart.attachments) ? this.currentPart.attachments : [];
    }

    @computed get assignedRealizationsList(): Array<PartRealizationShortModel> {
        if (!this.currentPart) {
            return [];
        }
        return this.currentPart.partRealizations.filter(item => item.partStatus === 'assigned')
    }

    @computed get spareRealizationsList(): Array<PartRealizationShortModel> {
        if (!this.currentPart) {
            return [];
        }
        return this.currentPart.partRealizations.filter(item => item.partStatus === 'spare')
    }

    @computed get stockRealizationsList(): Array<PartRealizationShortModel> {
        if (!this.stockPart) {
            return [];
        }
        return this.stockPart.partRealizations.filter(item => item.partStatus === 'stock');
    }

    @action public toggleLoading = (): void => {
        this.isLoading = !this.isLoading;
    };

    @action public clearContextPlace = (): void => {
        this.currentContextPlace = null;
    };

    @action public changeContextPlace = (place: PartStoreContextPlace): void => {
        this.currentContextPlace = place;
    };

    @action public clearPlace = () => {
        this.currentPlace = 'grid';
        this.currentPart = null;
    }

    @action partTypeSelectFirstStep = ({type}) => {
        this.currentPlace = 'part_second_step';
        this._creatingPartType = type;
    }

    @action goToThirdStepOfCreate = () => {
        this.currentPlace = 'part_third_step';
    }

    @action goCreate = () => {
        this.currentPart = null;
        this.currentPlace = 'part_type_select';
    }

    @action goUpdate = (item: PartFullModel, catalogueMode = false) => {
        this.currentPlace = 'part_update';
        if (this.routing) {
            const route = catalogueMode ? `/products/${idFromEntity(item)}` : `/inventory/${idFromEntity(item)}`;
            this.routing.history.push(route)
        }
    }

    @action public fetchCollection = (query): void => {
        this.isLoading = true;
        this.apiClient.partsCollectionFetch({...query, isStock: true}).then(
            action("fetchCollection ok", response => {
                this.collectionResponse = response;
                this.isLoading = false;
            })
        ).catch(action("fetchCollection failed", e => {
            this.isLoading = false;
            this.uiBus.notify("Something went wrong", "warning");
        })).finally(action(() => {
            this.isLoading = false;
        }));
    }

    @action public fetchItem = (id) => {
        this.isLoading = true;
        return this.apiClient.partItemFetch(id).then(
            action("fetchPartItem ok", response => {
                this.currentPart = response;
                this.isLoading = false;
            })
        ).catch(action("fetchItem failed", e => {
            this.isLoading = false;
            this.uiBus.notify("Something went wrong", "warning");
        }));
    }

    @action public fetchCollapsed = (id) => {
        this.isLoading = true;
        return this.apiClient.partCollapsedFetch(id).then(
            action("fetchCollapsed part ok", response => {
                this.currentCollapsedPart = response;
                this.isLoading = false;
            })
        ).catch(action("fetchCollapsed part failed", e => {
            this.isLoading = false;
        }));
    }

    @action public create = async (values): Promise<SubmissionErrors | null> => {
        const newValues = {
            ...values,
            configParts: Number(values.configParts)
        };
        const {response, errors} = await handleFormSubmit(this.createPartDependingOnType(newValues));

        return runInAction("create ok", () => {
            if (!errors) {
                this.currentPart = response;
                this.goUpdate(response, true);
                this.uiBus.notify(`Part #${idFromEntity(this.currentPart)} created`, 'success')
            }
            return errors;
        });
    };

    createPartDependingOnType(newValues) {
        if (this._creatingPartType === 'hardware') {
            return this.apiClient.createHardwarePart(newValues)
        }
        if (this._creatingPartType === 'software') {
            return this.apiClient.createSoftwarePart(newValues);
        }
        if (this._creatingPartType === 'system') {
            return this.apiClient.createSystemPart(newValues);
        }
    }

    @action public createForMaster = async (values: CreatePartForMasterInput): Promise<SubmissionErrors | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.createPartForMaster(values));

        return runInAction("create ok", () => {
            if (!errors) {
                this.currentPart = response;
                this.goUpdate(response, true);
                this.uiBus.notify(`Part #${idFromEntity(this.currentPart)} created`, 'success')
            }
            return errors;
        });
    };

    @action public assignToSystem = async (part: Iri, system: Iri): Promise<number | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.assignStockPartToSystem({
            stockPart: part,
            system: system
        }));

        return runInAction("assignToSystem ok", () => {
            if (!errors) {
                this.currentPart = response;
                this.goUpdate(response, true);
                const partId = idFromEntity(this.currentPart)
                this.uiBus.notify(`Part #${partId} created`, 'success')
                return partId;
            }
            return null;
        });
    }

    @action public assignToProject = async (part: Iri, project: Iri): Promise<number | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.assignStockPartToProject({
            stockPart: part,
            project: project
        }));

        return runInAction("assignToProject ok", () => {
            if (!errors) {
                this.currentPart = response;
                this.goUpdate(response, true);
                const partId = idFromEntity(this.currentPart)
                this.uiBus.notify(`Part #${partId} created`, 'success')
                return partId;
            }
            return null;
        });
    }

    @action public assignToContract = async (part: Iri, contract: Iri): Promise<number | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.assignStockPartToContract({
            stockPart: part,
            contract: contract
        }));

        return runInAction("assignToContract ok", () => {
            if (!errors) {
                this.currentPart = response;
                this.goUpdate(response, true);
                const partId = idFromEntity(this.currentPart)
                this.uiBus.notify(`Part #${partId} created`, 'success')
                return partId;
            }
            return null;
        });
    }

    @action public update = async (values): Promise<SubmissionErrors | null> => {
        values = {
            ...values,
            endOfLife: values.endOfLife !== null ? moment(values.endOfLife).format('YYYY-MM-DD') : null,
            endOfService: values.endOfService !== null ? moment(values.endOfService).format('YYYY-MM-DD') : null
        }
        const {response, errors} = await handleFormSubmit(this.apiClient.updatePart(idFromEntity(this.currentPart), hydrateInitialValues(this.currentPart, values)));
        return runInAction("update ok", () => {
            if (!errors) {
                this.currentPart = response;
                this.uiBus.notify(`Part #${idFromEntity(this.currentPart)} updated`, 'success')
            }
            return errors;
        });
    };

    @action public remove = (e): Promise<void> => {
        return new Promise((resolved => {
            this.uiBus.confirm("Are you sure?", e).then(() => {
                this.toggleLoading();
                this.apiClient.partReturnToStock(this.currentPart.id).then(action("remove ok", () => {
                    this.uiBus.notify(`Part #${this.currentPart.id} removed`, 'info');
                    this.toggleLoading();
                    resolved();
                }));
            })
        }));
    };

    @action public saveAttachments = (files: FormData): void => {
        this.attachmentManager.persist(this.apiClient.partAttachmentsUpdate(idFromEntity(this.currentPart), files)).then(action("saveAttachments ok", (res) => {
            this.currentPart.attachments = res;
        }))
    };

    canRemoveAttachment(attachment: IAttachmentModel): boolean {
        return this.canEdit;
    }

    @action public removeAttachment = (item: IAttachmentModel): void => {
        this.attachmentManager.remove(item).then(action("removeAttachment ok", () => {
            this.currentPart.attachments = this.currentPart.attachments.filter(a => a['@id'] !== item['@id']);
        }));
    }

    @action public markRealizationAsFaulty = (iri: Iri, e) => {
        this.uiBus.confirm("Mark item as faulty?", e).then(() => {
            this.apiClient.markRealizationAsFaulty(iri).then(() => {
                this.fetchItem(this.currentPart.id).then(() => {
                    this.uiBus.notify("Item marked as faulty", "warning");
                })
            })
        })
    }

    @action public fetchStockPart = (part: PartFullModel | null) => {
        if (part) {
            this.apiClient.stockPartFetch(part.partNumber).then(
                action("stockPartFetch ok", response => {
                    if (response['hydra:member'].length > 0) {
                        this.stockPart = response['hydra:member'][0];
                    }
                })
            ).catch(action("stockPartFetch failed", e => {
                this.uiBus.notify("Something went wrong", "warning");
            }));
        }
    };


    @action
    public async changeRealizationsStatus(realizationsList: Array<Iri>, oldStatus: PartRealizationStatusType, newStatus: PartRealizationStatusType) {
        if (!this.currentPart || !this.stockPart) {
            this.uiBus.notify("Something went wrong", "warning");
        } else {
            let queryBody = {
                status: newStatus,
                part: newStatus === 'stock' ? this.stockPart['@id'] : this.currentPart['@id']
            };
            for (let realId of realizationsList) {
                await this.apiClient.addRealizationToPartWithStatus(realId, queryBody)
            }
            await this.fetchItem(this.currentPart.id);
        }
    }

    @action partCategoriesCreate = ({name = 'new category', parent}): Promise<ICategoryModel> => {
        return this.apiClient.partCategoriesCreate({
            name: name,
            parent: parent
        }).then((response) => {
            return this.partCategoriesFetch().then(() => {
                return response;
            });
        })
    }

    @action partCategoriesDelete = (id) => {
        handleFormSubmit(this.apiClient.partCategoriesDelete(id)).then(this.partCategoriesFetch);
    }

    @action partCategoriesUpdate = (id, values) => {
        return handleFormSubmit(this.apiClient.partCategoriesUpdate(id, values)).then(this.partCategoriesFetch);
    }

    @action partCategoriesFetch = () => {
        this.categoriesListLoading = true;
        return this.apiClient.partCategoriesFetch({
            is_null_parent: true,
            _pagination: false
        }).then(action("partCategoriesFetch ok", (response) => {
            this.categoriesList = response;
            this.categoriesListLoading = false;
        }));
    }

    @action assignRealizationToSystem(realization: PartRealizationShortModel, system: Iri) {
        return this.apiClient.realizationAssignToSystem(realization['@id'], system)
            .then(action("assignRealizationToSystem", (response) => {
                this.routing.history.push({pathname: `/customers/${response.part.customerId}`, search: `partId=${idFromIri(response.part['@id'])}`});
            }));
    }

    @action createSoftwarePartForQuote(body: CreateSoftwarePartForQuoteInput): Promise<PartFullModel> {
        return this.apiClient.createSoftwarePartForQuote(body)
            .then(action("createSoftwarePartForQuote ok", (response) => {
                return response;
            }));
    }

    @action createSystemPartForQuote(body: CreateSystemPartForQuoteInput): Promise<PartFullModel> {
        return this.apiClient.createSystemPartForQuote(body)
            .then(action("createSystemPartForQuote ok", (response) => {
                return response;
            }));
    }

}
