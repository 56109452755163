import {IApiEntity, Iri} from "./models";

export function idFromEntity(entity: IApiEntity): number {
    return Number(entity['@id'].split('/').reverse()[0])
}

export function idFromIri(iri: Iri): number {
    return Number(iri.split('/').reverse()[0])
}

export function uuidFromIri(iri: Iri): string {
    return iri.split('/').reverse()[0];
}
export function stringIdFromIri(iri: Iri): string {
    return iri.split('/').reverse()[0];
}
