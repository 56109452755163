export const contractPeriodList = ['week', 'month', 'quarter', 'year', 'all'] as const;
export const contractTypeList = ['maintenance', 'administration_saas', 'pay_as_you_go', 'bank_of_hours', 'bank_of_tickets'] as const;
export const contractStatusList = ['active', 'expired', 'closed', 'future'] as const;
export type ContractPeriodListType = typeof contractPeriodList[number];
export type ContractTypeListType = typeof contractTypeList[number];
export type ContractStatusType = typeof contractStatusList[number];

export function formatContractStatus(status: ContractStatusType): string {
    return status.charAt(0).toUpperCase() + status.slice(1);
}

export function formatContractPeriod(status: ContractPeriodListType): string {
    switch (status) {
        case "week":
            return 'Week';
        case 'month':
            return 'Month';
        case 'quarter':
            return 'Quarter';
        case 'year':
            return 'Year';
        case 'all':
            return 'All';
    }
}

export function formatContractType(status: ContractTypeListType): string {
    switch (status) {
        case "maintenance":
            return 'Maintenance';
        case 'administration_saas':
            return 'Administration';
        case 'pay_as_you_go':
            return 'Pay As You Go';
        case 'bank_of_hours':
            return 'Bank Of Hours';
        case 'bank_of_tickets':
            return 'Bank Of Tickets';
    }
}
