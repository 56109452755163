import React from 'react';

import ResourceForm from "./ServiceForm";
import {GenericResourceStore} from "../../../store/mobx/GenericResourceStore";

type ResourceCreateProps = {
    sharedGridResourceCollectionStore: GenericResourceStore
    canEdit: boolean,
}
const ResourceCreate = ({sharedGridResourceCollectionStore, canEdit}: ResourceCreateProps) => {
    return (
        <ResourceForm
            initialValues={{}}
            disabled={!canEdit}
            onSubmit={sharedGridResourceCollectionStore.createResource}
        />
    );
};


export default ResourceCreate;
