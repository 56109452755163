import {
    CollectionResponseType,
    FlatActiveFiltersType,
    GridResourceCollectionInterface
} from "../interfaces/GridResourceCollectionInterface";
import {AddInventoryNotesInput, Iri, PartRealizationShortModel} from "../../utils/models";
import {action, observable} from "mobx";
import ApiClient from "../ApiClient";
import UiBus from "../service/UiBus";
import {SubmissionErrors} from "final-form";
import {handleFormSubmit} from "../../utils/form/handleFormSubmit";

export class RealizationStore implements GridResourceCollectionInterface<PartRealizationShortModel> {
    @observable collectionResponse: CollectionResponseType<PartRealizationShortModel> | null;
    @observable isLoading: boolean;
    constructor(private apiClient: ApiClient, private uiBus: UiBus) {}
    getEntityName: 'PartRealization';

    @action public fetchCollection(query: FlatActiveFiltersType): void {
        this.isLoading = true;
        this.apiClient.realizationsCollectionFetch(query).then(
            action("fetchRealizationsCollection ok", response => {
                this.collectionResponse = response;
                this.isLoading = false;
            })
        ).catch(action("fetchRealizationsCollection failed", e => {
            this.isLoading = false;
            this.uiBus.notify("Something went wrong", "warning");
        })).finally(action(() => {
            this.isLoading = false;
        }));
    }

    @action addInventoryNotes = (iri: Iri, data: AddInventoryNotesInput) : Promise<SubmissionErrors | null> => {
        return handleFormSubmit(this.apiClient.addInventoryNotesToRealization(iri, data));
    }

    @action public toggleLoading(): void {
        this.isLoading = !this.isLoading;
    }

}
