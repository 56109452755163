import React, {useState} from "react";
import {Field} from "react-final-form";
import {Input, NumberInput} from "../../../components/ui/form";
import {QuotePartInfoModel} from "../../../utils/models";
import {FieldArrayRenderProps} from "react-final-form-arrays";
import {Icon} from "@mdi/react";
import {mdiFolderEditOutline} from "@mdi/js";
import StandalonePopover from "../../../components/ui/StandalonePopover";
import QuoteRealizationsForm from "./QuoteRealizationsForm";
import {ComponentTabType, RemoveComponentButton} from "./OpportunityQuoteDetails";
import {ContractTypeListType} from "../../../store/enum/contractEnumeration";

type QuotePartInfoTableProps = {
    values: Partial<QuotePartInfoModel>[],
    fieldArrayProps: FieldArrayRenderProps<Partial<QuotePartInfoModel>, any>,
    canEdit: boolean,
    quoteType: ContractTypeListType
    componentTab: "hardware" | "software",
}

type PartTableTitleRowProps = {
    componentTab: ComponentTabType,
    item: Partial<QuotePartInfoModel>,
    quoteType: ContractTypeListType,
    handleStockRealizationsChange
}

const QuotePartInfoTable = ({
                                componentTab,
                                values,
                                quoteType,
                                fieldArrayProps,
                                canEdit
                            }: QuotePartInfoTableProps) => {
    return <div className={'components-table'}>
        <div className={'components-table-row heading'}>
            <div>
                Title
            </div>
            <div>
                Quantity
            </div>
            <div>
                Price
            </div>
            <div>

            </div>
        </div>
        {fieldArrayProps.fields.map((name, index) => {
                const item = fieldArrayProps.fields['value'][index];
                const handleStockRealizationsChange = (iris) => {

                    const updatedIris = iris.length ? iris.map(itemIri => {
                            return {
                                stockRealization: itemIri
                            }
                        })
                        : item.quoteRealizationInfos.filter(real => real.stockRealization);

                    fieldArrayProps.fields.update(index, {
                        ...item, quoteRealizationInfos: updatedIris
                    });
                };

                return <div key={item["@id"]} className={'components-table-row'}>
                    <PartTableTitleRow
                        componentTab={componentTab}
                        item={item}
                        quoteType={quoteType}
                        handleStockRealizationsChange={handleStockRealizationsChange}
                    />
                    <div>
                        <Field name={`${name}.quantity`}
                               component={NumberInput}
                               min_value={1}
                               canEdit={canEdit}/>
                    </div>
                    <div>
                        <Field name={`${name}.price`}
                               component={Input}
                               canEdit={canEdit}/>
                    </div>
                    <RemoveComponentButton fieldArrayProps={fieldArrayProps} index={index}/>
                </div>
            }
        )}
    </div>
};

const PartTableTitleRow = ({
                               componentTab,
                               item,
                               quoteType,
                               handleStockRealizationsChange
                           }: PartTableTitleRowProps) => {
    const [contextMenuCoordinates, setContextMenuCoordinates] = useState<{ x, y } | null>(null);

    return (<div>
        {item.part.name}
        <div className="other-info">
            {item.part.partNumber}
            {item.part.partNumber && <span className={'dot'}>•</span>}
            {componentTab}
            {quoteType === 'pay_as_you_go' && <span className={'dot'}>•</span>}
            {quoteType === 'pay_as_you_go' && ((item.part.stockCount ? item.part.stockCount : '0') + ' in stock')}

            {quoteType === 'pay_as_you_go' && <span className="browse"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setContextMenuCoordinates({
                                                            x: e.clientX + 20,
                                                            y: e.clientY + 60
                                                        })
                                                    }}>
                <Icon path={mdiFolderEditOutline}/>
            </span>}

            {contextMenuCoordinates &&
            <StandalonePopover title={null}
                               closePopover={() => setContextMenuCoordinates(null)}
                               size={'sd'}
                               darken={false}
                               position={contextMenuCoordinates}
                               direction={"TOP_RIGHT"}>
                <QuoteRealizationsForm
                    item={item}
                    handleStockRealizationsChange={handleStockRealizationsChange}
                />
            </StandalonePopover>}
        </div>
    </div>);
};

export default QuotePartInfoTable;
