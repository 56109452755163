import * as React from 'react';
import {Field, Form} from "react-final-form";
import {observer} from "mobx-react";
import {useStore} from "../../../context";
import {TagList} from "../../../components/ui/widgets/TagList";
import {CustomerTypeEnum} from "../../../store/mobx/CustomerStore";
import {FinalButtonSet, FinalButtonSetMode, Input, Select, Textarea} from "../../../components/ui/form";
import arrayMutators from "final-form-arrays";
import {FieldArray} from "react-final-form-arrays";
import {idFromEntity} from "../../../utils/iri";
import {useHistory} from "react-router";
import FileGallery from "../../../components/ui/widgets/FileGallery";
import useDataListFetch from "../../../utils/form/useDataListFetch";

type CustomerDetailsFormProps = {
    mode: FinalButtonSetMode
}

const CustomerDetailsForm = observer(({mode}: CustomerDetailsFormProps) => {
    const {customerStore, authStore} = useStore();
    const {push} = useHistory();
    const partnersDL = useDataListFetch('partners');
    const attachments = customerStore.fetchAttachments;
    return <Form<typeof customerStore.customerToUpdate> onSubmit={customerStore.updateOrCreateCustomer} mutators={{
        ...arrayMutators
    }}
                                                        initialValues={customerStore.customerToUpdate}
                                                        render={
                                                            ({values, pristine, submitting, form, handleSubmit}) =>
                                                                <form className={'customer-form'}
                                                                      onSubmit={handleSubmit}>
                                                                    <Field
                                                                        name='name'
                                                                        label='Company Name'
                                                                        component={Input}
                                                                        canEdit={customerStore.canEdit}
                                                                    />
                                                                    <Field
                                                                        name='code'
                                                                        label='Customer code'
                                                                        component={Input}
                                                                        canEdit={customerStore.canEdit}
                                                                    />

                                                                    <Field
                                                                        name='type'
                                                                        label='Type'
                                                                        placeholder='Select ...'
                                                                        options={CustomerTypeEnum}
                                                                        component={Select}
                                                                        canEdit={customerStore.canEdit}
                                                                    />

                                                                    <Field
                                                                        name={'address'}
                                                                        label='Address'
                                                                        placeholder={'Address'}
                                                                        component={Input}
                                                                        canEdit={customerStore.canEdit}
                                                                    />

                                                                    <Field
                                                                        name={'email'}
                                                                        label='Email'
                                                                        placeholder={'Email'}
                                                                        type={'email'}
                                                                        component={Input}
                                                                        canEdit={customerStore.canEdit}
                                                                    />


                                                                    <Field
                                                                        name='plainPassword'
                                                                        label='Password'
                                                                        placeholder={'Password'}
                                                                        type='password'
                                                                        component={Input}
                                                                        canEdit={customerStore.canEdit}
                                                                    />

                                                                    <Field
                                                                        name='contactNumber'
                                                                        label='Contact number'
                                                                        placeholder={'Contact number'}
                                                                        component={Input}
                                                                        canEdit={customerStore.canEdit}
                                                                    />

                                                                    <Field
                                                                        name='contactName'
                                                                        label='Contact name'
                                                                        placeholder={'Contact name'}
                                                                        component={Input}
                                                                        canEdit={customerStore.canEdit}
                                                                    />

                                                                    <Field
                                                                        name='onBehalf'
                                                                        label='On behalf'
                                                                        placeholder={'On behalf'}
                                                                        component={Select}
                                                                        {...partnersDL}
                                                                        canEdit={customerStore.canEdit}
                                                                    />

                                                                    {!authStore.isCustomerOrPartner &&
                                                                    <Field
                                                                        name='notes'
                                                                        label='Notes'
                                                                        placeholder={'Notes'}
                                                                        component={Textarea}
                                                                        rows={mode === "RELATIVE" ? 6 : 20}
                                                                        canEdit={customerStore.canEdit}
                                                                    />}


                                                                    {values.requesterCustomers &&
                                                                    values.requesterCustomers.length > 0 &&
                                                                    <FieldArray name="requesterCustomers">
                                                                        {({fields}) =>
                                                                            <div className='custom-field requesters'>
                                                                                <label>Requesters </label>

                                                                                <TagList
                                                                                    editable={true}
                                                                                    onDelete={(index) => {
                                                                                        fields.remove(index);
                                                                                    }}
                                                                                    items={values.requesterCustomers.map(item => ({
                                                                                        label: item.email,
                                                                                        name: item.name,
                                                                                        value: idFromEntity(item),
                                                                                    }))}
                                                                                    onClick={(item) => push(`/customers/${item.value}`)}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </FieldArray>}
                                                                    {!authStore.isCustomerOrPartner && attachments.length > 0 && (
                                                                        <div className='custom-field'>
                                                                            <label>Attachments: </label>
                                                                            <FileGallery
                                                                                attachmentInterface={customerStore}/>
                                                                        </div>
                                                                    )}
                                                                    <FinalButtonSet submitting={submitting}
                                                                                    pristine={pristine}
                                                                                    reset={form.reset} mode={mode}
                                                                                    label={"Save"}/>
                                                                </form>
                                                        }/>
});

export default CustomerDetailsForm;
