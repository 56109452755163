import React, {ButtonHTMLAttributes, DetailedHTMLProps, ReactNode} from 'react';
import {Link as VendorLink} from 'react-router-dom'
import {Intent, Spinner} from '@blueprintjs/core';

import Icon from '@mdi/react'

import {Checkbox, DateInput, Input, MultiSelect, NumberInput, RadioGroup, Select, StarRating, Textarea,} from './form';
import {mdiCheckboxBlankOutline, mdiCheckboxMarked} from "@mdi/js";
import {IApiEntity, Iri} from "../../utils/models";

export const Link = ({children, ...rest}: any) => (
    <VendorLink {...rest}>{children}</VendorLink>
)

export const Loader = () => {
    return (
        <div className='loader'>
            <Spinner intent={Intent.SUCCESS}/>
        </div>
    );
};

export const Button = (props: DetailedHTMLProps<ButtonHTMLAttributes<any>, any> & {icon?: string, classes?: any}) => {
    return (
        <button
            className={'roundButton ' + (props.color ? props.color : '') + ' ' + (props.classes || '')}
            {...props} >
            {props.icon && <Icon path={props.icon}/>}
            {props.children}
        </button>
    );
}

type LinkButtonProps = {
    children?: ReactNode,
    onClick?,
    className?
    style?,
    removePadding?
}

export const LinkButton = ({children, onClick, removePadding, className}: LinkButtonProps) => {

    return (<button
        type="button"
        className={className}
        onClick={onClick}
    >
        {children}
    </button>)
}

export const StandaloneDateInput = (props) => {
    const DateInput = require('@blueprintjs/datetime').DateInput;
    return (
        <DateInput
            {...props}
        />
    );
}

type CheckboxToggleListProps<T> = {
    data: T[],
    chosen: Iri[],
    setChosen: (iris: Iri[]) => any,
    renderLabelFn?: (data: T) => string,
}

function CheckboxToggleList<T extends IApiEntity>({data, chosen, setChosen, renderLabelFn}: CheckboxToggleListProps<T>) {

    return (
        <div className={'checkbox-list'}>
            {data.map((item) => {
                const labelClass = chosen.includes(item['@id']) ? 'checkbox selected' : 'checkbox';
                return (
                    <label key={item['@id']} className={labelClass}
                           onClick={(e) => {
                               e.stopPropagation();
                               const itemIndex = chosen.findIndex(real => real === item['@id']);

                               if (itemIndex === -1) {
                                   if (item['@id']) {
                                       setChosen([...chosen, item['@id']]);
                                   }
                               } else {
                                   setChosen(chosen.filter(item => item !== undefined).filter((item, i) => i !== itemIndex));
                               }
                           }}>
                        {!chosen.includes(item['@id']) ? <Icon path={mdiCheckboxBlankOutline}/> :
                            <Icon className={'checked'} path={mdiCheckboxMarked}/>}
                        {renderLabelFn && <p> {renderLabelFn(item)} </p>}
                    </label>
                )
            })}
        </div>
    );
}

export {
    Select,
    Input,
    CheckboxToggleList,
    NumberInput,
    Textarea,
    RadioGroup,
    StarRating,
    DateInput,
    MultiSelect,
    Checkbox,
}
