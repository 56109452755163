import React, {useState} from 'react';
import _ from 'underscore';
import ViolationMessage from "../ViolationMessge";

export const roundToDigits = (value) => {
    return parseFloat(value.toFixed(2))
};

const TimeNumberInput = ({label, input, meta, step, ...rest}) => {

    const [timesValue, setTimesValue] = useState<string>('00:00');
    const [isHandWrite, setIsHandWrite] = useState(false);

    const countStep = step ? step : 1;

    const setValue = (value) => {
        input.onChange(value);
        maskTimeStepper(value)
    };

    const getTimeValue = (floatValue, step, direction) => {
        let hours = Math.floor(floatValue * 100 / 100);
        let minutes = roundToDigits(floatValue - hours) * 100;
        if (direction === 'incr') {
            minutes += step * 100;
        }
        if (direction === 'decr') {
            minutes -= step * 100;
        }
        let additionalHours = 0;

        if (minutes >= 60) {
            additionalHours = Math.floor(minutes / 60);
            minutes -= additionalHours * 60;
            hours += additionalHours
        } else if (minutes < 0) {
            additionalHours = Math.ceil(Math.abs(minutes / 60));
            minutes = 60 - Math.abs(minutes);
            hours -= additionalHours
        }
        return hours + minutes / 100;
    };

    const {error, submitError} = (meta || {} as any);
    const minValue = rest.min_value ? Number(rest.min_value) : 0;

    const maskTimeStepper = (value) => {
        const valueStr = value.toString().split('.') || [];
        const min = valueStr[1] ? addZeroAfter(valueStr[1].toString()) : '00';
        const hrs = valueStr[0] ? addZeroBefore(valueStr[0].toString()) : '00';
        setTimesValue(`${hrs}:${min}`);
    };

    const addZeroBefore = (num) => {
        return num < 10 ? `0${num}` : num
    };

    const addZeroAfter = (num) => {
        return num < 10 ? `${num}0` : num
    };

    return (
        <div className={'ls-form ls-textinput numberinput'}>
            <label>{label ? label : input.name}</label>
            <div className="numberinput-wrap">
                <input
                    type="text"
                    placeholder={label ? label : input.name}
                    {...rest}
                    value={isHandWrite ? input.value : timesValue}
                    onClick={() => setIsHandWrite(true)}
                    onChange={setValue}
                    onBlur={(e) => {
                        const num = e.target.value.length > 0 ? parseFloat(e.target.value) : '';
                        setValue(num);
                        setIsHandWrite(false)
                    }}
                />
                <button
                    onClick={(event) => {
                        event.stopPropagation();
                        setIsHandWrite(false)
                        const timeValue = getTimeValue(parseFloat(input.value), countStep, 'decr');
                        setValue(_.isNumber(input.value) && (timeValue > minValue) ? roundToDigits(timeValue) : minValue);

                    }}
                    className='decrease'
                    type='button'>
                    <i className="fa fa-minus" aria-hidden="true"/>
                </button>
                <button
                    onClick={(event) => {
                        event.stopPropagation();
                        setIsHandWrite(false)
                        const timeValue = getTimeValue(parseFloat(input.value), countStep, 'incr');
                        setValue(_.isNumber(input.value) ? roundToDigits(timeValue) : countStep);

                    }}
                    className='increase'
                    type='button'>
                    <i className="fa fa-plus" aria-hidden="true"/>
                </button>
            </div>
            {(error || submitError) && (<ViolationMessage message={error || submitError}/>)}
        </div>
    );

}

export default TimeNumberInput;
