import React from "react";
import {FilterItem} from "./FiltersForm";
import {Form} from "react-final-form";
import {activeFilters, flatActiveFilters} from "../../utils/filters";
import Icon from '@mdi/react'
import {mdiPlus} from '@mdi/js';
import {useHistory} from "react-router-dom";
import {useQuery} from "../../utils/query";
import {stringify} from "querystring";
import {useStore} from "../../context";

type ActiveFiltersPropsType = {
    filterKeys: { [key: string]: string }
}

const ActiveFilters = ({filterKeys}: ActiveFiltersPropsType) => {
    const query = useQuery();
    const history = useHistory();
    const {filterStore} = useStore();
    return (
        <Form onSubmit={() => {
        }} initialValues={flatActiveFilters(filterKeys, true, query)}>
            {() =>
                activeFilters(filterKeys, true, query).map((item, index) => (
                        <div key={item.key}>
                            <FilterItem index={index} filter={item}/>
                            <span className="delete-filter" onClick={() => {
                                const old = query;
                                if(Object.entries(item.value)[0][1] === 'true') {
                                    const key = Object.keys(item.value);
                                    delete old[`${item.key}[${key}]`]
                                }

                                if (item.key === 'rate') {
                                    delete old[item.key + '[lte]'];
                                    delete old[item.key + '[gte]'];
                                }

                                if (item.key === 'closeAt'
                                    || item.key === 'createdAt'
                                    || item.key === 'lastActivityTime') {

                                    delete old[item.key + '[before]'];
                                    delete old[item.key + '[after]'];
                                }

                                delete old[item.key];
                                filterStore.clearLastActiveSegment();
                                history.push({search: stringify(old)});
                            }}>
                                <Icon path={mdiPlus}/>
                             </span>
                        </div>
                    )
                )}
        </Form>
    )
};
export default ActiveFilters;
