import React from 'react';
import {Field, Form} from 'react-final-form'

import {mdiTwoFactorAuthentication} from '@mdi/js';

import {Input} from './../../components/ui/form';
import {Button} from './../../components/ui';

const ForceTwoFactorForm = ({onSubmit}) => {
    return (
        <Form onSubmit={onSubmit}>
            {({handleSubmit, pristine, submitting, submitError}) => (
                <form onSubmit={handleSubmit}>
                    <Field type='text'
                           name='code'
                           label=''
                           placeholder='Security key'
                           withIco={true}
                           ico={mdiTwoFactorAuthentication}
                           component={Input}/>
                    {submitError && <span className="error">{submitError}</span>}
                    <Button
                        type='submit'
                        className={'roundButton'}
                        disabled={pristine || submitting}
                    >
                        Authorize
                    </Button>
                </form>
            )}
        </Form>
    );
};

export default ForceTwoFactorForm;
