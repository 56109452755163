import React from "react";
import {ActivityReadModelOutput, IncomingAttachmentItemDto} from "../../generated";
import UserAvatar from "../../components/ui/UserAvatar";
import {ActivityIcon} from "./ActivityIcon";
import moment from "moment";
import cn from 'classnames';
import {sortActivities} from "./formatter";
import {observer} from "mobx-react";


const extensionType = {
    'text/csv': 'csv-file.png',
    'application/pdf': 'pdf-file.png',
    'application/msword': 'doc-file.png',
    default: 'default-icon.png'
}

const Attachments = (props: { attachments: IncomingAttachmentItemDto[] }) => (
    <ul className={"message-attachments"}>
        {props.attachments.map(attachment => {
                const path = `./document-images/${extensionType[attachment.contentType] ?? extensionType.default}`
                return <li key={attachment.id} className={'attachment'}>
                    <img
                        className={'attachment-icon'}
                        src={require(`${path}`).default}
                        alt="document"/>

                    <a target={"_blank"} href={attachment.link}>
                        {attachment.name}
                    </a>
                </li>
            }
        )}
    </ul>
)
const ExtractImagesFromHtml = ({content}: {content: string }) => {
    const parser = new DOMParser();
    const clearContent = parser.parseFromString(content, 'text/html');
    Array.prototype.slice.call(clearContent.getElementsByTagName('base')).forEach(item=>
            item.remove()
        );
    let output = new XMLSerializer().serializeToString(clearContent);
    // useEffect(() => {
    //     const detailsTag = document.createElement('div');
    //     detailsTag.innerHTML = content.trim();
    //     const images = detailsTag.getElementsByTagName('img');
    //     const imagesDisplay = Array.from(images);
    //     imagesDisplay.forEach((item) => {
    //         const dimensionHorizontal = item.width > 500 && item.height <= 200;
    //         const dimensionHeight = item.width > 500 && item.height > 400;
    //         const dimensionVertical = item.height > 500 && item.height > 200;
    //         if (dimensionHorizontal) item.className = 'horizontal'
    //         else if (dimensionVertical) item.className = 'vertical'
    //         else if (dimensionHeight) item.className = 'fix-height'
    //         else item.className = 'common'
    //     });
    //     const elemMain = document.getElementById(`dimension-activity-${key}`);
    //     if (elemMain) elemMain.appendChild(detailsTag)
    // }, [])
    // if (!content.includes('<html>')) {
    //     return <p>{content}</p>;
    // }
    // if (!content.includes('<tr>'))
    //     return (
    //         <SRLWrapper>
    //             <div className={'dimension-activities'} id={`dimension-activity-${key}`} dangerouslySetInnerHTML={{__html: content}}></div>
    //         </SRLWrapper>
    //     )
    return <div style={{maxWidth: '100%', overflow: 'scroll'}} dangerouslySetInnerHTML={{__html: output}}></div>
}


type ActivityMessagesType = {
    activities: ActivityReadModelOutput[]
    isSupportRequest?: boolean,
    forceActivityVisibleMode?: 'internal' | 'public',
}

export const ActivityLogMessages = observer((props: ActivityMessagesType) => {
    const {activities, isSupportRequest, forceActivityVisibleMode = 'public'} = props;
    return (
        <div className={cn({
            'activity-messages': isSupportRequest,
            'internal': forceActivityVisibleMode === 'internal',
            'public': forceActivityVisibleMode === 'public',
        })}>
            {sortActivities(activities)
                .filter(item => {
                        if (!props.isSupportRequest) {
                            return true;
                        }
                        return forceActivityVisibleMode === 'internal' ? item.isInternal : !item.isInternal
                    }
                )
                .map(elem =>
                    <div
                        key={elem.id}
                        className={elem.isInternal ? 'activity-list-item internal' : 'activity-list-item reply'}
                    >
                        <div className="user">
                            <UserAvatar full={false} name={elem.username}/>

                            <div className="user-info">
                                <p className="user-info__name">{elem.username}</p>
                                <div className={'user-info__status'}>
                                    <ActivityIcon elem={elem}/>
                                    <p className="createAt">{moment(elem.createdAt).format('LLL')}</p>
                                </div>
                            </div>
                        </div>

                        <div className={elem.isInternal ? "message internal" : "message reply"}>
                            <ExtractImagesFromHtml key={elem.id} content={elem.messageDetails}/>
                            {elem.attachments.length > 0 && <Attachments attachments={elem.attachments}/>}
                        </div>

                    </div>
                )}
        </div>
    )
})