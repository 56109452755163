import * as React from 'react';
import {Field, Form} from "react-final-form";
import {Input} from "../../components/ui";
import {useStore} from "../../context";
import {FinalButtonSet} from "../../components/ui/form";


function RequesterCreateForm() {
    const {customerStore} = useStore();
    return (
        <Form onSubmit={customerStore.createRequester}
              render={
                  ({pristine, submitting, handleSubmit}) =>
                      <form onSubmit={handleSubmit}>
                          <Field
                              name='email'
                              autoComplete='off'
                              label='Enter email'
                              type={"email"}
                              component={Input}
                              required
                          />
                          <Field
                              name='name'
                              label='Contact name'
                              component={Input}
                              required
                          />
                          <Field
                              name='code'
                              label='Customer code'
                              component={Input}
                              required
                          />
                          <Field
                              name='plainPassword'
                              autoComplete='off'
                              type='password'
                              label='Enter password'
                              component={Input}
                              required
                          />
                          <FinalButtonSet pristine={pristine} submitting={submitting}/>
                      </form>}/>
    );
}

export default RequesterCreateForm
