import React from 'react';
import {createUltimatePagination, ITEM_TYPES} from 'react-ultimate-pagination';

const Page = ({value, isActive, onClick}) => (
    <button type='button' className={'pager-page ' + (isActive ? 'active' : '')} onClick={onClick}>{value}</button>
);

const Ellipsis = ({onClick}) => (
    <button type='button' className="pager-elipsis" onClick={onClick}><span className="fa fa-ellipsis-h"/></button>
);

const FirstPageLink = ({isActive, onClick}) => (
    <button type='button' className="pager-side" onClick={onClick}><span className="fa fa-angle-double-left"/></button>
);

const PreviousPageLink = ({isActive, onClick}) => (
    <button type='button' className="pager-offset-right" onClick={onClick}><span className="fa fa-angle-left"/></button>
);

const NextPageLink = ({isActive, onClick}) => (
    <button type='button' className="pager-offset-left" onClick={onClick}><span className="fa fa-angle-right"/></button>
);

const LastPageLink = ({isActive, onClick}) => (
    <button type='button' className="pager-side" onClick={onClick}><span className="fa fa-angle-double-right"/></button>
);

function Wrapper(props) {
    return <div className="pager">{props.children}</div>
}

const itemTypeToComponent = {
    [ITEM_TYPES.PAGE]: Page,
    [ITEM_TYPES.ELLIPSIS]: Ellipsis,
    [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
    [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
    [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink
};

const UltimatePaginationBasic = createUltimatePagination({
    itemTypeToComponent,
    WrapperComponent: Wrapper
});

export default UltimatePaginationBasic;
