import remotedev from "mobx-remotedev";
import {action, computed, observable} from "mobx";
import ApiClient from "../ApiClient";
import {INotificationsModel} from "../../utils/models";
import {AuthStore} from "./AuthStore";
import {handleFormSubmit} from "../../utils/form/handleFormSubmit";
import {SubmissionErrors} from "final-form";
import UiBus from "../service/UiBus";
import moment from "moment";
import {RouterStore} from "mobx-react-router";
import {stringIdFromIri} from "../../utils/iri";


@remotedev({global: true})
export class NotificationStore {
    @observable notifications: INotificationsModel[] = [];
    @observable isLoading: boolean = false;

    constructor(private apiClient: ApiClient, private authStore: AuthStore, private uiBus: UiBus, private router: RouterStore) {

    }

    @action fetchCollection = () => {
        this.toggleLoading();
        const dateBefore = moment().format();
        let query = {
            'notificationDate[before]': dateBefore
        };
        this.apiClient.notificationsCollectionFetch(query).then(action('fetch notification ok', (response) => {
            this.notifications = response['hydra:member'];
            this.toggleLoading();
        }));
    };

    @action create = async (values): Promise<SubmissionErrors | null> => {
        return handleFormSubmit(this.apiClient.notificationsCreate(values)).then(res => {
            if (!res.errors) {
                this.uiBus.notify("Notification created, related users will be notified", "info");
            }
            return res.errors;
        })
    };

    @action public readMessage = (message: INotificationsModel) => {
        this.apiClient.notificationMarkAsRead(message.id).then();
        this.notifications = this.notifications.map(item => {
            if (item.id === message.id) {
                return {...item, isRead: true};
            } else {
                return item;
            }
        });
    };

    @action public handleNotificationRemove = (message: INotificationsModel) => {
        this.apiClient.notificationRemove(message.id).then();
        this.notifications = this.notifications.filter(item => item.id !== message.id);
    };

    @action public handleNotificationTouch = (message: INotificationsModel) => {
        if (message.supportRequest) {
            this.router.history.push({pathname: `/support_requests/${stringIdFromIri(message.supportRequest)}`})
        }
    };

    @action public readAllMessage = () => {
        this.apiClient.notificationMarkAllAsRead().then();
        this.notifications = this.notifications.map((item) => {
            return {...item, isRead: true};
        })
    };

    @action toggleLoading = (): void => {
        this.isLoading = !this.isLoading
    };


    @computed get countUnreadMessages(): number {
        return this.notifications.filter(item => !item.isRead).length;
    }

    @computed get canCreate() {
        return this.authStore.isGranted('ROLE_ADD_USER_NOTIFICATION')
    }
}
