import React from 'react';

import {FinalButtonSet, RadioGroup} from '../../components/ui/form';
import {Select} from '../../components/ui';

import {useStore} from "../../context";
import {Field, Form} from "react-final-form";
import {observer} from "mobx-react";
import {formatPartTypeListStatus, partTypeList} from "../../store/enum/partEnumeration";
import {formatPurchaseReason, purchaseReasonList} from "../../store/enum/purchaseEnumeration";


const PurchaseItemSelect = observer(() => {
    const {purchaseStore} = useStore();

    return <Form initialValues={{
        type: 'hardware',
        purchaseReason: 'spare',
    }} onSubmit={purchaseStore.addPurchaseItemStart} render={({handleSubmit, submitting, form}) =>
        <form onSubmit={handleSubmit}>
            <Field
                name="purchaseReason"
                label="Reason of purchase"
                component={Select}
                clearable={false}
                options={purchaseReasonList}
                formatOptions={formatPurchaseReason}
            />
            <Field
                name="type"
                label="Type"
                component={RadioGroup}
                options={partTypeList}
                formatOptions={formatPartTypeListStatus}
            />
            <FinalButtonSet pristine={false} submitting={submitting} reset={form.reset} label={"Next"}/>
        </form>
    }/>
});


export default PurchaseItemSelect;
