import React from "react";
import {observer} from "mobx-react";
import {Field, Form} from "react-final-form";
import {FinalButtonSet, Select} from "../../../components/ui/form";
import useDataListFetch from "../../../utils/form/useDataListFetch";

type QuoteItemHeaderProps = {
    handleSubmit
}
const QuoteDeclineForm = observer(({handleSubmit}: QuoteItemHeaderProps) => {
    const cancellationReasonDL = useDataListFetch('cancellation_reasons')
    return (
        <Form
            onSubmit={handleSubmit}
        >
            {({handleSubmit, pristine, submitting}) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        label='Cancellation reason'
                        name='cancellationReason'
                        component={Select}
                        {...cancellationReasonDL}
                    />
                    <FinalButtonSet submitting={submitting} pristine={pristine} mode={"RELATIVE"}/>
                </form>
            )}
        </Form>
    )
})
export default QuoteDeclineForm;
