import remotedev from "mobx-remotedev";
import {CollectionResponseType, GridResourceCollectionInterface} from "../interfaces/GridResourceCollectionInterface";
import ApiClient from "../ApiClient";
import {action, computed, observable, runInAction} from "mobx";
import ReportDownloader from "../service/ReportDownloader";
import {resolveTime} from "../../containers/opportunities/OpportunityHelper";
import moment, {MomentInput} from "moment";
import UiBus from "../service/UiBus";
import {FileAttachmentsInterface} from "../interfaces/FileAttachmentsInterface";
import AttachmentManager from "../service/AttachmentManager";
import {idFromEntity, idFromIri} from "../../utils/iri";
import {RouterStore} from "mobx-react-router";
import {SubmissionErrors} from "final-form";
import {handleFormSubmit, hydrateInitialValues} from "../../utils/form/handleFormSubmit";
import {ActivitiesLogInterface} from "../../containers/activity_log/ActivitiesLogInterface";
import {AuthStore} from "./AuthStore";
import {ItemResourceInterface} from "../interfaces/ItemResourceInterface";
import OpportunityPermissionResolver from "../service/OpportunityPermissionResolver";
import {formatOpportunityStatus} from "../enum/opportunityEnumeration";
import {
    IAlertModel,
    IAppointmentModel,
    IAttachmentModel,
    IItemOpportunityModel,
    IListOpportunityModel,
    Iri
} from "../../utils/models";
import {FilterStore} from "./FilterStore";
import {fromPromise, IPromiseBasedObservable} from "mobx-utils";
import {ActivityReadModelOutput} from "../../generated";
import {assert} from "ts-essentials";

type OpportunityStorePlaceType = 'grid' | 'create';
type OpportunityStoreContextPlace =
    'attachment'
    | 'assign'
    | 'invite'
    | 'alert'
    | 'set_appointment'
    | 'close_date'
    | 'rate'


@remotedev({global: true})
export class OpportunityStore implements GridResourceCollectionInterface<IListOpportunityModel>, FileAttachmentsInterface, ActivitiesLogInterface, ItemResourceInterface<IItemOpportunityModel> {
    @observable collectionResponse: CollectionResponseType<IListOpportunityModel> | null;
    @observable isLoading: boolean = false;
    @observable currentOpportunity: IItemOpportunityModel | null;
    @observable currentPlace: OpportunityStorePlaceType = 'grid';
    @observable currentContextPlace: OpportunityStoreContextPlace | null;
    private attachmentManager: AttachmentManager;
    private permissionResolver: OpportunityPermissionResolver;

    constructor(private apiClient: ApiClient, private authStore: AuthStore, private reportDownloader: ReportDownloader, private uiBus: UiBus, private routing?: RouterStore, private filterStore?: FilterStore) {
        this.attachmentManager = new AttachmentManager(apiClient, this, uiBus);
        this.permissionResolver = new OpportunityPermissionResolver(authStore);
    }

    @computed get canEdit(): boolean {
        return this.currentPlace === "create"
            ? (this.authStore.isAdmin || this.authStore.isSalesAgent)
            : this.permissionResolver.canEdit(this.currentOpportunity);
    }

    @computed get canAssign(): boolean {
        return this.permissionResolver.canEdit(this.currentOpportunity) || this.authStore.isSalesAgent;
    }

    @computed get fetchAttachments(): IAttachmentModel[] {
        if (!this.currentOpportunity || !this.currentOpportunity.attachments) {
            return [];
        }
        return this.currentOpportunity ? this.currentOpportunity.attachments : [];
    }
    @observable public activities: IPromiseBasedObservable<ActivityReadModelOutput[]>|null = null;

    @action fetchActivities(): void {
        this.activities = fromPromise(this.apiClient.opportunityItemFetch(this.id).then((r) => r.activities));
    }

    @computed get resolvedStatus() {
        if (this.currentOpportunity && this.currentOpportunity.status) {
            return formatOpportunityStatus(this.currentOpportunity.status);
        } else {
            return 'N/A';
        }
    }

    @computed
    public get id(): number {
        return this.currentOpportunity ? idFromEntity(this.currentOpportunity) : null;
    }

    @computed
    public get iri(): Iri {
        return this.currentOpportunity ? this.currentOpportunity['@id'] : null;
    }

    @computed
    public get status(): string {
        return this.currentOpportunity.status;
    }

    @computed
    public get title(): string {
        return this.currentOpportunity.title;
    }

    @computed
    public get timeLeft(): Date | null {
        return (this.currentOpportunity.closeAt ? new Date(this.currentOpportunity.closeAt) : null)
    }

    @computed
    public get showTimer(): Date | null {
        return (this.status === 'successful' || this.status === 'unsuccessful') && this.timeLeft;
    }

    public get isThereTimeLeft(): Boolean | null {
        assert(this.currentOpportunity);
        if (!this.currentOpportunity.closeAt) {
            return null;
        }
        return moment(this.currentOpportunity.closeAt).diff(moment.now()) > 0;
    }

    public get showSlaSpent(): Boolean {
        return false;
    }

    public get formattedSlaSpent(): string {
        return `not set`;
    }

    @action public clearContextPlace = (): void => {
        this.currentContextPlace = null;
    };

    @action public clearPlace = (): void => {
        this.currentPlace = 'grid';
        this.currentOpportunity = null;
    };

    @action public changeContextPlace = (place: OpportunityStoreContextPlace): void => {
        if (this.canEdit || this.canAssign) {
            this.currentContextPlace = place;
        }
    };

    @action toggleLoading = (): void => {
        this.isLoading = !this.isLoading;
    };

    @action goUpdate = (iri: Iri): void => {
        if (this.routing) {
            this.routing.history.push({pathname: `/opportunities/${idFromIri(iri)}`});
        }
    };

    @action goCreate = (): void => {
        this.currentOpportunity = null;
        this.currentPlace = 'create';
    };
    getEntityName: 'Opportunity';

    @action fetchCollectionReport(query): void {
        this.reportDownloader.download('Opportunity', this, this.apiClient.opportunityCollectionFetch(query), (item) => {
            const createdAt = moment(item.createdAt);
            const closeDate = moment(item.closeAt);
            const appointment = item.appointment && item.appointment.date && item.appointment.timeFrom
                ? resolveTime(new Date(item.appointment.date), new Date(item.appointment.timeFrom))
                : '';
            return {
                'Opportunity name': item.title,
                'customer/partner': item.customerName,
                'Total value': item.totalValue,
                'close date': closeDate.isValid() ? closeDate.format('DDMMYY') : '',
                rate: item.rate ? item.rate + '%' : '',
                type: item.type ? item.type.label : '',
                'sales agent': item.salesAgent ? item.salesAgent.username : '',
                appointment: appointment ? moment(appointment).format('DDMMYY') : '',
                id: item['@id'].split('/').reverse()[0],
                'created at': createdAt.isValid() ? createdAt.format('DDMMYY') : '',
                source: item.source,
                status: item.status,
                'Last activity date': moment(item.lastActivity.createdAt).format('DDMMYY'),
                'Last activity': moment(item.lastActivity.createdAt).format('DD MMM \'YY HH:mm') + ' ' + item.lastActivity.username + ' ' + item.lastActivity.messageDetails
            }
        });
    }

    @action fetchCollection = (query): void => {
        this.isLoading = true;
        this.apiClient.opportunityCollectionFetch(query).then(
            action("fetchCollection ok", response => {
                this.collectionResponse = response;
                this.isLoading = false;
            })
        ).catch(action("fetchCollection failed", () => {
            this.isLoading = false;
            this.uiBus.notify("Something went wrong", "warning");
        }));
    };

    @action fetchItem = (id) => {
        this.isLoading = true;
        return this.apiClient.opportunityItemFetch(id).then(
            action("fetchItem ok", response => {
                this.currentOpportunity = response;
                this.isLoading = false;
            })
        ).catch(action("fetchItem failed", () => {
            this.isLoading = false;
            this.uiBus.notify("Something went wrong", "warning");
        }));
    };

    @action public saveAttachments = (files: FormData): void => {
        this.attachmentManager.persist(this.apiClient.opportunityAttachmentsUpdate(idFromEntity(this.currentOpportunity), files)).then(action("saveAttachments ok", (res) => {
            this.currentOpportunity.attachments = res;
        }))
    };

    canRemoveAttachment(attachment: IAttachmentModel): boolean {
        return this.canEdit;
    }

    @action public removeAttachment = (item: IAttachmentModel): void => {
        this.attachmentManager.remove(item).then(action("removeAttachment ok", () => {
            this.currentOpportunity.attachments = this.currentOpportunity.attachments.filter(a => a['@id'] !== item['@id']);
        }));
    };

    @action assignUser = async ({salesAgent}): Promise<SubmissionErrors | null> => {

        const {errors, response} = await handleFormSubmit(this.apiClient.opportunityUpdate(idFromEntity(this.currentOpportunity), {salesAgent}));
        return runInAction("assignUser", () => {
            if (!errors) {
                this.currentOpportunity.salesAgent = response.salesAgent;
                this.uiBus.notify(`Opportunity #${idFromEntity(this.currentOpportunity)} updated`, "success");
                this.clearContextPlace();
            }
            return errors;
        });

    };

    @action public goExportOpportunity = async () => {
        this.toggleLoading();
        await this.apiClient.opportunityExport(this.id);
        this.toggleLoading();
    };

    @action updateOrCreate = async (data): Promise<SubmissionErrors | null> => {
        if (data.salesAgent) {
            data.salesAgent = data.salesAgent['@id'];
        }
        let req: Promise<IItemOpportunityModel>;
        if (this.currentOpportunity) {
            let hydratedValues = hydrateInitialValues(this.currentOpportunity, data);
            if (hydratedValues.invited.length > 0) {
                hydratedValues.invited = hydratedValues.invited.map(invited => invited['@id']);
            }
            req = this.apiClient.opportunityUpdate(idFromEntity(this.currentOpportunity), hydratedValues);
        } else {
            req = this.apiClient.opportunityCreate(data)
        }
        const {response, errors} = await handleFormSubmit(req);
        return runInAction("updateOrCreate ok", () => {
            if (!errors) {
                this.clearContextPlace();
                this.currentOpportunity = response;
                if (this.currentPlace === 'create') {
                    this.uiBus.notify(`Opportunity #${idFromEntity(response)} created`, "success");
                    this.goUpdate(response['@id']);
                } else {
                    this.uiBus.notify(`Opportunity #${idFromEntity(response)} updated`, "success");
                }
            }
            return errors;
        });

    };

    @action updateAttribute = async (data): Promise<SubmissionErrors | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.opportunityUpdate(idFromEntity(this.currentOpportunity), data));
        return runInAction("updateAttribute ok", () => {
            if (!errors) {
                this.clearContextPlace();
                this.currentOpportunity = response;
                this.uiBus.notify(`Opportunity #${idFromEntity(this.currentOpportunity)} updated`, "success");
            }
            return errors;
        });

    };

    @action createActivities = async (values): Promise<SubmissionErrors | null> => {
        const request = this.apiClient.addOpportunityComment(this.iri, values);
        const {errors} = await handleFormSubmit(request);
        if (!errors) {
            this.uiBus.notify(`Added comment to opportunity #${idFromEntity(this.currentOpportunity)}`, 'success');
            this.fetchItem(idFromEntity(this.currentOpportunity));
        }
        return errors;
    };

    @action removeAlert = (e) => {
        this.uiBus.confirm("Remove alert?", e).then(() => {
            this.apiClient.alertRemove(idFromEntity(this.currentOpportunity.alert)).then(action("removeAlert ok", () => {
                this.clearContextPlace();
                this.uiBus.notify(`Alert removed`, 'info');
                this.fetchItem(this.id).then();
            }));
        })

    };

    @action mutateAlert = async (values): Promise<SubmissionErrors | null> => {
        let request;
        if (values['dateAndTime']) {
            if (values['dateAndTime']['date']) {
                values['dateAndTime']['date'] = OpportunityStore.formatEmbeddedDate(values['dateAndTime']['date']);
            }
            values['dateAndTime']['hours'] = OpportunityStore.formatEmbeddedHours(values['dateAndTime']['hours']);
            values['dateAndTime']['minutes'] = OpportunityStore.formatEmbeddedMinutes(values['dateAndTime']['minutes']);
            values['dateAndTime']['offsetTimezone'] = OpportunityStore.getEmbeddedTimezone();
            values['dateAndTime']['offsetTimezoneDirection'] = OpportunityStore.getEmbeddedTimezoneDirection();
        }
        if (!this.currentOpportunity.alert) {
            request = this.apiClient.createAlertForOpportunity({...values, ...{opportunity: this.iri}});
        } else {
            request = this.apiClient.alertUpdate(idFromEntity(this.currentOpportunity.alert), values);
        }
        const {errors} = await handleFormSubmit<IAlertModel>(request);
        if (!errors) {
            this.clearContextPlace();
            this.uiBus.notify(`Alert is set`, 'success');
            this.fetchItem(idFromEntity(this.currentOpportunity));
        }
        return errors;
    };

    public static formatEmbeddedDate(date: MomentInput): string {
        return moment(date).format('YYYY-MM-DD').toString();
    }

    public static formatEmbeddedHours(hours: string): string {
        if (hours.length === 1) {
            return '0' + hours;
        }
        return hours;
    }

    public static formatEmbeddedMinutes(minutes: string): string {
        if (minutes.length === 1) {
            return '0' + minutes;
        }
        return minutes;
    }

    public static getEmbeddedTimezone(): number {
        const myDate = new Date();
        const timezoneOffset = myDate.getTimezoneOffset();
        return Math.floor(Math.abs(timezoneOffset) / 60);
    }

    public static getEmbeddedTimezoneDirection(): string {
        const myDate = new Date();
        const timezoneOffset = myDate.getTimezoneOffset();
        return timezoneOffset > 0 ? '-' : '+';
    }

    @action mutateAppointment = async (values): Promise<SubmissionErrors | null> => {

        let request;
        if (values['dateAndTimeFrom']) {
            if (values['dateAndTimeFrom']['date']) {
                values['dateAndTimeFrom']['date'] = OpportunityStore.formatEmbeddedDate(values['dateAndTimeFrom']['date']);
            }
            values['dateAndTimeFrom']['hours'] = OpportunityStore.formatEmbeddedHours(values['dateAndTimeFrom']['hours']);
            values['dateAndTimeFrom']['minutes'] = OpportunityStore.formatEmbeddedMinutes(values['dateAndTimeFrom']['minutes']);
            values['dateAndTimeFrom']['offsetTimezone'] = OpportunityStore.getEmbeddedTimezone();
            values['dateAndTimeFrom']['offsetTimezoneDirection'] = OpportunityStore.getEmbeddedTimezoneDirection();
        }
        if (values['hoursTo']) {
            values['hoursTo'] = OpportunityStore.formatEmbeddedHours(values['hoursTo']);
        }
        if (values['minutesTo']) {
            values['minutesTo'] = OpportunityStore.formatEmbeddedHours(values['minutesTo']);
        }
        if (!this.currentOpportunity.appointment) {
            request = this.apiClient.appointmentCreate({...values, ...{opportunity: this.iri}});
        } else {
            request = this.apiClient.appointmentUpdate(idFromEntity(this.currentOpportunity.appointment), values);
        }
        const {errors} = await handleFormSubmit<IAppointmentModel>(request);
        if (!errors) {
            this.clearContextPlace();
            this.uiBus.notify(`Appointment set`, 'success');
            this.fetchItem(idFromEntity(this.currentOpportunity));
        }
        return errors;
    };

    @computed get itemCreatedSalesAgent(): boolean {
        return this.authStore.username === this.collectionResponse["hydra:member"][0].salesAgent.username
    }

    @computed get idForActivityLogParent(): string {
        return this.currentOpportunity ? this.currentOpportunity["@id"] : '';
    }
}
