import {Icon} from "@mdi/react";
import {mdiPlus} from "@mdi/js";
import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useStore} from "../../../context";
import QuoteItem from "../Quote/QuoteItem";
import StandalonePopover from "../../../components/ui/StandalonePopover";
import QuoteDeclineForm from "./QuoteDeclineForm";
import {useLocation} from "react-router";
import {formatCurrency} from "../OpportunityHelper";

const QuoteListPanel = observer(() => {
    const {opportunityQuoteStore, opportunityStore} = useStore();
    const location = useLocation();
    useEffect(() => {
        if (opportunityStore.currentOpportunity) {
            opportunityQuoteStore.fetchCollection({opportunity: opportunityStore.currentOpportunity['@id']});
        }
    }, [opportunityStore.currentOpportunity, location.pathname]);

    if (!opportunityStore.currentOpportunity) {
        return null;
    }
    return (
        <React.Fragment>
            {opportunityQuoteStore.currentContextPlace === 'decline' &&
            <StandalonePopover closePopover={opportunityQuoteStore.clearContextPlace}>
                <QuoteDeclineForm handleSubmit={opportunityQuoteStore.declineQuote}/>
            </StandalonePopover>}
            <div className="right-panel__title">
                Quotes

                {(opportunityStore.canEdit && opportunityStore.currentOpportunity.quotes.length > 0) &&
                <button onClick={opportunityQuoteStore.goCreate}
                        className={'tertiaryBtn'}>
                    <Icon path={mdiPlus}/>
                    Add quote
                </button>}
                {opportunityStore.currentOpportunity.quotes.length > 0 &&
                    <div className="quotes-item__quote-id">
                        Total value {formatCurrency(
                        opportunityStore.currentOpportunity.quotes.reduce((acc, quote) => acc + quote.totalValue, 0)
                    )}
                    </div> }
            </div>

            <div className="right-panel-content">
                {opportunityStore.currentOpportunity.quotes.length === 0 && <div className={'no-items'}>
                    <div className="attachment-list">
                        <p>No Quotes</p>
                        {opportunityStore.canEdit && <button onClick={opportunityQuoteStore.goCreate}
                                                             className={'tertiaryBtn'}>
                            <Icon path={mdiPlus}/>
                            Add quote
                        </button>}
                    </div>
                </div>}
                {opportunityQuoteStore.collectionResponse &&
                opportunityQuoteStore.collectionResponse['hydra:member'].map((item, index) => <QuoteItem
                    key={item["@id"]} item={item} />)}
            </div>
        </React.Fragment>
    )
});
export default QuoteListPanel;


