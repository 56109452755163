import * as React from 'react';
import {Field, Form} from "react-final-form";
import {observer} from "mobx-react";
import {FinalButtonSet, Select,} from "../../components/ui/form";
import useDataListFetch from "../../utils/form/useDataListFetch";
import {formatTicketType, ticketTypeList} from "../../store/enum/ticketEnumeration";
import {SupportRequestProjectOutput, SupportRequestSystemOutput} from "../../generated";

type CreateTicketWithSelectedFormProps = {
    createTicket,
    title: string,
    system: SupportRequestSystemOutput | null,
    project: SupportRequestProjectOutput | null
}

const CreateTicketWithSelectedForm = observer(({createTicket, system, project, title}: CreateTicketWithSelectedFormProps) => {
    const ticketCategoriesDataList = useDataListFetch('ticket_categories');
    const contractStatus = system ? system.contractStatus : project.contractStatus;

    return <Form onSubmit={(values) => {
                     if (system) {
                         values['system'] = `/api/systems/${system.id}`
                     }
                     if (project) {
                         values['project'] = `/api/projects/${project.id}`
                     }
                     values['title'] = title;
                     createTicket(values);
                 }}

                 render={
                     ({pristine, submitting, handleSubmit, form}) =>
                         <form onSubmit={handleSubmit}>

                             <div>

                                 {contractStatus && contractStatus === 'expired'
                                 && <div className={'expired-contract-warning'}>
                                     <span>Ticket for expired contract</span>
                                 </div>}

                                 <h3>{title}</h3>

                                 {system && <h3>System #{system.modelNumber}</h3>}

                                 {project && <h3>Project #{project.name}</h3>}

                                 <Field
                                     name='type'
                                     label='Type'
                                     required={true}
                                     options={ticketTypeList}
                                     formatOptions={formatTicketType}
                                     component={Select}
                                 />

                                 <Field
                                     name='category'
                                     label='Category'
                                     required={true}
                                     component={Select}
                                     menuPlacement={'top'}
                                     {...ticketCategoriesDataList}
                                 />

                             </div>

                             <div className={'popover-buttons'}>
                                 <FinalButtonSet pristine={pristine} submitting={submitting} reset={form.reset}/>
                             </div>
                         </form>
                 }/>
});

export default CreateTicketWithSelectedForm;
