import React, {useState} from 'react';
import {Field, Form} from 'react-final-form'
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {Input} from './../../../components/ui';
import {FinalButtonSet} from "../../../components/ui/form";
import ViolationMessage from "../../../components/ui/ViolationMessge";

const toolbar = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'image'],
    inline: {
        options: ['bold', 'italic', 'underline'],
    },
}
const EditorAdapter = ({input, meta, ...rest}) => {
    const [editorState, editorStateChange] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(
                htmlToDraft(input.value)
            )
        )
    )
    return (
        <Editor {...rest}
                onEditorStateChange={(e) => {
                    editorStateChange(e)
                    input.onChange(draftToHtml(convertToRaw(e.getCurrentContent())))
                }}
                toolbar={toolbar}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="wysivyg-redactor-canvas"/>
    );
}
type ResourceFormProps = {
    initialValues, onSubmit
}
const TermsAndConditionsForm = ({initialValues = {}, onSubmit}: ResourceFormProps) => {
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
        >
            {({handleSubmit, pristine, submitting, submitErrors}) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        name='label'
                        label='Label'
                        required
                        component={Input}
                    />
                    {submitErrors && submitErrors.content && <ViolationMessage message={submitErrors.content}/>}
                    <Field
                        name='content'
                        label='Content'
                        required
                        component={EditorAdapter}
                    />
                    <FinalButtonSet submitting={submitting} pristine={pristine} mode={"RELATIVE"}/>
                </form>
            )}
        </Form>
    )
}


export default TermsAndConditionsForm
