import React, {useEffect} from 'react';
import {useStore} from "../../context";
import {observer} from "mobx-react";
import useDataListFetch from "../../utils/form/useDataListFetch";
import {HourGlass, HourGlassSize} from '../../components/ui/widgets/HourGlass';

type ContractProfitDetailsProps = {
    id
}

const ContractProfitDetails = observer(({id}: ContractProfitDetailsProps) => {
    const {contractRProfitStore} = useStore();
    const expenseTypes = useDataListFetch("expense_types");
    useEffect(() => {
        if (!expenseTypes.isLoading) {
            contractRProfitStore.fetchProfitDetails(id, expenseTypes.options)
        }
    }, [id, expenseTypes.isLoading]);
    let localTotalValue = contractRProfitStore.totalValue;

    const renderEmptyPlaceholder = () => {
        return (
            <p className={'contract-profit__empty'}>
                <i className={'fa fa-folder-open-o'}/>
                No entries yet
            </p>
        )
    };

    const contractData = contractRProfitStore.contractData;
    const expenseRowsKeys = Object.keys(contractRProfitStore.expensesRows);

    return (
        <div className={'contract-profit'}>
            {contractData && <div className={''}>
                <div className={'profits-period'}>
                    {expenseRowsKeys.length ? expenseRowsKeys.map((expenseDate) => {
                        return (
                            <div key={expenseDate} className="date-period">
                                <h3>{expenseDate}</h3>
                                {contractRProfitStore.expensesRows[expenseDate].map((expenseRow, index) => {
                                    localTotalValue += expenseRow.sum;
                                    // eslint-disable-next-line react/no-array-index-key
                                    return (<div key={index} className={'profits-row'}>
                                        <p className={'message'}>{expenseRow.message}</p>
                                        <p className={expenseRow.sum > 0 ? 'increase' : 'decrease'}>£{expenseRow.sum.toFixed(2)}</p>
                                        <p className={'total-value-left'}>£{localTotalValue.toFixed(2)}</p>
                                    </div>)
                                })}
                            </div>)
                    }) : renderEmptyPlaceholder()}

                </div>

                <div className="profits-tile">
                    {contractData.endDate &&
                    (<div className={'contract-profit__summary-row summary-row'}>
                        <span className={'summary-row__label'}>Contract time remain</span>
                        <div className={'summary-row__value'}>
                            {<HourGlass noIcon={true} targetTime={new Date(contractData.endDate)} inline={true}
                                        size={HourGlassSize.SMALL}/>}
                        </div>
                    </div>)
                    }
                    <div className={'contract-profit__summary-row summary-row'}>
                        <span className={'summary-row__label'}>Contract duration</span>
                        <span
                            className={`summary-row__value summary-row__value--green`}>
                                {contractData.durationInDays}d
                            </span>
                    </div>
                    <div className={'contract-profit__summary-row summary-row'}>
                        <span className={'summary-row__label'}>Initial cost</span>
                        <span
                            className={`summary-row__value summary-row__value--${Number(contractData.initialCost) >= 0 ? 'red' : 'green'}`}>
                                £{contractData.initialCost !== '0' && ('-')}{contractData.initialCost ? contractData.initialCost : 0}
                            </span>
                    </div>
                    <div className={'contract-profit__summary-row summary-row'}>
                        <span className={'summary-row__label'}>Shared cost</span>
                        <span
                            className={`summary-row__value summary-row__value--${Number(contractData.sharedCost) >= 0 ? 'green' : 'red'}`}>
                                £{contractData.sharedCost ? contractData.sharedCost : 0}
                            </span>
                    </div>
                    <div className={'contract-profit__summary-row summary-row'}>
                        <span className={'summary-row__label'}>Cashflow left</span>
                        <span
                            className={`summary-row__value summary-row__value--${contractRProfitStore.totalLeft >= 0 ? 'green' : 'red'}`}>
                                £{contractRProfitStore.totalLeft.toFixed(2)}
                            </span>
                    </div>
                    <div className={'contract-profit__summary-row summary-row'}>
                        <span className={'summary-row__label'}>Av profit per month</span>
                        <span
                            className={`summary-row__value summary-row__value--${contractRProfitStore.averageProfit >= 0 ? 'green' : 'red'}`}>
                                £{contractRProfitStore.averageProfit.toFixed(2)}
                            </span>
                    </div>
                </div>


            </div>}
        </div>);
});

export default ContractProfitDetails;
