import React from 'react'
import {RouteComponentProps} from "react-router";
import {inject, observer} from "mobx-react";
import RootStore from "../../store/mobx";

@inject("rootStore") @observer
class Logout extends React.Component<{ rootStore: RootStore } & RouteComponentProps> {

    componentDidMount() {
        this.props.rootStore.authStore.logout();
        window.location.replace('/');
    }

    render() {
        return null;
    }
}

export default Logout
