import React from "react";
import StandalonePopover from "../../components/ui/StandalonePopover";
import FileUploader from "../../components/ui/widgets/FileUploader";
import {useStore} from "../../context";
import ExpenseSelect from "../settings/expenses/ExpenseSelect";
import SpentTimeSelect from "./SpentTimeSelect";
import TicketAssignToSelectForm from "./TicketAssignToSelectForm";
import TicketInvitedSelectForm from "./TicketInvitedSelectForm";

import {Icon} from "@mdi/react";
import {
    mdiAccountArrowLeftOutline,
    mdiAccountPlusOutline, mdiAlertOutline,
    mdiCash,
    mdiCheckCircleOutline,
    mdiClockOutline,
    mdiFolderOutline
} from '@mdi/js';
import {observer} from "mobx-react";
import TicketSetStatusForm from "./TicketSetStatusForm";
import AlertForm from "../../components/ui/AlertForm";


const TicketDetailsButtonList = observer(() => {
    const {ticketStore} = useStore();
    return (
        <div onClick={e => e.stopPropagation()} className="buttons">
            {ticketStore.contextPlace === 'attachment' &&
            <StandalonePopover size={"bd"} title={"Attachments"} closePopover={ticketStore.clearContextPlace}>
                <FileUploader attachmentStore={ticketStore}/>
            </StandalonePopover>}

            {ticketStore.contextPlace === 'expenses' &&
            <StandalonePopover title={"Add expense"} closePopover={ticketStore.clearContextPlace}>
                <ExpenseSelect onFormSubmit={ticketStore.addExpense} canEdit={ticketStore.canEdit}/>
            </StandalonePopover>}

            {ticketStore.contextPlace === 'assign' &&
            <StandalonePopover title={"Assign to"} closePopover={ticketStore.clearContextPlace}>
                <TicketAssignToSelectForm/>
            </StandalonePopover>}

            {ticketStore.contextPlace === 'spent_time' &&
            <StandalonePopover title={"Add spent time"} closePopover={ticketStore.clearContextPlace}>
                <SpentTimeSelect/>
            </StandalonePopover>}

            {ticketStore.contextPlace === 'invite' &&
            <StandalonePopover size={"ld"} title={"Invite"} closePopover={ticketStore.clearContextPlace}>
                <TicketInvitedSelectForm/>
            </StandalonePopover>}

            {ticketStore.contextPlace === 'set_status' &&
            <StandalonePopover title={"Set status"} closePopover={ticketStore.clearContextPlace}>
                <TicketSetStatusForm/>
            </StandalonePopover>}

            {ticketStore.contextPlace === 'alert' &&
            <StandalonePopover title={"Alert"} closePopover={ticketStore.clearContextPlace}>
                <AlertForm currentItemAlert={ticketStore.currentTicket}
                           onSubmit={ticketStore.mutateAlert}
                           removeAlert={ticketStore.removeAlert}
                           values={ticketStore.currentTicket.alert}/>
            </StandalonePopover>}

            {ticketStore.canEdit && <div className="buttons-item" onClick={() => ticketStore.changeContextPlace('attachment')}>
                <Icon path={mdiFolderOutline}/>Attachment
            </div>}
            {ticketStore.canEdit && <div className="buttons-item" onClick={() => ticketStore.changeContextPlace('invite')}>
                <Icon path={mdiAccountPlusOutline}/>Invite
            </div>}
            {ticketStore.canEdit && <div className="buttons-item" onClick={() => ticketStore.changeContextPlace('assign')}>
                <Icon path={mdiAccountArrowLeftOutline}/>Assign to
            </div>}
            {ticketStore.canEdit && <div className="buttons-item" onClick={() => ticketStore.changeContextPlace('spent_time')}>
                <Icon path={mdiClockOutline}/>
                Add time
            </div>}
            {ticketStore.canEdit && <div className="buttons-item" onClick={() => ticketStore.changeContextPlace('expenses')}>
                <Icon path={mdiCash}/>Add expense
            </div>}
            <div className="buttons-item" onClick={() => ticketStore.changeContextPlace('set_status')}>
                <Icon path={mdiCheckCircleOutline}/>
                Set status
            </div>
            {ticketStore.canEdit && <div className="buttons-item" onClick={() => ticketStore.changeContextPlace('alert')}>
                <Icon path={mdiAlertOutline}/>Alert
            </div>}
        </div>
    );
})

export default TicketDetailsButtonList;
