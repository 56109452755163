import {Icon} from "@mdi/react";
import {mdiAccountGroupOutline, mdiClockOutline, mdiFileDocumentOutline, mdiInboxArrowUpOutline,} from "@mdi/js";
import React from "react";
import {HourGlass, HourGlassSize} from "../../components/ui/widgets/HourGlass";
import {observer} from "mobx-react";
import {useStore} from "../../context";
import useDataListFetch from "../../utils/form/useDataListFetch";
import {Link} from "react-router-dom";
import {idFromEntity} from "../../utils/iri";
import ExpensesDetailsWidget from "../../components/ui/widgets/ExpensesDetailsWidget";
import TopInfoBlock from "../../components/ui/widgets/TopInfoBlock";

const TicketDetailsInfoPanel = observer(({canEdit}) => {
    const {ticketStore, authStore} = useStore();
    const usersDL = useDataListFetch("engineers+admins");
    const ticketContract = ticketStore.currentTicket.contract;
    const forExpired = ticketContract && ticketContract.status === 'expired';

    return (<React.Fragment>
            <TopInfoBlock store={ticketStore} forExpired={forExpired}/>
            <div className={`tile`}>

                <div className="tile-item">
                    <Icon path={mdiAccountGroupOutline}/>
                    <div className="tile-item__info" onClick={() => {
                        if (!authStore.isCustomerOrPartner)
                            ticketStore.changeContextPlace('assign');
                    }}>
                        <p className={'tile-item__info-title'}>Engineer</p>
                        <p>{ticketStore.currentTicket.engineerName ?
                            <span>{ticketStore.currentTicket.engineerName} <span>(owner)</span></span> : ''}
                            {ticketStore.currentTicket.invited.map(elem => (
                                <span key={elem}>, {usersDL.options[elem]} <span>(guest)</span></span>
                            ))}
                        </p>
                    </div>
                </div>
                {canEdit && <ExpensesDetailsWidget withExpenses={ticketStore}/>}
                <div className="tile-item" onClick={() => {
                    ticketStore.changeContextPlace('spent_time');
                }}>
                    <Icon path={mdiClockOutline}/>
                    <div className="tile-item__info">
                        <p className={'tile-item__info-title'}>Spent time:</p>
                        <span> {((ticketStore.currentTicket['spentHours'] || ticketStore.currentTicket['spentMinutes'])
                            ?
                            <b>{Number(ticketStore.currentTicket['spentHours']).toString() + 'h ' + Number(ticketStore.currentTicket['spentMinutes']).toString() + 'm'}</b>
                            : <p>Not set</p>)}</span>
                    </div>
                </div>

                {ticketContract && <div className="tile-item">
                    <Icon path={mdiFileDocumentOutline}/>
                    <div className="tile-item__info">
                        <p className={'tile-item__info-title'}>Contract:</p>
                        <Link
                            to={`/customers/${idFromEntity(ticketContract.customer)}?contractId=${ticketContract.id}`}>{ticketContract.contractNumber}
                            <Icon path={mdiInboxArrowUpOutline}/></Link>
                    </div>
                </div>}
            </div>


            <div className='info-list'>
                <div className="row">
                    {ticketStore.currentTicket['system'] && ticketStore.currentTicket['system']['endDate'] && (
                        <React.Fragment>
                            <label> <i className="fa fa-hourglass"
                                       aria-hidden="true"/> Contract
                                expires in:
                            </label>
                            <HourGlass
                                targetTime={new Date(ticketStore.currentTicket['system']['endDate'])}
                                noIcon={true}
                                inline={true}
                                size={HourGlassSize.SMALL}
                            />
                        </React.Fragment>
                    )}
                    {ticketStore.currentTicket['project'] && ticketStore.currentTicket['project']['endDate'] && (
                        <React.Fragment>
                            <label> <i className="fa fa-hourglass"
                                       aria-hidden="true"/> Contract
                                expires in:
                            </label>
                            <HourGlass
                                targetTime={new Date(ticketStore.currentTicket['project']['endDate'])}
                                noIcon={true}
                                inline={true}
                                size={HourGlassSize.SMALL}
                            />
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
});

export default TicketDetailsInfoPanel;
