import React from 'react';


import LoginForm from './LoginForm';
import logo from '../../media/primenet-logo.png';
import cert1 from '../../media/cert1.jpg';
import cert2 from '../../media/cert2.png';
import cert3 from '../../media/cert3.png';
import cert4 from '../../media/cert4.jpg';
import {observer} from "mobx-react";
import {useStore} from "../../context";

const Login = observer(() => {

    const {authStore} = useStore();

    return (
        <div className='login'>
            <div className="form-side">
                <img className='logo' src={logo} alt="logo"/>
                <LoginForm onSubmit={authStore.login}/>
                <div className={'certificates'}>
                    <img width={'40'} className='certificate-img' src={cert1} alt="NCVO Partner Primenet"/>
                    <img width={'56'} className='certificate-img' src={cert2} alt="Cyber Essentials certified"/>
                    <img width={'72'} className='certificate-img' src={cert3} alt="ISO 9001 Primenet IT Services"/>
                    <img width={'72'} className='certificate-img' src={cert4} alt="ISO 27001 Primenet IT Services"/>
                </div>
            </div>
        </div>
    );
})


export default Login;