import React from 'react';
import ResourceGridView from './../../../components/grid/ResourceGridView';
import {useStore} from "../../../context";

import ResourceUpdate from "./UserUpdate";
import ResourceCreate from "./UserCreate";
import {observer} from "mobx-react";
import {IUserModel} from "../../../utils/models";
import StandalonePopover from "../../../components/ui/StandalonePopover";

const ResourceGrid = observer(() => {
    const {genericResourceStore, authStore} = useStore();

    const canEdit = authStore.isGranted('ROLE_ADMIN') || authStore.isGranted('ROLE_ALL_ACCOUNT');

    genericResourceStore.configure('/api/users', 'User');


    return (
        <div className='dashboard'>
            {genericResourceStore.place === 'create' &&
            <StandalonePopover size={'bd'} title={'Create user'}
                               closePopover={() => genericResourceStore.changePlace('grid')}>
                <ResourceCreate sharedGridResourceCollectionStore={genericResourceStore} canEdit={canEdit}/>
            </StandalonePopover>}
            {genericResourceStore.place === 'update' &&
            <StandalonePopover size={'bd'} title={`Update user #${genericResourceStore.currentResourceId}`}
                               closePopover={() => genericResourceStore.changePlace('grid')}>
                <ResourceUpdate canRemove={canEdit} sharedGridResourceCollectionStore={genericResourceStore}
                                canEdit={canEdit}/>
            </StandalonePopover>}
            <ResourceGridView<IUserModel>
                columns={[
                    {
                        name: 'id',
                        value: (item) => item['@id'].split('/').reverse()[0],
                    },
                    {
                        name: 'email',
                        value: (item) => item.email ? item.email : '',
                    },
                    {
                        name: 'username',
                        value: (item) => item.username,
                    },
                    {
                        name: 'specialization',
                        value: (item) => item.specialization && item.specialization.label,
                    },
                    {
                        name: 'active',
                        value: (item) => item.enabled ? item.enabled.toString() : 'N\\A',
                    },
                ]}
                onRowClick={(item) => {
                    genericResourceStore.changePlace('update', item)
                }}
                onCreate={() => genericResourceStore.changePlace('create')}
                trProps={(item) => {
                    if (!item.enabled) {
                        return {className: 'not-approved'};
                    }
                }}

                gridResource={genericResourceStore}
            />
        </div>
    );
})


export default ResourceGrid;
