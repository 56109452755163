import React from 'react';
import ResourceGridView, {GridProps} from './../../components/grid/ResourceGridView';
import {confirm} from '../../components/ui/dialogs';
import {useStore} from "../../context";
import {observer} from "mobx-react";
import StandalonePopover from "../../components/ui/StandalonePopover";
import PurchaseForm from "./PurchaseForm";
import PurchaseItemSelect from "./PurchaseItemSelect"

import {mdiPlus, mdiTrashCanOutline} from '@mdi/js';
import PurchaseItemForm from "./PurchaseItemForm";
import PurchaseItemWithSerialsUpdateForm from "./PurchaseItemWithSerialsUpdateForm";
import {PurchaseItemModel, PurchaseShortModel} from "../../utils/models";
import {idFromEntity} from "../../utils/iri";
import PurchaseItemSoftwareUpdateForm from "./PurchaseItemSoftwareUpdateForm";

const filters = {
    'purchaseItems.partRealization.serialNumber': 'Serial number',
    id: 'Purchase ID',
    'delivery.trackingNumber': 'Tracking number',
    invoiceNumber: 'Invoice number',
    'supplier.id': 'Supplier id',
    'purchaseItems.part.partNumber': 'Part number'
};


const PurchasesGrid = observer(() => {
    const {purchaseStore} = useStore();

    const gridProps: GridProps<PurchaseShortModel> = {
        columns: [
            {
                name: 'invoiceNumber',
                label: 'Invoice',
                value: (item) => {
                    let dataOfPurchase;

                    if (item['dateOfPurchase']) {
                        dataOfPurchase = new Date(item['dateOfPurchase']).toDateString()
                    }

                    return (<div>
                            <div className="title">{item['invoiceNumber']}</div>
                            <div className="other-info">
                                {item['@id'].split('/').reverse()[0]}
                                <span className={'dot'}>•</span>
                                {item['dateOfPurchase'] ? dataOfPurchase : ''}
                                <span className={'dot'}>•</span>
                                {item['supplier'] && item['supplier']['name']}
                            </div>
                        </div>
                    )
                },
            },
            {
                name: 'totalPrice',
                label: 'Total price',
                value: (item) => (
                    <div>{item['totalPrice'] ? item['totalPrice'] : <span className={'not-available'}>N/A</span>}</div>
                ),
            },
            {
                name: 'trackingNumber',
                label: 'Delivery tracking number',
                value: (item) => (
                    <div>{item['delivery'] ? item['delivery']['trackingNumber'] :
                        <span className={'not-available'}>N/A</span>}</div>
                ),
            },
            {
                name: 'courierCompany',
                label: 'Courier company',
                value: (item) => (
                    <div>{item['delivery'] && item['delivery']['company'] ?
                        item['delivery']['company']['name'] : <span className={'not-available'}>N/A</span>} </div>
                ),
            }
        ],
        filterKeys: filters,
        childGrids: [
            {
                title: 'Software',
                getChildren: (item) => item.items.filter(
                    filterItem => filterItem.part && filterItem.part.type === 'software'),
                onRowClick: (item) => purchaseStore.changePlace('update_purchase_item', {purchaseItem: item}),
                getColumns: () => [
                    {
                        name: 'name',
                        label: 'Name',
                        value: (item: PurchaseItemModel) => item.partName,
                    },
                    {
                        name: 'modelNumber',
                        label: 'License number',
                        value: (item: PurchaseItemModel) => item.modelNumber,
                    },
                    {
                        name: 'manufacturer',
                        label: 'Manufacturer',
                        value: (item: PurchaseItemModel) => item.manufacturer ? item.manufacturer.name : 'N/A',
                    },
                    {
                        name: 'price',
                        label: 'Price',
                        value: (item: PurchaseItemModel) => item.price,
                    },
                    {
                        name: 'quantity',
                        label: 'Quantity',
                        value: (item: PurchaseItemModel) => item.quantity.toString(),
                    },
                ],
            },
            {
                title: 'Hardware',
                getChildren: item => item.items.filter(filterItem => filterItem.partRealization && filterItem.partRealization.part && filterItem.partRealization.part.type === 'hardware'),
                onRowClick: item => purchaseStore.changePlace('update_purchase_item', {purchaseItem: item}),
                getColumns: () => [
                    {
                        name: 'name',
                        label: 'Name',
                        value: (item: PurchaseItemModel) => (
                            <div>
                                <div className={'title'}>{item.partName}</div>
                                <div className="other-info">
                                    {item.manufacturer && item.manufacturer.name}
                                    <span className={'dot'}>•</span>
                                    {item.modelNumber}
                                </div>
                            </div>
                        ),
                    },
                    {
                        name: 'serialNumber',
                        label: 'Serial number',
                        value: (item: PurchaseItemModel) => item.partRealization.serialNumber,
                    },
                    {
                        name: 'partNumber',
                        label: 'Part number',
                        value: (item: PurchaseItemModel) => item.partNumber,
                    },
                    {
                        name: 'warranty',
                        label: 'Warranty',
                        value: (item: PurchaseItemModel) => item.warranty.toString(),
                    },
                    {
                        name: 'price',
                        label: 'Price',
                        value: (item: PurchaseItemModel) => item.price,
                    },
                ],
            },
            {
                title: 'System',
                getChildren: item => item.items.filter(filterItem => filterItem.partRealization && filterItem.partRealization.part && filterItem.partRealization.part.type === 'system'),
                onRowClick: item => purchaseStore.changePlace('update_purchase_item', {purchaseItem: item}),
                getColumns: () => [
                    {
                        name: 'name',
                        label: 'Name',
                        value: (item: PurchaseItemModel) => (
                            <div>
                                <div className={'title'}>{item.modelNumber}</div>
                                <div className="other-info">
                                    {item.manufacturer && item.manufacturer.name}
                                    <span className={'dot'}>•</span>
                                    {item.partName}
                                </div>
                            </div>
                        ),
                    },
                    {
                        name: 'serialNumber',
                        label: 'Serial number',
                        value: (item: PurchaseItemModel) => item.partRealization.serialNumber,
                    },
                    {
                        name: 'warranty',
                        label: 'Warranty',
                        value: (item: PurchaseItemModel) => item.warranty.toString(),
                    },
                    {
                        name: 'price',
                        label: 'Price',
                        value: (item: PurchaseItemModel) => item.price,
                    },
                ],
            }
        ],
        title: "Purchase",
        options: [
            {
                label: 'Add',
                icon: mdiPlus,
                color: 'green',
                onClick: (item) => {
                    purchaseStore.changePlace('add_purchase_item_start', {purchaseId: idFromEntity(item)});
                },
            },
            {
                label: 'Delete',
                icon: mdiTrashCanOutline,
                color: 'red',
                onClick: (item, e) => {
                    confirm({x: e.clientX, y: e.clientY}, 'Delete purchase',
                        'Are you sure, you want to delete this purchase ?').then(() => {
                        purchaseStore.removeResource(item['@id'].split('/').reverse()[0])
                    });
                },
            },
        ],
        gridResource: purchaseStore,
        onCreate: (e) => {
            purchaseStore.changePlace('purchase_create')
        },
        onRowClick: (item, e) => {
            purchaseStore.changePlace('purchase_update', {purchaseId: idFromEntity(item)})
        },
    };
    const close = () => {
        purchaseStore.changePlace('grid')
    };
    return (
        <div className='dashboard purchase-grid'>
            {purchaseStore.currentPlace === 'purchase_update' &&
            <StandalonePopover size={'md'} columnCount={2} classNames={'add-purchase'} title={`Update purchase | #${idFromEntity(purchaseStore.currentPurchase)}`} closePopover={close}>
                <PurchaseForm handleSubmit={purchaseStore.updatePurchase}
                              initialValues={purchaseStore.currentPurchase}/>
            </StandalonePopover>}

            {purchaseStore.currentPlace === 'purchase_create' &&
            <StandalonePopover columnCount={2} classNames={'add-purchase'} title={'Create Purchase'}
                               closePopover={purchaseStore.clearPlace}>
                <PurchaseForm handleSubmit={purchaseStore.createPurchase}/>
            </StandalonePopover>}

            {purchaseStore.currentPlace === 'add_purchase_item_start' &&
            <StandalonePopover size={"md"} classNames={'add-purchase'} title={"Add item | Step 1"} closePopover={close}>
                <PurchaseItemSelect/>
            </StandalonePopover>}

            {purchaseStore.currentPlace === 'purchase_item_create' &&
            <StandalonePopover classNames={'purchase-item'}
                               columnCount={2}
                               title={'Add ' + purchaseStore.partDraftType + ' item | Step 2'}
                               closePopover={() => {purchaseStore.clearPlace();purchaseStore.clearNewItemData();}}>
                <PurchaseItemForm/>
            </StandalonePopover>}


            {purchaseStore.currentPlace === 'update_purchase_item' &&
            <StandalonePopover classNames={'purchase-item'} columnCount={2} title={'Update item'}
                               closePopover={purchaseStore.clearPlace}>
                {purchaseStore.purchaseItemToEdit.partRealization ? <PurchaseItemWithSerialsUpdateForm/> : <PurchaseItemSoftwareUpdateForm/>}
            </StandalonePopover>}

            <ResourceGridView<PurchaseShortModel> {...gridProps} tableClass={'purchases'}/>
        </div>
    );
});


export default PurchasesGrid;
