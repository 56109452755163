import * as React from 'react';
import {Field, Form} from 'react-final-form'
import {FinalButtonSet, Select} from "../../../components/ui/form";
import {observer} from "mobx-react";
import {useStore} from "../../../context";
import {contractTypeList, formatContractType} from "../../../store/enum/contractEnumeration";

const ContractSelectTypeForm = observer(() => {
    const {customerStore} = useStore();
    return <Form onSubmit={customerStore.setContractDraftType}>
        {({handleSubmit, pristine, submitting, form}) => (
            <form onSubmit={handleSubmit}>
                <Field name='type' label={"Contract type"} component={Select} options={contractTypeList}
                       formatOptions={formatContractType}/>
                <FinalButtonSet pristine={pristine} reset={form.reset} submitting={submitting} label={"Next"}/>
            </form>
        )}
    </Form>
});

export default ContractSelectTypeForm;
