import remotedev from "mobx-remotedev";
import {CollectionResponseType, GridResourceCollectionInterface} from "../interfaces/GridResourceCollectionInterface";
import ApiClient from "../ApiClient";
import {action, computed, observable, runInAction} from "mobx";
import {RouterStore} from "mobx-react-router";
import {parse} from "querystring";
import {handleFormSubmit} from "../../utils/form/handleFormSubmit";
import {idFromEntity} from "../../utils/iri";
import UiBus from "../service/UiBus";
import {SubmissionErrors} from "final-form";
import {PartTypeListType} from "../enum/partEnumeration";
import {
    AddSerialsToPurchaseBatchInput, AddSoftwareToPurchaseInput, Iri,
    PartFullModel, PurchaseItemModel, PurchaseShortModel,
} from "../../utils/models";

type PurchaseHardwareItemDraft = {
    modelNumber: string | null,
    partNumber: string | null,
    name: string | null,
    serialNumber: string | null,
    warranty: string | null,
    price: string | null,
    type: PartTypeListType,
    purchaseReason: string | null,
    partId: string | null,
    purchaseId: string | null,
    manufacturer: string | null
}

type PurchaseStorePlaceType =
    'grid'
    | 'add_purchase_item_start'
    | 'purchase_item_create'
    | 'update_purchase_item'
    | 'purchase_update'
    | 'purchase_create'

@remotedev({global: true})
export class PurchaseStore implements GridResourceCollectionInterface<PurchaseShortModel> {
    @observable collectionResponse: CollectionResponseType<PurchaseShortModel> | null;
    @observable isLoading: boolean = false;
    @observable currentPurchase: PurchaseShortModel | null;
    @observable purchaseItemToEdit: PurchaseItemModel | null;
    @observable currentPlace: PurchaseStorePlaceType = 'grid';
    @observable onlyNewSerials: boolean = false;
    @observable newSerials: Array<string> = [];
    @observable private partDraft: PurchaseHardwareItemDraft | null;

    constructor(private apiClient: ApiClient, private routing: RouterStore, private uiBus: UiBus) {
    }

    getEntityName: 'Purchase';

    @computed get partDraftType() {
        return this.partDraft.type;
    };

    @computed get canSaveCreatePurchaseItemForm() {
        if (this.partDraftType === 'hardware') {
            return this.newSerials.length > 0;
        }
        return true;
    }

    @action toggleLoading = (): void => {
        this.isLoading = !this.isLoading;
    };

    @action changePlace = (place: PurchaseStorePlaceType, context?: { purchaseId?: number, purchaseItem?: PurchaseItemModel }): void => {
        if (place === 'purchase_update' || place === 'add_purchase_item_start') {
            this.fetchItem(context.purchaseId).then(action("changePlace ok", () => {
                this.currentPlace = place;
            }));
        } else if (place === 'update_purchase_item') {
            this.purchaseItemToEdit = context.purchaseItem;
            this.currentPlace = place;
        } else {
            this.currentPlace = place;
        }
    };

    @action clearPlace = () => {
        this.currentPlace = 'grid';
    }

    @action clearNewItemData = () => {
        this.partDraft = null;
        this.onlyNewSerials = false;
        this.newSerials = [];
    };

    @action removeResource = (id) => {
        this.isLoading = true;
        this.apiClient.purchaseRemove(id).then(action("genericResource delete ok", () => {
            this.changePlace('grid');
            this.uiBus.notify("Resource was successfully deleted.")
            this.fetchCollection({});
        })).catch(action("genericResource delete failed", e => {
            this.isLoading = false;
            this.uiBus.notify("Something went wrong", "warning");
        }));

    };

    @action fetchCollection = (query): void => {
        this.isLoading = true;
        this.apiClient.purchaseCollectionFetch(query).then(
            action("fetchCollection ok", response => {
                this.collectionResponse = response;
                this.isLoading = false;
            })
        ).catch(action("fetchCollection failed", e => {
            this.isLoading = false;
        }));
    };

    @action fetchItem = (id: number) => {
        this.isLoading = true;
        return this.apiClient.purchaseFetch(id).then(
            action("fetchItem ok", response => {
                this.currentPurchase = response;
                this.isLoading = false;

            })
        ).catch(action("fetchItem failed", e => {
            this.isLoading = false;
        }));
    };

    @action createPurchase = async (values): Promise<SubmissionErrors | null> => {
        const request = this.apiClient.purchaseCreate(values);
        const {errors, response} = await handleFormSubmit(request);
        return runInAction("createPurchase ok", () => {
            if (!errors) {
                this.currentPurchase = response;
                this.changePlace('grid');
                this.fetchCollection(parse(this.routing.location.search));
                this.uiBus.notify(`Purchase ${idFromEntity(this.currentPurchase)} created`)
            }
            return errors;
        });

    };

    @action updatePurchase = async (values): Promise<SubmissionErrors | null> => {
        const request = this.apiClient.purchaseUpdate(idFromEntity(this.currentPurchase), values);
        const {errors, response} = await handleFormSubmit(request);
        return runInAction("updatePurchase ok", () => {
            if (!errors) {
                this.currentPurchase = response;
                this.changePlace('grid');
                this.fetchCollection(parse(this.routing.location.search));
                this.uiBus.notify(`Purchase ${idFromEntity(this.currentPurchase)} updated`)
            }
            return errors;
        });
    };

    @action createPurchaseItem = async (values) => {
        let postData: any = {
            price: values.price,
            purchase: this.partDraft.purchaseId,
            purchaseReason: this.partDraft.purchaseReason,
            warranty: Number(values.warranty)
        };
        if (this.onlyNewSerials) {
            if (this.partDraft.type === 'hardware') {
                await this.handlePartsCreatedForPurchase(this.partDraft.partId, postData);
            } else if (this.partDraft.type === 'software') {
                await this.handleSoftwarePartCreatedForPurchase(this.partDraft.partId, postData, Number(values['quantity']));
            } else if (this.partDraft.type === 'system') {
                await this.handlePartsCreatedForPurchase(this.partDraft.partId, postData);
            }
        } else {
            if (this.partDraft.type === 'hardware') {
                const partData = {
                    manufacturer: this.partDraft.manufacturer,
                    name: this.partDraft.name,
                    partNumber: this.partDraft.partNumber,
                    modelNumber: this.partDraft.modelNumber,
                };
                this.apiClient.createPurchaseHardwarePart(partData).then(async (part) => {
                    await this.handlePartsCreatedForPurchase(part['@id'], postData);
                });
            } else if (this.partDraft.type === 'software') {
                const partData = {
                    manufacturer: this.partDraft.manufacturer,
                    name: this.partDraft.name,
                    modelNumber: this.partDraft.modelNumber,
                };
                this.apiClient.createPurchaseSoftwarePart(partData).then(async (part) => {
                    await this.handleSoftwarePartCreatedForPurchase(part['@id'], postData, Number(values['quantity']));
                });
            } else if (this.partDraft.type === 'system') {
                const partData = {
                    manufacturer: this.partDraft.manufacturer,
                    name: this.partDraft.name,
                    modelNumber: this.partDraft.modelNumber
                };
                this.apiClient.createPurchaseSystemPart(partData).then(async (part) => {
                    await this.handlePartsCreatedForPurchase(part['@id'], postData);
                });
            }
        }
    };

    handlePartsCreatedForPurchase = (partId: Iri, postData: AddSerialsToPurchaseBatchInput) => {
        postData = {
            ...postData,
            part: partId,
            serialNumbers: this.newSerials
        };
        return handleFormSubmit(this.apiClient.purchaseSerialsBatchCreate(postData)).then(action("convert ok", (res) => {
            if (!res.errors) {
                this.changePlace('grid');
                this.fetchCollection({});
                this.clearNewItemData();
                this.uiBus.notify(`Purchase item created`)
            }
        }));
    }

    handleSoftwarePartCreatedForPurchase = (partId: Iri, postData: AddSoftwareToPurchaseInput, quantity: number) => {
        postData = {
            ...postData,
            quantity: quantity,
            part: partId
        };
        return handleFormSubmit(this.apiClient.purchaseSoftwareCreate(postData)).then(action("convert ok", (res) => {
            if (!res.errors) {
                this.changePlace('grid');
                this.fetchCollection({});
                this.clearNewItemData();
                this.uiBus.notify(`Purchase item created`)
            }
        }));
    }

    @action updatePurchaseItemWithSerial = (values: PurchaseItemModel) => {
        const itemId = idFromEntity(this.purchaseItemToEdit);
        return handleFormSubmit(this.apiClient.purchaseItemUpdateWithSerial(itemId, {
            serialNumber: values.partRealization.serialNumber,
            warranty: Number(values.warranty),
            price: values.price,
        })).then(action("updatePurchaseItemWithSerial ok", (response) => {
            if (!response.errors) {
                this.changePlace('grid');
                this.purchaseItemToEdit = null;
                this.fetchCollection(parse(this.routing.location.search));
                this.uiBus.notify(`Purchase item ${itemId} updated`)
            }
        }));
    }

    @action updatePurchaseSoftwareItem = (values: PurchaseItemModel) => {
        const itemId = idFromEntity(this.purchaseItemToEdit);
        return handleFormSubmit(this.apiClient.purchaseItemUpdateSoftware(itemId, {
            warranty: Number(values.warranty),
            quantity: Number(values.quantity),
            price: values.price,
        })).then(action("updatePurchaseSoftwareItem ok", (response) => {
            if (!response.errors) {
                this.changePlace('grid');
                this.purchaseItemToEdit = null;
                this.fetchCollection(parse(this.routing.location.search));
                this.uiBus.notify(`Purchase item #${itemId} updated`)
            }
        }));
    }

    @action addPurchaseItemStart = async (values) => {
        this.partDraft = {
            ...values,
            purchaseId: this.currentPurchase['@id']
        };

        this.changePlace('purchase_item_create');
    };

    @action acceptPartFromAutocomplete = (item: PartFullModel | string) => {
        if (typeof item === 'string') {
            this.onlyNewSerials = false;
        } else {
            this.onlyNewSerials = true;
            this.partDraft.partId = item['@id']
        }
    };

    @action addNewSerialNumber = (serialNumber: string) => {
        this.newSerials.push(serialNumber);
    };

    @action removeNewSerialNumber = (index: number) => {
        this.newSerials = this.newSerials.filter((item, i) => i !== index);
    };

    @action updatePartDraftData(formValues) {
        this.partDraft = {
            ...this.partDraft,
            ...formValues
        }
    }

    @action updatePurchaseItemData(formValues) {
        this.purchaseItemToEdit = {
            ...this.purchaseItemToEdit,
            ...formValues
        }
    }
}
