import * as React from 'react';
import {Field, Form} from 'react-final-form'
import {FinalButtonSet, NumberInput, Select} from "../../../components/ui/form";
import {useStore} from "../../../context";
import {observer} from "mobx-react";
import {ContractAdministrationFormInterface} from "../../../store/mobx/ContractStore";
import {ContractDetailsFormSharedFieldsSet} from "./ContractDetailsForm";
import {contractPeriodList, formatContractPeriod} from "../../../store/enum/contractEnumeration";


const ContractAdministrationForm = observer(({mode}) => {
    const {customerStore} = useStore();

    return <Form<Partial<ContractAdministrationFormInterface>> onSubmit={customerStore.updateOrCreateContract}
                                                               initialValues={customerStore.contractStore.currentContract ? customerStore.contractStore.currentContract : {period: 'week'}}>

        {({handleSubmit, pristine, submitting, form}) => (
            <form onSubmit={handleSubmit}>
                <ContractDetailsFormSharedFieldsSet/>

                <Field label='Number of tickets' name='ticketsNumber' component={NumberInput} step={1}
                       canEdit={customerStore.canEdit}/>

                <Field label='Number of hours' name='hoursNumber' component={NumberInput} step={1}
                       canEdit={customerStore.canEdit}/>

                <Field name='period' label={"Period"} component={Select} options={contractPeriodList}
                       formatOptions={formatContractPeriod} canEdit={customerStore.canEdit}/>

                <FinalButtonSet pristine={pristine} reset={form.reset} submitting={submitting} mode={mode}/>
            </form>
        )}
    </Form>
});

export default ContractAdministrationForm;
