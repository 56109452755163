import {action, computed, observable, runInAction} from "mobx";
import remotedev from 'mobx-remotedev';
import ApiClient from "../ApiClient";
import {handleFormSubmit} from "../../utils/form/handleFormSubmit";
import {idFromEntity} from "../../utils/iri";
import UiBus from "../service/UiBus";
import {SubmissionErrors} from "final-form";
import {CollectionResponseType, GridResourceCollectionInterface} from "../interfaces/GridResourceCollectionInterface";
import {CollapsedProjectModel, IProjectModel} from "../../utils/models";


@remotedev({global: true})
export class ProjectStore implements GridResourceCollectionInterface<IProjectModel> {

    @observable currentProject: IProjectModel | null;
    @observable currentCollapsedProject: CollapsedProjectModel | null;
    @observable isLoading: boolean = false;
    @observable collectionResponse: CollectionResponseType<IProjectModel> | null;

    constructor(private apiClient: ApiClient, private uiBus: UiBus) {
    }

    @computed get canEdit(): boolean {
        if (!this.currentProject) {
            return true;
        }

        return this.currentProject.contract.status === 'active'
            || this.currentProject.contract.status === 'expired'
            || this.currentProject.contract.status === 'future';
    }

    @action toggleLoading = (): void => {
        this.isLoading = !this.isLoading;
    };

    @action create = async (body, withNetworkSupport): Promise<SubmissionErrors | null> => {
        const initialBody = withNetworkSupport ? {isNetworkSupport: true} : {};

        const {errors, response} = await handleFormSubmit(this.apiClient.projectCreate({
            ...initialBody,
            ...body
        }));
        return runInAction("update ok", () => {
            if (!errors) {
                this.currentProject = response;
                this.uiBus.notify(`Project #${idFromEntity(this.currentProject)} created`, "success");
            } else {
                return errors;
            }
        })
    };
    getEntityName: 'Project';
    @action update = async (body): Promise<SubmissionErrors | null> => {
        const values = {
            name: body['name'],
            slaTime: typeof body['slaTime'] === 'object' ? body['slaTime']['@id'] : body['slaTime'],
            description: body['description']
        }
        const {errors, response} = await handleFormSubmit(this.apiClient.projectUpdate(idFromEntity(this.currentProject), values));
        return runInAction("update ok", () => {
            if (response) {
                this.currentProject = response;
                this.uiBus.notify(`Project #${idFromEntity(this.currentProject)} updated`, "success");
            } else {
                this.uiBus.notify('Problem with project updating', "warning");
            }
            return errors;
        })
    };
    @action fetchCollapsed = (id) => {
        this.isLoading = true;
        return this.apiClient.projectCollapsedFetch(id).then(
            action("fetchCollapsed ok", response => {
                this.currentCollapsedProject = response;
                this.isLoading = false;
            })
        ).catch(action("fetchCollapsed failed", e => {
            this.isLoading = false;
        }));
    }
    @action fetchItem = (id) => {
        this.isLoading = true;
        return this.apiClient.projectFetch(id).then(action("fetchItem ok", (data) => {
            this.currentProject = data;
            this.currentCollapsedProject = null;
            this.isLoading = false;
        }));
    };

    @action fetchCollection = (params): void => {
        this.isLoading = true;
        this.apiClient.projectCollectionFetch(params).then(
            action("fetchCollection ok", response => {
                this.collectionResponse = response;
                this.isLoading = false;
            })
        ).catch(action("fetchCollection failed", e => {
            this.isLoading = false;
        }));
    }
}
