import * as React from 'react';
import classNames from 'classnames';

export enum HourGlassSize {
    DEFAULT = '',
    MEDIUM = 'md',
    SMALL = 'sm'
}

interface IProps {
    targetTime: Date,
    size?: HourGlassSize,
    noNumbers?: boolean,
    inline?: boolean,
    fitSize?: boolean,
    noIcon?: boolean
}

export const HourGlass =  ({...props}: IProps) => {
    const widgetSize: HourGlassSize = (props.size ? props.size : HourGlassSize.DEFAULT);
    const daysLeft: number = Number((
        Math.max(0,
            (props.targetTime.getTime() - (new Date()).getTime())
        ) / (1000 * 60 * 60 * 24)
    ).toFixed());

    const classname = classNames({
        'hour-glass': true,
        'hour-glass--fit': props.fitSize,
        'hour-glass--inline': props.inline,
        'hour-glass--noicon': props.noIcon,
        'hour-glass--md': widgetSize === HourGlassSize.MEDIUM,
        'hour-glass--sm': widgetSize === HourGlassSize.SMALL,
        'hour-glass--green': daysLeft > 90,
        'hour-glass--blue': daysLeft > 30 && daysLeft <= 90,
        'hour-glass--yellow': daysLeft > 3 && daysLeft <= 30,
        'hour-glass--red': daysLeft <= 3
    });

    const hourGlassClass = classNames({
        'fa': true,
        'fa-hourglass-start': daysLeft > 90,
        'fa-hourglass-half': daysLeft > 30 && daysLeft < 90,
        'fa-hourglass-end': daysLeft < 30
    });

    return (
        <div className={classname}>
            <div className='hour-glass__wrap'>
                {!props.noIcon && <i className={hourGlassClass}/>}
                {!props.noNumbers && <span className='hour-glass__days'>{daysLeft}d</span>}
            </div>
        </div>
    );
}
