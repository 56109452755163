import React from 'react';
import {Field, Form} from 'react-final-form'
import {FinalButtonSet, SliderInput} from "../../components/ui/form";
import {observer} from "mobx-react";
import {useStore} from "../../context";

const OpportunityRateForm = observer(() => {
    const {opportunityStore} = useStore();
    return (
        <Form
            onSubmit={opportunityStore.updateAttribute}
            initialValues={{rate: opportunityStore.currentOpportunity.rate}}
        >
            {({handleSubmit, pristine, submitting}) => (

                <form onSubmit={handleSubmit}>
                    <Field
                        label='Rate'
                        name='rate'
                        min={0}
                        max={100}
                        labelStepSize={25}
                        component={SliderInput}
                        renderLabel={(value: number) => value + '%'}
                    />

                    <FinalButtonSet pristine={pristine} submitting={submitting} mode={"RELATIVE"}/>

                </form>
            )}
        </Form>

    )
});


export default OpportunityRateForm;
