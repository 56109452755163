/* eslint-disable no-prototype-builtins */

import _ from 'underscore';

export const normalizeSelectData = (data: any) => {

    let res: any = [];
    if (_.isArray(data)) {
        if (data.length) {
            if (typeof data[0] !== 'object') {
                res = data.map((item) => {
                    return {value: item, label: item};
                });
            } else if (Object.keys(data[0]).length === 1) {
                res = data.map(item => {
                    let key = Object.keys(item)[0];
                    return {value: item[key], label: key};
                });
            }
        }
    } else {
        if (typeof data === 'object') {
            Object.keys(data).map((key: any) => {
                if (key.value === undefined) {
                    const isNumber = (n) => {
                        return !isNaN(parseFloat(n)) && isFinite(n);
                    };
                    res.push({
                        value: isNumber(key) ? Number(key) : key,
                        label: data[key],
                    });
                    return res;
                } else {
                    res.push(key);
                    return res;
                }
            });
        }
    }
    return res;
};

export const compareObjects = (x, y) => {
    if (x === y) return true;
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (let p in x) {
        if (!x.hasOwnProperty(p)) continue;
        // other properties were tested using x.constructor === y.constructor

        if (!y.hasOwnProperty(p)) return false;
        // allows to compare x[ p ] and y[ p ] when set to undefined

        if (x[p] === y[p]) continue;
        // if they have the same strict value or identity then they are equal

        if (typeof (x[p]) !== 'object') return false;
        // Numbers, Strings, Functions, Booleans must be strictly equal

        if (!compareObjects(x[p], y[p])) return false;
        // Objects and Arrays must be tested recursively
    }

    for (let p in y) {
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
        // allows x[ p ] to be set to undefined
    }
    return true;
};
