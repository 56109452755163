import {Field, Form} from "react-final-form";
import {FinalButtonSet, Select} from "../../../components/ui/form";
import React from "react";
import {useStore} from "../../../context";
import {observer} from "mobx-react";


const AssignPartModalInNavigationForm = observer(() => {
    const {customerStore} = useStore();
    const getSelectRestProps = () => {
        if (customerStore.assignPartTo === 'system') {
            return {
                options: customerStore.systemList,
                isSearchable: true,
                isLoading: false,
            }
        } else if (customerStore.assignPartTo === 'contract') {
            return {
                options: customerStore.contractList,
                isSearchable: true,
                isLoading: false,
            }
        } else if (customerStore.assignPartTo === 'project') {
            return {
                options: customerStore.projectList,
                isSearchable: true,
                isLoading: false,
            }
        }
        return {
            options: [],
            isSearchable: true,
            isLoading: false
        }
    }
    return (
        <Form<{ system?, contract?, project? }>
            onSubmit={customerStore.assignParts}
            render={({handleSubmit, pristine, submitting, form}) => {
                return (<form onSubmit={handleSubmit}>
                    <Field
                        name={customerStore.assignPartTo}
                        component={Select}
                        {...getSelectRestProps()}
                        required
                    />


                    <FinalButtonSet pristine={pristine} submitting={submitting}
                                    label={"Assign parts"} reset={form.reset}/>
                </form>)
            }}>
        </Form>
    );
})

export default AssignPartModalInNavigationForm;
