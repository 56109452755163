import React from 'react';
import ResourceGridView from './../../components/grid/ResourceGridView';
import * as cells from './../../components/ui/cells';

import {useStore} from "../../context";
import {observer} from "mobx-react";
import {Link, useHistory} from "react-router-dom";
import {SupportRequestOutput} from "../../generated";

export const srStatusCell = (item: SupportRequestOutput) => {
    let apprStatus = 'status-ico ';
    if (item.status === 'floating') {
        apprStatus += 'orange';
    }
    if (item.status === 'converted') {
        apprStatus += 'green';
    }

    return (
        <div className="status-cell">
            <div className="status-col">
                <div className={apprStatus}>
                    {item.status}
                </div>
            </div>
        </div>
    );
};


const SupportRequestGrid = observer(() => {
    const {supportRequestStore} = useStore();

    const history = useHistory();
    return (
        <div className='dashboard'>
            <ResourceGridView<SupportRequestOutput>
                onRowClick={(item) => {
                    history.push({
                        pathname: `/support_requests/${item.id}`
                    });
                }}
                filterKeys={{
                    id: 'ID',
                    createdAt: 'Created at',
                    ticketRef: 'Ticket id'
                }}
                tableClass={'support-requests'}
                title={"Support Requests"}
                columns={[
                    {
                        name: 'title',
                        label: 'Title',
                        value: (item) => (

                            <div key={item.id}>
                                <div className="title">
                                    {item.subject}
                                </div>
                                <div className="other-info wrap">
                                    {srStatusCell(item)}
                                    <div className="status-col">
                                        {item.id}
                                    </div>
                                    {item.createdAt ? cells.dateAndTimeCell(item.createdAt) : ''}
                                </div>
                            </div>
                        ),
                    },
                    {
                        name: 'message',
                        label: 'Message',
                        value: (item) => {
                            const message = item.messages[0];
                            return message.messagePreview;
                        },
                    },
                    {
                        name: 'customer',
                        label: 'Customer',
                        value: (item) => {
                            return (
                                <div>
                                    <div className="title">
                                        <div>{item.customer.name}</div>
                                    </div>
                                    <div className="other-info">
                                        <div>{item.customer.email}</div>
                                        {item.customer.customerRef && <span className={'dot'}>•</span>}
                                        {item.customer.customerRef && <Link onClick={e => e.stopPropagation()}
                                                  to={`/customers/${item.customer.customerRef}`}>
                                                {item.customer.code}
                                            </Link>}
                                    </div>
                                </div>
                            );
                        },
                    },
                    {
                        name: 'ticketRef',
                        label: 'Related ticket',
                        value: (item) => {
                            return (
                                <div>
                                    {item.ticketRef ? <Link
                                        onClick={e => e.stopPropagation()}
                                        to={`/tickets/${item.ticketRef}`}>
                                        {item.ticketRef}
                                    </Link> : <span className={'not-available'}>N/A</span>}
                                </div>
                            );
                        },
                    },

                ]}

                gridResource={supportRequestStore}/>
        </div>
    );
});


export default SupportRequestGrid;
