import React, {useState} from "react";
import {Field, Form} from 'react-final-form';
import {MultiSelect, Select, Textarea} from './../../../components/ui';
import {Icon} from "@mdi/react";
import {
    mdiAlarmPlus,
    mdiBookmarkPlusOutline,
    mdiCreditCardOutline,
    mdiSettingsOutline,
    mdiWrenchOutline
} from "@mdi/js";
import {useStore} from "../../../context";
import {observer} from "mobx-react";
import useDataListFetch from "../../../utils/form/useDataListFetch";

type PickOpportunityTypeAdapterProps = {
    input,
    meta,
    canEdit: boolean,
    mode: 'STANDALONE' | 'EMBEDDED',
    changeStep?
}

export function PickOpportunityTypeAdapter({input, meta, mode, canEdit, changeStep}: PickOpportunityTypeAdapterProps) {
    return (
        <div>
            <p className="step-title">
                Contract type
            </p>
            <div className={'chooses-content'}>
                <div className={input.value === 'maintenance' ? 'cards active' : 'cards'}
                     onClick={() => {
                         canEdit && input.onChange('maintenance')
                         changeStep && changeStep('create')
                     }
                     }>
                    {mode === 'STANDALONE' && <div className="cards-ico">
                        <Icon path={mdiWrenchOutline}/>
                    </div>}
                    <p>Maintenance</p>
                </div>
                <div className={input.value === 'administration_saas' ? 'cards active' : 'cards'}
                     onClick={() => {
                         canEdit && input.onChange('administration_saas')
                         changeStep && changeStep('create')
                     }
                     }>
                    {mode === 'STANDALONE' && <div className="cards-ico">
                        <Icon path={mdiSettingsOutline}/>
                    </div>}
                    <p>Administration</p>
                </div>

                <div className={input.value === 'bank_of_hours' ? 'cards active' : 'cards'}
                     onClick={() => {
                         canEdit && input.onChange('bank_of_hours')
                         changeStep && changeStep('create')
                     }
                     }>
                    {mode === 'STANDALONE' && <div className="cards-ico">
                        <Icon path={mdiAlarmPlus}/>
                    </div>}
                    <p>Bank of hours</p>
                </div>

                <div className={input.value === 'bank_of_tickets' ? 'cards active' : 'cards'}
                     onClick={() => {
                         canEdit && input.onChange('bank_of_tickets')
                         changeStep && changeStep('create')
                     }
                     }>
                    {mode === 'STANDALONE' && <div className="cards-ico">
                        <Icon path={mdiBookmarkPlusOutline}/>
                    </div>}
                    <p>Bank of tickets</p>
                </div>

                <div className={input.value === 'pay_as_you_go' ? 'cards active' : 'cards'}
                     onClick={() => {
                         canEdit && input.onChange('pay_as_you_go')
                         changeStep && changeStep('create')
                     }
                     }>
                    {mode === 'STANDALONE' && <div className="cards-ico">
                        <Icon path={mdiCreditCardOutline}/>
                    </div>}
                    <p>T&M</p>
                </div>
            </div>
        </div>
    );
}

const OpportunityQuoteDetailsForm = observer(({closePopover}) => {
    const {opportunityQuoteStore, opportunityStore} = useStore();
    const termsAndConditionDataList = useDataListFetch('terms_and_conditions');
    const slaDataList = useDataListFetch('sla_times');
    const [step, setStep] = useState<'selectType' | 'create'>('selectType');

    function handleSubmit(values) {
        if (step === 'selectType') {
            setStep('create')
        } else {
            return opportunityQuoteStore.createQuote(opportunityStore.iri, values)
        }
    }

    function stepBack(e) {
        if (step === 'create') {
            setStep('selectType');
        } else {
            closePopover()
        }
        e.preventDefault();
    }

    return (
        <Form onSubmit={handleSubmit}>
            {({handleSubmit, pristine}) => (
                <form onSubmit={handleSubmit}
                      className={step === 'selectType' ? 'first-step_no-buttons' : ''}
                >
                    {step === 'selectType' &&
                    <Field name={"type"} component={PickOpportunityTypeAdapter} changeStep={setStep} canEdit={true}
                           mode={"STANDALONE"}/>}

                    {step === 'create' && (<div>
                        <p className="step-title">
                            Additional information
                        </p>
                        <Field name={'termsAndConditions'}
                               label={'Terms & Conditions'}
                               component={MultiSelect}
                               {...termsAndConditionDataList}
                        />
                        <Field name={'slaTime'}
                               label={'SLA'}
                               component={Select}
                               {...slaDataList}
                        />

                        <Field name={'description'}
                               label={'Description'}
                               component={Textarea}
                        />
                    </div>)}


                    {step === 'create' && <div className="relative-buttons">
                        <button className={'secondaryBtn'}
                                onClick={stepBack}>Back
                        </button>
                        <button disabled={pristine}
                                className={'tertiaryBtn'}>Create
                        </button>
                    </div>}
                </form>
            )}
        </Form>
    )
});

export default OpportunityQuoteDetailsForm
