import React, {useEffect, useState} from 'react';
import {LinkButton} from "../ui";

export type GridTabType = { [id: string]: { label: string, additionalQuery } }

type GridTabProps = {
    tabs: GridTabType
    active: string | null
    handleTabChange: (tab: string) => void
}

const GridTab = ({tabs, handleTabChange, active}: GridTabProps) => {
    const [activeTabIndex, setActiveTabIndex] = useState(active);
    const [tabDirection, setTabDirection] = useState('right');
    const [previousTabIndex, setPreviousTabIndex] = useState(null);

    useEffect(() => {
        setActiveTabIndex(active);
    }, [active]);

    function tabChange(newIndex: string) {
        const newIndexNumerical = Object.keys(tabs).findIndex(i => newIndex === i);
        const prevIndexNumerical = Object.keys(tabs).findIndex(i => activeTabIndex === i);
        if (newIndexNumerical > prevIndexNumerical) {
            setTabDirection('right')
        } else {
            setTabDirection('left')
        }
        setPreviousTabIndex(activeTabIndex);
        setActiveTabIndex(newIndex);
        handleTabChange(newIndex);
    }

    return (<div className={`tabs tabs--big`}>
        <ul className='tabs-list'>
            {Object.keys(tabs).map((tabIndex) => {
                return (<li
                    className={
                        tabIndex === activeTabIndex
                            ? 'active active-' + tabDirection
                            : tabIndex === previousTabIndex
                            ? 'inactive inactive-' + tabDirection
                            : ''
                    }
                    onClick={() => tabChange(tabIndex)}
                    key={tabIndex}>
                    <LinkButton removePadding={false}>{tabs[tabIndex].label}</LinkButton>
                </li>)
            })}
        </ul>
    </div>)
};

export default GridTab;
