import React from 'react';


import Menu from './Menu';
import Header from './Header';

import {observer} from "mobx-react";
import {useStore} from "../../context";

import {
    mdiAccountOutline, mdiBookOpenVariant,
    mdiCartArrowDown,
    mdiCartArrowUp,
    mdiCashUsd,
    mdiClipboardOutline,
    mdiFileDocumentOutline,
    mdiHarddisk,
    mdiProgressWrench,
    mdiServer, mdiTicketConfirmation,
    mdiTicketOutline,
    mdiTruck
} from '@mdi/js';
import ReactNotification from 'react-notifications-component'

const BaseLayout = observer(({children}: any) => {
    const {authStore} = useStore();
    const handleAreaClick = (area) => {
        if (!area) return;
    };

    let opportunityPath = '/opportunities';
    if (authStore.isGranted('ROLE_SALE') && !authStore.isGranted('ROLE_ADMIN')) {
        opportunityPath += '?activeTab=all&salesAgent=' + authStore.userIri;
    }

    return (
        <div className='base-layout'>
            <ReactNotification/>
            <Menu
                isGranted={authStore.isGranted}
                items={[
                    {
                        label: 'tickets',
                        path: '/tickets',
                        icon: mdiTicketOutline,
                        isGranted: true,
                    },
                    {
                        label: 'support_requests',
                        path: '/support_requests',
                        icon: mdiTicketConfirmation,
                        isGranted: authStore.isGranted('ROLE_ADMIN') || authStore.isGranted('ROLE_ENGINEER'),
                    },
                    {label: 'customer/partner', path: '/customers', icon: mdiAccountOutline, isGranted: true},
                    {label: 'contracts', path: '/contracts', icon: mdiFileDocumentOutline, isGranted: true},
                    {label: 'systems', path: '/systems', icon: mdiServer, isGranted: true},
                    {label: 'projects', path: '/projects', icon: mdiClipboardOutline, isGranted: true},

                    {label: 'products', path: '/products', icon: mdiBookOpenVariant, isGranted: !authStore.isCustomerOrPartner},
                    {label: 'inventory', path: '/inventory', icon: mdiHarddisk, isGranted: !authStore.isCustomerOrPartner},
                    {
                        label: 'opportunities',
                        path: opportunityPath,
                        icon: mdiProgressWrench,
                        isGranted: authStore.isGranted('ROLE_ADMIN') || authStore.isGranted('ROLE_SALE'),
                    },
                    {
                        label: 'sales',
                        path: '/sales',
                        icon: mdiCartArrowUp,
                        isGranted: authStore.isGranted('ROLE_ADMIN') || authStore.isGranted('ROLE_SALE') || authStore.isGranted('ROLE_ALL_ACCOUNT')
                    },
                    {
                        label: 'purchases',
                        path: '/purchases',
                        icon: mdiCartArrowDown,
                        isGranted: authStore.isGranted('ROLE_ADMIN') || authStore.isGranted('ROLE_ALL_ACCOUNT')
                    },
                    {
                        label: 'suppliers',
                        path: '/suppliers',
                        icon: mdiTruck,
                        isGranted: authStore.isGranted('ROLE_ADMIN') || authStore.isGranted('ROLE_ALL_ACCOUNT') || authStore.isGranted('ROLE_SALE')
                    },

                    {
                        label: 'Contract Profits',
                        path: '/contract_profits',
                        icon: mdiCashUsd,
                        isGranted: authStore.isGranted('ROLE_ADMIN') || authStore.isGranted('ROLE_ALL_ACCOUNT') || authStore.isGranted('ROLE_SALE')
                    },
                ]}
            />
            <div ref={handleAreaClick} className='main-wrap'>
                <Header username={authStore.username}/>
                <div className='content-container'>
                    {children}
                </div>
            </div>
        </div>
    );
});

export default BaseLayout;
