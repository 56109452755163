import React from "react";
import {observer} from "mobx-react";
import {useStore} from "../../context";
import TopInfoBlock from "../../components/ui/widgets/TopInfoBlock";
import {Icon} from "@mdi/react";
import {mdiAccountGroupOutline, mdiStarOutline} from "@mdi/js";

const OpportunityDetailsInfoPanel = observer(() => {
    const {opportunityStore} = useStore();

    return (<React.Fragment>
            <TopInfoBlock store={opportunityStore}/>
            <div className={'tile opportunity-tile'}>
                <div className="tile-item" onClick={() => {
                    opportunityStore.changeContextPlace('assign');
                }}>
                    <Icon path={mdiAccountGroupOutline}/>
                    <div className="tile-item__info">
                        <p className={'tile-item__info-title'}>Assigned to</p>
                        <p>{opportunityStore.currentOpportunity.salesAgent ? <span>{opportunityStore.currentOpportunity.salesAgent.username} <span>(owner)</span></span> : ''}
                            {opportunityStore.currentOpportunity.invited.map(elem => (
                                <span key={elem.username}>, {elem.username} <span>(guest)</span></span>
                            ))}
                        </p>
                    </div>
                </div>
                <div className="tile-item" onClick={() => {
                    opportunityStore.changeContextPlace('rate');
                }}>
                    <Icon path={mdiStarOutline}/>
                    <div className="tile-item__info">
                        <p className={'tile-item__info-title'}>Rate</p>
                        <p>{opportunityStore.currentOpportunity.rate ? opportunityStore.currentOpportunity.rate + '%' : 'N\\A'}</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
});

export default OpportunityDetailsInfoPanel;
