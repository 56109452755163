import React, {useEffect} from 'react';


import {observer} from "mobx-react";
import {useStore} from "../../context";
import PartTypeDetailsForm from "./DetailsForm/PartTypeDetailsForm";
import {useParams} from "react-router";
import {Loader} from "../../components/ui";
import {Link} from "react-router-dom";
import {Icon} from "@mdi/react";
import {mdiChevronLeft} from "@mdi/js";
import PartDetailsButtonList from "./PartDetailsButtonList";
import {Media} from 'react-breakpoints'
import FloatButtons from "../../components/ui/FloatButtons";

type PartDetailsType = {
    catalogueMode: boolean
}

const PartDetails = observer(({catalogueMode}: PartDetailsType) => {
    const {partStore, authStore} = useStore();
    const params = useParams()
    useEffect(() => {
        partStore.fetchItem(params['partId'])
    }, [params]);
    if (partStore.isLoading) {
        return <Loader/>
    }
    const routeBack = catalogueMode ? "/products" : "/inventory";

    return <div className='center-form-container'>

        <Media>
            {({breakpoints, currentBreakpoint}) => {
                return breakpoints[currentBreakpoint] > breakpoints.tablet && <div className='left-panel'>
                    <Link to={routeBack} className="back"><Icon path={mdiChevronLeft}/>Back</Link>
                    <PartDetailsButtonList/>
                </div>
            }
            }
        </Media>
        <div className='form-wrapper'>
            <Media>
                {({breakpoints, currentBreakpoint}) => {
                    return breakpoints[currentBreakpoint] < breakpoints.tablet &&
                        <Link to={routeBack} className="back"><Icon path={mdiChevronLeft}/>Back</Link>
                }
                }
            </Media>
            <PartTypeDetailsForm
                isGranted={!authStore.isCustomerOrPartner}
                catalogueMode={catalogueMode} partStore={partStore} mode={"UPDATE"} standalone={true}/>
        </div>

        <Media>
            {({breakpoints, currentBreakpoint}) => {
                return breakpoints[currentBreakpoint] < breakpoints.tablet &&
                    <FloatButtons type={'normal'}><PartDetailsButtonList/></FloatButtons>
            }}
        </Media>
    </div>
});


export default PartDetails;
