export const quoteStatusList = ['new', 'sent', 'declined', 'converted'] as const;
export const opportunityStatusList = ['pristine', 'working', 'successful', 'unsuccessful'] as const;
export type OpportunityStatusType = typeof opportunityStatusList[number];
export type QuoteStatusType = typeof quoteStatusList[number];

export function formatOpportunityStatus(status: OpportunityStatusType): string {
    switch (status) {
        case "pristine":
            return 'Pristine';
        case 'successful':
            return 'Successful';
        case 'unsuccessful':
            return 'Unsuccessful';
        case 'working':
            return 'Working';
    }
}

export function formatQuoteStatus(status: QuoteStatusType): string {
    switch (status) {
        case "converted":
            return 'Converted';
        case 'declined':
            return 'Declined';
        case 'new':
            return 'New';
        case 'sent':
            return 'Sent';
    }
}
