import {Icon} from "@mdi/react";
import {mdiCash} from "@mdi/js";
import * as React from "react";

const ExpensesBlock = ({total}) => {
    return (<div className={'clickable'}>
        <Icon path={mdiCash}/>
        <div className="tile-item__info">
            <p className={'tile-item__info-title'}>Expenses:</p>
            <p>
                £ {total}
            </p>
        </div>
    </div>)
};

export default ExpensesBlock
