import React from "react";
import cn from 'classnames';

import Icon from "@mdi/react";
import {
    mdiChip,
    mdiClipboardOutline,
    mdiClockOutline,
    mdiFileLock,
    mdiFileRestore,
    mdiOfficeBuilding,
    mdiServer,
    mdiWrench
} from "@mdi/js";

import {CustomerNavPlaceType} from "../../../store/mobx/CustomerStore";
import {useStore} from "../../../context";

const iconsMap = {
    system: mdiServer,
    project: mdiClipboardOutline,
    contract: mdiWrench,
    part: mdiChip,
};

const chooseIcons = (type: CustomerDurationType) => {
    return iconsMap[type] || mdiOfficeBuilding
};

type CustomerDurationType = CustomerNavPlaceType | 'not_expire';

type ContractDurationTypes = {
    typeList: CustomerDurationType
    duration?
    status?
    endDate?
    renewedContract?
    partHasSpare?: boolean
    partHasStock?: boolean
}

export const ContractDuration = ({typeList, duration, status, endDate, renewedContract, partHasSpare = false, partHasStock = false}: ContractDurationTypes) => {
    const {contractStore} = useStore();
    const isStatusSoon = contractStore.durationStatus(endDate, duration) === 'red';
    const isClosed = '';

    const durationInfoClasses = cn({
        'duration-info': true,
        'show': duration && (isStatusSoon || isClosed || endDate),
        'has-stock': partHasStock && !partHasSpare,
        'has-spare': partHasSpare,
    })

    if (status === 'closed') {
        return (
            <div className={`duration-info no-hide`}>
                <div className="icon">
                    {renewedContract ? <Icon path={mdiFileLock}/> : <Icon path={mdiFileRestore}/>}
                </div>
            </div>
        )
    }

    return (
        <div className={durationInfoClasses}>
            {!isStatusSoon ? (
                <div className={duration ? 'icon' : 'icon no-hidden'}>
                    <Icon path={chooseIcons(typeList)}/>
                </div>
            ) : null}

            {duration ? (
                <div className={'duration-item ' + contractStore.durationStatus(endDate, duration)}>
                    {!isClosed ? <span className={isClosed ? 'grey' : ''}>
                            <Icon path={mdiClockOutline}/>
                        {contractStore.expireTime(endDate, duration)}d
                        </span> : <Icon path={mdiFileLock}/>}
                </div>
            ) : null}
        </div>
    )
};
