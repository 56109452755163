import React, {useEffect, useState} from 'react';
import {Button, LinkButton} from './../';
import {FileAttachmentsInterface} from "../../../store/interfaces/FileAttachmentsInterface";
import {useDropzone} from 'react-dropzone'
import {mdiTrashCanOutline} from "@mdi/js";
import {Icon} from '@mdi/react';

type FileUploaderProps = {
    attachmentStore: FileAttachmentsInterface
}


type PreviewFile = { preview? } & File;
const FileUploader = ({attachmentStore}: FileUploaderProps) => {

    const [files, setFiles] = useState<PreviewFile[]>([]);

    const {getRootProps, getInputProps, open} = useDropzone(
        {
            onDrop: acceptedFiles => {
                setFiles(acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));
            }
        }
    );

    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const Thumbs = ({file}) =>
        <div style={{
            marginBottom: 8,
            marginRight: 8,
            width: 100,
            height: 100,
            padding: 4,
            boxSizing: 'border-box'
        }} key={file.name}>
            <div style={{
                display: 'flex',
                minWidth: 0,
                overflow: 'hidden',
                height: '100%'
            }}>
                <img
                    src={file.preview}
                    alt={""}
                    style={{
                        display: 'block',
                        width: 'auto',
                        height: '100%',
                    }}
                />
            </div>
        </div>
    ;

    const renderFileField = (item: File, index: number) => {
        return (
            <div className="file-field" key={index}>
                <span className={'file-type ' + item.type}/>
                <p className="file-name">{item.name}</p>
                <aside style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    marginTop: 16
                }}>
                    <Thumbs file={item}/>
                </aside>
                <LinkButton className="file-delete"
                            onClick={() => handleDeleteFile(index)}>
                    <Icon path={mdiTrashCanOutline}/>
                </LinkButton>
            </div>
        );
    };

    const handleDeleteFile = (index: number) => {
        setFiles(files.filter((file, i) => i !== index));
    };


    const handleAttach = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let formData = new FormData();
        for (let uploadFile of files) {
            formData.append(uploadFile.name, uploadFile);
        }
        attachmentStore.saveAttachments(formData)
    };

    return (
        <div className="file-uploader">
            <button className="add-files-button" type="button"
                    onClick={open} {...getRootProps()}>
                <i className="fa fa-plus" aria-hidden="true"/>
                <input {...getInputProps()} />
                <span className="drag-text">
                        Drag 'n' drop some files here, or click to select files
                   </span>
            </button>

            <div className="file-list">
                {files.map(renderFileField)}
            </div>
            <div className="buttons-block">
                <Button
                    color={'dark'}
                    disabled={Object.keys(files).length === 0}
                    classes="tertiaryBtn"
                    onClick={handleAttach}>
                    Attach
                </Button>
            </div>
        </div>
    );
}

export default FileUploader;
