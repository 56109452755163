import React, {useState} from "react";
import useDataListFetch from "../../../utils/form/useDataListFetch";
import {Field} from "react-final-form";
import {Input, Select} from "../../../components/ui/form";
import {RemoveComponentButton} from "./OpportunityQuoteDetails";
import {QuoteSystemInfoRead} from "../../../utils/models";
import {FieldArrayRenderProps} from "react-final-form-arrays";
import {Icon} from "@mdi/react";
import {mdiFolderEditOutline} from "@mdi/js";
import StandalonePopover from "../../../components/ui/StandalonePopover";
import QuoteSystemRealizationsForm from "./QuoteSystemRealizationsForm";
import {ContractTypeListType} from "../../../store/enum/contractEnumeration";
import {useStore} from "../../../context";

type QuoteSystemInfoTableProps = {
    values: Partial<QuoteSystemInfoRead>[],
    customerName: string,
    canEdit: boolean,
    quoteType: ContractTypeListType,
    fieldArrayProps: FieldArrayRenderProps<Partial<QuoteSystemInfoRead>, any>,
    isRenewalQuote: boolean,
}

type SystemTableTitleRowProps = {
    item: Partial<QuoteSystemInfoRead>,
    quoteType: ContractTypeListType,
    index: number,
    fieldArrayProps: FieldArrayRenderProps<Partial<QuoteSystemInfoRead>, any>,
}

const QuoteSystemInfoTable = ({
                                  values,
                                  canEdit,
                                  quoteType,
                                  customerName,
                                  isRenewalQuote,
                                  fieldArrayProps
                              }: QuoteSystemInfoTableProps) => {
    const manufacturerDataList = useDataListFetch('manufacturers');
    const {_apiClient} = useStore();

    return <div className={'components-table'}>
        <div className={'components-table-row heading'}>
            <div>
                Title
            </div>
            <div>
                Serial number
            </div>
            <div>
                Manufacturer
            </div>
            <div>
                Price
            </div>
            <div>
            </div>
        </div>

        {fieldArrayProps.fields.map((name, index) => {
            const item = fieldArrayProps.fields['value'][index];
            let serialNumberField;
            if (quoteType !== 'pay_as_you_go') {
                serialNumberField = <Field name={`${name}.serialNumber`}
                       validate={(value) => {
                           if (value && value.length > 1) {
                               return _apiClient.validateQuoteSystemSerial(value, customerName).then(response => {
                                   if (!response.validated) {
                                       return `Serial number is already present in contract: #${response.contractId}`;
                                   }
                               })
                           }
                       }}
                       component={Input}
                       canEdit={canEdit}/>
            }
            if (item.stockRealization) {
                serialNumberField = item.stockRealization.serialNumber
            }
            if (isRenewalQuote && item.systemAsExample) {
                serialNumberField = item.systemAsExample.serialNumber;
            }


            return <div key={index} className={'components-table-row'}>
                <SystemTableTitleRow
                    index={index}
                    item={item}
                    quoteType={quoteType}
                    fieldArrayProps={fieldArrayProps}
                />
                <div>
                    {serialNumberField}
                </div>
                <div>
                    {((item.systemAsProduct && item.systemAsProduct.manufacturer) || item.systemAsExample !== null)
                        ? item.manufacturerName
                        : <Field name={`${name}.manufacturer`}
                                 component={Select}
                                 {...manufacturerDataList}
                                 canEdit={canEdit}/>}
                </div>
                <div>
                    <Field name={`${name}.price`}
                           component={Input}
                           canEdit={canEdit}/>
                </div>
                <RemoveComponentButton fieldArrayProps={fieldArrayProps} index={index}/>
            </div>
        })}
    </div>
};

const SystemTableTitleRow = ({
                                 item,
                                 quoteType,
                                 index,
                                 fieldArrayProps
                             }: SystemTableTitleRowProps) => {
    const [contextMenuCoordinates, setContextMenuCoordinates] = useState<{ x, y } | null>(null);

    return (<div>
        <div className={"system-title-header"}>
            {item.modelNumber}
            <span className="browse"
                  onClick={(e) => {
                      e.stopPropagation();
                      setContextMenuCoordinates({
                          x: e.clientX + 20,
                          y: e.clientY + 60
                      })
                  }}><Icon path={mdiFolderEditOutline}/>
            </span>
        </div>
        {quoteType === "pay_as_you_go" && <div className="other-info">
            {(item.systemAsProduct && item.systemAsProduct.stockCount) ? item.systemAsProduct.stockCount : '0'} in stock
        </div>}
        {contextMenuCoordinates &&
        <StandalonePopover title={null}
                           closePopover={() => setContextMenuCoordinates(null)}
                           size={'sd'}
                           darken={false}
                           position={contextMenuCoordinates}
                           direction={"TOP_RIGHT"}>
            <QuoteSystemRealizationsForm
                item={item}
                index={index}
                quoteType={quoteType}
                fieldArrayProps={fieldArrayProps}
                closePopover={() => setContextMenuCoordinates(null)}
            />
        </StandalonePopover>}
    </div>);
};

export default QuoteSystemInfoTable;
