import {action, computed, observable, runInAction} from "mobx";
import remotedev from 'mobx-remotedev';
import ApiClient from "../ApiClient";
import {CollectionResponseType, GridResourceCollectionInterface} from "../interfaces/GridResourceCollectionInterface";
import {IProjectModel, SystemFullModel} from "../../utils/models";
import {
    ActivitiesLogSupportInterface
} from "../../containers/activity_log/ActivitiesLogInterface";
import {SubmissionErrors} from "final-form";
import {v4 as uuidv4} from 'uuid';
import {assert} from "ts-essentials";
import {handleFormSubmit} from "../../utils/form/handleFormSubmit";
import UiBus from "../service/UiBus";
import {fromPromise, IPromiseBasedObservable} from "mobx-utils";
import {ActivityReadModelOutput, SupportRequestOutput} from "../../generated";
import moment from "moment/moment";


type SupportRequestStorePlace =
    'update'
    | 'convert'
    | 'select_customer'
    | 'change_status'
    | 'select_system'
    | 'select_project';

@remotedev({global: true})
export class SupportRequestStore implements GridResourceCollectionInterface<SupportRequestOutput>, ActivitiesLogSupportInterface {
    @observable current: SupportRequestOutput | null;
    @observable collectionResponse: CollectionResponseType<SupportRequestOutput> | null;
    @observable isLoading: boolean = true;
    @observable contextPlace: SupportRequestStorePlace | null;
    @observable currentSystemsList: IPromiseBasedObservable<SystemFullModel[]> | null;
    @observable currentProjectsList: IPromiseBasedObservable<IProjectModel[]> | null;
    @observable ticketDataEngineerName: String | null;
    @observable loaderInternal: boolean = true;
    @observable loaderReply: boolean = true;

    constructor(private apiClient: ApiClient, private uiBus: UiBus) {
    }

    @computed get activitiesMessagesInternal() {
        return this.activities.filter(item => item.isInternal)
    }

    @computed get activitiesMessagesReply() {
        return this.activities.filter(item => !item.isInternal)
    }

    public get title() {
        return `#${this.current.id}: ${this.current.subject}`;
    }

    @action clearContextPlace = () => {
        this.contextPlace = null;
    }
    @action goConvert = () => {
        this.contextPlace = 'convert';
    }

    @action goChangeStatus = () => {
        this.contextPlace = 'change_status';
    }

    @action goSelectCustomer = () => {
        this.contextPlace = 'select_customer';
    }

    @action goSelectSystem = () => {
        this.contextPlace = 'select_system';
    }

    @action goSelectProject = () => {
        this.contextPlace = 'select_project';
    }

    @computed get id(): string {
        return this.current.id;
    }

    @action toggleLoading = (): void => {
        this.isLoading = !this.isLoading;
    };


    @action fetchCollection = (query): void => {
        this.isLoading = true;
        this.apiClient.supportRequestCollectionFetch({params: query}).then(
            action("supportRequestsCollectionFetch ok", response => {
                this.collectionResponse = response;
                this.isLoading = false;
            })
        ).catch(action("supportRequestsCollectionFetch failed", e => {
            this.isLoading = false;
        }));
    };

    @action remove = (id) => {
        this.isLoading = true;
        return this.apiClient.supportRequestItemDelete(id).then(
            action("remove ok", response => {
                this.isLoading = false;
                this.fetchCollection({})
            })
        ).catch(action("remove failed", e => {
            this.isLoading = false;
        }));
    }

    @action selectSystem = async ({relatedSystem}): Promise<SubmissionErrors | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.supportRequestSelectSystem(this.current.id,
            {relatedSystem: relatedSystem}
        ));
        return runInAction("selectSystem ok", () => {
            if (!errors) {
                this.current = response;
                this.clearContextPlace();
                this.uiBus.notify(`System for #${this.current.id} selected`, "success");
            }
            return errors;
        });
    };

    @action selectProject = async ({relatedProject}): Promise<SubmissionErrors | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.supportRequestSelectProject(this.current.id,
            {relatedProject: relatedProject}
        ));
        return runInAction("selectProject ok", () => {
            if (!errors) {
                this.current = response;
                this.clearContextPlace();
                this.uiBus.notify(`Project for #${this.current.id} selected`, "success");
            }
            return errors;
        });
    };

    @action assignCustomer = async ({customer}): Promise<SubmissionErrors | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.supportRequestAssignCustomer(this.current.id,
            {customer: customer}
        ));
        return runInAction("assignCustomer ok", () => {
            if (!errors) {
                this.loadCurrent(response.id);
                this.clearContextPlace();
                this.uiBus.notify(`Customer for #${response.id} assigned`, "success");
            }
            return errors;
        });
    }

    @action loadCurrent = (id) => {
        this.isLoading = true;
        this.activities = [];
        this.current = null;
        return this.apiClient.supportRequestItemFetch(id).then(
            action("loadCurrent ok", response => {
                this.current = response;
                this.loaderInternal = true;
                this.loaderReply = true;
                this.fetchActivities();
                this.isLoading = false;
                if (this.current.customer.customerRef) {
                    this.currentSystemsList = fromPromise(this.apiClient.systemCollectionFetch({
                        'contract.customer': `/api/customers/${this.current.customer.customerRef}`,
                        _pagination: false
                    }).then((body) => body['hydra:member']))
                    this.currentProjectsList = fromPromise(this.apiClient.projectCollectionFetch({
                        'contract.customer': `/api/customers/${this.current.customer.customerRef}`,
                        _pagination: false
                    }).then((body) => body['hydra:member']))
                }
                this.fetchTicketEngineerName();

            })
        ).catch(action("loadCurrent failed", e => {
            this.isLoading = false;
        }));
    };

    @observable public activities: ActivityReadModelOutput[] = [];

    @action fetchActivities() {
        this.activities = [];
        this.apiClient.supportRequestItemFetch(this.id).then(action((r) => {
                this.activities = r.messages
                if (this.loaderReply) this.loaderReply = false;
                if (this.loaderInternal) this.loaderInternal = false;
            })
        )
    }

    @action fetchTicketEngineerName() {
        if (this.current.ticketRef) {
            this.apiClient.ticketFetch(this.current.ticketRef).then(action((r) => {
                    this.ticketDataEngineerName = r.engineerName
                })
            )
        }
    }

    createTicket = async (data): Promise<SubmissionErrors | null> => {
        const dataWithContext = {...data, ...{id: uuidv4(), supportRequest: `/api/support_requests/${this.current.id}`}}
        const {
            errors
        } = await handleFormSubmit(data.system ? this.apiClient.ticketCreateForSystem(dataWithContext) : this.apiClient.ticketCreateForProject(dataWithContext));
        return runInAction("createTicket ok", () => {
            if (!errors) {
                this.loadCurrent(this.id);
                this.uiBus.notify(`Ticket #${dataWithContext.id} created`, "success");
                this.contextPlace = 'update';
            }
            return errors;
        })
    }
    @action
    createActivities = async (activity: { body: string, isInternal: boolean }): Promise<SubmissionErrors | null> => {
        assert(this.current);
        if (activity.isInternal) this.loaderInternal = true;
        else this.loaderReply = true;
        const request = this.apiClient.createSupportRequestAnswer({
            id: uuidv4(),
            supportRequest: `/api/support_requests/${this.current.id}`,
            ...activity
        });
        const {errors} = await handleFormSubmit(request);
        if (!errors) {
            this.uiBus.notify(`Reply created, client will be notified by email!`, 'success');
            this.fetchActivities();
        }
        return errors;
    }

    public get isThereTimeLeft(): Boolean | null {
        assert(this.current);
        if (!this.current.deadline) {
            return null;
        }
        return moment(this.current.deadline).diff(moment.now()) > 0;
    }

    public get timeLeft(): Date | null {
        assert(this.current);
        return (this.current.deadline ? new Date(this.current.deadline) : null)
    }

    public get showTimer(): Boolean {
        assert(this.current);
        return this.current.deadline && !this.current.firstReplyAt;
    }

    public get showSlaSpent(): Boolean {
        assert(this.current);
        return this.current.firstReplyAt !== null;
    }

    public get formattedSlaSpent(): string {
        assert(this.current);
        const startDate = moment(this.current.createdAt);
        const convertedDate = moment(this.current.firstReplyAt);
        const diffTime = moment.duration(convertedDate.diff(startDate));
        const secs = SupportRequestStore.formatAsTimer(diffTime.seconds());
        const mins = SupportRequestStore.formatAsTimer(diffTime.minutes());
        const hours = SupportRequestStore.formatAsTimer(diffTime.hours());
        const days = SupportRequestStore.formatAsTimer(diffTime.days());
        return `${days}:${hours}:${mins}:${secs}`;
    }

    private static formatAsTimer(val: number): string {
        if (val < 10) {
            return `0${val}`;
        }
        return String(val);
    }

    @computed get idForActivityLogParent(): string {
        return this.current ? this.current.id : '';
    }
}
