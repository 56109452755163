import React from 'react';
import {observer} from "mobx-react";
import {PartRealizationShortModel} from "../../utils/models";
import ResourceGridView from "../../components/grid/ResourceGridView";
import {useStore} from "../../context";
import Categories from "../../components/grid/Categories";
import FloatButtons from "../../components/ui/FloatButtons";
import {Media} from 'react-breakpoints'
import {stringify} from "querystring";
import {idFromEntity} from "../../utils/iri";
import {useHistory} from "react-router";

const InventoryGrid = observer(() => {
    const {realizationStore} = useStore();
    const {push} = useHistory();
    return (
        <div className='dashboard inventory-grid'>
            <Media>
                {({breakpoints, currentBreakpoint}) => {
                    return breakpoints[currentBreakpoint] > breakpoints.desktop &&
                        <Categories/>
                }}
            </Media>

            <div className="float-buttons">
                <Media>
                    {({breakpoints, currentBreakpoint}) => {
                        return breakpoints[currentBreakpoint] < breakpoints.desktop &&
                            <FloatButtons type={"normal"}> <Categories/></FloatButtons>
                    }}
                </Media>
            </div>
            <ResourceGridView<PartRealizationShortModel>
                gridResource={realizationStore}
                columns={[
                    {
                        name: 'Serial number',
                        value: (item) => (
                            <div>
                                <div className="title">{item.serialNumber}</div>
                                <div className="other-info">
                                    {item['@id'].split('/').reverse()[0]}
                                    <span className={'dot'}>•</span>
                                    {item.part && item.part['@id'].split('/').reverse()[0]}
                                    {item.collapsedPart && item.collapsedPart['@id'].split('/').reverse()[0]}
                                </div>
                            </div>
                        )
                    },
                    {
                        name: 'Part number',
                        value: (item) => {
                            if (item.part) {
                                return item.part.partNumber;
                            }
                            if (item.collapsedPart) {
                                return item.collapsedPart.partNumber;
                            }
                            return '';
                        }
                    },
                    {
                        name: 'Part status',
                        value: (item) => item.partStatus
                    },
                    {
                        name: 'Notes',
                        value: (item) => (<div style={{wordBreak: "break-all"}}>{item.location}</div>)
                    },
                ]}
                filterKeys={{
                    'part.modelNumber': 'Model number',
                    'part.partNumber': 'Part number',
                    partStatus: 'Status',
                    'serialNumber': 'Serial number',
                    'location': 'Notes'
                }}
                onRowClick={(item) => {
                    if (item.part) {
                        if (item.part.customerId) {
                            push({
                                pathname: `/customers/${item.part.customerId}`,
                                search: stringify({partId: idFromEntity(item.part)})
                            })
                        } else {
                            push(`/inventory/${idFromEntity(item.part)}`)
                        }
                    }
                    if (item.collapsedPart) {
                        if (item.part.customerId) {
                            push({
                                pathname: `/customers/${item.collapsedPart.customerId}`,
                                search: stringify({
                                    partId: idFromEntity(item.collapsedPart),
                                    isCollapsed: 1
                                })
                            })
                        } else {
                            push(`/inventory/${idFromEntity(item.collapsedPart)}&isCollapsed=1`)
                        }
                    }
                }}
            />
        </div>
    );
});

export default InventoryGrid
