import * as React from 'react';
import {useState} from 'react';
import {Field, Form} from "react-final-form";
import {observer} from "mobx-react";
import {FinalAutocomplete, FinalButtonSet, Input, Select,} from "../../components/ui/form";
import useDataListFetch from "../../utils/form/useDataListFetch";
import {Icon} from "@mdi/react";
import {mdiClipboardOutline, mdiServer} from "@mdi/js";
import {IProjectModel, SystemFullModel} from "../../utils/models";
import {formatTicketType, ticketTypeList} from "../../store/enum/ticketEnumeration";
import {ContractStatusType} from "../../store/enum/contractEnumeration";


const CreateTicketForm = observer(({createTicket}) => {
    const ticketCategoriesDataList = useDataListFetch('ticket_categories');


    const [stepCreateTicket, setStepCreateTicket] = useState('first-step');
    const [type, setType] = useState(null);
    const [contractStatus, setContractStatus] = useState<ContractStatusType>(null);
    return <Form onSubmit={createTicket}

                 render={
                     ({pristine, submitting, handleSubmit, form}) =>
                         <form onSubmit={handleSubmit}
                           className={stepCreateTicket === 'first-step' ? 'first-step_no-buttons' : ''}
                         >
                             {stepCreateTicket === 'first-step' && <div className={'chooses-content'}>
                                 <div className={type === 'system' ? 'cards active' : 'cards'}
                                      onClick={() => {
                                          setType('system');
                                          setStepCreateTicket('second-step')
                                      }}>
                                     <div className="cards-ico">
                                         <Icon path={mdiServer}/>
                                     </div>
                                     <p>System</p>
                                 </div>
                                 <div className={type === 'projects' ? 'cards active' : 'cards'}
                                      onClick={() => {
                                          setType('projects');
                                          setStepCreateTicket('second-step')
                                      }}>
                                     <div className="cards-ico">
                                         <Icon path={mdiClipboardOutline}/>
                                     </div>
                                     <p>Project</p>
                                 </div>
                             </div>}

                             {stepCreateTicket === 'second-step' && <div>

                                 {contractStatus && contractStatus === 'expired'
                                 && <div className={'expired-contract-warning'}>
                                     <span>Ticket for expired contract</span>
                                 </div>}

                                 <Field
                                     name='title'
                                     label='Title'
                                     placeholder={'Title'}
                                     component={Input}
                                     required={true}
                                 />
                                 {type === 'system' && <Field
                                     name='serialNumber'
                                     label='System'
                                     component={FinalAutocomplete}
                                     scope={"systems"}
                                     fieldName={"serialNumber"}
                                     onComplete={(response: SystemFullModel) => {
                                         if (typeof response === 'object') {
                                             form.change("system", response['@id'])
                                             if (response.contract) {
                                                 setContractStatus(response.contract.status)
                                             }
                                         }
                                     }}
                                 />}

                                 {type === 'projects' && <Field
                                     name='projectName'
                                     label='Project'
                                     component={FinalAutocomplete}
                                     scope={"projects"}
                                     fieldName={"name"}
                                     onComplete={(response: IProjectModel) => {
                                         if (typeof response === 'object') {
                                             if (response.contract) {
                                                 setContractStatus(response.contract.status)
                                             }
                                             form.change("project", response['@id'])
                                         }
                                     }}
                                 />}

                                 <Field
                                     name='type'
                                     label='Type'
                                     required={true}
                                     options={ticketTypeList}
                                     formatOptions={formatTicketType}
                                     component={Select}
                                 />

                                 <Field
                                     name='category'
                                     label='Category'
                                     required={true}
                                     component={Select}
                                     menuPlacement={'top'}
                                     {...ticketCategoriesDataList}
                                 />

                             </div>}

                             {stepCreateTicket === 'second-step' && <div className={'popover-buttons'}>
                                 <FinalButtonSet pristine={pristine} submitting={submitting}  reset={form.reset}/>
                             </div>}
                         </form>
                 }/>
});

export default CreateTicketForm;
