import React from "react";
import StandalonePopover from "../../components/ui/StandalonePopover";
import FileUploader from "../../components/ui/widgets/FileUploader";

import {Icon} from "@mdi/react";
import {mdiFolderOutline} from '@mdi/js';
import {observer} from "mobx-react";
import {useStore} from "../../context";

const PartDetailsButtonList = observer(() => {
    const {partStore} = useStore();

    return (
        <div className="buttons">
            {partStore.currentContextPlace === 'attachment' &&
            <StandalonePopover size={"bd"} title={"Attachments"} closePopover={partStore.clearContextPlace}>
                <FileUploader attachmentStore={partStore}/>
            </StandalonePopover>}

            <div className="buttons-item" onClick={() => partStore.changeContextPlace('attachment')}>
                <Icon path={mdiFolderOutline}/>Attachment
            </div>
        </div>
    );
})

export default PartDetailsButtonList;
