import React from 'react';

import ResourceForm from "./UserForm";
import {GenericResourceStore} from "../../../store/mobx/GenericResourceStore";

type ResourceCreateProps = {
    sharedGridResourceCollectionStore: GenericResourceStore
    canEdit: boolean,
}
const ResourceCreate = ({sharedGridResourceCollectionStore, canEdit}: ResourceCreateProps) => {
    return (
        <React.Fragment>
            <ResourceForm
                initialValues={{}}
                disabled={!canEdit}
                onSubmit={sharedGridResourceCollectionStore.createResource}
            />
        </React.Fragment>
    );
};


export default ResourceCreate;
