import React from 'react';

type NoResultsType = {
    icon?,
    title?,
    subtitle?
}
const NoResults = (props: NoResultsType) => {
    return (
        <div className='no-results'>
            {props.icon
                ? (<i className={props.icon}></i>)
                : (<div className='no-results-icon'/>)}

            <p className='no-results-text title'>
                {props.title ? props.title : 'Sorry, no items matched your search!'}
            </p>
            <p className='no-results-text'>
                {props.subtitle ? props.subtitle : 'Please, try some other filters.'}
            </p>
        </div>
    );
}

export default NoResults;
