import React from 'react';

import {FinalButtonSet, Input, Select} from '../../components/ui/form';
import {Loader, NumberInput} from '../../components/ui';

import {useStore} from "../../context";
import {Field, Form} from "react-final-form";
import {observer} from "mobx-react";
import useDataListFetch from "../../utils/form/useDataListFetch";
import {PurchaseItemModel} from "../../utils/models";

const PurchaseItemSoftwareUpdateForm = observer(() => {
    const {purchaseStore} = useStore();
    const manufacturerDataList = useDataListFetch('manufacturers');
    if (purchaseStore.isLoading) {
        return <Loader/>
    }
    return <Form<PurchaseItemModel>
        onSubmit={purchaseStore.updatePurchaseSoftwareItem}
        validate={(values) => {
            let errors = {};
            if (!values.warranty) {
                errors['warranty'] = "Required"
            }
            if (!values.price) {
                errors['price'] = "Required"
            } else if (!(Number(values.price) > 0)) {
                errors['price'] = "Price should be greater than 0"
            }
            if (!values.quantity) {
                errors['quantity'] = "Required"
            } else if (!(Number(values.quantity) > 0)) {
                errors['quantity'] = "Quantity should be greater than 0"
            }
            return errors
        }}
        initialValues={purchaseStore.purchaseItemToEdit}
        render={
            ({handleSubmit, pristine, submitting}) => {
                return (<form onSubmit={handleSubmit}>
                    <Field
                        placeholder='Name'
                        name="partName"
                        label="Name"
                        component={Input}
                        disabled
                    />
                    <Field
                        name="modelNumber"
                        label={"License Number"}
                        component={Input}
                        disabled
                    />
                    <Field
                        placeholder={'Manufacturer'}
                        name="manufacturer"
                        label="Manufacturer"
                        component={Select}
                        {...manufacturerDataList}
                        disabled
                    />
                    <Field
                        placeholder='Price'
                        name="price"
                        label="Price"
                        component={Input}
                    />
                    <Field
                        placeholder='Quantity'
                        name="quantity"
                        label="Quantity"
                        component={NumberInput}
                        canEdit={true}
                    />
                    <Field
                        placeholder='Warranty'
                        name="warranty"
                        label="Warranty"
                        component={NumberInput}
                        canEdit={true}
                    />
                    <FinalButtonSet pristine={pristine} submitting={submitting}/>
                </form>)
            }}>
    </Form>
});


export default PurchaseItemSoftwareUpdateForm;
