import {action, observable} from "mobx";

export class UiStore {

    @observable isOpenMenu: boolean = false;

    @action toggleMenu = () => {
        this.isOpenMenu = !this.isOpenMenu;
        localStorage.setItem('isOpenMenu', JSON.stringify(this.isOpenMenu));
    };

    @action hideMenu = () => {
        this.isOpenMenu = false;
    };
}
