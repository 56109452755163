import {action, computed, observable, runInAction} from "mobx";
import remotedev from 'mobx-remotedev';
import ApiClient from "../ApiClient";
import {CollectionResponseType, GridResourceCollectionInterface} from "../interfaces/GridResourceCollectionInterface";
import ReportDownloader from "../service/ReportDownloader";
import {formatCurrency} from "../../containers/opportunities/OpportunityHelper";
import moment from "moment";
import {handleFormSubmit} from "../../utils/form/handleFormSubmit";
import * as _ from "underscore";

import {mdiCashUsdOutline, mdiChartLine, mdiFileDocumentOutline, mdiTicketOutline} from '@mdi/js'
import {
    ContractFullModel,
    IApiEntity,
    IContractRProfitModel,
    IExceededHoursModel,
    IExceededTicketModel,
    IExpenseModel,
    ISnippetModel,
} from "../../utils/models";

export enum SummaryColor {
    RED = 'red',
    BLUE = 'blue',
    GREEN = 'green',
    ORANGE = 'orange'
}

enum SnippetId {
    TOTAL_CONTRACTS_COUNT = 'total_contracts_count',
    TICKETS_COUNT = 'tickets_count',
    CASHFLOW_LEFT = 'cashflow_left',
    AVERAGE_PER_MONTH = 'average_per_month'
}

interface ContractRProfitSummaryType {
    label,
    value: number,
    format?: (value) => string;
    icon,
    color: SummaryColor
}

interface ContractTicketPerPeriodModel extends IApiEntity {
    title: string,
    expenses: Array<IExpenseModel>,
    spentHours: number,
    spentMinutes: number,
    createdAt: string
}

export interface ITicketsPerPeriodWithExceeded {
    includedTime: number,
    excludedTime: number,
    ticket: ContractTicketPerPeriodModel
}

export interface TicketsPerPeriodWithExceededCollection {
    [key: string]: {
        tickets: Array<ITicketsPerPeriodWithExceeded>
        exceededHours: Array<IExceededHoursModel>,
        exceededTickets: Array<IExceededTicketModel>
        timeLeft: number,
    }
}

type ExpenseDataType = {
    date: number,
    message: string,
    sum: number,
}

@remotedev({global: true})
export class ContractRProfitStore implements GridResourceCollectionInterface<IContractRProfitModel> {
    @observable collectionResponse: CollectionResponseType<IContractRProfitModel> | null;
    @observable isLoading: boolean = false;
    @observable isSummariesLoading: boolean = false;
    @observable summaries = [];
    @observable contractData: ContractFullModel;
    private expenseTypes;

    constructor(private apiClient: ApiClient, private reportDownloader: ReportDownloader) {
    }

    getEntityName: 'ContractRProfit';

    @computed
    public get expensesRows(): { [key: string]: Array<ExpenseDataType> } {
        if (!this.contractData) {
            return {};
        }
        let expensesData = this.contractData.expenses.map(expense => {
            return {
                date: new Date(expense.createdAt).getTime(),
                message: `Contract expense (${this.expenseTypes[expense.type]})`,
                sum: -1 * (_.isNaN(expense.cost) ? 0 : Number(expense.cost))
            }
        });
        const realizations = this.contractData.allAssignedPartRealizations.map(realization => {
            return {
                date: new Date(realization.assignedAt).getTime(),
                message: `Part #${realization.part.partNumber} (${realization.partStatus}) s/n ${realization.serialNumber}`,
                sum: -1 * (_.isNaN(realization.price) ? 0 : Number(realization.price))
            }
        });
        expensesData = [...expensesData, ...realizations];

        for (let ticketPerPeriodWithExceeded of Object.values(this.contractData.ticketsPerPeriodWithExceeded)) {
            for (let ticketData of ticketPerPeriodWithExceeded.tickets) {
                if (ticketData.includedTime > 0 || ticketData.excludedTime > 0) {
                    let spentTimeDescr = `Spent ${ticketData.ticket.spentHours}h ${ticketData.ticket.spentMinutes}m on Ticket "${ticketData.ticket.title}"`;
                    if (ticketData.excludedTime > 0) {
                        const incH = Math.floor(ticketData.includedTime / 60);
                        const incM = Math.round(ticketData.includedTime % 60);
                        const excH = Math.floor(ticketData.excludedTime / 60);
                        const excM = Math.round(ticketData.excludedTime % 60);

                        spentTimeDescr += ` (included ${incH}h ${incM}m, excluded ${excH}h ${excM}m)`
                    }
                    expensesData.push({
                        date: new Date(ticketData.ticket.createdAt).getTime(),
                        message: spentTimeDescr,
                        sum: Number(this.contractData.rate) * ticketData.ticket.spentHours
                            + Math.round(Number(this.contractData.rate) / .6 * ticketData.ticket.spentMinutes) / 100
                    })
                }
                for (let tExpense of ticketData.ticket.expenses) {
                    expensesData.push({
                            date: new Date(tExpense.createdAt).getTime(),
                            message: `Ticket #${ticketData.ticket['@id'].split('/').reverse()[0]} - 
                            Expense (${this.expenseTypes[tExpense.type]})`,
                            sum: -1 * (_.isNaN(tExpense.cost) ? 0 : Number(tExpense.cost))
                        }
                    )
                }
            }

            for (let excHour of ticketPerPeriodWithExceeded.exceededHours) {
                const excH = Math.floor(excHour.minutesNumber / 60);
                const excM = Math.round(excHour.minutesNumber % 60);
                expensesData.push({
                    date: excHour.valueUpdatedAt
                        ? new Date(excHour.valueUpdatedAt).getTime()
                        : new Date(excHour.periodStartDate).getTime(),
                    message: `Exceeded ${excH}h ${excM}m outstanding settlement`,
                    sum: Number(excHour.additionalValue),
                })
            }

            for (let excTicket of ticketPerPeriodWithExceeded.exceededTickets) {
                expensesData.push({
                        date: excTicket.valueUpdatedAt
                            ? new Date(excTicket.valueUpdatedAt).getTime()
                            : new Date(excTicket.periodStartDate).getTime(),
                        message: `Exceeded Ticket "${excTicket.ticket.title}" outstanding settlement`,
                        sum: Number(excTicket.additionalValue)
                    }
                )
            }
        }

        return expensesData.sort((a: ExpenseDataType, b: ExpenseDataType) => a.date - b.date)
            .reduce((prev: _.Dictionary<Array<ExpenseDataType>>, expense: ExpenseDataType) => {
                const dayKey = this.getDayKey(expense.date);
                return {
                    ...prev,
                    [dayKey]: _.has(prev, dayKey) ? [...prev[dayKey], expense] : [expense]
                }
            }, {})

    }

    @computed
    public get totalValue(): number {
        if (!this.contractData) {
            return 0;
        }
        return this.contractData.totalValue ? Number(this.contractData.totalValue) : 0;
    }

    @computed
    public get totalLeft(): number {
        if (!this.contractData) {
            return 0;
        }
        return this.totalValue
            + Number(this.contractData.outstandingSettlements)
            - (Number(this.contractData.cost) + Number(this.contractData.sharedCost));
    }

    @computed
    public get averageProfit(): number {
        if (!this.contractData) {
            return 0;
        }

        const mStartDate = moment(this.contractData.startDate);
        const mTermDate = moment();
        const monthsDiff = mTermDate.diff(mStartDate, 'months');

        return Number((this.totalLeft / Math.max(1, monthsDiff)).toFixed(2));
    }

    static getSummaryOptions(snippet: ISnippetModel): ContractRProfitSummaryType {
        switch (snippet.id) {
            case SnippetId.TOTAL_CONTRACTS_COUNT:
                return {
                    icon: mdiFileDocumentOutline,
                    label: 'Total contracts',
                    color: SummaryColor.BLUE,
                    value: snippet.count
                };
            case SnippetId.TICKETS_COUNT:
                return {
                    icon: mdiTicketOutline,
                    label: 'Tickets',
                    color: SummaryColor.BLUE,
                    value: snippet.count
                };
            case SnippetId.CASHFLOW_LEFT:
                return {
                    icon: mdiCashUsdOutline,
                    label: 'Cashflow left',
                    color: SummaryColor.BLUE,
                    format: (value) => formatCurrency(value, false),
                    value: Number(snippet.count.toFixed(2))
                };
            case SnippetId.AVERAGE_PER_MONTH:
                return {
                    icon: mdiChartLine,
                    label: 'Average per month',
                    format: (value) => formatCurrency(value, false),
                    color: SummaryColor.BLUE,
                    value: Number(snippet.count.toFixed(2))
                };
            default:
                return {
                    icon: 'ban',
                    label: 'Wrong snippet',
                    color: SummaryColor.RED,
                    value: 0
                }
        }
    }

    @action toggleLoading = (): void => {
        this.isLoading = !this.isLoading;
    };

    @action fetchProfitDetails = (id, expenseTypes) => {
        this.expenseTypes = expenseTypes;
        this.apiClient.fetchContract(id).then(action("fetchProfitDetails ok", (response) => {
            this.contractData = response;
        }))
    };

    getDayKey(miliseconds: number) {
        const date: Date = new Date(miliseconds);
        date.setMilliseconds(0);
        date.setSeconds(0);
        date.setMinutes(0);
        date.setHours(0);
        return moment(date).format('Y-MM-DD');
    }

    @action fetchCollection = (query): void => {
        this.isLoading = true;
        this.apiClient.contractRProfitCollectionFetch({params: query}).then(
            action("contract fetchCollection ok", response => {
                this.collectionResponse = response;
                this.isLoading = false;
            })
        ).catch(action("contract fetchCollection failed", e => {
            this.isLoading = false;
        }));
    };

    @action fetchSummaries = (filters) => {
        this.isSummariesLoading = true;
        this.apiClient.contractRProfitSummaries(filters).then(action("fetchSummaries ok", (response) => {
            this.summaries = response['hydra:member'];
            this.isSummariesLoading = false;
        })).catch(action("contract fetchCollection failed", e => {
            this.isLoading = false;
        }));
    };

    @action updateSharedCost = async (id, {sharedCost, remove}, query) => {
        this.isLoading = true;
        const {errors} = await handleFormSubmit(this.apiClient.contractUpdate(id, {sharedCost}));
        runInAction("updateSharedCost ok", () => {
            this.isLoading = false;
            if (!errors) {
                this.fetchCollection(query);
            }
        });
        if (errors) {
            return errors;
        }
    };

    @action fetchCollectionReport = (query): void => {
        this.reportDownloader.download<IContractRProfitModel>('ContractRProfit', this, this.apiClient.contractRProfitCollectionFetch({
            params: query
        }), (item) => {
            return {
                'Company name': item.customerName,
                'Partner name': item.partnerName,
                'Contract number': item.contractNumber,
                'Total value': item.totalValue ? parseFloat(item.totalValue as any).toFixed(2) : '',
                'Customer purchase order': item.customerPO,
                'Start date': moment(item.startDate).format('YYYY MMM DD'),
                'Expire date': item.expiryDate ? moment(item.expiryDate).format('YYYY MMM DD') : 'PAYG',
                'Sales agent': item.salesAgent,
            }
        }, (report) => {
            const csvSummaries = this.summaries.map(summary => {
                const options = ContractRProfitStore.getSummaryOptions(summary);
                return {
                    'Company name': options.label,
                    'Partner name': options.value,
                }
            });
            report.push({}, ...csvSummaries);
            return report;
        });
    }
}
