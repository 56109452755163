import axios, {AxiosRequestConfig, AxiosStatic, CancelTokenSource} from "axios";
import {isTokenExpired} from "../utils/tokenUtils";
import {LoginTokenType} from "./mobx/AuthStore";
import {CollectionResponseType,} from "./interfaces/GridResourceCollectionInterface";
import _ from "underscore";
import {RouterStore} from 'mobx-react-router';
import {
    ContractFullModel,
    ContractShortModel,
    CustomerFullModel,
    IAlertModel,
    IAppointmentModel,
    IAttachmentModel,
    ICategoryModel,
    CollapsedProjectModel,
    CollapsedSystemModel,
    IContractRProfitModel,
    IExpenseModel,
    IFilterSettings,
    IFullSaleModel,
    IItemOpportunityModel,
    IListOpportunityModel,
    INotificationsModel,
    IOpportunityAlertModel,
    IProjectModel,
    IQuoteModel,
    IQuoteWriteModel,
    IReportTrackModel,
    Iri,
    IShortSaleModel,
    CreateSpentTimeInput,
    ITermsAndConditionsModel,
    ITicketAlertModel,
    IUserModel,
    PartFullModel,
    PartRealizationShortModel,
    PurchaseShortModel,
    SystemFullModel,
    TicketFullModel,
    TicketShortModel,
    CollapsedPartModel,
    CreateExpenseForContractInput,
    CreateExpenseForTicketInput,
    ExpenseUpdateInput,
    AddRealizationToPartWithStatusInput,
    AddSerialsToPurchaseBatchInput,
    AddInventoryNotesInput,
    CreatePartForMasterInput,
    AddStockPartToSystemInput,
    AddStockPartToProjectInput,
    AddStockPartToContractInput,
    ReassignPartToSystemInput,
    ReassignPartToProjectInput,
    ReassignPartToContractInput,
    PartMutateInput,
    CreateSoftwarePartForQuoteInput,
    CreateSystemPartForQuoteInput,
    CustomerNavigationModel,
    ReassignContractToCustomerInput,
    CreateHardwarePartInput,
    CreatePurchaseHardwarePartInput,
    CreatePurchaseSoftwarePartInput,
    CreatePurchaseSystemPartInput,
    CreateSoftwarePartInput,
    CreateSystemPartInput,
    AddSoftwareToPurchaseInput,
    UpdatePurchaseItemWithSerialInput,
    UpdatePurchaseSoftwareInput,
    CreateTicketForSystem,
    CreateTicketForProject,
    ExceededTicketModel,
    ExceededHourModel, SupportRequestMessage,
} from "../utils/models";
import {DataListStoreItemType} from "../utils/form/useDataListFetch";
import {SupportRequestOutput} from "../generated";

class ApiClient {
    private cancellationToken: CancelTokenSource;

    constructor(private _client: AxiosStatic = axios, private routingStore: RouterStore) {
        axios.interceptors.response.use(
            response => response,
            error => {
                const {status} = error.response;
                if (status === 401 && error.config.url !== '/api/login_check') {
                    routingStore.history.push({pathname: "/logout"});
                }
                return Promise.reject(error);
            }
        );
        _client.defaults.baseURL = process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : ''
        this.cancellationToken = _client.CancelToken.source();
    }

    public get client() {
        const token = localStorage.getItem('token');
        if (
            token &&
            !isTokenExpired(token)
        ) {

            this._client.defaults.headers.Authorization = `Bearer ${token}`;
            this._client.defaults.headers.Accept = 'application/ld+json';
            this._client.defaults.headers['Content-Type'] = 'application/ld+json';
            this._client.defaults.cancelToken = this.cancellationToken.token;
        }

        return this._client;
    }

    public cancelRequest() {
        return this.cancellationToken.cancel();
    }

    public ticketCollectionFetch(config: AxiosRequestConfig) {
        const notArchivedConfig = config.params.status
            ? config
            : {...config, 'params': {...config.params, 'status[not_equal]': 'archived'}};
        return this.client.get<CollectionResponseType<TicketShortModel>>(`/api/tickets`, notArchivedConfig).then(response => response.data);
    }

    public supportRequestCollectionFetch(config: AxiosRequestConfig) {
        return this.client.get<CollectionResponseType<SupportRequestOutput>>(`/api/support_requests`, config).then(response => response.data);
    }

    public supportRequestItemFetch(id: string) {
        return this.client.get<SupportRequestOutput>(`/api/support_requests/${id}`).then(response => response.data);
    }
    public createSupportRequestAnswer(body) {
        return this.client.post<SupportRequestMessage>(`/api/support_request_messages`, body).then(response => response.data);
    }

    public supportRequestSelectSystem(id: string, body: {relatedSystem: Iri}) {
        return this.client.put(`/api/support_requests/${id}/related_system`, body).then(response => response.data);
    }

    public supportRequestSelectProject(id: string, body: {relatedProject: Iri}) {
        return this.client.put(`/api/support_requests/${id}/related_project`, body).then(response => response.data);
    }

    public supportRequestAssignCustomer(id: string, body: {customer: Iri}) {
        return this.client.put(`/api/support_requests/${id}/assign_customer`, body).then(response => response.data);
    }

    public supportRequestItemDelete(id: string) {
        return this.client.delete<void>(`/api/support_requests/${id}`);
    }

    public salesCollectionFetch(config: AxiosRequestConfig) {
        return this.client.get<CollectionResponseType<IShortSaleModel>>(`/api/sales`, config).then(response => response.data);
    }

    public salesItemFetch(id) {
        return this.client.get<IFullSaleModel>(`/api/sales/${id}`).then(response => response.data);
    }

    public salesUpdate(body, id) {
        return this.client.put(`/api/sales/${id}`, body).then(response => response.data);
    }

    public contractsAttachmentsUpdate(id: number, data: FormData) {
        return this.addAttachment(`/api/contracts/${id}/attachments`, data);
    }

    public contractRenew(id: Iri) {
        return this.client.post<IItemOpportunityModel>(`/api/create-renewal-quote`, {contract: id}).then(response => response.data);
    }

    public contractDisassemble(id: number) {
        return this.client.put<ContractFullModel>(`/api/contracts/${id}/disassemble`, {}).then(response => response.data);
    }

    public contractReassign(id: number, data: ReassignContractToCustomerInput) {
        return this.client.put(`/api/contracts/${id}/reassign-to-customer`, data).then(response => response.data);
    }

    public contractCollectionFetch(config: AxiosRequestConfig) {
        return this.client.get<CollectionResponseType<ContractShortModel>>(`/api/contracts`, config).then(response => response.data);
    }

    public partCategoriesCreate(data: Partial<{ name: string, parent: Iri | null }>) {
        return this.client.post<ICategoryModel>('/api/part_categories', data).then(response => response.data);
    }

    public partCategoriesDelete(id: number) {
        return this.client.delete(`/api/part_categories/${id}`).then(response => response.data);
    }

    public partCategoriesUpdate(id: number, data: Partial<ICategoryModel>) {
        return this.client.put<ICategoryModel>(`/api/part_categories/${id}`, data).then(response => response.data);
    }

    public partCategoriesFetch(query) {
        return this.client.get<CollectionResponseType<ICategoryModel>>(`/api/part_categories`, {params: query}).then(response => response.data);
    }

    public realizationAssignToSystem(realizationIri: Iri, system: Iri) {
        return this.client.put<PartRealizationShortModel>(`${realizationIri}/assign-to-system`, {'system': system}).then(response => response.data);
    }

    public realizationAssignToTicket(realizationIri: Iri, ticket: Iri) {
        return this.client.put<PartRealizationShortModel>(`${realizationIri}/assign-to-ticket`, {'ticket': ticket}).then(response => response.data);
    }

    public realizationRemoveFromTicket(realizationIri: Iri) {
        return this.client.put<PartRealizationShortModel>(`${realizationIri}/remove-from-ticket`, {}).then(response => response.data);
    }

    public contractUpdate(id, values: Partial<ContractFullModel>) {
        return this.client.put<ContractFullModel>(`/api/contracts/${id}`, values).then(response => response.data);
    }

    public contractCreate(values: Partial<ContractFullModel>) {
        return this.client.post<ContractFullModel>(`/api/contracts`, values).then(response => response.data);
    }

    public contractUpdateExceededTicket(iri: Iri, values: ExceededTicketModel) {
        return this.client.put<ContractFullModel>(iri, values).then(response => response.data);
    }

    public contractUpdateExceededHour(iri: Iri, values: ExceededHourModel) {
        return this.client.put<ContractFullModel>(iri, values).then(response => response.data);
    }

    public contractRProfitCollectionFetch(config: AxiosRequestConfig) {
        return this.client.get<CollectionResponseType<IContractRProfitModel>>(`/api/contract_r_profits`, config).then(response => response.data);
    }

    public contractRProfitSummaries(filters) {
        return this.client.get<CollectionResponseType<IContractRProfitModel>>(`/api/snippets`, {params: filters}).then(response => response.data);
    }

    public customerNavigationFetch(id: number) {
        return this.client.get<CustomerNavigationModel>(`/api/customer_navigations/${id.toString()}`).then(response => response.data);
    }

    public genericResourceCollectionFetch(uri: string, config: AxiosRequestConfig) {
        return this.client.get<CollectionResponseType<any>>(uri, config).then(response => response.data);
    }

    public genericResourceItemFetch(uri: string, id) {
        return this.client.get<CollectionResponseType<any>>(`${uri}/${id}`).then(response => response.data);
    }

    public genericResourceCreate(uri: string, values) {
        return this.client.post<CollectionResponseType<any>>(uri, values).then(response => response.data);
    }

    public genericResourceUpdate(uri: string, id, values) {
        return this.client.put<CollectionResponseType<any>>(`${uri}/${id}`, values).then(response => response.data);
    }

    public genericResourceDelete(uri: string, id) {
        return this.client.delete(`${uri}/${id}`);
    }

    public ticketFetch(id) {
        return this.client.get<TicketFullModel>(`/api/tickets/${id}`).then(response => response.data);
    }

    public customerFetch(id: number) {
        return this.client.get<CustomerFullModel>(`/api/customers/${id}`).then(response => response.data);
    }

    public customerUpdate(id: number, body: Partial<CustomerFullModel>) {
        return this.client.put<CustomerFullModel>(`/api/customers/${id}`, body).then(response => response.data);
    }

    public customerCreate(body: Partial<CustomerFullModel>) {
        return this.client.post<CustomerFullModel>(`/api/customers`, body).then(response => response.data);
    }

    public customerRequesterCreate(body: Partial<CustomerFullModel>) {
        return this.client.post<CustomerFullModel>(`/api/customers/create-requester`, body).then(response => response.data);
    }

    public customersFetch(config?: AxiosRequestConfig) {
        return this.client.get<CollectionResponseType<CustomerFullModel>>(`/api/customers`, config).then(response => response.data);
    }

    public fetchContract(id: number) {
        return this.client.get<ContractFullModel>(`/api/contracts/${id}`).then(response => response.data);
    }

    public systemItemFetch(id: number) {
        return this.client.get<SystemFullModel>(`/api/systems/${id}`).then(response => response.data);
    }

    public systemCollapsedItemFetch(id: number) {
        return this.client.get<CollapsedSystemModel>(`/api/collapsed_systems/${id}`).then(response => response.data);
    }

    public systemCollectionFetch(query) {
        return this.client.get<CollectionResponseType<SystemFullModel>>(`/api/systems`, {params: query}).then(response => response.data);
    }

    public partsCollectionFetch(query) {
        return this.client.get<CollectionResponseType<PartFullModel>>(`/api/parts`, {params: query}).then(response => response.data);
    }

    public partReturnToStock(id) {
        return this.client.put(`/api/parts/${id}/return-to-stock`, {}).then(response => response.data);
    }

    public partItemFetch(id) {
        return this.client.get<PartFullModel>(`/api/parts/${id}`).then(response => response.data);
    }

    public partCollapsedFetch(id) {
        return this.client.get<CollapsedPartModel>(`/api/collapsed_parts/${id}`).then(response => response.data);
    }

    public stockPartFetch(partNumber) {
        return this.client.get<PartFullModel>(`/api/parts`, {
            params: {partNumber: partNumber, isStock: true, 'context[group]': 'part_get'}
        }).then(response => response.data);
    }

    public notificationsCollectionFetch(query) {
        return this.client.get<CollectionResponseType<INotificationsModel>>(`/api/user_notifications`, {params: query}).then(response => response.data);
    }

    public notificationsCreate(values) {
        return this.client.post<INotificationsModel>(`/api/create_user_notifications`, values).then(response => response.data);
    }

    public notificationMarkAsRead(id) {
        return this.client.put<INotificationsModel>(`/api/user_notifications/${id}`, {isRead: true}).then(response => response.data);
    }

    public notificationMarkAllAsRead() {
        return this.client.post<INotificationsModel>(`/api/read_all_notifications`, {}).then(response => response.data);
    }

    public notificationRemove(id) {
        return this.client.delete(`/api/user_notifications/${id}`).then(response => response.data);
    }

    public opportunityCollectionFetch(query) {
        return this.client.get<CollectionResponseType<IListOpportunityModel>>(`/api/opportunities`, {params: query}).then(response => response.data);
    }

    public opportunityItemFetch(id) {
        return this.client.get<IItemOpportunityModel>(`/api/opportunities/${id}`).then(response => response.data);
    }

    public opportunityExport(id) {
        return this.client.post(`/api/opportunities/${id}/export`, {}, {responseType: "blob"}).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `opportunity_${id}.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    public quoteItemFetch(id) {
        return this.client.get<IQuoteModel>(`/api/quotes/${id}`).then(response => response.data);
    }

    public quoteExport(id) {
        return this.client.post(`/api/quotes/${id}/export`, {}, {responseType: "blob"}).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `quote_${id}.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    public quoteCollectionFetch(query) {
        return this.client.get<CollectionResponseType<IQuoteModel>>(`/api/quotes`, {params: query}).then(response => response.data);
    }

    public termsAndConditionsCollectionFetch(query) {
        return this.client.get<CollectionResponseType<ITermsAndConditionsModel>>(`/api/terms_and_conditions`, {params: query}).then(response => response.data);
    }

    public opportunityUpdate(id, values: Partial<IItemOpportunityModel>) {
        return this.client.put<IItemOpportunityModel>(`/api/opportunities/${id}`, values).then(response => response.data);
    }

    public opportunityCreate(values: Partial<IItemOpportunityModel>) {
        return this.client.post<IItemOpportunityModel>(`/api/opportunities/create`, values).then(response => response.data);
    }

    public purchaseCollectionFetch(query) {
        return this.client.get<CollectionResponseType<PurchaseShortModel>>(`/api/purchases`, {params: query}).then(response => response.data);
    }

    public purchaseFetch(id) {
        return this.client.get<PurchaseShortModel>(`/api/purchases/${id}`).then(response => response.data);
    }

    public purchaseRemove(id) {
        return this.client.delete(`/api/purchases/${id}`).then(response => response.data);
    }

    public purchaseUpdate(id, body: Partial<PurchaseShortModel>) {
        return this.client.put<PurchaseShortModel>(`/api/purchases/${id}`, body).then(response => response.data);
    }

    public purchaseCreate(body: Partial<PurchaseShortModel>) {
        return this.client.post<PurchaseShortModel>(`/api/purchases`, body).then(response => response.data);
    }

    public purchaseSerialsBatchCreate(postData: AddSerialsToPurchaseBatchInput) {
        return this.client.post(`/api/purchase_items/add-serials-batch`, postData).then(response => response.data);
    }

    public purchaseSoftwareCreate(postData: AddSoftwareToPurchaseInput) {
        return this.client.post(`/api/purchase_items/add-software`, postData).then(response => response.data);
    }

    public purchaseItemUpdateWithSerial(id, data: UpdatePurchaseItemWithSerialInput) {
        return this.client.put(`/api/purchase_items/${id}/update-with-serial`, data).then(response => response.data);
    }

    public purchaseItemUpdateSoftware(id, data: UpdatePurchaseSoftwareInput) {
        return this.client.put(`/api/purchase_items/${id}/update-software`, data).then(response => response.data);
    }

    public createHardwarePart(body: CreateHardwarePartInput) {
        return this.client.post<PartFullModel>(`/api/create-hardware`, body).then(response => response.data);
    }

    public createSoftwarePart(body: CreateSoftwarePartInput) {
        return this.client.post<PartFullModel>(`/api/create-software`, body).then(response => response.data);
    }

    public createSystemPart(body: CreateSystemPartInput) {
        return this.client.post<PartFullModel>(`/api/create-system`, body).then(response => response.data);
    }

    public createPurchaseHardwarePart(body: CreatePurchaseHardwarePartInput) {
        return this.client.post<PartFullModel>(`/api/create-hardware-for-purchase`, body).then(response => response.data);
    }

    public createPurchaseSoftwarePart(body: CreatePurchaseSoftwarePartInput) {
        return this.client.post<PartFullModel>(`/api/create-software-for-purchase`, body).then(response => response.data);
    }

    public createPurchaseSystemPart(body: CreatePurchaseSystemPartInput) {
        return this.client.post<PartFullModel>(`/api/create-system-for-purchase`, body).then(response => response.data);
    }

    public createPartForMaster(body: CreatePartForMasterInput) {
        return this.client.post<PartFullModel>(`/api/parts-for-master`, body).then(response => response.data);
    }

    public createSoftwarePartForQuote(body: CreateSoftwarePartForQuoteInput) {
        return this.client.post<PartFullModel>(`/api/software-parts-for-quote`, body).then(response => response.data);
    }

    public createSystemPartForQuote(body: CreateSystemPartForQuoteInput) {
        return this.client.post<PartFullModel>(`/api/system-parts-for-quote`, body).then(response => response.data);
    }

    public assignStockPartToSystem(body: AddStockPartToSystemInput) {
        return this.client.post<PartFullModel>(`/api/add-stock-part-to-system`, body).then(response => response.data);
    }

    public assignStockPartToProject(body: AddStockPartToProjectInput) {
        return this.client.post<PartFullModel>(`/api/add-stock-part-to-project`, body).then(response => response.data);
    }

    public assignStockPartToContract(body: AddStockPartToContractInput) {
        return this.client.post<PartFullModel>(`/api/add-stock-part-to-contract`, body).then(response => response.data);
    }

    public updatePart(id, body: PartMutateInput) {
        return this.client.put<PartFullModel>(`/api/parts/${id}`, body).then(response => response.data);
    }

    public reassignPartToSystem(partId: number, body: ReassignPartToSystemInput) {
        return this.client.put<PartFullModel>(`/api/parts/${partId}/reassign-to-system`, body).then(response => response.data);
    }

    public reassignPartToProject(partId: number, body: ReassignPartToProjectInput) {
        return this.client.put<PartFullModel>(`/api/parts/${partId}/reassign-to-project`, body).then(response => response.data);
    }

    public reassignPartToContract(partId: number, body: ReassignPartToContractInput) {
        return this.client.put<PartFullModel>(`/api/parts/${partId}/reassign-to-contract`, body).then(response => response.data);
    }

    public addRealizationToPartWithStatus(iri: Iri, body: AddRealizationToPartWithStatusInput) {
        return this.client.put<PartRealizationShortModel>(`${iri}/add-to-part-with-status`, body).then(response => response.data);
    }

    public systemUpdate(id: number, data: Partial<SystemFullModel>) {
        return this.client.put<SystemFullModel>(`/api/systems/${id}`, data).then(response => response.data);
    }

    public systemUnassign(id: number) {
        return this.client.put<SystemFullModel>(`/api/systems/${id}/unassign`, {}).then(response => response.data);
    }

    public systemCreate(data) {
        return this.client.post<SystemFullModel>(`/api/systems`, data).then(response => response.data);
    }

    public projectFetch(id: number) {
        return this.client.get<IProjectModel>(`/api/projects/${id}`).then(response => response.data);
    }

    public projectCollapsedFetch(id: number) {
        return this.client.get<CollapsedProjectModel>(`/api/collapsed_projects/${id}`).then(response => response.data);
    }

    public projectCollectionFetch(query) {
        return this.client.get<CollectionResponseType<IProjectModel>>(`/api/projects`, {params: query}).then(response => response.data);
    }

    public projectCreate(body) {
        return this.client.post<IProjectModel>(`/api/projects`, body).then(response => response.data);
    }

    public projectUpdate(id, body) {
        return this.client.put<IProjectModel>(`/api/projects/${id}`, body).then(response => response.data);
    }

    public login(username: string, password: string) {
        return this._client.post<LoginTokenType>('/api/login_check', {
            username: username,
            password: password
        }, {headers: {"Content-type": 'application/json'}}).then(response => {
            return response.data
        });
    }

    public completeTwoFactor(values, twoFactorRoute: string, twoFactorSecret?) {
        if (twoFactorSecret) {
            values['secret'] = twoFactorSecret;
        }
        return this.client.post<LoginTokenType>(twoFactorRoute, values, {headers: {"Content-type": 'application/json'}}).then(response => response.data);
    }

    public ticketCreateForSystem(body: CreateTicketForSystem) {
        return this.client.post<TicketFullModel>(`/api/create-ticket-for-system`, body).then(response => response.data);
    }

    public ticketCreateForProject(body: CreateTicketForProject) {
        return this.client.post<TicketFullModel>(`/api/create-ticket-for-project`, body).then(response => response.data);
    }

    public expenseCreateForContract(body: CreateExpenseForContractInput) {
        return this.client.post<IExpenseModel>(`/api/expenses-for-contract`, body).then(response => response.data);
    }

    public expenseCreateForTicket(body: CreateExpenseForTicketInput) {
        return this.client.post<IExpenseModel>(`/api/expenses-for-ticket`, body).then(response => response.data);
    }

    public expenseUpdate(iri: Iri, body: ExpenseUpdateInput) {
        return this.client.put<IExpenseModel>(iri, body).then(response => response.data);
    }

    public expenseDelete(iri: Iri) {
        return this.client.delete(iri).then(response => response.data);
    }

    public spentTimeCreate(body: CreateSpentTimeInput) {
        return this.client.post<CreateSpentTimeInput>(`/api/ticket_spent_times`, body).then(response => response.data);
    }

    public realizationFetch(id) {
        return this.client.get<PartRealizationShortModel>(`/api/part_realizations/${id}`).then(response => response.data);
    }

    public markRealizationAsFaulty(iri: Iri) {
        return this.client.put<PartRealizationShortModel>(`${iri}/mark-as-faulty`, {}).then(response => response.data);
    }

    public quoteCreate(body: Partial<IQuoteModel>) {
        return this.client.post<IQuoteModel>('/api/quotes', body).then(response => response.data);
    }

    public quoteConvert(id, startDate = null) {
        return this.client.put<null>(`/api/quotes/${id}/convert`, {startDate: startDate}).then(response => response.data);
    }

    public cloneQuote(quoteIri: Iri) {
        return this.client.post<IQuoteModel>(`/api/quote-clones`, {quote: quoteIri}).then(response => response.data);
    }

    public quoteDecline(id, body: Partial<IQuoteWriteModel>) {
        return this.client.put<IQuoteModel>(`/api/quotes/${id}/decline`, body).then(response => response.data);
    }

    public quoteUpdate(id, body: Partial<IQuoteWriteModel>) {
        return this.client.put<IQuoteModel>(`/api/quotes/${id}`, body).then(response => response.data);
    }

    public quoteRemove(id) {
        return this.client.delete(`/api/quotes/${id}`).then(response => response.data);
    }

    public realizationsCollectionFetch(query) {
        return this.client.get<CollectionResponseType<PartRealizationShortModel>>(`/api/part_realizations`, {params: query}).then(response => response.data);
    }

    public addInventoryNotesToRealization(iri: Iri, data: AddInventoryNotesInput) {
        return this.client.put<PartRealizationShortModel>(`${iri}/add-inventory-notes`, data).then(response => response.data);
    }

    public ticketUpdate(id: number, body: Partial<TicketFullModel>) {
        return this.client.put<TicketFullModel>(`/api/tickets/${id}`, body).then(response => response.data);
    }

    public ticketAttachmentsUpdate(id: number, data: FormData) {
        return this.addAttachment(`/api/tickets/${id}/attachments`, data);
    }

    public systemsAttachmentsUpdate(id: number, data: FormData) {
        return this.addAttachment(`/api/systems/${id}/attachments`, data);
    }

    public customerAttachmentsUpdate(id: number, data: FormData) {
        return this.addAttachment(`/api/customers/${id}/attachments`, data);
    }

    public partAttachmentsUpdate(id: number, data: FormData) {
        return this.addAttachment(`/api/parts/${id}/attachments`, data);
    }

    public opportunityAttachmentsUpdate(id: number, data: FormData) {
        return this.addAttachment(`/api/opportunities/${id}/attachments`, data);
    }

    public quoteAttachmentsUpdate(id: number, data: FormData) {
        return this.addAttachment(`/api/quotes/${id}/attachments`, data);
    }

    public autocomplete<T>(url: string, query) {
        return this.client.get<CollectionResponseType<T>>(url, {params: query}).then(response => response.data);
    }

    public addTicketComment(ticketId: string, body) {
        return this.client.put<null>(`/api/tickets/${ticketId}/comments`, body).then(response => response.data);
    }

    public addOpportunityComment(iri: string, body) {
        return this.client.put<null>(`${iri}/comments`, {
            ...body,
            opportunity: iri
        }).then(response => response.data);
    }

    public addOpportunityQuoteComment(iri: string, body) {
        return this.client.put<void>(`${iri}/comments`, {
            ...body,
            quote: iri
        }).then(response => response.data);
    }

    public attachmentRemove(id: number) {
        return this.client.delete(`/api/attachments/${id}`).then(response => response.data);
    }

    public createAlertForOpportunity(data) {
        return this.client.post<IOpportunityAlertModel>('/api/add-opportunity-alerts', data).then(response => response.data);
    }

    public createAlertForTicket(data) {
        return this.client.post<ITicketAlertModel>('/api/add-ticket-alerts', data).then(response => response.data);
    }

    public appointmentCreate(body: Partial<IAppointmentModel>) {
        return this.client.post<IAppointmentModel>('/api/add-opportunity-appointments', body).then(response => response.data);
    }

    public sendReportToJournal(body: IReportTrackModel) {
        return this.client.post(`/api/activities/track_exported_file`, body).then(response => response.data);
    }

    public appointmentUpdate(id, body: Partial<IAppointmentModel>) {
        return this.client.put<IAppointmentModel>(`/api/appointments/${id}`, body).then(response => response.data);
    }

    public alertRemove(id) {
        return this.client.delete(`/api/alerts/${id}`).then(response => response.data);
    }

    public validateQuoteSystemSerial(serial, customerName) {
        return this.client.get<{ validated: boolean, contractId?: number }>(`/api/validate_quote_system_serial/${serial}/${customerName}`)
            .then(response => response.data);
    }

    public alertUpdate(id, data) {
        return this.client.put<IAlertModel>(`/api/alerts/${id}`, data).then(response => response.data);
    }

    public notificationsUsersListFetch() {
        return this.client.get<CollectionResponseType<IUserModel>>('/api/users', {
            params: {
                'specialization.type': ['admin', 'sales-agent', 'engineer'],
                '_pagination': false
            }
        }).then(response => response.data);
    }

    public loadDataList(group: DataListStoreItemType, query = {}, config?: AxiosRequestConfig) {
        const groupListMapper = (data) => {
            switch (group) {
                case 'sales_agents+admins':
                case 'engineers+admins':
                case 'engineers+sales_agents+admins':
                    return data.reduce(
                        (prev, item) => ({...prev, [item['@id']]: item.username}), {});
                case 'systems':
                    return data.reduce(
                        (prev, item) => ({...prev, [item['serialNumber']]: item['serialNumber']}), {});
                case 'systems_with_iri':
                    return data.reduce(
                        (prev, item) => ({...prev, [item['@id']]: item['serialNumber']}), {});
                case 'sla_times':
                case 'expense_types':
                case 'opportunity_types':
                case 'terms_and_conditions':
                case 'services':
                case 'user_specializations':
                case 'part_families':
                case 'part_series':
                    return data.reduce(
                        (prev, item) => ({...prev, [item['@id']]: item['label']}), {});
                case 'cancellation_reasons':
                    return data.reduce(
                        (prev, item) => ({...prev, [item['@id']]: item['title']}), {});
                case 'model_numbers':
                    return data.reduce((prev, item) => ([...prev, item['modelNumber']]), []);
                case 'invited':
                    return data.reduce((prev, item) => ([...prev, item['invited']]), []);
                case 'manufacturers':
                    return data.reduce((prev, item) => ({...prev, [item['@id']]: item['name']}), {});
                case 'customerNames':
                    return data.reduce((prev, item) => ({...prev, [item['name']]: item['name']}), {});
                case 'part_categories': {
                    let list = {};
                    data.forEach((item) => {
                        let name = item['name'];
                        if (item['parent'] !== null) {
                            name += ' [' + item['parent']['name'] + ']'
                        }
                        list[item['@id']] = name;
                    });
                    return list;
                }
                default:
                    return data.reduce((prev, item) => ({...prev, [item['@id']]: item.name}),
                        {});
            }
        }
        const prepareQuery = (query) => {
            if (group === 'sales_agents+admins') {
                query = {
                    ...query,
                    'enabled':true,
                    'specialization.type': ['admin', 'sales-agent'],
                }
            }
            if (group === 'engineers+sales_agents+admins') {
                query = {
                    ...query,
                    'enabled':true,
                    'specialization.type': ['admin', 'sales-agent', 'engineer'],
                }
            }
            if (group === 'admins') {
                query = {
                    ...query,
                    'enabled':true,
                    'specialization.type': 'admin'
                }
            }
            if (group === 'engineers+admins') {
                query = {
                    ...query,
                    'enabled':true,
                    'specialization.type': ['admin', 'engineer'],
                }
            }
            if (group === 'partners') {
                query = {
                    ...query,
                    type: 'partner'
                }
            }
            if (group === 'part_categories') {
                query = {
                    ...query,
                    '_order[name]': 'asc'
                }
            }
            return query;
        }
        let finalConfig = {
            params: {
                _pagination: false,
                isDraft: false,
                ...prepareQuery(query)
            },
        };
        if (config) {
            finalConfig = {...finalConfig, ...config};
        }
        const detectUri = (group: DataListStoreItemType) => {
            if (group === 'partners') {
                return '/api/customers';
            }
            if (group === 'customerNames') {
                return '/api/customers';
            }
            if (['sales_agents+admins', 'engineers+sales_agents+admins', 'engineers+admins', 'admins'].includes(group)) {
                return '/api/users';
            }
            if (group === 'systems_with_iri') {
                return '/api/systems';
            }
            return '/api/' + group;
        }
        return this.client.get(detectUri(group), finalConfig).then(response => response.data['hydra:member']).then(response => {
            if (_.isArray(response)) {
                return groupListMapper(response);
            } else {
                return [];
            }
        });
    };

    public updateSavedSegments(id, settings): Promise<IFilterSettings> {
        return this.client.put(`/api/users/${id}/update-filter-settings`, settings).then(res => res.data.filterSettings)
    }


    private addAttachment(uri: string, data) {
        return this.client.post<IAttachmentModel[]>(uri, data, {
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(response => response.data);
    }

}

export default ApiClient;
