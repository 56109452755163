import React from "react";
import {observer} from "mobx-react";
import QuoteItemHeader from "./QuoteItemHeader";
import {formatContractType} from "../../../store/enum/contractEnumeration";
import {IQuoteModel} from "../../../utils/models";
import {formatCurrency} from "../OpportunityHelper";

type QuoteItemProps = {
    item: IQuoteModel
}

const QuoteItem = observer(({item}: QuoteItemProps) => {

    return (
        <div className={'quotes-item'}>
            <QuoteItemHeader mode={"LIST"} item={item}/>

            <div className="quotes-item-info">
                <div className="row">
                    <div className="quotes-item-info__title">Type:</div>
                    <div className="quotes-item-info__desc">{formatContractType(item.type)}</div>
                </div>
                <div className="row">
                    <div className="quotes-item-info__title">Total value:</div>
                    <div className="quotes-item-info__desc">{formatCurrency(item.totalValue)}</div>
                </div>
                {item.description && <div className="row">
                    <div className="quotes-item-info__title">Description:</div>
                    <div className="quotes-item-info__desc">{item.description}</div>
                </div>}
                {item.slaTime && <div className="row">
                    <div className="quotes-item-info__title">SLA:</div>
                    <div className="quotes-item-info__desc">{item.slaTime.label}</div>
                </div>}
                <div className="row">
                    <div className="quotes-item-info__title">Components:</div>
                    <div>
                        {item.quotePartInfos.length > 0 ? item.quotePartInfos.map(item => {
                            return (
                                <div key={item["@id"]} className="quotes-item-info__desc">
                                    {item.part.name} ({item.quantity})
                                </div>
                            )
                        }) : null}
                        {item.quoteSystemInfos.length > 0 ? item.quoteSystemInfos.map(item => {
                            return (
                                <div key={item["@id"]} className="quotes-item-info__desc">
                                    {item.modelNumber} (1)
                                </div>
                            )
                        }) : null}
                    </div>
                </div>
            </div>
        </div>
    )
});
export default QuoteItem;
