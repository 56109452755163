import {FORM_ERROR, SubmissionErrors} from "final-form";
import UiBus from "../../store/service/UiBus";
import {isEmpty, isObject, merge} from 'lodash'

export const handleFormSubmit = async <T>(request: Promise<T>): Promise<{ errors: SubmissionErrors | null, response: T | null }> => {
    try {
        const response = await request;
        return {response: response, errors: null};
    } catch (err) {
        if (!err.response || !err.response.data) {
            return {response: null, errors: {[FORM_ERROR]: 'Server side error. Please contact the support.'}};
        }
        const response = {errors: errorProcess(err.response.data), response: null};
        if (response.errors && response.errors[FORM_ERROR]) {
            (new UiBus()).notify(response.errors[FORM_ERROR], "danger");
        }
        return response;
    }
}
export const hydrateInitialValues = <T>(_initialValues: T, _newValues: Partial<T>) => {
    const initialValues = JSON.parse(JSON.stringify(_initialValues));
    const newValues = JSON.parse(JSON.stringify(_newValues));

    // library issue via https://github.com/final-form/react-final-form/issues/130#issuecomment-493447888
    const emptiedData = initialValues ? Object.keys(initialValues).reduce((acc, key) => {
        if (isObject(newValues[key])) {
            acc[key] = hydrateInitialValues(initialValues[key], newValues[key]);
        } else {
            acc[key] = typeof newValues[key] === 'undefined' ? '' : newValues[key];
        }
        return acc;
    }, {}) : {};

    // need to deep merge to get new child properties
    return merge(emptiedData, newValues);
};

function matchStringifiedArrayOfEntities(string: string): { arrayName: string, index: number, itemName: string } | null {
    const match = string.match(/(.+)\[(\d+)]\.(.+)/i);

    return match ? {arrayName: match[1], index: parseInt(match[2]), itemName: match[3]} : null;
}

function errorProcess(responseData) {
    if (responseData.code && responseData.code === 401) {
        return {[FORM_ERROR]: responseData.message};
    }
    let res = {};
    if (responseData && responseData.violations) {
        for (let list of responseData.violations) {
            if (list.propertyPath) {
                const match = matchStringifiedArrayOfEntities(list.propertyPath);
                if (match) {
                    const realArray: Array<{}> = [];
                    if (match.index > 0) {
                        for (let i = 0; i < match.index; i++) {
                            realArray.push({});
                        }
                    }
                    realArray.push({[match.itemName]: list.message});
                    res[match.arrayName] = realArray;
                } else {
                    res[list.propertyPath] = list.message;
                }
            } else {
                res[FORM_ERROR] = list.message;
            }
        }
        if (isEmpty(res)) {
            res[FORM_ERROR] = responseData["hydra:description"];
        }
    } else if (responseData && responseData.error) {
        res[FORM_ERROR] = 'Server side error. Please contact the support.';
    } else if (responseData && responseData['hydra:description']) {
        res[FORM_ERROR] = responseData['hydra:description'];
    }

    return isEmpty(res) ? null : res;
}
