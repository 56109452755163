import React from 'react';
import {Input, Select} from './../../../components/ui';
import {Field, Form} from "react-final-form";
import {FinalButtonSet} from "../../../components/ui/form";
import useDataListFetch from "../../../utils/form/useDataListFetch";

type ResourceFormProps = {
    initialValues, disabled: boolean, onSubmit
}
const ResourceForm = ({initialValues = {}, disabled = false, onSubmit}: ResourceFormProps) => {
    const termsDL = useDataListFetch('terms_and_conditions')

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues} render={({handleSubmit, pristine, submitting}) =>
            <form onSubmit={handleSubmit}>
                <Field
                    label='Label'
                    name='label'
                    component={Input}
                />
                <Field
                    name='termsAndConditions'
                    label='Terms And Conditions'
                    {...termsDL}
                    component={Select}
                />

                {!disabled && <FinalButtonSet submitting={submitting} pristine={pristine} mode={"RELATIVE"}/>}
            </form>}/>

    );
};


export default ResourceForm;
