import React, {useEffect} from 'react';

import {observer} from "mobx-react";
import {Route, Switch, useHistory, useLocation} from "react-router";
import UsersGrid from "./users/UsersGrid";
import SlaTimeGrid from "./slaTime/SlaTimeGrid";
import ServicesGrid from "./services/ServicesGrid";
import HourlyRatesGrid from "./hourlyRates/HourlyRatesGrid";
import PartFamilyGrid from "./partFamilies/PartFamilyGrid";
import PartSeriesGrid from "./partSeries/PartSeriesGrid";
import CancellationReasonsGrid from "./cancellationReasons/CancellationReasonsGrid";
import TicketCategoriesGrid from "./ticketCategories/TicketCategoriesGrid";
import ManufacturersGrid from "./manufacturers/ManufacturersGrid";
import CourierCompaniesGrid from "./courierCompanies/CourierCompaniesGrid";
import ExpenseTypesGrid from "./expenses/ExpenseTypesGrid";
import OpportunityTypesGrid from "./opportunityTypes/OpportunityTypesGrid";
import TermsAndConditionsGrid from "./termsAndConditions/ResourceGrid";

const SettingsPage = observer(({children}) => {
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === '/settings') {
            history.push({pathname: '/settings/users'});
        }
    }, [location.pathname]);

    const configItems = [
        {label: 'Users', path: '/settings/users', component: UsersGrid},
        {label: 'SLA', path: '/settings/sla_times', component: SlaTimeGrid},
        {label: 'Services', path: '/settings/services', component: ServicesGrid},
        {label: 'Hourly Rates', path: '/settings/hourly_rates', component: HourlyRatesGrid},
        {label: 'Part Families', path: '/settings/part_families', component: PartFamilyGrid},
        {label: 'Part Series', path: '/settings/part_series', component: PartSeriesGrid},
        {label: 'Cancellation Reasons', path: '/settings/cancellation_reasons', component: CancellationReasonsGrid},
        {label: 'Ticket categories', path: '/settings/ticket_categories', component: TicketCategoriesGrid},
        {label: 'Opportunity types', path: '/settings/opportunity_types', component: OpportunityTypesGrid},
        {label: 'Manufacturers', path: '/settings/manufacturers', component: ManufacturersGrid},
        {label: 'Courier companies', path: '/settings/courier_companies', component: CourierCompaniesGrid},
        {label: 'Expense types', path: '/settings/expense_types', component: ExpenseTypesGrid},
        {label: 'Terms and conditions', path: '/settings/terms_and_conditions', component: TermsAndConditionsGrid}
    ];
    return <div className='center-form-container settings'>
        <div className='left-panel'>
            <div className="buttons">
                {
                    configItems.map(
                        (item, i) => {
                            const isActiveRoute = location.pathname === item.path;

                            return (
                                <div key={item.path} className={`buttons-item ${isActiveRoute ? 'active' : ''}`}
                                     onClick={() => history.push({pathname: item.path})}>
                                    {item.label}
                                </div>
                            );
                        })
                }
            </div>
        </div>
        <div className='form-wrapper'>
            {children}
            <Switch>
                {configItems.map((item) => <Route key={item.path} path={item.path} component={item.component}/>)
                }
            </Switch>
        </div>

    </div>
});


export default SettingsPage;
