import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import {normalizeSelectData} from '../_utils';

import AutocompleteInput, {AutocompleteInputProps} from './AutocompleteInput';
import NumberInput from './NumberInput';
import VendorSelect from 'react-select';
import ReactSelect from 'react-select';

import {Icon} from '@mdi/react'
import {FieldRenderProps, Field} from "react-final-form";
import ViolationMessage from "../ViolationMessge";
import {Button} from "../index";
import {IApiEntity} from "../../../utils/models";
import TextareaAutosize from 'react-textarea-autosize';
import {mdiToggleSwitchOffOutline, mdiToggleSwitchOutline} from "@mdi/js";
import moment from "moment";

export const formatChoices = (options, formatOptions) => {
    let mappedOptions: any = [{value: '', label: ''}];

    if (options) {
        if (formatOptions) {
            mappedOptions = options.map((val) => {
                const label = formatOptions(val);
                if (!label) {
                    throw Error("Wrong format function!");
                }
                return {
                    value: val,
                    label: label
                }
            });
        } else {
            mappedOptions = Object.entries(options).map((val) => {
                return {
                    value: val[0],
                    label: val[1]
                }
            });
        }
    }
    return mappedOptions;
}
export const StarRating = ({input, meta: {touched, error}, ...props}) => {
    return (
        <div className="ls-form ls-starrating">
            {props.label && <label>{props.label}</label>}
            <StarRatingComponent
                starCount={5}
                value={Number(input.value) || 0}
                onStarClick={
                    (nextValue, prevValue, name) => {
                        input.onChange(nextValue);
                    }
                }
                renderStarIcon={() => (
                    <i className="fa fa-star-o" aria-hidden="true"></i>)}
                starColor={'#F5A623'} /* color of selected icons, default `#ffb400` */
                emptyStarColor={'#c4c4c4'}
                {...props}
            />
            {touched && error && <span>{error}</span>}
        </div>
    );
};
type InputProps = {
    input,
    defaultValue,
    onChange,
    ico,
    canEdit: boolean,
    withIco: boolean,
    label: string,
    hideLabel: boolean,
    disabled: boolean,
    required: boolean,
    placeholder: boolean,
    formatOptions?,
    meta: { touched, error },
}
export const Input = ({input, meta, hideLabel = false, label, formatOptions = undefined, withIco, ico, canEdit = true, ...props}: InputProps) => {
    const {error, submitError} = (meta || {} as any);
    return (
        <div className={'ls-form ls-textinput'}>
            {label ? <label className={props.required ? 'required' : ''} id={'label-for-' + input.name}>
                {label}
            </label> : undefined}
            <div className={'input-field'}>
                {withIco && <Icon path={ico}/>}
                <input
                    className={withIco ? 'withico' : ''}
                    aria-labelledby={'label-for-' + input.name}
                    disabled={props.disabled || !canEdit}
                    type="text"
                    {...input}
                    {...props}
                    defaultValue={props.defaultValue}
                    onChange={(e) => {
                        props.onChange && props.onChange(e.target.value);
                        input.onChange(e.target.value);
                    }}/>
                {(error || submitError) && (<ViolationMessage message={error || submitError}/>)}
            </div>
        </div>
    );
};

export const Textarea = ({autoSize = false, input, meta, canEdit = true, ...props}) => {
    const {error, submitError} = (meta || {} as any);

    return (
        <div className={'ls-form ls-textinput ls-textarea'}>
            {props.label &&
            <label className={props.required ? 'required' : ''} id={'label-for-' + input.name}>{props.label}</label>}

            {autoSize && <TextareaAutosize aria-labelledby={'label-for-' + input.name}
                                           {...input}
                                           {...props}
                                           disabled={props.disabled || !canEdit}
                                           onChange={(e) => {
                                               props.onChange && props.onChange(e.target.value);
                                               input.onChange(e);
                                           }}/>}

            {!autoSize && <textarea
                aria-labelledby={'label-for-' + input.name}
                {...input}
                {...props}
                disabled={props.disabled || !canEdit}
                onChange={(e) => {
                    props.onChange && props.onChange(e.target.value);
                    input.onChange(e);
                }}
            />}

            {(error || submitError) && (<ViolationMessage message={error || submitError}/>)}
        </div>
    );
};

export const RadioGroup = ({input, options, meta, formatOptions = null, ...props}) => {
    const {error, submitError} = (meta || {} as any);
    let data;
    if (formatOptions) {
        data = options;
    } else {
        data = normalizeSelectData(options || []);
    }
    const mappedOptions = formatChoices(data, formatOptions)

    return (
        <div className="ls-form ls-radiogroup">
            {props.label &&
            <label className={props.required ? 'required' : ''} id={'label-for-' + input.name}>{props.label}</label>}
            <div className="ls-radiogroup-wrap">
                {mappedOptions &&
                mappedOptions.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>
                        <input type="radio"
                               aria-labelledby={'label-for-' + input.name}
                               id={item.label + '_' + index}
                               name={input.name}
                               checked={input.value === item.value}
                               onClick={() => input.onChange(item.value)}
                               onChange={() => input.onChange(item.value)}
                               {...props}
                        />
                        <label className={props.color ? props.color : ''}
                               htmlFor={item.label + '_' + index}>{item.label}</label>
                    </div>
                ))
                }
            </div>
            {(error || submitError) && (<ViolationMessage message={error || submitError}/>)}
        </div>
    );
};

export const Checkbox = ({input, meta, ...props}) => {
    const {error, submitError} = (meta || {} as any);

    return (
        <div className="ls-form ls-checkbox">
            <div className='field'>
                <input
                    id={input.name}
                    name={input.name}
                    type='checkbox'
                    onChange={(e) => {
                        props.onChange && props.onChange(e.target.checked);
                        input.onChange(e.target.checked);
                    }}
                    checked={input.value}
                    disabled={props.disabled}
                />
                <label htmlFor={input.name}>{props.label
                    ? props.label
                    : input.name}</label>
            </div>
            {(error || submitError) && (<ViolationMessage message={error || submitError}/>)}
        </div>
    );
};


export const Select = ({input, meta, options, formatOptions = undefined, canEdit = true, menuPlacement = 'bottom', ...props}) => {
    const {error, submitError} = (meta || {} as any);

    const preparedValue = input.value instanceof Object ? input.value['@id'] : input.value;
    const mappedOptions = formatChoices(options, formatOptions);

    return (
        <div className={`ls-form ls-select ${props.disabled || !canEdit ? 'disabled' : ''}`}>
            {props.label &&
            <label className={props.required ? 'required' : ''} id={'label-for-' + input.name}>{props.label}</label>}
            <ReactSelect
                aria-labelledby={'label-for-' + input.name}
                className={"select"}
                options={mappedOptions}
                value={mappedOptions.filter(({value}) => value.toString() === preparedValue.toString())}
                isDisabled={props.disabled || !canEdit}
                menuPlacement={menuPlacement}
                onChange={(e) => {
                    props.onBlur && props.onBlur();
                    props.onChange && props.onChange(e);
                    input.onChange(e ? e.value : null);
                }}
                {...props}
            />
            {(error || submitError) && (<ViolationMessage message={error || submitError}/>)}
        </div>
    );
};


export const MultiSelect = ({input, meta, canEdit = true, ...props}) => {
    const mappedOptions = formatChoices(props.options, props.formatOptions)
    let selectProps = {
        ...input,
        ...meta,
        ...props,
        onBlur: () => input.onBlur(input.value),
        onFocus: () => {
            props.dataLoader && props.dataLoader();
            input.onFocus();
        },
        options: mappedOptions
    };
    const {error, submitError} = (meta || {} as any);

    let mappedValues = [];
    let arrayValues = input.value instanceof Array ? input.value : [input.value];
    if (input.value) {
        arrayValues.forEach((iVal) => {
            if (typeof iVal === 'string') {
                const selectedOption = mappedOptions.find((option) => {
                    return option.value === iVal
                });
                if (selectedOption) {
                    mappedValues.push({value: iVal, label: selectedOption.label});
                } else {
                    mappedValues.push({value: iVal, label: 'Label not found'});
                }
            } else {
                mappedValues.push(iVal);
            }
        });
    }
    return (
        <div className="ls-form ls-select multiselect">
            {props.label && <label className={props.required ? 'required' : ''}>{props.label}</label>}
            <VendorSelect
                {...selectProps}
                isMulti={true}
                isDisabled={props.disabled || !canEdit}
                onChange={(value) => {
                    input.onChange(value ? value.map(item => item.value) : [])
                }}
                menuPlacement="auto"
                value={mappedValues}
            />
            {(error || submitError) && (<ViolationMessage message={error || submitError}/>)}
        </div>
    );
};

export const TimeInput = ({hoursName, minutesName, label, desc = null}) => {
    return (<div className='ls-form ls-timeinput'>
        <label className={'required'}>{label}</label>
        {desc && <p className={'notice'}>{desc}</p>}
        <div className={'timeinput-wrapper'}>
            <Field
                name={hoursName}
                component="input"
                placeholder="hh"
                type={"number"}
                required={true}
            />
            <div>:</div>
            <Field
                name={minutesName}
                component="input"
                placeholder="mm"
                type={"number"}
                required={true}
            />
        </div>
    </div>);
}

// export const TimeInput = ({input, meta, desc, ...props}) => {
//     const TimeInput = require('./../').StandaloneTimeInput;
//     const {error, submitError} = (meta || {} as any);
//
//     return (
//         <div className='ls-form ls-timeinput'>
//             {props.label && <label className={props.required ? 'required' : ''}>{props.label}</label>}
//             {desc && <p className={'notice'}>{desc}</p>}
//             <TimeInput
//                 {...props}
//                 {...input}
//                 value={(input.value ? new Date(input.value) : undefined)}
//                 onChange={(selectedDate) => {
//                     props.onChange && props.onChange(selectedDate);
//                     input.onChange && input.onChange(selectedDate);
//                     props.onBlur && props.onBlur();
//                 }}
//             />
//             {(error || submitError) && (<ViolationMessage message={error || submitError}/>)}
//         </div>
//     );
// };

export const DateInput = ({input, meta, canEdit = true, ...props}) => {
    const DateInput = require('./../').StandaloneDateInput;
    const {error, submitError} = (meta || {} as any);
    return (
        <div className='ls-form ls-dateinput'>
            {props.label && <label className={props.required ? 'required' : ''}>{props.label}</label>}
            <DateInput
                {...props}
                value={input.value ? new Date(input.value) : undefined}
                parseDate={str => new Date(str)}
                minDate={new Date(1990, 0, 0)}
                maxDate={new Date(2040, 0, 0)}
                disabled={!canEdit || props.disabled}
                formatDate={date => moment(date).add(8, 'hours').format('YYYY-MM-DD')}
                onChange={(selectedDate) => {
                    if (canEdit) {
                        props.onChange && props.onChange(selectedDate);
                        input.onChange && input.onChange(selectedDate);
                        props.onBlur && props.onBlur();
                    }
                }}
            />
            {(error || submitError) && (<ViolationMessage message={error || submitError}/>)}

        </div>
    );
};

export interface FinalAutocompleteProps<T> extends AutocompleteInputProps<T>, FieldRenderProps<T, HTMLInputElement> {
    label?: string,
    input,
    canEdit: boolean
}


function FinalAutocomplete<T extends IApiEntity>({input, meta, label = null, scope, additionalQuery = {}, clearOnComplete = undefined, fieldName, onBlur = undefined, onChange = undefined, onComplete, onFocus = undefined, placeholder = undefined, canEdit = true, ...rest}: FinalAutocompleteProps<T>) {
    const {error, submitError} = (meta || {} as any);

    return (
        <div className='ls-form ls-autocomplete'>
            {label && <label className={rest.required ? 'required' : ''}>{label}</label>}
            <AutocompleteInput<T>
                fieldName={fieldName}
                onComplete={item => {
                    onComplete && onComplete(item);
                    input.onChange(item[fieldName ? fieldName : input.name] ? item[fieldName ? fieldName : input.name] : item);
                }}
                input={input}
                scope={scope}
                additionalQuery={additionalQuery}
                clearOnComplete={clearOnComplete}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                placeholder={placeholder}
                canEdit={canEdit}
                {...rest}
            />
            {(error || submitError) && (<ViolationMessage message={error || submitError}/>)}
        </div>
    );
}

export const SwitchToggler = ({input, meta, label, ...props}) => {
    const {error, submitError} = (meta || {} as any);
    return (
        <div className={'switch-toggler'}>
            <label htmlFor={input.name}>
                {label && <p>{label}</p>}
                {!input.value ? <Icon path={mdiToggleSwitchOffOutline}/> :
                    <Icon className={'checked'} path={mdiToggleSwitchOutline}/>}
            </label>
            <input
                id={input.name}
                name={input.name}
                type='checkbox'
                onClick={(e) => {
                    e.stopPropagation();
                    input.onChange(!input.value);
                }}
                checked={input.value}
                disabled={props.disabled}
            />
            {(error || submitError) && (<ViolationMessage message={error || submitError}/>)}
        </div>
    )
}


export type FinalButtonSetMode = 'RELATIVE' | 'FIXED' ;

type FinalButtonSetProps = {
    pristine?: boolean,
    submitting: boolean,
    reset?: any,
    label?: string,
    mode?: FinalButtonSetMode,
}

export function FinalButtonSet({pristine, submitting, reset, label = 'Save', mode = 'RELATIVE'}: FinalButtonSetProps) {
    if (mode === 'RELATIVE') {
        return <React.Fragment>
            <div className="relative-buttons">
                {reset && <Button className={'roundButton secondaryBtn offset'}
                                  onClick={reset}>
                    Reset
                </Button>}
                <Button className={'roundButton tertiaryBtn '} type='submit' disabled={
                    pristine || submitting
                }> {label}
                </Button>
            </div>
        </React.Fragment>
    }
    if (pristine) {
        return null;
    }
    return (<div className={'forms-buttons'}>
        {reset && <Button className={'roundButton secondaryBtn'} onClick={reset}>
            Reset
        </Button>}

        <Button className={'roundButton tertiaryBtn '} type='submit' disabled={
            pristine || submitting
        }> {label}
        </Button>
    </div>)
}

export const SliderInput = ({input, meta: {touched, error}, ...props}) => {
    const Slider = require('@blueprintjs/core').Slider;
    return (
        <div className='ls-form ls-sliderinput'>
            {props.label && <label className={props.required ? 'required' : ''}>{props.label}</label>}
            <Slider
                {...props}
                {...input}
                value={(input.value ? input.value : 0)}
                onChange={(selectedDate) => {
                    props.onChange && props.onChange(selectedDate);
                    input.onChange && input.onChange(selectedDate);
                    props.onBlur && props.onBlur();
                }}
            />
        </div>
    );
};
export {
    NumberInput,
    FinalAutocomplete
};
