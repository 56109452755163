import {Icon} from "@mdi/react";
import {mdiCheck} from "@mdi/js";
import ViolationMessage from "../ViolationMessge";
import React from "react";
import {formatChoices} from "./index";
import UserAvatar from "../UserAvatar";

function resolveAvatarFn({label}) {
    return <React.Fragment><UserAvatar name={label} full={false}/>
        <div className={"name"}>{label}</div>
    </React.Fragment>
}

const SelectButtons = ({input, meta, options, formatOptions = undefined, canEdit = true, isMulti = false, resolveLabel = (option) => resolveAvatarFn(option)}) => {
    const {error, submitError} = (meta || {} as any);

    let preparedValue = null;
    if (!isMulti) {
        preparedValue = input.value instanceof Object ? input.value['@id'] : input.value;
    } else {
        preparedValue = input.value;
    }
    const mappedOptions = formatChoices(options, formatOptions);
    let columnCount;
    if (options.length < 5) {
        columnCount = 1;
    } else if (options.length >= 5 && options.length < 12) {
        columnCount = 2
    } else {
        columnCount = 3;
    }
    return (

        <div style={{columnCount: columnCount}}>
            {mappedOptions.map((option) => {
                let isChecked = false;
                if (!isMulti) {
                    isChecked = preparedValue === option.value
                } else {
                    if (preparedValue instanceof Array) {
                        isChecked = preparedValue.includes(option.value);
                    }
                }
                return (
                    <button
                        type={'button'}
                        key={option.value}
                        onClick={(e) => {
                            if (!isMulti) {
                                input.onChange(option.value);
                            } else {
                                if (option.value) {
                                    if (!(input.value instanceof Array)) {
                                        input.onChange([option.value]);
                                    } else {
                                        if (input.value.includes(option.value)) {
                                            input.onChange(input.value.filter(filterItem => filterItem !== option.value));
                                        } else {
                                            input.onChange(input.value.concat(option.value));
                                        }
                                    }
                                }
                            }
                        }}
                        className={`selectButton ${isChecked ? 'checked' : ''}`}
                    >
                        <div className={"titleWrap"}>
                            {resolveLabel(option)}
                        </div>
                        <div className={"check"}>
                            <Icon path={mdiCheck}/>
                        </div>
                    </button>
                )
            })}
            {(error || submitError) && (<ViolationMessage message={error || submitError}/>)}
        </div>
    );
};
export default SelectButtons;
