import React from 'react';

import {Field, Form} from "react-final-form";
import useDataListFetch from "../../utils/form/useDataListFetch";
import {useStore} from "../../context";
import AutoSave from "../../utils/form/AutoSave";
import {observer} from "mobx-react";
import SelectButtons from "../../components/ui/form/SelectButtons";


const TicketAssignToSelectForm = observer(() => {
    const usersDL = useDataListFetch('engineers+admins')
    const {ticketStore} = useStore();
    return (
        <Form
            onSubmit={ticketStore.assignUser}
            subscription={{}}
            initialValues={{engineer: ticketStore.currentTicket.engineer}}
        >
            {() => (
                <div>
                    <AutoSave save={ticketStore.assignUser} debounce={50}/>
                    <Field
                        name='engineer'
                        {...usersDL}
                        placeholder='Assign To'
                        canEdit={ticketStore.canEdit}
                        component={SelectButtons}
                    />

                </div>
            )}
        </Form>
    );
})


export default TicketAssignToSelectForm;
