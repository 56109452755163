import * as React from 'react';
import {Field, Form} from 'react-final-form'
import {FinalButtonSet, Input, NumberInput, Select} from "../../../components/ui/form";
import {useStore} from "../../../context";
import {observer} from "mobx-react";
import {ContractBankOfHoursFormInterface} from "../../../store/mobx/ContractStore";
import {ContractDetailsFormSharedFieldsSet, TimeMinStepFieldAdapter} from "./ContractDetailsForm";
import {contractPeriodList, formatContractPeriod} from "../../../store/enum/contractEnumeration";


const ContractBankOfHoursForm = observer(({mode}) => {
    const {customerStore} = useStore();

    return <Form<Partial<ContractBankOfHoursFormInterface>> onSubmit={customerStore.updateOrCreateContract}
                                                            initialValues={customerStore.contractStore.currentContract ? customerStore.contractStore.currentContract : {period: 'week'}}>

        {({handleSubmit, pristine, submitting, form}) => (
            <form onSubmit={handleSubmit}>
                <ContractDetailsFormSharedFieldsSet/>
                <Field name="timeMinStep" component={TimeMinStepFieldAdapter}
                       canEdit={customerStore.contractStore.canChangeTimeMinStep}/>
                <Field name='hoursNumber' label='Number of hours' component={NumberInput} step={1}
                       canEdit={customerStore.canEdit}/>
                <Field name='rate'
                       label={"Rate"}
                       component={Input}
                       canEdit={customerStore.canEdit}/>
                <Field name='period'
                       label={"Period"}
                       component={Select}
                       options={contractPeriodList}
                       formatOptions={formatContractPeriod}
                       canEdit={customerStore.canEdit}/>
                       <br/>
                       <br/>
                       <br/>
                       <br/>

                <FinalButtonSet pristine={pristine} reset={form.reset} submitting={submitting} mode={mode}/>
            </form>
        )}
    </Form>
});

export default ContractBankOfHoursForm;
