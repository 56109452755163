const timeStamps = [
    {'today': 'Today'},
    {'last_three_days': 'Last 3 days'},
    {'last_month': 'Last month'},
    {'last_week': 'Last week'},
    {'last_year': 'Last year'}
];

export const createFutureShortCuts = () => {
    return timeStamps;
};
