import React from 'react';

import {Input, Select, Textarea} from '../../components/ui';
import FileGallery from './../../components/ui/widgets/FileGallery';
import {Field, Form} from "react-final-form";
import {FinalButtonSet} from "../../components/ui/form";
import {TicketStore} from "../../store/mobx/TicketStore";
import {observer} from "mobx-react";
import useDataListFetch from "../../utils/form/useDataListFetch";
import {TicketFullModel} from "../../utils/models";
import {formatTicketType, ticketTypeList} from "../../store/enum/ticketEnumeration";

type TicketDetailsProps = {
    initialValues: TicketFullModel,
    ticketStore: TicketStore,
    onSubmit,
    canEdit: boolean
}

const TicketDetailsForm = observer(({initialValues, onSubmit, ticketStore, canEdit}: TicketDetailsProps) => {
    const attachments = ticketStore.fetchAttachments;
    const ticketCategoriesDL = useDataListFetch('ticket_categories');
    const partnersDL = useDataListFetch('partners');

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues}>
            {({handleSubmit, values, pristine, submitting, form}) => {
                return (
                    <form id={"ticket-details-form"} className="entity-details-form"
                          onSubmit={handleSubmit}>
                        <div className="section-block">

                            <div className="field-group">
                                <Field
                                    name='title'
                                    label='Title'
                                    component={Input}
                                    canEdit={canEdit}
                                />
                                <h3>Contacts</h3>
                                <Field
                                    name='requester'
                                    label='Requester'
                                    component={Input}
                                    canEdit={canEdit}
                                />
                                {initialValues.system && <Field
                                    name={"system.serialNumber"}
                                    label={'System'}
                                    canEdit={false}
                                    component={Input}/>}
                                {initialValues.project && <Field
                                    name={"project.name"}
                                    label={'Project'}
                                    canEdit={false}
                                    component={Input}/>}
                                {/*<Field*/}
                                {/*    name='systemSerialNumber'*/}
                                {/*    label='System'*/}
                                {/*    component={FinalAutocomplete}*/}
                                {/*    scope={"systems"}*/}
                                {/*    fieldName={"serialNumber"}*/}
                                {/*    onComplete={(response: SystemFullModel) => {*/}
                                {/*        if (response && typeof response === 'object') {*/}
                                {/*            form.change("system", response['@id'])*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    canEdit={false}*/}
                                {/*/>*/}
                            </div>

                            {values.contract && <div className="field-group">
                                <React.Fragment>
                                    <h3>Info (from contract)</h3>

                                    <Field
                                        name='contract.slaTime.label'
                                        label='SLA'
                                        canEdit={false}
                                        component={Input}
                                    />
                                    <Field
                                        name='contract.customer.name'
                                        label='Customer/Partner'
                                        canEdit={false}
                                        component={Input}
                                    />
                                    <Field
                                        name='contract.customer.onBehalf'
                                        label='On behalf'
                                        canEdit={
                                            (canEdit && (!values.contract.customer.onBehalf || !values.id))
                                        }
                                        {...partnersDL}
                                        component={Select}
                                    />
                                </React.Fragment>
                            </div>
                            }

                        </div>

                        <div className="section-block">
                            <div className="field-group">
                                <h3>Ticket info</h3>
                                <Field
                                    name='category'
                                    label='Category'
                                    {...ticketCategoriesDL}
                                    required={true}
                                    canEdit={canEdit}
                                    component={Select}
                                />
                                <Field
                                    name='type'
                                    label='Type'
                                    required={true}
                                    options={ticketTypeList}
                                    formatOptions={formatTicketType}
                                    canEdit={canEdit}
                                    component={Select}
                                />
                                <Field
                                    name='description'
                                    label='Description'
                                    canEdit={canEdit}
                                    component={Textarea}
                                    autoSize={true}
                                />
                                {attachments.length > 0 && (
                                    <div className='custom-field'>
                                        <label>Attachments: </label>
                                        <FileGallery attachmentInterface={ticketStore}/>
                                    </div>
                                )}
                            </div>
                        </div>
                        <FinalButtonSet pristine={pristine} submitting={submitting} reset={form.reset}
                                        mode={"FIXED"}/>
                    </form>
                )
            }}
        </Form>

    );
});


export default TicketDetailsForm;
