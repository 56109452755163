import BaseLayout from "./containers/layout/BaseLayout";
import Routes from "./routes";
import ForceTwoFactor from "./containers/login/ForceTwoFactor";
import Login from "./containers/login/Login";
import React from "react";
import {useStore} from "./context";
import {observer} from "mobx-react";

import ReactBreakpoints from 'react-breakpoints'
import {breakpointsList} from "./breakpoints";
import {Route, Switch} from "react-router";
import {Impersonate} from "./Impersonate";


export default observer(() => {
    const {authStore} = useStore();
    const forceTwoFactor = () => {
        return authStore.userId && !authStore.twoFactorPasses
    };
    const forceLoginForm = () => {
        return !authStore.userId && !authStore.twoFactorPasses;
    };


    return (
        <div className='app'>
            <Switch>
                <Route path='/_impersonate' component={Impersonate}/>
            </Switch>
            <ReactBreakpoints breakpoints={breakpointsList}>
                {authStore.isAuthenticated &&
                <BaseLayout>
                    <Routes/>
                </BaseLayout>}
            </ReactBreakpoints>
            {forceTwoFactor() && <ForceTwoFactor/>}
            {forceLoginForm() && <Login/>}
        </div>
    )
})
