import React from "react";
import Icon from "@mdi/react";
import {
    mdiAttachment,
    mdiInformation,
    mdiBriefcaseEdit,
    mdiCommentAccountOutline,
    mdiFaceAgent
} from "@mdi/js";
import {ActivityReadModelOutput, ActivityTypeEnum} from "../../generated";
import {UnreachableCaseError} from "ts-essentials";


export const ActivityIcon = ({elem}: { elem: Pick<ActivityReadModelOutput, 'type'> }) => {
    const viewIcon = () => {
        switch (elem.type) {
            case ActivityTypeEnum.SUPPORT_REQUEST_MESSAGE:
                return <Icon path={mdiFaceAgent}/>
            case ActivityTypeEnum.EDIT:
                return <Icon path={mdiBriefcaseEdit}/>
            case ActivityTypeEnum.COMMENT:
                return <Icon path={mdiCommentAccountOutline}/>
            case ActivityTypeEnum.ATTACHMENT:
                return <Icon path={mdiAttachment}/>
            case ActivityTypeEnum.INFO:
                return <Icon path={mdiInformation}/>
            default:
                throw new UnreachableCaseError(elem.type)
        }
    }
    return viewIcon();
}
