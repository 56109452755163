import React, {useState} from "react";
import {QuoteProjectInfoModel} from "../../../utils/models";
import {FieldArrayRenderProps} from "react-final-form-arrays";
import {Field} from "react-final-form";
import {Input} from "../../../components/ui/form";
import {RemoveComponentButton} from "./OpportunityQuoteDetails";
import {Icon} from "@mdi/react";
import {mdiFolderEditOutline} from "@mdi/js";
import StandalonePopover from "../../../components/ui/StandalonePopover";
import QuoteProjectTimeEntry from "./QuoteProjectTimeEntry";
import {ContractTypeListType} from "../../../store/enum/contractEnumeration";

type QuoteProjectInfoTableProps = {
    values: Partial<QuoteProjectInfoModel>[],
    canEdit: boolean,
    quoteType: ContractTypeListType,
    fieldArrayProps: FieldArrayRenderProps<Partial<QuoteProjectInfoModel>, any>,
}

const QuoteProjectInfoTable = ({
                                   values,
                                   canEdit,
                                   quoteType,
                                   fieldArrayProps
                               }: QuoteProjectInfoTableProps) => {

    const [contextMenuCoordinates, setContextMenuCoordinates] = useState<{ x, y } | null>(null);
    return <div className={'components-table'}>
        <div className={'components-table-row heading'}>
            <div>
                Name
            </div>
            <div>
                {quoteType === 'pay_as_you_go' && 'Time Entries'}
            </div>
            <div>
                Price
            </div>
            <div>
            </div>
        </div>
        {fieldArrayProps.fields.map((name, index) => {
            const item = values[index];

            return <div key={`project-info-` + item["@id"]} className={'components-table-row'}>
                <div>
                    <Field name={`${name}.name`}
                           component={Input}
                           canEdit={canEdit}/>
                </div>
                <div className={'other-info'}>
                    {quoteType === 'pay_as_you_go' && <>
                        <span>Total: {item.totalHours}h {item.totalMinutes}m </span>
                        <span className="browse"
                              onClick={(e) => {
                                  e.stopPropagation();
                                  setContextMenuCoordinates({
                                      x: e.clientX + 20,
                                      y: e.clientY + 60
                                  })
                              }}><Icon path={mdiFolderEditOutline}/></span>
                    </>}
                </div>
                <div>
                    <Field name={`${name}.price`}
                           component={Input}
                           canEdit={canEdit}/>
                </div>
                <RemoveComponentButton fieldArrayProps={fieldArrayProps} index={index}/>
                {contextMenuCoordinates &&
                <StandalonePopover title={null}
                                   closePopover={() => setContextMenuCoordinates(null)}
                                   size={'sd'}
                                   darken={false}
                                   position={contextMenuCoordinates}
                                   direction={"TOP_RIGHT"}>
                    <QuoteProjectTimeEntry
                        fields={fieldArrayProps}
                        index={index}
                        item={item}
                    />
                </StandalonePopover>}
            </div>
        })}
    </div>
};

export default QuoteProjectInfoTable
