import React from 'react';
import ResourceGridView from './../../../components/grid/ResourceGridView';
import {useStore} from "../../../context";
import StandalonePopover from "../../../components/ui/StandalonePopover";
import ResourceUpdate from "./ServiceUpdate";
import ResourceCreate from "./ServiceCreate";
import {observer} from "mobx-react";

const ResourceGrid = observer(() => {
    const {genericResourceStore, authStore} = useStore();

    const canEdit = authStore.isGranted('ROLE_ADMIN') || authStore.isGranted('ROLE_ALL_ACCOUNT');

    genericResourceStore.configure('/api/services', 'Service')


    return (
        <div className='dashboard'>
            {genericResourceStore.place === 'create' &&
            <StandalonePopover title={'Create'} closePopover={() => genericResourceStore.changePlace('grid')}>
                <ResourceCreate sharedGridResourceCollectionStore={genericResourceStore} canEdit={canEdit}/>
            </StandalonePopover>}
            {genericResourceStore.place === 'update' &&
            <StandalonePopover title={`${genericResourceStore.label} #${genericResourceStore.currentResourceId} update`}
                               closePopover={() => genericResourceStore.changePlace('grid')}>
                <ResourceUpdate canRemove={canEdit} sharedGridResourceCollectionStore={genericResourceStore}
                                canEdit={canEdit}/>
            </StandalonePopover>}
            <ResourceGridView<any>
                columns={[
                    {
                        name: 'id',
                        value: (item) => item['@id'].split('/').reverse()[0],
                    },
                    {
                        name: 'label',
                        value: (item) => item.label,
                    },
                ]}
                onRowClick={(item) => {
                    genericResourceStore.changePlace('update', item)
                }}


                onCreate={() => genericResourceStore.changePlace('create')}
                gridResource={genericResourceStore}
            />
        </div>
    );
})


export default ResourceGrid;
