import React from 'react';


const SwitcherBox = ({input, label, options}) => {
    return (
        <div className={'ls-form ls-picker'}>
            {label && <label>{label}</label>}

           <div className={'ls-picker__list'}>
               {
                   options && options.map((item) => (
                       <div key={item}
                            onClick={() => {

                                if(input.value === item.value) {
                                    input.onChange('')
                                } else {
                                    input.onChange(item.value)
                                }
                            }}
                            className={`${input.value === item.value ? 'secondaryBtn tertiaryBtn' : 'secondaryBtn'}`}>
                           {item.label}
                       </div>
                   ))
               }
           </div>
        </div>
    );
};

export default SwitcherBox