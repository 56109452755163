import React from 'react';

import {Field, Form} from "react-final-form";
import {FinalButtonSet} from "../../components/ui/form";
import useDataListFetch from "../../utils/form/useDataListFetch";
import {observer} from "mobx-react";
import {useStore} from "../../context";
import SelectButtons from "../../components/ui/form/SelectButtons";


const TicketInvitedSelectForm = observer(() => {
    const usersDL = useDataListFetch('engineers+admins')
    const {ticketStore} = useStore()
    return (
        <Form
            onSubmit={ticketStore.invite}
            initialValues={{invited: ticketStore.currentTicket.invited}}
        >
            {({handleSubmit, pristine, submitting, form}) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        name='invited'
                        {...usersDL}
                        placeholder='Invited'
                        component={SelectButtons}
                        isMulti={true}
                    />
                    <FinalButtonSet pristine={pristine} submitting={submitting}
                                    mode={"RELATIVE"}/>
                </form>
            )}
        </Form>
    );
})


export default TicketInvitedSelectForm;
