import {configure} from "mobx";
import {UiStore} from './UiStore'
import {CustomerStore} from "./CustomerStore";
import ApiClient from "../ApiClient";
import {TicketStore} from "./TicketStore";
import {AuthStore} from "./AuthStore";
import ReportDownloader from "../service/ReportDownloader";
import {ContractStore} from "./ContractStore";
import {GenericResourceStore} from "./GenericResourceStore";
import {SalesStore} from "./SalesStore";
import {SystemStore} from "./SystemStore";
import {PartStore} from "./PartStore";
import {OpportunityStore} from "./OpportunityStore";
import {PurchaseStore} from "./PurchaseStore";
import {ContractRProfitStore} from "./ContractRProfitStore";

import {RouterStore} from 'mobx-react-router';
import UiBus from "../service/UiBus";
import {ProjectStore} from "./ProjectStore";
import {OpportunityQuoteStore} from "./OpportunityQuoteStore";
import {NotificationStore} from "./NotificationStore";
import {RealizationStore} from "./RealizationStore";
import {FilterStore} from "./FilterStore";
import {SupportRequestStore} from "./SupportRequestStore";

if (process.env.NODE_ENV !== 'production') {
    configure({enforceActions: "observed"})
}
export default class RootStore {
    uiStore: UiStore;
    customerStore: CustomerStore;
    ticketStore: TicketStore;
    authStore: AuthStore;
    contractStore: ContractStore;
    router: AuthStore;
    salesStore: SalesStore;
    systemStore: SystemStore;
    genericResourceStore: GenericResourceStore;
    partStore: PartStore;
    realizationStore: RealizationStore;
    opportunityStore: OpportunityStore;
    notificationStore: NotificationStore;
    opportunityQuoteStore: OpportunityQuoteStore;
    purchaseStore: PurchaseStore;
    contractRProfitStore: ContractRProfitStore;
    projectStore: ProjectStore;
    routing: RouterStore;
    filterStore: FilterStore;
    supportRequestStore: SupportRequestStore;
    _apiClient: ApiClient;

    constructor(apiClient: ApiClient, routerStore: RouterStore, uiBus: UiBus) {
        this._apiClient = apiClient;
        const reportDownloader = new ReportDownloader(this._apiClient);

        this.routing = routerStore;
        this.genericResourceStore = new GenericResourceStore(apiClient, uiBus);
        this.uiStore = new UiStore();
        this.authStore = new AuthStore(apiClient, localStorage.getItem('token'));
        this.filterStore = new FilterStore(apiClient, this.authStore, routerStore, uiBus);
        this.notificationStore = new NotificationStore(apiClient, this.authStore, uiBus, routerStore);
        this.systemStore = new SystemStore(apiClient, uiBus);
        this.ticketStore = new TicketStore(apiClient, this.authStore, reportDownloader, this.routing, uiBus, this.filterStore);
        this.customerStore = new CustomerStore(apiClient, this.authStore, uiBus, this.routing);
        this.contractStore = new ContractStore(apiClient, uiBus, this.routing);
        this.salesStore = new SalesStore(apiClient, reportDownloader);
        this.realizationStore = new RealizationStore(apiClient, uiBus);
        this.partStore = new PartStore(apiClient, this.authStore, uiBus, routerStore);
        this.opportunityStore = new OpportunityStore(apiClient, this.authStore, reportDownloader, uiBus, routerStore, this.filterStore);
        this.purchaseStore = new PurchaseStore(apiClient, this.routing, uiBus);
        this.contractRProfitStore = new ContractRProfitStore(apiClient, reportDownloader);
        this.projectStore = new ProjectStore(apiClient, uiBus);
        this.opportunityQuoteStore = new OpportunityQuoteStore(apiClient, this.authStore, uiBus, routerStore);
        this.supportRequestStore = new SupportRequestStore(apiClient, uiBus);
    }
}
