import React, {useState} from 'react';
import {Input, Select, Textarea} from '../../components/ui';
import {Field, FieldRenderProps, Form, FormRenderProps} from "react-final-form";
import {FinalAutocomplete, FinalButtonSet} from "../../components/ui/form";
import FileGallery from "../../components/ui/widgets/FileGallery";
import {FileAttachmentsInterface} from "../../store/interfaces/FileAttachmentsInterface";
import {observer} from "mobx-react";
import {useStore} from "../../context";
import useDataListFetch from "../../utils/form/useDataListFetch";
import {CollapsedSystemModel, SystemFullModel} from "../../utils/models";

type SystemCreateFormProps = {
    onSubmit,
    initialValues,
    attachmentInterface?: FileAttachmentsInterface,
}

const ModelNumberAdapter = ({
                                input,
                                meta,
                                form: {change},
                                canEdit,
                                setRelatedPartId
                            }: FieldRenderProps<SystemFullModel, any> & FormRenderProps & { canEdit }) => {
    return <FinalAutocomplete
        canEdit={canEdit}
        fieldName={"modelNumber"}
        required={true}
        onComplete={(response) => {
            if (response && response['@id']) {
                change("modelNumber", response.modelNumber);
                change("manufacturer", response.manufacturer);
                setRelatedPartId(response['@id'])
            }
        }}
        scope={"parts"}
        additionalQuery={{'is_stock': true, 'type': 'system'}}
        input={input}
        meta={meta}
        label={'Model number'}
        placeholder='Model number'
    />
};

const SerialNumberAdapter = ({
                                 input,
                                 meta,
                                 form: {change},
                                 canEdit,
                                 changeId
                             }: FieldRenderProps<SystemFullModel, any> & FormRenderProps & { canEdit }) => {
    return <FinalAutocomplete
        input={input}
        canEdit={canEdit}
        required={true}
        onComplete={(response) => {
            if (response) {
                if (response['@id']) {
                    change("serialNumber", response.serialNumber);
                    change("modelNumber", response.modelNumber);
                    change("name", response.name);
                    change("manufacturer", response.manufacturer);
                    change("location", response.location);
                    change("address", response.address);
                    change("addressNotes", response.addressNotes);
                    if (response.location) {
                        change("location.notes", response.location.notes)
                    }
                    if (response.slaTime) {
                        change("slaTime", response.slaTime['@id'])
                    }
                    change("notes", response.notes);
                    changeId(response['@id'].split('/').reverse()[0]);
                } else {
                    changeId(null);
                }
            } else {
                changeId(null);
            }
        }}
        fieldName={'serialNumber'}
        additionalQuery={{'is_null_contract': true}}
        scope={"systems"}
        label={'Serial number'}
        placeholder='Serial number'
        meta={meta} />
};

const SystemCreateForm = observer(({
                                 onSubmit,
                                 initialValues,
                                 attachmentInterface,
                             }: SystemCreateFormProps) => {
    const {authStore} = useStore();
    const manufacturersDL = useDataListFetch('manufacturers');
    const slaTimeDL = useDataListFetch('sla_times');
    const [relatedPartId, setRelatedPartId] = useState(null);
    const [id, setId] = useState(null);

    return (
        <Form<SystemFullModel | CollapsedSystemModel>
            onSubmit={(values) => {
                if (relatedPartId) {
                    values['relatedPart'] = relatedPartId;
                }
                if (id) {
                    values['id'] = id;
                }
                onSubmit(values)
            }}
            initialValues={initialValues}
            render={({handleSubmit, pristine, submitting, form}) =>
                <form onSubmit={handleSubmit}>
                    <Field
                        name='modelNumber'
                        component={ModelNumberAdapter}
                        required
                        canEdit={id === null}
                        form={form}
                        setRelatedPartId={setRelatedPartId}
                    />

                    <Field
                        name='manufacturer'
                        label='Manufacturer'
                        placeholder='Select ...'
                        component={Select}
                        canEdit={false}
                        {...manufacturersDL}
                    />

                    <Field
                        name='serialNumber'
                        component={SerialNumberAdapter}
                        required
                        canEdit={true}
                        form={form}
                        changeId={setId}
                    />

                    <Field name={'name'}
                           label={'Name'}
                           component={Input}
                           canEdit={id===null}
                    />

                    <Field
                        name='address'
                        label='Address'
                        component={Input}
                        canEdit={id===null}
                    />

                    <Field
                        name='addressNotes'
                        label='Address notes'
                        rows={5}
                        component={Textarea}
                        canEdit={id===null}
                    />

                    <Field
                        name='slaTime'
                        label='SLA'
                        clearable={false}
                        {...slaTimeDL}
                        component={Select}
                        required
                        canEdit={id===null}
                    />

                    {!authStore.isCustomerOrPartner &&
                    <Field
                        name='notes'
                        label='Notes'
                        rows={5}
                        canEdit={id===null}
                        component={Textarea}
                    />}
                    {attachmentInterface && attachmentInterface.fetchAttachments.length > 0 && (
                        <div className='custom-field'>
                            <label>Attachments: </label>
                            <FileGallery
                                attachmentInterface={attachmentInterface}/>
                        </div>
                    )}

                    <FinalButtonSet pristine={pristine}
                                    submitting={submitting} mode={'RELATIVE'}
                                    reset={form.reset}/>
                </form>}
        />
    );
});


export default SystemCreateForm;
