import React from 'react';
import {Input, Select} from './../../../components/ui';
import {Field, Form} from "react-final-form";
import {FinalButtonSet, SwitchToggler} from "../../../components/ui/form";
import useDataListFetch from "../../../utils/form/useDataListFetch";

type ResourceFormProps = {
    initialValues, disabled: boolean, onSubmit
}
const ResourceForm = ({initialValues = {}, disabled = false, onSubmit}: ResourceFormProps) => {
    const userSpecializationsDL = useDataListFetch('user_specializations')

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues} render={({handleSubmit, pristine, submitting}) =>
            <form onSubmit={handleSubmit}>
                <Field
                    name='enabled'
                    label='Active'
                    component={SwitchToggler}
                />

                <Field
                    name='username'
                    label='Name'
                    autoComplete='username'
                    component={Input}
                />
                <Field
                    name='email'
                    label='Email'
                    component={Input}
                />
                <Field
                    name='plainPassword'
                    label='Password'
                    type='password'
                    component={Input}
                />
                <Field
                    name='specialization'
                    label='Specialization'
                    component={Select}
                    {...userSpecializationsDL}
                />

                {!disabled && <FinalButtonSet submitting={submitting} pristine={pristine} mode={"RELATIVE"}/>}
            </form>}/>

    );
};


export default ResourceForm;
