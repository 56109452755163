import React from 'react';
import {Input} from './../../../components/ui';
import {Field, Form} from "react-final-form";
import {FinalButtonSet} from "../../../components/ui/form";

type ResourceFormProps = {
    initialValues, disabled: boolean, onSubmit
}
const ResourceForm = ({initialValues = {}, disabled = false, onSubmit}: ResourceFormProps) => {

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues} render={({handleSubmit, pristine, submitting}) =>
            <form onSubmit={handleSubmit}>
                <Field
                    name='name'
                    label='Name'
                    component={Input}
                />

                {!disabled && <FinalButtonSet submitting={submitting} pristine={pristine} mode={"RELATIVE"}/>}
            </form>}/>

    );
};


export default ResourceForm;
