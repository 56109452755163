import {useLocation} from "react-router";
import {parse} from "querystring";

export function useQuery() {
    const location = useLocation();
    if (!location.search) {
        return {};
    }
    return parse(location.search.slice(1))
}

const inflate = (query, parent = {}) => {
    let res = parent;
    let keys = Object.keys(query);

    keys.forEach(key => {
        if (key.indexOf('.') >= 0) {
            let newKeys = key.split('.');
            let newKey = newKeys[0];
            let nextKey = newKeys.slice(1).join('.');

            res = {
                ...res,
                [newKey]: {
                    ...res[newKey],
                    ...inflate({[nextKey]: query[key]}, res[newKey])
                }
            }
        } else {
            res = {
                ...res,
                [key]: query[key]
            }
        }
    });

    return res;
};

export default inflate;