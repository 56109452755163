import React from "react";
import {observer} from "mobx-react";
import {useStore} from "../../context";
import {Field, Form} from "react-final-form";
import {Intent, Spinner} from "@blueprintjs/core";
import {Button, Select} from "../../components/ui";

export const SupportRequestSelectProjectForm = observer(() => {
    const {supportRequestStore} = useStore();
    const currentList = supportRequestStore.currentProjectsList;
    const initialRelatedProjectId = supportRequestStore.current.relatedProject ? `/api/projects/${supportRequestStore.current.relatedProject.id}` : '';
    if (currentList?.state !== 'fulfilled')
        return <Spinner size={20} intent={Intent.PRIMARY}/>
    return (<Form
        onSubmit={supportRequestStore.selectProject}
        initialValues={{relatedProject: initialRelatedProjectId}}
    >
        {({handleSubmit, pristine}) => {
            return (<div className={'status-form'}>
                <form onSubmit={handleSubmit}>
                    <Field
                        name={'relatedProject'}
                        component={Select}
                        options={Object.fromEntries(currentList?.value.map(item => {
                            return [item['@id'], item.name];
                        }))}
                        isSearchable={true}
                        required
                    />
                    <Button disabled={pristine} className={'roundButton tertiaryBtn '} type='submit'>Save</Button>
                </form>
            </div>)
        }}

    </Form>)
});

