import React, {useState} from "react";
import {Field, Form} from "react-final-form";
import {Checkbox} from "../../../components/ui/form";
import moment from "moment";
import {FieldArray} from 'react-final-form-arrays'
import {Button, NumberInput} from "../../../components/ui";
import arrayMutators from 'final-form-arrays'
import {ContractStore} from "../../../store/mobx/ContractStore";

type ContractHistoryListType = {
    contractStore: ContractStore
}

const ContractTicketHistoryListForm = ({contractStore}: ContractHistoryListType) => {
    const [touchedFields, setTouchedFields] = useState({});

    return <Form
        onSubmit={contractStore.saveExceededTicketsData}
        mutators={{
            // potentially other mutators could be merged here
            ...arrayMutators
        }}
        initialValues={contractStore.currentContract}
        render={({values, handleSubmit, pristine}) => (
            <form onSubmit={handleSubmit}>
                <FieldArray name={"exceededTickets"}>
                    {({fields}) => (
                        <div>
                            {Object.keys(contractStore.currentContract.ticketsPerPeriodWithExceeded)
                                .sort((a, b) => moment(a).diff(moment(b)))
                                .map(period => {
                                    return (<div key={period} className="contract-history-item">
                                        <div>
                                            <p className="pre-text">Tickets list from {period} period
                                                without exceeded</p>
                                            <br/>
                                            <br/>
                                        </div>
                                        {contractStore.currentContract.ticketsPerPeriodWithExceeded[period].tickets
                                            .filter(ticket =>
                                                contractStore.currentContract.exceededTickets
                                                    .find(exceeded => exceeded.ticket["@id"] === ticket.ticket["@id"]) === undefined)
                                            .map(ticket => {
                                                return (<div key={ticket.ticket["@id"]}
                                                             className={'contract-history-item__heading'}>
                                                    <p className="contract-history-item__title">
                                                        {moment(ticket.ticket.createdAt).format("LLLL")} -
                                                        ticket "{ticket.ticket.title}"
                                                    </p>
                                                </div>);
                                            })}
                                    </div>)
                                })}
                            {fields.map((name, index) => {
                                    const item = contractStore.currentContract;
                                    const excT = item.exceededTickets[index];
                                    const status = excT.additionalValue || excT.takeFromNextPeriod;

                                    return (<div key={excT["@id"]} className="contract-history-item">
                                        <div className={'contract-history-item__heading'}>
                                            <p className="contract-history-item__title">
                                                {moment(excT.ticket.createdAt).format("LLLL")} -
                                                ticket "{excT.ticket.title}"
                                            </p>
                                            <div className={status ? 'true' : 'false'}>{status ? 'True' : 'False'}</div>
                                        </div>

                                        {!status && <div className={'boxes'}>
                                            <div className="boxes-item">
                                                <p className="boxes-item__title">Outstanding settlement</p>
                                                <Field name={`${name}.additionalValue`}
                                                       component={NumberInput}
                                                       disabled={touchedFields[name] && touchedFields[name] === 'from_next'}
                                                       onChange={(val) => {
                                                           if (val) {
                                                               setTouchedFields({...touchedFields, ...{[name]: 'additional'}})
                                                           } else {
                                                               setTouchedFields({...touchedFields, ...{[name]: null}})
                                                           }
                                                       }}
                                                />
                                            </div>
                                            {item.period !== 'all' && <span>or</span>}
                                            {item.period !== 'all' && <div className="boxes-item">
                                                <p className="boxes-item__title">Take from next period</p>
                                                <Field name={`${name}.takeFromNextPeriod`}
                                                       component={Checkbox}
                                                       disabled={touchedFields[name] && touchedFields[name] === 'additional'}
                                                       type={'checkbox'}
                                                       onChange={(val) => {
                                                           if (val) {
                                                               setTouchedFields({...touchedFields, ...{[name]: 'from_next'}})
                                                           } else {
                                                               setTouchedFields({...touchedFields, ...{[name]: null}})
                                                           }
                                                       }}
                                                       label={'Take from next period'}/>
                                            </div>}
                                        </div>}
                                        {Number(excT.additionalValue) !== 0 && <div>
                                            <p className={'pre-text'}>Outstanding settlement:</p>
                                            <p>£{excT.additionalValue}</p>
                                        </div>}
                                        {excT.takeFromNextPeriod && <div>
                                            <p className={'pre-text'}>Taken from next period: </p>
                                            <p>From {moment(excT.nextPeriodStartDate).format("LLLL")} to {moment(excT.nextPeriodEndDate).format("LLLL")}</p>
                                        </div>}
                                    </div>);
                                }
                            )}
                            <div className={"relative-buttons"}>
                                {!pristine && <Button color={"blue"}>
                                    Save exceeded tickets data
                                </Button>}
                            </div>

                        </div>
                    )}
                </FieldArray>
            </form>
        )}/>
};

export default ContractTicketHistoryListForm;
