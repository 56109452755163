import * as React from 'react';
import {Field, Form} from "react-final-form";
import {observer} from "mobx-react";
import {useStore} from "../../context";
import {FinalButtonSet, Input, Select, Textarea} from "../../components/ui/form";
import useDataListFetch from "../../utils/form/useDataListFetch";
import {formatTicketType, ticketTypeList} from "../../store/enum/ticketEnumeration";


const CreateTicketForm = observer(() => {
    const {customerStore} = useStore();
    const ticketCategoriesDataList = useDataListFetch("ticket_categories")
    return <Form onSubmit={customerStore.createTicket}
                 render={
                     ({pristine, submitting, handleSubmit, form}) =>
                         <form onSubmit={handleSubmit}>

                             <Field
                                 name='title'
                                 label='Title'
                                 component={Input}
                                 required={true}
                             />

                             <Field
                                 name='category'
                                 label='Category'
                                 {...ticketCategoriesDataList}
                                 required={true}
                                 component={Select}
                             />

                             <Field
                                 name='type'
                                 label='Type'
                                 required={true}
                                 options={ticketTypeList}
                                 formatOptions={formatTicketType}
                                 component={Select}
                             />

                             <Field
                                 name='description'
                                 label='Description'
                                 component={Textarea}
                             />

                             <FinalButtonSet pristine={pristine} submitting={submitting}/>
                         </form>
                 }/>
});

export default CreateTicketForm;
