import {Field, Form} from "react-final-form";
import React from "react";
import {observer} from "mobx-react";
import {useStore} from "../../context";
import {
    formatTicketStatus,
    manuallySettingTicketStatusList
} from "../../store/enum/ticketEnumeration";
import AutoSave from "../../utils/form/AutoSave";
import SelectButtons from "../../components/ui/form/SelectButtons";
import {StatusColorBlock} from "../../components/ui/widgets/TopInfoBlock";

const TicketSetStatusForm = observer(() => {
    const {ticketStore} = useStore();
    return (<Form
        onSubmit={ticketStore.updateTicketStatus}
        initialValues={{status: ticketStore.currentTicket.status}}
    >
        {() => (
            <div className={'status-form'}>
                <AutoSave save={ticketStore.updateTicketStatus}/>
                <Field name='status'

                       component={SelectButtons}
                       resolveLabel={({value, label}) => {
                           return <StatusColorBlock resolvedStatus={label} status={value}/>

                       }} options={manuallySettingTicketStatusList}
                       formatOptions={formatTicketStatus}
                />
            </div>
        )}

    </Form>)
});

export default TicketSetStatusForm
