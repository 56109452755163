import * as React from 'react';
import CountUp from 'react-countup';
import {Icon} from '@mdi/react'
import {SummaryColor} from "../../../store/mobx/ContractRProfitStore";


export interface ISummaryProps {
    label,
    value: number,
    format?: (value) => string;
    icon,
    color: SummaryColor
}


export const SummaryWidget = ({...props}: ISummaryProps) => {
    return (
        <div className={`summary-widget summary-widget--${props.color}`}>
            <div className="summary-widget__icon">
                <Icon path={props.icon}/>
            </div>
            <div className="summary-widget-info">
                <p className={`summary-widget__label`}>{props.label}</p>
                <p className={`summary-widget__value`}>
                    <CountUp
                        start={0}
                        end={Number(props.value)}
                        duration={2}
                        formattingFn={props.format}
                    />
                </p>
            </div>
        </div>
    );
}
