import {Route, Switch} from "react-router";
import React from "react";

import TicketsGrid from "./containers/tickets/TicketsGrid";
import TicketDetails from "./containers/tickets/TicketDetails";
import CustomersGrid from "./containers/customers/CustomersGrid";
import CustomerPage from "./containers/customers/CustomerPage";
import ContractsGrid from "./containers/contracts/ContractsGrid";
import SystemsGrid from "./containers/systems/SystemsGrid";
import PartsGrid from "./containers/parts/PartsGrid";
import PurchasesGrid from "./containers/purchases/PurchasesGrid";
import SuppliersGrid from "./containers/suppliers/ResourceGrid";
import OpportunityGrid from "./containers/opportunities/OpportunityGrid";
import SalesGrid from "./containers/sales/SalesGrid";
import ContractProfitsGrid from "./containers/contractProfits/ContractProfitsGrid";

import Logout from "./containers/login/Logout";
import NotFound from "./containers/layout/NotFound";
import PartDetails from "./containers/parts/PartDetails";
import OpportunityDetails from "./containers/opportunities/OpportunityDetails";
import ProjectGrid from "./containers/projects/ProjectGrid";
import OpportunityQuoteDetails from "./containers/opportunities/Quote/OpportunityQuoteDetails";
import SettingsPage from "./containers/settings";
import InventoryGrid from "./containers/parts/InventoryGrid";
import SupportRequestGrid from "./containers/support_requests/SupportRequestGrid";
import SupportRequestItem from "./containers/support_requests/SupportRequestItem";

const routes = () => {
    return (<Switch>
        <Route path='/' exact component={TicketsGrid}/>
        <Route path='/index' component={TicketsGrid}/>
        <Route exact path='/tickets' component={TicketsGrid}/>
        <Route path='/tickets/:ticketId' component={TicketDetails}/>
        <Route path='/support_requests' component={SupportRequestGrid} exact={true}/>
        <Route path='/support_requests/:requestId' component={SupportRequestItem}/>
        <Route exact path='/customers' component={CustomersGrid}/>
        <Route path='/customers/:customerId' component={CustomerPage}/>
        <Route path='/contracts' component={ContractsGrid}/>
        <Route path='/systems' component={SystemsGrid}/>
        <Route exact path='/products' component={PartsGrid}/>
        <Route exact path='/inventory' component={InventoryGrid}/>
        <Route
            path='/products/:partId'
            render={(props) => <PartDetails {...props} catalogueMode={true}/>}
        />
        <Route
            path='/inventory/:partId'
            render={(props) => <PartDetails {...props} catalogueMode={false}/>}
        />
        <Route path='/purchases' component={PurchasesGrid}/>
        <Route path='/suppliers' component={SuppliersGrid}/>
        <Route exact path='/opportunities' component={OpportunityGrid}/>
        <Route exact path='/opportunities/:opportunityId' component={OpportunityDetails}/>
        <Route exact path='/opportunities/:opportunityId/quotes/:quoteId' component={OpportunityQuoteDetails}/>
        <Route path='/sales' component={SalesGrid}/>
        <Route path='/projects' component={ProjectGrid}/>

        <Route path='/contract_profits' component={ContractProfitsGrid}/>

        {/*SETTINGS COMPONENTS*/}

        <Route path='/settings' component={SettingsPage}/>


        {/*SYSTEM COMPONENTS*/}
        <Route path='/logout' component={Logout}/>
        <Route component={NotFound}/>
    </Switch>)
}

export default routes;
