import React, {Component} from 'react';
import classnames from 'classnames';

class Timer extends Component<{ targetDate: Date, updateOffset: number, workMode: 'reverse' | 'straight' }, { timeLeft }> {

    private timer;

    constructor(props) {
        super(props);

        this.state = {
            timeLeft: this.calcTimeLeft(),
        };
    }

    render() {
        const classname = classnames({
            timer: true,
            red: (this.state.timeLeft <= .5 * 3600),
            orange: (this.state.timeLeft > .5 && this.state.timeLeft <= 2 * 3600),
            green: (this.state.timeLeft > 2 * 3600),
        });

        return (<span className={classname}>{this.getFormattedTime(
            this.state.timeLeft)}</span>);
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            this.setState({
                timeLeft: this.calcTimeLeft(),
            });
        }, this.props.updateOffset);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.targetDate.getTime() !== this.props.targetDate.getTime()) {
            this.setState({
                timeLeft: this.calcTimeLeft(),
            });
        }
    }

    componentWillUnmount() {
        this.timer && clearInterval(this.timer);
    }

    calcTimeLeft() {
        const now = new Date().getTime();
        const target = this.props.targetDate.getTime();

        return this.props.workMode === 'reverse'
            ? Number((Math.max(target - now, 0) / 1000).toFixed())
            : Number((Math.max(now - target, 0) / 1000).toFixed());
    }

    getFormattedTime(timeLeft) {
        let seconds = timeLeft;
        const days = ((seconds - seconds % (3600 * 24)) / (3600 * 24));
        seconds -= days * (3600 * 24);
        const hours = ((seconds - seconds % 3600) / 3600);
        seconds -= hours * 3600;
        const minutes = ((seconds - seconds % 60) / 60);
        seconds -= minutes * 60;

        return (days > 9 ? '' : '0') + days + ' : '
            + (hours > 9 ? '' : '0') + hours + ' : '
            + (minutes > 9 ? '' : '0') + minutes + ' : '
            + (seconds > 9 ? '' : '0') + seconds;
    }
}

export default Timer;
