import React from 'react';
import {observer} from "mobx-react";
import {useStore} from "../../context";
import {Icon} from "@mdi/react";
import moment from 'moment';
import {mdiCheck, mdiTrashCanOutline} from "@mdi/js";
import {formatNotification} from "../../store/formatters";
import {Loader} from "../../components/ui";

const NotificationsList = observer(() => {
    const {notificationStore} = useStore();

    return (
        <div className={'messages'}>

            {notificationStore.countUnreadMessages > 0 &&
            <div className={'read-all'} onClick={() => notificationStore.readAllMessage()}>Mark all as read</div>}

            <div className="notification-list">
                {notificationStore.isLoading && <Loader/>}
                {notificationStore.notifications.map((item) => (
                    formatNotification(item) && <div key={item.id} className={'messages-item'}
                                                     onClick={(e) => {
                                                         e.stopPropagation();
                                                         notificationStore.readMessage(item);
                                                     }}>
                        <div className={'messages-item__heading'} onClick={(e) => {
                            notificationStore.handleNotificationTouch(item);
                        }}>
                            <span className={'messages-item__title'}>{formatNotification(item).title} </span>
                            <span className={'messages-item__action'}>{formatNotification(item).notes}</span>
                            <div className={'messages-item__controller'}>
                            <span onClick={(e) => {
                                e.stopPropagation();
                                notificationStore.handleNotificationRemove(item);
                            }} className={'messages-item__remove'}>
                                <Icon path={mdiTrashCanOutline}/>
                            </span>

                                {!item.isRead &&
                                <div className={'messages-item__new'}>
                                    New
                                    <span className={'messages-item__new-overlay'}> <Icon path={mdiCheck}/> </span>
                                </div>}
                            </div>
                        </div>

                        <div className={'time-ago'}>
                            {moment(item.notificationDate).format('LLL')}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default NotificationsList
