import React from 'react';
import {Field, Form} from 'react-final-form'
import {Input, Select} from './../../../components/ui';
import {FinalButtonSet} from "../../../components/ui/form";
import useDataListFetch from "../../../utils/form/useDataListFetch";
import {formatPartServiceType, partServiceTypeList} from "../../../store/enum/partEnumeration";

type PartFamilyFormProps = { initialValues, onSubmit }
const PartFamilyForm = ({initialValues = {}, onSubmit}: PartFamilyFormProps) => {
    const manufacturerDL = useDataListFetch('manufacturers')

    return (
        <Form
            onSubmit={(values) => {
                if (values['manufacturer'] && values['manufacturer']['@id']) {
                    values['manufacturer'] = values['manufacturer']['@id'];
                }
                onSubmit(values)
            }}
            initialValues={initialValues}
        >
            {({handleSubmit, pristine, submitting}) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        name='label'
                        label='Name'
                        component={Input}
                    />
                    <Field
                        name='manufacturer'
                        label='Manufacturer'
                        canEdit={!initialValues.id}
                        clearable={false}
                        {...manufacturerDL}
                        component={Select}
                    />
                    <Field
                        name='serviceType'
                        label='Service Type'
                        component={Select}
                        options={partServiceTypeList}
                        formatOptions={formatPartServiceType}
                    />
                    <FinalButtonSet submitting={submitting} pristine={pristine} mode={"RELATIVE"}/>
                </form>
            )}
        </Form>
    )
}


export default PartFamilyForm;
