import React from 'react';
import {Field, Form} from 'react-final-form'
import {DateInput, Input, Textarea} from './../../components/ui';
import {FinalButtonSet, TimeInput} from "../../components/ui/form";
import {observer} from "mobx-react";
import {useStore} from "../../context";

const OpportunityAppointmentForm = observer(() => {
    const {opportunityStore} = useStore();
    return (
        <Form
            onSubmit={opportunityStore.mutateAppointment}
            initialValues={opportunityStore.currentOpportunity.appointment}
        >
            {({handleSubmit, pristine, submitting}) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        label='Title'
                        name='title'
                        component={Input}
                    />
                    <Field
                        label='Date'
                        name='dateAndTimeFrom.date'
                        placeholder='Select date'
                        component={DateInput}
                    />
                    <div className='custom-grid'>
                        <div className='cell-6'>
                            <TimeInput hoursName={'dateAndTimeFrom.hours'}
                                       minutesName={'dateAndTimeFrom.minutes'}
                                       label={'Time From'}/>
                        </div>
                        <div className='cell-6'>
                            <TimeInput hoursName={'hoursTo'}
                                       minutesName={'minutesTo'}
                                       label={'Time To'}/>
                        </div>
                    </div>
                    <Field
                        label='Notes'
                        name='notes'
                        component={Textarea}
                    />

                    <FinalButtonSet submitting={submitting} pristine={pristine} mode={"RELATIVE"}/>
                </form>
            )}
        </Form>
    )
})


export default OpportunityAppointmentForm;
