import React, {useState} from 'react';
import {Input, Select, Textarea} from '../../components/ui';
import {Field, FieldRenderProps, Form, FormRenderProps} from "react-final-form";
import {FinalAutocomplete, FinalButtonSet, FinalButtonSetMode} from "../../components/ui/form";
import FileGallery from "../../components/ui/widgets/FileGallery";
import {FileAttachmentsInterface} from "../../store/interfaces/FileAttachmentsInterface";
import {observer} from "mobx-react";
import {useStore} from "../../context";
import useDataListFetch from "../../utils/form/useDataListFetch";
import {CollapsedSystemModel, SystemFullModel} from "../../utils/models";

type SystemFormProps = {
    canEdit: boolean,
    canEditModelNumber: boolean,
    onSubmit,
    initialValues,
    mode: FinalButtonSetMode,
    attachmentInterface?: FileAttachmentsInterface,
    collapsed?
}


const ModelNumberAdapter = ({
                                input,
                                meta,
                                form: {change},
                                canEdit,
                                setRelatedPartId,
                                setModelNumberHasChanged
                            }: FieldRenderProps<SystemFullModel, any> & FormRenderProps & { canEdit }) => {
    return <FinalAutocomplete
        canEdit={canEdit}
        fieldName={"modelNumber"}
        required={true}
        onComplete={(response) => {
            if (response) {
                if (response['@id']) {
                    change("modelNumber", response.modelNumber);
                    change("manufacturer", response.manufacturer);
                    setRelatedPartId(response['@id'])
                } else {
                    change("modelNumber", response);
                    setRelatedPartId(null)
                }
                setModelNumberHasChanged(true)
            }
        }}
        scope={"parts"}
        additionalQuery={{'is_stock': true, 'type': 'system'}}
        input={input}
        meta={meta}
        label={'Model number'}
        placeholder='Model number'
    />
};

const SerialNumberAdapter = ({
                                 input,
                                 meta,
                                 form: {change},
                                 canEdit,
                                 changeId,
                                 setModelNumberHasChanged
                             }: FieldRenderProps<SystemFullModel, any> & FormRenderProps & { canEdit }) => {
    return <FinalAutocomplete
        canEdit={canEdit}
        fieldName={"serialNumber"}
        required={true}
        onComplete={(response) => {
            if (response) {
                if (response['@id']) {
                    change("serialNumber", response.serialNumber);
                    change("modelNumber", response.modelNumber);
                    change("manufacturer", response.manufacturer);
                    change("location", response.location);
                    if (response.location) {
                        change("location.notes", response.location.notes)
                    }
                    if (response.slaTime) {
                        change("slaTime", response.slaTime['@id'])
                    }
                    change("notes", response.notes);
                    changeId(response['@id'].split('/').reverse()[0]);
                    setModelNumberHasChanged(true)
                } else {
                    changeId(null);
                }
            } else {
                changeId(null);
            }
        }}
        scope={"systems"}
        additionalQuery={{'is_null_contract': true}}
        input={input}
        meta={meta}
        label={'Serial number'}
        placeholder='Serial number'
    />
};

const SystemForm = observer(({
                                 canEdit,
                                 canEditModelNumber,
                                 onSubmit,
                                 initialValues,
                                 attachmentInterface,
                                 mode
                             }: SystemFormProps) => {
    const {authStore} = useStore();
    const slaTimeDL = useDataListFetch('sla_times');
    const [id, setId] = useState(null);
    const [relatedPartId, setRelatedPartId] = useState(null);
    const [modelNumberHasChanged, setModelNumberHasChanged] = useState(false);

    return (
        <Form<SystemFullModel | CollapsedSystemModel>
            onSubmit={(values) => {
                if (relatedPartId !== null) {
                    values['relatedPart'] = relatedPartId;
                } else if (values['relatedPart'] !== null && typeof values['relatedPart'] === 'object') {
                    if (modelNumberHasChanged === true) {
                        values['relatedPart'] = null;
                    } else {
                        values['relatedPart'] = values['relatedPart']['@id'];
                    }
                }
                if (id) {
                    values['id'] = id;
                }
                onSubmit(values)
            }}
            initialValues={initialValues}
            render={({handleSubmit, pristine, submitting, form, values}) => {
                return (<form onSubmit={handleSubmit}>
                    {values['relatedPart'] && <Field
                        name='relatedPart.modelNumber'
                        component={ModelNumberAdapter}
                        label={'Model number'}
                        required
                        canEdit={canEditModelNumber}
                        setRelatedPartId={setRelatedPartId}
                        setModelNumberHasChanged={setModelNumberHasChanged}
                        form={form}
                    />}

                    {!values['relatedPart'] && <Field
                        name='modelNumber'
                        component={ModelNumberAdapter}
                        label={'Model number'}
                        required
                        setRelatedPartId={setRelatedPartId}
                        setModelNumberHasChanged={setModelNumberHasChanged}
                        canEdit={canEditModelNumber}
                        form={form}
                    />}

                    {values['relatedPart'] && <Field
                        name='relatedPart.manufacturer.name'
                        label='Manufacturer'
                        component={Input}
                        canEdit={false}
                    />}

                    <Field
                        name='serialNumber'
                        component={SerialNumberAdapter}
                        required
                        canEdit={canEdit}
                        form={form}
                        changeId={setId}
                        setModelNumberHasChanged={setModelNumberHasChanged}
                    />

                    <Field name={'name'}
                           label={'Name'}
                           component={Input}
                           canEdit={canEdit}
                    />

                    <Field
                        name='address'
                        label='Address'
                        component={Input}
                        canEdit={canEdit}
                    />

                    <Field
                        name='addressNotes'
                        label='Address notes'
                        canEdit={canEdit}
                        rows={mode === 'RELATIVE' ? 5 : 20}
                        component={Textarea}
                    />

                    <Field
                        name='slaTime'
                        label='SLA'
                        clearable={false}
                        {...slaTimeDL}
                        component={Select}
                        canEdit={canEdit}
                        required
                    />

                    {!authStore.isCustomerOrPartner &&
                    <Field
                        name='notes'
                        label='Notes'
                        canEdit={canEdit}
                        rows={mode === 'RELATIVE' ? 5 : 20}
                        component={Textarea}
                    />}
                    {attachmentInterface && attachmentInterface.fetchAttachments.length > 0 && (
                        <div className='custom-field'>
                            <label>Attachments: </label>
                            <FileGallery
                                attachmentInterface={attachmentInterface}/>
                        </div>
                    )}

                    {canEdit &&
                    <FinalButtonSet pristine={pristine}
                                    submitting={submitting} mode={mode}
                                    reset={form.reset}/>}
                </form>)
            }
            }
        />
    );
});


export default SystemForm;
