import * as React from 'react';
import {useState} from 'react';
import moment from 'moment';

import ResourceGridView, {GridColumnType} from "../../components/grid/ResourceGridView";
import {SummaryWidget} from '../../components/ui/widgets/SummaryWidget';
import {ContractRProfitStore} from "../../store/mobx/ContractRProfitStore";
import ContractProfitCostForm from "./ContractProfitCostForm";
import StandalonePopover from "../../components/ui/StandalonePopover";
import ContractProfitDetails from "./ContractProfitDetails";
import {Link} from "react-router-dom";

import {Icon} from '@mdi/react';
import {
    mdiCheckboxBlankOutline,
    mdiCheckboxMarked,
    mdiPlus,
    mdiToggleSwitchOffOutline,
    mdiToggleSwitchOutline
} from '@mdi/js'
import {observer} from "mobx-react";
import {useStore} from "../../context";
import {useQuery} from "../../utils/query";
import {IContractRProfitModel, ISnippetModel} from "../../utils/models";


type IState = {
    selectedContracts: Array<IContractRProfitModel>,
    allSelected: boolean,
    ignoreIsOn: boolean,
    filters: Object,
    contractSharedCost: {
        active: boolean,
        item?: IContractRProfitModel
    }
    contractProfitDetails: {
        active: boolean,
        item?: IContractRProfitModel
    }

}


const ContractProfitsGrid = observer(() => {
    const {authStore, contractRProfitStore} = useStore();
    const [state, setState] = useState<IState>({
        filters: {},
        selectedContracts: [],
        allSelected: false,
        ignoreIsOn: false,
        contractSharedCost: {
            active: false
        },
        contractProfitDetails: {
            active: false
        }
    })

    const query = useQuery();
    const toggleItem = (toggleItem: IContractRProfitModel, toggle = true) => {
        let selectedContracts = toggle
            ? [...state.selectedContracts, toggleItem]
            : state.selectedContracts.filter(item => item.id !== toggleItem.id);

        setState({
            ...state,
            selectedContracts: selectedContracts
        });

        if (state.ignoreIsOn) {
            submitIgnored(true, selectedContracts);
        }
    };

    const toggleItems = (allItems: Array<IContractRProfitModel>, allSelected: boolean) => {
        let selectedContracts = allSelected ? allItems : [];
        setState({
            ...state,
            allSelected: allSelected,
            selectedContracts: selectedContracts
        });
        if (state.ignoreIsOn) {
            submitIgnored(true, selectedContracts);
        }
    };

    const isToggledItem = (item: IContractRProfitModel) => {
        return !!state.selectedContracts.find(contract => contract.id === item.id)
    }

    const getClassnameForToggle = (toggled: boolean) => {
        let className = 'toggle-btn';
        if (toggled) {
            className += ' selected';
        }
        if (state.ignoreIsOn) {
            className += ' red';
        }
        return className;
    }

    const gridColumns = (): GridColumnType<IContractRProfitModel>[] => {
        let columns: GridColumnType<IContractRProfitModel>[] = [
            {
                name: 'bulk',
                label: (items) => {
                    return (<span
                        onClick={() => toggleItems(items, !state.allSelected)}
                        className={getClassnameForToggle(state.allSelected)}
                    >
                        {!state.allSelected ? <Icon path={mdiCheckboxBlankOutline}/> :
                            <Icon path={mdiCheckboxMarked}/>}
                        </span>)
                },
                value: (item) => {
                    return (<span
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleItem(item, !isToggledItem(item))
                        }}
                        className={getClassnameForToggle(isToggledItem(item))}>
                        {!isToggledItem(item) ? <Icon path={mdiCheckboxBlankOutline}/> :
                            <Icon path={mdiCheckboxMarked}/>}
                    </span>)
                }
            },
            {
                name: 'partnerName',
                label: 'Company name',
                value: (item) => (
                    <div>
                        <div className="title">{item.customerName}</div>
                        <div className="other-info">
                            {item.partnerName}
                            {item.contractNumber ? <span className={'dot'}>•</span> : ''}
                            <Link
                                onClick={e => e.stopPropagation()}
                                to={`/customers/${item.customerId}?active=/api/contracts/${item.id}`}>
                                {item.contractNumber}
                            </Link>
                            {item.customerPO ? <span className={'dot'}>•</span> : ''}
                            {item.customerPO}
                        </div>
                    </div>
                )
            },
            {
                name: 'totalValue',
                label: 'Total value',
                value: (item) => item.totalValue ? parseFloat(item.totalValue).toFixed(2) : ''
            },
            {
                name: 'startDate',
                label: 'Start date',
                value: (item) => moment(item.startDate).format('YYYY MMM DD')
            },
            {
                name: 'expiryDate',
                label: 'Expire date',
                value: (item) => item.expiryDate ? moment(item.expiryDate).format('YYYY MMM DD') : 'PAYG'
            },
            {
                name: 'salesAgent',
                label: 'Sales agent',
                value: (item) => item.salesAgent
            },
        ];

        if (authStore.isGranted('ROLE_ADMIN') || authStore.isGranted('ROLE_ALL_ACCOUNT')) {
            columns.push({
                name: '',
                label: '',
                value: (item) => {
                    return (
                        <div
                            className={'secondaryBtn'}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                setState({
                                    ...state,
                                    contractSharedCost: {
                                        active: true,
                                        item: item
                                    }
                                })
                            }}>
                            <Icon path={mdiPlus}/>
                            Set shared cost
                        </div>
                    );
                }
            });
        }

        return columns;
    }

    const submitIgnored = (doIgnore: boolean, items: Array<IContractRProfitModel> = []) => {
        setState({
            ...state,
            ignoreIsOn: doIgnore
        });

        let contracts = items.length > 0 ? items : state.selectedContracts;

        fetchSummaries(doIgnore, contracts);
    };


    const fetchSummaries = (ignore?: boolean, contracts?: Array<IContractRProfitModel>): void => {
        if (typeof ignore === 'undefined') {
            ignore = state.ignoreIsOn;
        }
        let filters = state.filters;
        if (ignore) {
            if (typeof contracts === 'undefined') {
                contracts = state.selectedContracts;
            }

            filters = {
                ...state.filters,
                ignoreIds: contracts.map((item) => {
                    return item.id
                }).join(',')
            }
        }
        contractRProfitStore.fetchSummaries(filters);

    }

    const renderSummaryRow = (item: ISnippetModel, index: number) => {
        return (
            <div key={index} className='dashboard__summary-widget'>
                <SummaryWidget {...ContractRProfitStore.getSummaryOptions(item)}/>
            </div>
        )
    };

    return (
        <div className='dashboard profits'>
            {!contractRProfitStore.isSummariesLoading && (
                <div className='dashboard__summaries'>
                    {contractRProfitStore.summaries.map(renderSummaryRow)}
                </div>
            )}
            {state.contractSharedCost.active &&
            <StandalonePopover
                title={"Set shared cost"}
                closePopover={() => {
                    setState({...state, contractSharedCost: {active: false}})
                }
                }>
                <ContractProfitCostForm
                    handleSubmit={async (data) => {
                        const errors = await contractRProfitStore.updateSharedCost(
                            state.contractSharedCost.item.id,
                            data,
                            query
                        );
                        if (!errors) {
                            setState({...state, contractSharedCost: {active: false, item: data}});
                        } else {
                            return errors;
                        }
                    }}
                    initialValues={state.contractSharedCost.item}
                />
            </StandalonePopover>}
            {state.contractProfitDetails.active &&
            <StandalonePopover
                title={"Profit details"}
                size={"ld"}
                closePopover={() => {
                    setState({...state, contractProfitDetails: {active: false}})
                }
                }
                classNames={'profits'}>
                <ContractProfitDetails id={state.contractProfitDetails.item.id}/>
            </StandalonePopover>}
            <ResourceGridView<IContractRProfitModel>
                filterKeys={{
                    salesAgent: 'Sales agent',
                    contractNumber: 'Contract number',
                    rate: 'Rate',
                    status: 'Status',
                    customer: 'Company',
                    partner: 'Partner',
                    startDate: 'Start date',
                    endDate: 'Expire date'
                }}
                gridResource={contractRProfitStore}
                columns={gridColumns()}
                onRowClick={(item) => {
                    setState({
                        ...state,
                        contractProfitDetails: {
                            active: true,
                            item: item
                        }
                    })
                }}
                filtersUpdateClb={(filters) => {
                    setState({...state, filters: filters});
                    fetchSummaries(filters)
                }}
                panels={() => [{
                    label: 'Ignore selected',
                    icon: state.ignoreIsOn ? mdiToggleSwitchOutline : mdiToggleSwitchOffOutline,
                    visible: true,
                    classes: 'ignoredBtn',
                    onClick: () => {
                        submitIgnored(!state.ignoreIsOn)
                    }
                }]}
                tableClass={'profit'}
            />
        </div>
    );

})


export default ContractProfitsGrid;
