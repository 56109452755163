import React, {useState} from 'react';
import {Icon} from "@mdi/react";
import {mdiHistory} from "@mdi/js";
import ContractTicketHistoryListForm from "./ContractTicketHistoryListForm";
import StandalonePopover from "../../../components/ui/StandalonePopover";
import {observer} from "mobx-react";
import {ContractStore} from "../../../store/mobx/ContractStore";
import {Link} from "react-router-dom";
import moment from "moment";
import ContractHoursHistoryListForm from "./ContractHoursHistoryListForm";

type ContractExceededHistoryProps = {
    contractStore: ContractStore
}

const ContractExceededHistory = observer(({contractStore}: ContractExceededHistoryProps) => {
    const [periodTab, setPeriodTab] = useState('current');

    function countLeftPercentForWeek() {
        const fullK = 7;
        const today = moment(moment().format("YYYYMMDD"));
        const startDate = moment(moment(contractStore.currentContract.currentPeriodStartDate).format("YYYYMMDD"));
        const diff = today.diff(startDate, "days") + 1;
        return String(Math.ceil(diff * 100 / fullK)) + "%";
    }

    function countLeftPercentForMonth() {
        const fullK = 30;
        const today = moment(moment().format("YYYYMMDD"));
        const startDate = moment(moment(contractStore.currentContract.currentPeriodStartDate).format("YYYYMMDD"));
        const diff = today.diff(startDate, "days") + 1;
        return String(Math.ceil(diff * 100 / fullK)) + "%";
    }

    function countLeftPercentForQuarter() {
        const fullK = 16;
        const today = moment(moment().format("YYYYMMDD"));
        const startDate = moment(moment(contractStore.currentContract.currentPeriodStartDate).format("YYYYMMDD"));
        const diff = today.diff(startDate, "weeks") + 1;
        return String(Math.ceil(diff * 100 / fullK)) + "%";
    }

    function countLeftPercentForYear() {
        const fullK = 12;
        const today = moment(moment().format("YYYYMMDD"));
        const startDate = moment(moment(contractStore.currentContract.currentPeriodStartDate).format("YYYYMMDD"));
        const diff = today.diff(startDate, "months") + 1;
        return String(Math.ceil(diff * 100 / fullK)) + "%";
    }

    function countLeftPercentForAll() {
        const today = moment(moment().format("YYYYMMDD"));
        const startDate = moment(moment(contractStore.currentContract.currentPeriodStartDate).format("YYYYMMDD"));
        const diff = today.diff(startDate, "months") + 1;
        const endDate = moment(moment(contractStore.currentContract.currentPeriodEndDate).format("YYYYMMDD"));
        const fullK = endDate.diff(startDate, "months") + 1;
        return String(Math.ceil(diff * 100 / fullK)) + "%";
    }

    function getLeftPercent() {
        if (contractStore.currentContract.period === 'week') {
            return countLeftPercentForWeek();
        }
        if (contractStore.currentContract.period === 'month') {
            return countLeftPercentForMonth();
        }
        if (contractStore.currentContract.period === 'quarter') {
            return countLeftPercentForQuarter();
        }
        if (contractStore.currentContract.period === 'year') {
            return countLeftPercentForYear();
        }
        if (contractStore.currentContract.period === 'all') {
            return countLeftPercentForAll();
        }
    }

    function renderCurrentTickets() {
        const contract = contractStore.currentContract;
        return (<div>
            <ul className="period-list">
                <li className="period-list__item">
                    Remaining tickets number: {contract.remainingTicketsNumberForCurrentPeriod > 0
                    ? contract.remainingTicketsNumberForCurrentPeriod
                    : 0}
                </li>
                {contract.currentPeriodExceededTickets
                    .filter(item => {
                        return !item.takeFromNextPeriod
                            || (moment(item.nextPeriodStartDate).isBefore(moment.now()) && moment(item.nextPeriodEndDate).isAfter(moment.now()))
                    })
                    .map((item) => {
                        return (<li key={item["@id"]} className="period-list__item">
                            {moment(item.ticket.createdAt).format("LLLL")} - Added outstanding
                            ticket <Link to={`/tickets/${item.ticket.id}`}>"{item.ticket.title}"</Link>
                            {item.takeFromNextPeriod && <p className={'red'}> (taken from
                                period {moment(item.nextPeriodStartDate).format("L")} - {moment(item.nextPeriodEndDate).format("L")})</p>}
                        </li>)
                    })}
            </ul>
        </div>);
    }

    function renderCurrentHours() {
        return (<ul className="period-list">
            <li className="period-list__item">
                Remaining time: {Math.floor(contractStore.currentContract.remainingMinutesNumberForCurrentPeriod / 60) + 'h '
                + Math.round(contractStore.currentContract.remainingMinutesNumberForCurrentPeriod % 60) + 'm'}</li>
            {contractStore.currentContract.currentPeriodExceededHours
                .filter(item => {
                    return !item.takeFromNextPeriod
                        || (moment(item.nextPeriodStartDate).isBefore(moment.now()) && moment(item.nextPeriodEndDate).isAfter(moment.now()))
                })
                .map((item) => {
                    return (<li key={item["@id"]} className="period-list__item">
                        Outstanding {Math.floor(item.minutesNumber / 60)}h {Math.round(item.minutesNumber % 60)}m
                        {item.takeFromNextPeriod && <p className={'red'}> (taken from
                            period {moment(item.nextPeriodStartDate).format("L")} - {moment(item.nextPeriodEndDate).format("L")})</p>}
                    </li>)
                })}
        </ul>);
    }

    function renderNextTickets() {
        return (<ul className="period-list">
            {contractStore.currentContract.nextPeriodExceededTickets
                .map((item, key) => {
                    return (<li key={item["@id"]} className="period-list__item">
                        {moment(item.ticket.createdAt).format("LLLL")} - Added outstanding
                        ticket <Link to={`/tickets/${item.ticket.id}`}>"{item.ticket.title}"</Link>
                        <p className={'red'}> (taken from
                            period {moment(item.nextPeriodStartDate).format("L")} - {moment(item.nextPeriodEndDate).format("L")})</p>
                    </li>)
                })}
        </ul>);
    }


    function renderNextHours() {
        return (<ul className="period-list">
            {contractStore.currentContract.nextPeriodExceededHours
                .map((item, key) => {
                    return (<li key={item["@id"]} className="period-list__item">
                        Outstanding {Math.floor(item.minutesNumber / 60)}h {Math.round(item.minutesNumber % 60)}m
                        <p className={'red'}> (taken from
                            period {moment(item.nextPeriodStartDate).format("L")} - {moment(item.nextPeriodEndDate).format("L")})</p>
                    </li>)
                })}
        </ul>);
    }

    const renderHistory = contractStore.currentContract && contractStore.currentContract.ticketsPerPeriodWithExceeded;

    return (
        <div>
            {contractStore.contextPlace === 'history' && contractStore.currentContract && contractStore.currentContract.type === 'bank_of_tickets' &&
            <StandalonePopover title={'Contract profit details history'}
                               closePopover={contractStore.clearContextPlace}
                               size={'md'}
                               darken={false}
                               classNames={'contract-history'}>
                <ContractTicketHistoryListForm contractStore={contractStore}/>
            </StandalonePopover>}

            {contractStore.contextPlace === 'history' && contractStore.currentContract && contractStore.currentContract.type === 'bank_of_hours' &&
            <StandalonePopover title={'Contract profit details history'}
                               closePopover={contractStore.clearContextPlace}
                               size={'md'}
                               darken={false}
                               classNames={'contract-history'}>
                <ContractHoursHistoryListForm contractStore={contractStore}/>
            </StandalonePopover>}

            {contractStore.currentContract && <div className="progress-bar">
                <div className="progress-bar-date">
                    <div className="progress-bar-date__item start">
                        {moment(contractStore.currentContract.currentPeriodStartDate).format("MMM Do YY")}
                    </div>
                    <div className="progress-bar-date__item quarter">
                        {contractStore.currentContract.period}
                    </div>
                    <div className="progress-bar-date__item end">
                        {moment(contractStore.currentContract.currentPeriodEndDate).format("MMM Do YY")}
                    </div>
                </div>
                <div className="progress-bar-scale">
                    <span className="progress-bar-scale__done" style={{left: getLeftPercent()}}>
                    </span>
                </div>
            </div>}

            {contractStore.currentContract && <div className="period">
                <div className="period-head">
                    <div className="period-buttons">
                        <button
                            className={periodTab === 'current' ? 'active' : ''}
                            type={'button'}
                            onClick={() => {
                                setPeriodTab('current')
                            }}>
                            Current period
                        </button>

                        {contractStore.currentContract.period !== 'all' && <button
                            className={periodTab !== 'current' ? 'active' : ''}
                            type={'button'}
                            onClick={() => {
                                setPeriodTab('next')
                            }}>
                            Next period
                        </button>}
                    </div>
                    {renderHistory && <div className="period-reset">
                        <button
                            type={'button'}
                            onClick={() => {
                                contractStore.clearContextPlace();
                                contractStore.changeContextPlace('history')
                            }}
                            className={'secondaryBtn'}>
                            <Icon path={mdiHistory}/>
                            History
                        </button>
                    </div>}
                </div>

                {periodTab === 'current'
                && contractStore.currentContract.type === 'bank_of_tickets'
                && renderCurrentTickets()}

                {periodTab === 'current'
                && contractStore.currentContract.type === 'bank_of_hours'
                && renderCurrentHours()}

                {periodTab === 'next'
                && contractStore.currentContract.type === 'bank_of_tickets'
                && renderNextTickets()}

                {periodTab === 'next'
                && contractStore.currentContract.type === 'bank_of_hours'
                && renderNextHours()}
            </div>}
        </div>);
});

export default ContractExceededHistory
