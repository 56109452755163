import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {useStore} from "../../../context";
import {useHistory} from "react-router";
import {stringify} from "querystring";
import {formatCustomerContractName} from "../../../store/formatters";
import {
    ContractWrapper,
    CustomerStore,
    PartWrapper,
    ProjectWrapper,
    SystemWrapper
} from "../../../store/mobx/CustomerStore";
import {ContractDuration} from "../../../components/ui/contractNavigation/ContractDuration";
import {CheckboxToggleList, Select} from "../../../components/ui";
import cn from 'classnames';
import {Field, Form} from "react-final-form";
import AutoSave from "../../../utils/form/AutoSave";
import Icon from "@mdi/react";
import {mdiMinus, mdiPlus} from "@mdi/js";

type ProjectItemProps = {
    projectWrapper: ProjectWrapper,
    projectIndex: number,
    children?,
}
type SystemItemProps = {
    systemWrapper: SystemWrapper,
    systemIndex: number,
    children?,
}

type PartItemProps = {
    partWrapper: PartWrapper,
    partIndex: number,
    chosenList: number[],
    setChosen?: (ids: number[]) => void,
}

type ContractItemProps = {
    customerStore: CustomerStore,
    contractWrapper: ContractWrapper,
}

export const CustomerNavigation = observer(() => {
    const {customerStore} = useStore();
    const history = useHistory();

    const save = (values) => {
        if (values['assignedType']) {
            customerStore.setAssignedPartTo(values['assignedType'])
        }
    }
    return (
        <React.Fragment>

            {customerStore.selectedPartsToAssign.length > 0 &&
            <div className={`assigned-to `}>
                <Form
                    onSubmit={save}
                    subscription={{}}
                    render={({form}) => {
                        return (
                            <div>
                                <AutoSave save={save}/>

                                <Field name={"assignedType"} onBlur={form.submit}
                                       label={`Assign ${customerStore.selectedPartsToAssign.length} parts to`}
                                       component={Select}
                                       options={{
                                           system: 'To system',
                                           contract: 'To contract',
                                           project: 'To project',
                                       }} canEdit={customerStore.canEdit}/>
                            </div>
                        )
                    }}/>
            </div>}

            <div className={'contract-navigation-menu'}>

                {customerStore.customerNavigation && <ul className={'first-step'}>
                    <li>
                    <span className={'li-title'} onClick={() => history.push({search: ''})}>
                        <span className={'list-dot'}> </span>
                        <ContractDuration typeList={"not_expire"}/>
                        {customerStore.customerNavigation.name}
                    </span>
                        <ul className={'second-step'}>
                            {customerStore.wrappedContracts.map((contractWrapper, contractIndex) =>
                                <ContractItem key={`${contractIndex}=1`}
                                              contractWrapper={contractWrapper}
                                              customerStore={customerStore}/>)}
                        </ul>
                    </li>
                </ul>}
            </div>
        </React.Fragment>
    )
});

const ToggleButton = ({isCollapsed, setCollapsed}) => {

    return (
        <span className={'toggle-contract'}
              onClick={(e) => {
                  e.stopPropagation()
                  setCollapsed(!isCollapsed)
              }}>
            {isCollapsed ?
                <Icon size={0.5} path={mdiPlus}/> :
                <Icon size={0.5} path={mdiMinus}/>}
        </span>
    )
}

const ContractItem = observer(({customerStore, contractWrapper}: ContractItemProps) => {
    const history = useHistory();
    const [isCollapsed, setIsCollapsed] = useState(true);
    useEffect(() => {
        setIsCollapsed(!(contractWrapper.isActive || contractWrapper.isActiveParent));
    }, [contractWrapper.isActive, contractWrapper.isActiveParent]);

    const contractClass = cn({
        'contracts': true,
        [contractWrapper.status]: true,
        'alone': !contractWrapper.isParent,
        'last': contractWrapper.isLast,
        'hide': isCollapsed,
        'show': !isCollapsed
    })

    const listDotClass = cn({
        'list-dot': true,
    })

    return (
        <li key={contractWrapper.contractId}
            className={contractClass}
            onClick={(e) => {
                e.stopPropagation();
                history.push({
                    search: stringify({contractId: contractWrapper.contractId})
                });
            }}>
            <span className={contractWrapper.isActive ? 'li-title selected' : 'li-title'}>
                 {contractWrapper.isLast && <span className="decorate-block">
                </span>}
                <span className={listDotClass}>
                    {contractWrapper.isParent && <ToggleButton
                        isCollapsed={isCollapsed}
                        setCollapsed={setIsCollapsed}/>}
                 </span>
                <ContractDuration typeList={'contract'}
                                  duration={contractWrapper.durationInDays}
                                  endDate={contractWrapper.endDate}
                                  renewedContract={contractWrapper.isRenewedContract}
                />
                 Contract {formatCustomerContractName(contractWrapper.type, contractWrapper.contractNumber, contractWrapper.status)}
             </span>

            {contractWrapper.wrappedParts.map((partWrapper, partIndex) => {
                return <PartItem key={partWrapper.partId} partWrapper={partWrapper}
                                 partIndex={partIndex}
                                 chosenList={customerStore.selectedPartsToAssign}
                                 setChosen={customerStore.setSelectedPartsToAssign}
                />
            })}
            {contractWrapper.wrappedCollapsedParts.map((partWrapper, partIndex) => {
                return <PartItem key={partWrapper.partId} partWrapper={partWrapper}
                                 partIndex={partIndex}
                                 chosenList={[]}
                                 setChosen={() => {
                                 }}/>
            })}
            {contractWrapper.wrappedSystems.map((systemWrapper, systemIndex) => {
                return <SystemItem key={systemWrapper.systemId}
                                   systemWrapper={systemWrapper}
                                   systemIndex={systemIndex}>

                    {systemWrapper.wrappedParts.map((partWrapper, partIndex) => {
                        return <PartItem key={partWrapper.partId}
                                         partWrapper={partWrapper}
                                         partIndex={partIndex}
                                         chosenList={customerStore.selectedPartsToAssign}
                                         setChosen={customerStore.setSelectedPartsToAssign}/>
                    })}
                    {systemWrapper.wrappedCollapsedParts.map((partWrapper, partIndex) => {
                        return <PartItem key={partWrapper.partId} partWrapper={partWrapper}
                                         partIndex={partIndex}
                                         chosenList={[]}
                                         setChosen={() => {
                                         }}/>
                    })}
                </SystemItem>
            })}

            {contractWrapper.wrappedProjects.map((projectWrapper, projectIndex) => {
                return <ProjectItem key={projectWrapper.projectId}
                                    projectWrapper={projectWrapper}
                                    projectIndex={projectIndex}>
                    {projectWrapper.wrappedParts.map((partWrapper, partIndex) => {
                        return <PartItem key={partWrapper.partId}
                                         partWrapper={partWrapper}
                                         partIndex={partIndex}
                                         chosenList={customerStore.selectedPartsToAssign}
                                         setChosen={customerStore.setSelectedPartsToAssign}/>
                    })}
                    {projectWrapper.wrappedCollapsedParts.map((partWrapper, partIndex) => {
                        return <PartItem key={partWrapper.partId} partWrapper={partWrapper}
                                         partIndex={partIndex}
                                         chosenList={[]}
                                         setChosen={() => {
                                         }}/>
                    })}
                </ProjectItem>
            })}

            {contractWrapper.wrappedCollapsedSystems.map((systemWrapper, systemIndex) => {
                return <SystemItem key={systemWrapper.systemId}
                                   systemWrapper={systemWrapper}
                                   systemIndex={systemIndex}>
                    {systemWrapper.wrappedCollapsedParts.map((partWrapper, partIndex) => {
                        return <PartItem key={partWrapper.partId} partWrapper={partWrapper}
                                         partIndex={partIndex}
                                         chosenList={[]}
                                         setChosen={() => {
                                         }}/>
                    })}
                </SystemItem>
            })}

            {contractWrapper.wrappedCollapsedProject.map((projectWrapper, projectIndex) => {
                return <ProjectItem key={projectWrapper.projectId}
                                    projectWrapper={projectWrapper}
                                    projectIndex={projectIndex}>
                    {projectWrapper.wrappedCollapsedParts.map((partWrapper, partIndex) => {
                        return <PartItem key={partWrapper.partId} partWrapper={partWrapper}
                                         partIndex={partIndex}
                                         chosenList={[]}
                                         setChosen={() => {
                                         }}/>
                    })}
                </ProjectItem>
            })}
        </li>
    )
})


const SystemItem = ({systemWrapper, systemIndex, children}: SystemItemProps) => {
    const history = useHistory();
    const [isCollapsedSystem, setIsCollapsedSystem] = useState(true);


    useEffect(() => {
        setIsCollapsedSystem(!(systemWrapper.isActive || systemWrapper.isActiveParent));
    }, [systemWrapper.isActive, systemWrapper.isActiveParent]);


    const systemClass = cn({
        'contracts': true,
        'alone': !systemWrapper.isParent,
        'last': systemWrapper.isLast,
        'hide': isCollapsedSystem,
        'show': !isCollapsedSystem
    })

    return (
        <ul className={'third-step system'} key={systemIndex}>
            <li className={systemClass}
                onClick={e => {
                    e.stopPropagation();
                    history.push({
                        search: stringify({
                            systemId: systemWrapper.systemId,
                            isCollapsed: Number(systemWrapper.isCollapsed)
                        })
                    });
                }}>
                <span className={systemWrapper.isActive ? 'li-title selected' : 'li-title'}>
                    {(systemWrapper.isLast) &&
                    <span className="decorate-block"> </span>}
                    <span className={'list-dot'}>
                         <ToggleButton
                             isCollapsed={isCollapsedSystem}
                             setCollapsed={setIsCollapsedSystem}/>
                    </span>
                        <ContractDuration typeList={'system'}/>
                    {systemWrapper.modelNumber} #{systemWrapper.serialNumber} {systemWrapper.isCollapsed && '[Collapsed]'}
                    {systemWrapper.name}
                </span>
                {children}
            </li>
        </ul>
    );
};

const PartItem = ({partWrapper, partIndex, chosenList, setChosen}: PartItemProps) => {
    const history = useHistory();

    const partClass = cn({
        'alone': true,
        'last': partWrapper.isLast,
    })
    return (
        <ul className={'fourth-step parts'} key={partIndex}>
            <li className={partClass}
                onClick={e => {
                    e.stopPropagation();
                    history.push({
                        search: stringify({
                            partId: partWrapper.partId,
                            isCollapsed: Number(partWrapper.isCollapsed)
                        })
                    });
                }}>
                    <span className={partWrapper.isActive ? 'li-title selected' : 'li-title'}>
                        {partWrapper.isLast && <span className={'decorate-block'}>
                        </span>}
                        <span className={'list-dot'}>
                            </span>
                        <ContractDuration partHasStock={partWrapper.hasStock} partHasSpare={partWrapper.hasSpare} typeList={'part'}/>
                        {partWrapper.partNumber ? partWrapper.partNumber : partWrapper.partId} {partWrapper.isCollapsed && '[Collapsed]'}
                        {!partWrapper.isCollapsed && <CheckboxToggleList setChosen={(ids) => {
                            if (!ids.length) {
                                setChosen(chosenList.filter(item => item !== partWrapper.partId))
                            } else {
                                setChosen([...chosenList, Number(ids[0])]);
                            }
                        }} chosen={chosenList.filter(iri => iri === partWrapper.partId).map(item => item.toString())}
                           data={[{'@id': partWrapper.partId.toString()}]}/>}
                    </span>
            </li>
        </ul>
    );
};

const ProjectItem = ({projectWrapper, projectIndex, children}: ProjectItemProps) => {
    const history = useHistory();
    const [isCollapsedProject, setIsCollapsedProject] = useState(true);

    useEffect(() => {
        setIsCollapsedProject(!(projectWrapper.isActive || projectWrapper.isActiveParent));
    }, [projectWrapper.isActive, projectWrapper.isActiveParent]);

    const projectClass = cn({
        'alone': !projectWrapper.isParent,
        'last': projectWrapper.isLast,
        'hide': isCollapsedProject,
        'show': !isCollapsedProject
    })

    return (<ul className={'third-step projects'} key={projectIndex}>
        <li className={projectClass}
            onClick={(e) => {
                e.stopPropagation()
                history.push({
                    search: stringify({
                        projectId: projectWrapper.projectId,
                        isCollapsed: projectWrapper.isCollapsed
                    })
                });
            }}>
                <span className={projectWrapper.isActive ? 'li-title selected' : 'li-title'}>
                    {projectWrapper.isLast && <span className="decorate-block"> </span>}
                    <span className={'list-dot'}>
                       <ToggleButton
                           isCollapsed={isCollapsedProject}
                           setCollapsed={setIsCollapsedProject}/>
                    </span>
                    <ContractDuration typeList={'project'}/>
                    {projectWrapper.name} {projectWrapper.isCollapsed && '[Collapsed]'}
                </span>
            {children}
        </li>
    </ul>);
}
