import React from 'react';
import _ from 'underscore';
import ViolationMessage from "../ViolationMessge";
import {Icon} from '@mdi/react';
import {mdiMinus, mdiPlus} from "@mdi/js";

class NumberInput extends React.Component<any, { value: string, step: any }> {

    constructor(props) {
        super(props);

        this.state = {
            value: props.input.value,
            step: props.step ? props.step : 1
        }
    }

    setValue = (value, onChange) => {
        this.setState({value: value});
        this.props.input.onChange(value.toString());
        onChange(value)
    };

    handleClick = (e) => {
        if (!(this as any).container.contains(e.target)) {
            this.props.onBlur && this.props.onBlur();
        }
    };

    render() {
        const {label, input, meta, canEdit, disabled, onChange, ...rest} = this.props;
        const {value, step} = this.state;
        const {error, submitError} = (meta || {} as any);

        const minValue = rest.min_value ? Number(rest.min_value) : 0;
        const editable = (canEdit === true || canEdit === undefined) && (disabled === false || disabled === undefined || disabled === null);

        return (
            <div ref={node => ((this as any).container = node)} className={'ls-form ls-textinput numberinput'}>
                {label && <label>{label}</label>}
                <div className="numberinput-wrap">
                    <input
                        type="input"
                        disabled={!editable}
                        {...input}
                        {...rest}
                        onChange={(e) => {
                            this.setValue(e.target.value, onChange);
                        }}
                        onBlur={(e) => {
                            const num = e.target.value.length > 0 ? parseFloat(e.target.value) : '';
                            this.setValue(num, onChange);
                        }}
                    />
                    <button
                        onClick={(event) => {
                            event.stopPropagation();
                            if (editable) {
                                let newValue = (parseFloat(value) - step);
                                this.setValue(_.isNumber(value) && (newValue > minValue) ? parseFloat(newValue.toFixed(2)) : minValue, onChange);
                            }
                        }}
                        className='decrease'
                        type='button'>
                        <Icon path={mdiMinus}/>
                    </button>
                    <button
                        onClick={(event) => {
                            event.stopPropagation();
                            if (editable) {
                                let newValue = (parseFloat(value) + step);
                                this.setValue(_.isNumber(value) ? parseFloat(newValue.toFixed(2)) : step, onChange);
                            }
                        }}
                        className='increase'
                        type='button'>
                        <Icon path={mdiPlus}/>
                    </button>
                </div>
                {(error || submitError) && (<ViolationMessage message={error || submitError}/>)}

            </div>
        );
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick, false);
    }
}

export default NumberInput;
