import {AuthStore} from "../mobx/AuthStore";
import {IItemOpportunityModel} from "../../utils/models";

class OpportunityPermissionResolver {
    constructor(private authStore: AuthStore) {
    }

    public canEdit(opportunity: IItemOpportunityModel | null): boolean {
        if (opportunity && opportunity.status === 'successful') {
            return false;
        }
        return this.authStore.isAdmin || this.isAssignedOnMe(opportunity) || this.isInvited(opportunity);
    }

    private isAssignedOnMe(opportunity: IItemOpportunityModel | null): boolean {
        if (!opportunity || !opportunity.salesAgent) {
            return false;
        }
        return opportunity.salesAgent['@id'] === this.authStore.userIri;
    }

    private isInvited(opportunity: IItemOpportunityModel | null): boolean {
        if (!opportunity) {
            return false;
        }
        let haveInvited = opportunity.invited && opportunity.invited.length > 0;
        return !!(haveInvited && opportunity.invited.find(invitedId => this.authStore.userIri === invitedId['@id']));
    }

}

export default OpportunityPermissionResolver;
