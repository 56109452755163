import React from 'react';
import {Field, Form} from 'react-final-form'
import {Input, Select} from './../../../components/ui';
import {FinalButtonSet} from "../../../components/ui/form";
import useDataListFetch from "../../../utils/form/useDataListFetch";

type PartSeriesFormProps = { initialValues, onSubmit }
const PartSeriesForm = ({initialValues = {}, onSubmit}: PartSeriesFormProps) => {
    const partFamiliesDL = useDataListFetch('part_families')

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
        >
            {({handleSubmit, pristine, submitting}) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        name='label'
                        label='Name'
                        component={Input}
                    />
                    <Field
                        name='partFamily'
                        label='Part Family'
                        clearable={false}
                        canEdit={!initialValues.id}
                        {...partFamiliesDL}
                        component={Select}
                    />
                    <FinalButtonSet submitting={submitting} pristine={pristine} mode={"RELATIVE"}/>
                </form>
            )}
        </Form>
    )
}


export default PartSeriesForm;
