import * as React from 'react';
import {Field, Form} from 'react-final-form'
import { Select} from "../../../components/ui";
import {useStore} from "../../../context";
import {observer} from "mobx-react";
import {ContractDetailsFormSharedFieldsSet} from "./ContractDetailsForm";
import {ContractMaintenanceFormInterface} from "../../../store/mobx/ContractStore";
import {FinalButtonSet} from "../../../components/ui/form";
import {contractPeriodList, formatContractPeriod} from "../../../store/enum/contractEnumeration";


const ContractMaintenanceForm = observer(({mode}) => {
    const {customerStore} = useStore();

    return <Form<Partial<ContractMaintenanceFormInterface>> onSubmit={customerStore.updateOrCreateContract}
                                                            initialValues={customerStore.contractStore.currentContract ? customerStore.contractStore.currentContract : {period: "week"}}>
        {({handleSubmit, pristine, submitting, form}) => (
            <form onSubmit={handleSubmit}>
                <ContractDetailsFormSharedFieldsSet/>

                <Field name='period' label={"Period"} component={Select} options={contractPeriodList}
                       formatOptions={formatContractPeriod}
                       canEdit={customerStore.canEdit}/>
                <FinalButtonSet pristine={pristine} reset={form.reset} submitting={submitting} mode={mode}/>
            </form>
        )}
    </Form>
});

export default ContractMaintenanceForm;
