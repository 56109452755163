import React, {useState} from 'react';
import {Icon} from '@mdi/react';
import {mdiBellOutline, mdiBell, mdiPlusThick} from "@mdi/js";
import {observer} from "mobx-react";
import StandalonePopover from "../../components/ui/StandalonePopover";
import {useStore} from "../../context";
import NotificationsList from "./NotificationsList";
import {Button} from "../../components/ui";
import NotificationCreate from "./NotificationCreate";
import OnOutsiceClick from 'react-outclick';

export const Notification = observer(() => {
    const [showPopupMode, setShowPopupMode] = useState<'creating' | 'notification_list' | 'hidden'>('hidden');
    const {notificationStore} = useStore();

    if (notificationStore.notifications.length === 0 && !notificationStore.canCreate) {
        return <div className={`messages`}></div>
    }

    return (
        <div data-testid="notification-bell" className={`messages`}
             onClick={() => setShowPopupMode('notification_list')}>
            <div className={notificationStore.countUnreadMessages > 0 ? 'new-messages' : ''}>
                <Icon
                    className={notificationStore.countUnreadMessages > 0 ? 'messages-icon animate__animated animate__tada' : 'messages-icon'}
                    path={notificationStore.countUnreadMessages > 0 ? mdiBell : mdiBellOutline}
                    style={{maxWidth: '20px'}}
                />

                {notificationStore.countUnreadMessages > 0 && <span>{notificationStore.countUnreadMessages}</span>}
            </div>

            {
                showPopupMode === 'creating' &&
                <StandalonePopover size={'md'}
                                   direction={"BOTTOM_RIGHT"}
                                   darken={false}
                                   title={'Create notification'}
                                   closePopover={() => setShowPopupMode('notification_list')}>
                    <NotificationCreate/>
                </StandalonePopover>
            }


            {showPopupMode === 'notification_list' &&
            <OnOutsiceClick onOutsideClick={() => setShowPopupMode('hidden')}>
                <StandalonePopover size={'md'}
                                   direction={"BOTTOM_RIGHT"}
                                   darken={false}
                                   title={'Notification'}
                                   closePopover={() => setShowPopupMode('hidden')}>

                    <div>
                        {notificationStore.canCreate &&
                        <Button onClick={() => setShowPopupMode('creating')} icon={mdiPlusThick}>Create
                            notification</Button>}
                        <NotificationsList/>
                    </div>
                </StandalonePopover>
            </OnOutsiceClick>
            }

        </div>


    );
}
);
