export function resolveTime(date: Date, time: Date): Date {
    return new Date(
        Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            time.getUTCHours(),
            time.getUTCMinutes()
        )
    );
}

export const formatCurrency = (value, withCurrency = true) => {
    if (!value) {
        value = 0;
    }
    return value.toLocaleString('en-EN', {
        style: withCurrency ? 'currency' : undefined,
        currency: 'GBP'
    });
};

