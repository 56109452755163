import React from "react";
import {observer} from "mobx-react";
import {useStore} from "../../context";
import {Field, Form} from "react-final-form";
import {Button, Select} from "../../components/ui";
import {Intent, Spinner} from "@blueprintjs/core";

export const SupportRequestSelectSystemForm = observer(() => {
    const {supportRequestStore} = useStore();
    const currentList = supportRequestStore.currentSystemsList;
    const initialRelatedSystemId = supportRequestStore.current.relatedSystem ? `/api/systems/${supportRequestStore.current.relatedSystem.id}` : '';
    if (currentList?.state !== 'fulfilled')
        return <Spinner size={20} intent={Intent.PRIMARY}/>
    return (<Form
        onSubmit={supportRequestStore.selectSystem}
        initialValues={{relatedSystem: initialRelatedSystemId}}
    >
        {({handleSubmit, pristine}) => {
            return (<div className={'status-form'}>
                <form onSubmit={handleSubmit}>
                    <Field
                        name={'relatedSystem'}
                        component={Select}
                        options={Object.fromEntries(currentList?.value.map(item => {
                            return [item['@id'], `${item.modelNumber} (${item.serialNumber})`];
                        }))}
                        isSearchable={true}
                        required
                    />
                    <Button disabled={pristine} className={'roundButton tertiaryBtn '} type='submit'>Save</Button>
                </form>
            </div>)
        }}

    </Form>)
});

