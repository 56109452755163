import React from 'react';

import {Field, Form} from "react-final-form";
import {FinalButtonSet, NumberInput, Select, Textarea} from "../../../components/ui/form";
import useDataListFetch from "../../../utils/form/useDataListFetch";

type ExpenseSelectProps = {
    onFormSubmit,
    canEdit: boolean,
}
const ExpenseSelect = ({onFormSubmit, canEdit}: ExpenseSelectProps) => {
    const expenseTypesDL = useDataListFetch('expense_types')
    return (
        <Form
            onSubmit={onFormSubmit}
        >
            {({handleSubmit, pristine, submitting, values}) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        name='type'
                        component={Select}
                        canEdit={canEdit}
                        {...expenseTypesDL}
                    />
                    <Field
                        name='cost'
                        step={100}
                        component={NumberInput}
                        canEdit={canEdit}
                    />
                    <Field
                        name='notes'
                        placeholder='Notes'
                        canEdit={canEdit}
                        component={Textarea}
                    />
                    <FinalButtonSet submitting={submitting} pristine={pristine || !values.type || !values.cost}
                                    mode={"RELATIVE"}/>
                </form>
            )}
        </Form>
    );
}


export default ExpenseSelect;
