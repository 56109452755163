import React from 'react';

import {Field, Form} from "react-final-form";
import {FinalButtonSet, Select} from "../../components/ui/form";
import {useStore} from "../../context";
import {observer} from "mobx-react";
import {formatTicketType, ticketTypeList} from "../../store/enum/ticketEnumeration";
import TimeNumberInput from "../../components/ui/form/TimeNumberInput";

const SpentTimeSelect = observer(() => {
    const {ticketStore} = useStore();

    return (
        <Form
            onSubmit={ticketStore.addSpentTime}
        >
            {({handleSubmit, pristine, submitting}) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        name='type'
                        component={Select}
                        options={ticketTypeList}
                        canEdit={ticketStore.canEdit}
                        formatOptions={formatTicketType}
                    />
                    <Field
                        name='hours'
                        step={.10}
                        placeholder='Hours'
                        component={TimeNumberInput}
                        canEdit={ticketStore.canEdit}
                    />
                    <FinalButtonSet submitting={submitting} pristine={pristine}/>
                </form>
            )}
        </Form>
    )
})


export default SpentTimeSelect;
