import React from 'react';
import {Field, Form} from 'react-final-form';
import {Input, Select, Textarea} from "../../components/ui";
import {CustomerStore} from "../../store/mobx/CustomerStore";
import {FinalButtonSet, FinalButtonSetMode} from "../../components/ui/form";
import useDataListFetch from "../../utils/form/useDataListFetch";

type ProjectDetailsFormProps = {
    customerStore: CustomerStore,
    mode: FinalButtonSetMode
}
const ProjectDetailsForm = ({customerStore, mode}: ProjectDetailsFormProps) => {
    const slaTimesDL = useDataListFetch('sla_times')

    return (
        <Form onSubmit={customerStore.updateOrAddProject}
              initialValues={customerStore.projectStore.currentProject}
              render={
                  ({pristine, submitting, handleSubmit, form}) =>
                      <form onSubmit={handleSubmit}>
                          <Field
                              name='name'
                              label='Project name'
                              canEdit={customerStore.canEdit}
                              component={Input}
                          />
                          <Field
                              name='slaTime'
                              label='SLA'
                              clearable={false}
                              {...slaTimesDL}
                              component={Select}
                              canEdit={customerStore.canEdit}
                          />
                          <Field
                              name='description'
                              label='Description'
                              rows={mode === 'RELATIVE' ? 5 :  20}
                              canEdit={customerStore.canEdit}
                              component={Textarea}
                          />
                          <FinalButtonSet submitting={submitting} pristine={pristine} reset={form.reset} mode={mode}/>
                      </form>
              }/>
    );
};

export default ProjectDetailsForm
