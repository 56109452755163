import React from 'react';

import ResourceForm from "./HourlyRateForm";
import {GenericResourceStore} from "../../../store/mobx/GenericResourceStore";

type ResourceCreateProps = {
    sharedGridResourceCollectionStore: GenericResourceStore
}
const ResourceCreate = ({sharedGridResourceCollectionStore}: ResourceCreateProps) => {
    return (
        <ResourceForm
            initialValues={{}}
            onSubmit={sharedGridResourceCollectionStore.createResource}
        />
    );
};


export default ResourceCreate;
