import React from 'react';
import {DateInput, Input, Select} from '../../components/ui';
import {Field, Form} from "react-final-form";
import {FinalButtonSet} from "../../components/ui/form";
import useDataListFetch from "../../utils/form/useDataListFetch";

const PurchaseForm = ({handleSubmit, initialValues = {}}) => {
    const couriersDL = useDataListFetch('courier_companies')
    const suppliersDL = useDataListFetch('suppliers')

    return (
        <Form onSubmit={handleSubmit} initialValues={initialValues} render={({handleSubmit, pristine, submitting}) =>
            <form onSubmit={handleSubmit}>
                <Field
                    name='invoiceNumber'
                    label='Invoice Number'
                    component={Input}
                />
                <Field
                    name='delivery.trackingNumber'
                    label='Tracking Number'
                    component={Input}
                />
                <Field
                    name='delivery.company'

                    label='Courier company'
                    component={Select}
                    {...couriersDL}
                />
                <Field
                    name="delivery.price"
                    label="Delivery price"
                    component={Input}
                />
                <Field
                    name='supplier'
                    label='Supplier'
                    component={Select}
                    {...suppliersDL}
                />
                <Field
                    name='dateOfPurchase'
                    label='Date of purchase'
                    component={DateInput}
                />
                <Field
                    name="totalPrice"
                    label="Total price"
                    component={Input}
                />
                <FinalButtonSet pristine={pristine} submitting={submitting}/>
            </form>
        }/>

    );
};

export default PurchaseForm;
