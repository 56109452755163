import React, {useState} from 'react';
import {DateRangePicker} from '@blueprintjs/datetime';
import {Popover, PopoverInteractionKind, Position} from '@blueprintjs/core';
import ViolationMessage from "../ViolationMessge";
import Icon from '@mdi/react';
import {mdiChevronDown} from "@mdi/js";
import {createFutureShortCuts} from "./createFutureShortCuts";
import {useOnClickOutside} from "../../../utils/hooks/useOnClickOutside";

const DateRangeInput = ({input, meta, ...props}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [value, setValue] = useState<{ after: string, before: string }>({after: '', before: ''});
    const [shortCatsPicker, setShortCatsPicker] = useState<boolean>(false);
    const {ref} = useOnClickOutside(() => {
        setShortCatsPicker(false);
        setIsOpen(false);
    });

    const [lastDates, setLastDates]= useState('');
    const handleOnchange = (date) => {
        let val = {
            after: date[0] ? getFormattedDate(date[0]) : '',
            before: date[1] ? getFormattedDate(date[1]) : '',
        };
        setValue({...val});
        input.onChange(val);
        setIsOpen(value.after === '' && value.before === '')
    };

    const getFormattedDate = (date) => {
        let year = date.getFullYear();
        let month = date.getMonth() >= 9 ? (date.getMonth() + 1) : '0' +
            (date.getMonth() + 1);
        let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

        return year + '-' + month + '-' + day;
    };

    const handleInteraction = (newIsOpenState) => {
        setIsOpen(newIsOpenState);
    };

    let popoverContent = (<DateRangePicker minDate={new Date(2007, 1, 1)}
                                           maxDate={new Date(2027, 12, 31)}
                                           onChange={handleOnchange}
                                           shortcuts={false}
                                           allowSingleDayRange={true}/>);
    let inputValue = ''

    if (!input.value.after && !input.value.before) {
        const key = Object.keys(input.value);
        // eslint-disable-next-line array-callback-return
        createFutureShortCuts().map(item => {
            const arrKey = Object.keys(item);
            if (key[0] === arrKey[0]) {
                inputValue = item[`${key}`];
            }
        })
    }
    if (input.value.after) {
        inputValue = input.value.after;
    }
    if (input.value.before) {
        inputValue += '-' + input.value.before;
    }

    const {error, submitError} = (meta || {} as any);
    return (
        <Popover
            content={!shortCatsPicker && popoverContent}
            className='daterange-popover'
            isOpen={isOpen}
            position={Position.BOTTOM}
            interactionKind={PopoverInteractionKind.CLICK}
        >
            <div className="ls-form ls-daterange">
                <label>{props.label ? props.label : input.name}</label>

                <span className={'replaceable-inputs'}>
                    {!shortCatsPicker && <input
                        type="input" name={input.name}
                        value={inputValue || lastDates}
                        readOnly={true}
                        size={inputValue.length}
                        onClick={() => !shortCatsPicker && handleInteraction(true)}
                    />}

                    <span onClick={() => setShortCatsPicker(true)}
                          className={shortCatsPicker ? 'toggle-inputs hide' : 'toggle-inputs'}>
                        <Icon path={mdiChevronDown}/>
                    </span>
                </span>

                {shortCatsPicker && <div className={'dropdown'}
                                         ref={ref}>
                    {createFutureShortCuts().map((item, index) => {
                        const key = Object.keys(item);
                        return (
                            <div key={`${key[index]}${index}`} onClick={() => {
                                input.onChange(item[`${key}`]);
                                setLastDates(item[`${key}`]);
                                setShortCatsPicker(false);
                            }}>{item[`${key}`]}</div>
                        )
                    })}

                </div>}

                {(error || submitError) && (<ViolationMessage message={error || submitError}/>)}
            </div>
        </Popover>
    );
};

export default DateRangeInput;
