import React from 'react';
import {Field, Form} from 'react-final-form'
import {DateInput} from './../../components/ui';
import {FinalButtonSet} from "../../components/ui/form";
import {observer} from "mobx-react";
import {useStore} from "../../context";

const OpportunityCloseDateForm = observer(() => {
    const {opportunityStore} = useStore();
    return (
        <Form
            onSubmit={opportunityStore.updateAttribute}
            initialValues={{closeAt: opportunityStore.currentOpportunity.closeAt}}
        >
            {({handleSubmit, pristine, submitting}) => (
                <form onSubmit={handleSubmit}>

                    <Field
                        label='Close date'
                        name='closeAt'
                        placeholder='Select date'
                        component={DateInput}
                    />

                    <FinalButtonSet pristine={pristine} submitting={submitting} mode={"RELATIVE"}/>
                </form>

            )}
        </Form>

    )
});


export default OpportunityCloseDateForm;
