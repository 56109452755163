import * as React from 'react';
import * as _ from 'underscore';
import {Icon} from '@mdi/react'
import {mdiTrashCanOutline} from "@mdi/js";
import UserAvatar from "../UserAvatar";


export const TagList =  ({items, onDelete, editable, ...props}) => {

    const handleDelete = (e, index) => {
        e.stopPropagation();
        onDelete && onDelete(index);
    };

    const handleClick = (e, tag) => {
        if (props.onClick) {
            props.onClick && editable && props.onClick(tag);
        }
    };

    const renderTag = (item, index) => {
        return (
            <div key={index} onClick={(e) => handleClick(e, item)}>
                <div className={'user'}>
                    <UserAvatar full name={item.name}/>
                    {editable && onDelete && (<span
                        className='item-delete'
                        onClick={(e) => handleDelete(e, index)}>
                        <Icon path={mdiTrashCanOutline}/>
                    </span>)}

                </div>
                <div>
                    {_.isString(item) ? item : item.label}
                </div>
            </div>
        );
    };

    return (
        <div className={'tag-list editable'}>
            {items.map(renderTag)}
            {!items.length && (<span>List is empty.</span>)}
        </div>
    );
}
