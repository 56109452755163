import * as React from 'react';
import {Field, Form} from 'react-final-form'
import {FinalButtonSet, Input, NumberInput, Select} from "../../../components/ui/form";
import {useStore} from "../../../context";
import {observer} from "mobx-react";
import {ContractBankOfTicketsFormInterface} from "../../../store/mobx/ContractStore";
import {ContractDetailsFormSharedFieldsSet, TimeMinStepFieldAdapter} from "./ContractDetailsForm";
import {contractPeriodList, formatContractPeriod} from "../../../store/enum/contractEnumeration";


const ContractBankOfTicketsForm = observer(({mode}) => {
    const {customerStore} = useStore();

    return <Form<Partial<ContractBankOfTicketsFormInterface>> onSubmit={customerStore.updateOrCreateContract}
                                                              initialValues={customerStore.contractStore.currentContract ? customerStore.contractStore.currentContract : {period: 'week'}}>

        {({handleSubmit, pristine, submitting, form}) => (
            <form onSubmit={handleSubmit}>
                <ContractDetailsFormSharedFieldsSet/>
                <Field name='rate' label={"Rate"} component={Input} canEdit={customerStore.canEdit}/>

                <Field label='Number of tickets' name='ticketsNumber' component={NumberInput} step={1}
                       canEdit={customerStore.canEdit}/>

                <Field name='period' label={"Period"} component={Select} options={contractPeriodList}
                       formatOptions={formatContractPeriod}
                       canEdit={customerStore.canEdit}/>

                <Field name="timeMinStep" component={TimeMinStepFieldAdapter}
                       canEdit={customerStore.contractStore.canChangeTimeMinStep}/>


                <FinalButtonSet pristine={pristine} reset={form.reset} submitting={submitting} mode={mode}/>
            </form>
        )}
    </Form>
});

export default ContractBankOfTicketsForm;
