import React, {useEffect} from 'react';
import {createPortal} from 'react-dom';
import {Icon} from '@mdi/react';
import {mdiPlus} from '@mdi/js';

type PopoverDirectionEnum =
    'CENTER'
    | 'CENTER_LEFT'
    | 'CENTER_RIGHT'
    | 'BOTTOM_LEFT'
    | 'TOP_LEFT'
    | 'TOP_RIGHT'
    | 'BOTTOM_RIGHT';
type PopoverSizeEnum = 'sd' | 'bd' | 'md' | 'ld' | 'auto';

export type PopoverPositionType =  { x:string | number, y:string | number }

type PopoverProps = {
    title?: string | null,
    position?: PopoverPositionType,
    direction?: PopoverDirectionEnum,
    classNames?: string,
    size?: PopoverSizeEnum,
    closePopover,
    children?,
    darken?,
    columnCount?: number
}

const StandalonePopover = ({
                               closePopover, title, position = {
        x: '50%',
        y: '50%'
    }, direction = 'CENTER', columnCount = 1, classNames = '', children, darken = true, size = 'auto',
                           }: PopoverProps) => {
    const handleUserInput = (event) => {
        if (event.keyCode === 27) {
            event.stopImmediatePropagation();
            closePopover();
        }
    };

    const Popover = () => (
        <div onClick={closePopover} className={'popover-wrap'}>
            {darken && <div className={'darken'}> </div>}

            <div
                onClick={(event) => event.stopPropagation()}
                className={['popover', direction, classNames, 'visible', size].join(' ')}
                style={position ? {left: position.x, top: (position.y)} : {}}>
                {title && <div className="popover-head">
                    <h3>{title}</h3>
                    <div className='controls'>
                        <a className="close" onClick={closePopover}>
                            <Icon path={mdiPlus}/>
                        </a>
                    </div>
                </div>}
                <div className={`popover-content columncount-${columnCount}`}>
                    {children}
                </div>
            </div>
        </div>
    )

    useEffect(() => {
        window.addEventListener('keydown', handleUserInput, false);
    }, []);

    return createPortal(<Popover/>, document.querySelector('#root'))

}


export default StandalonePopover
