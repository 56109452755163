import React, {useState} from 'react';
import {Icon} from '@mdi/react';
import {mdiChip, mdiCubeOutline, mdiRefresh, mdiZipDisk} from '@mdi/js';
import AutocompleteInput from "../../components/ui/form/AutocompleteInput";
import {useStore} from "../../context";
import {CheckboxToggleList} from "../../components/ui";
import {PartRealizationStatusType, PartTypeListType} from "../../store/enum/partEnumeration";
import {Iri, PartFullModel, PartRealizationShortModel} from "../../utils/models";

type TicketAssignPartFormProps = {
    handleAssign: ({chosenRealizationsList}) => any,
    system: string|null,
    project: string|null,
    closePopover,
}
const TicketAssignPartForm = ({handleAssign, system, project}: TicketAssignPartFormProps) => {
    const [step, setStep] = useState('step-type');
    const [type, setType] = useState<PartTypeListType>('hardware');
    const [status, setStatus] = useState<PartRealizationStatusType>('spare');
    const [autocompleteValue, setAutocompleteValue] = useState<PartFullModel | null>(null);
    const [availableRealizationsList, setAvailableRealizationsList] = useState<Array<PartRealizationShortModel>>([]);
    const [chosenRealizationsList, setChosenRealizationsList] = useState<Iri[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const {_apiClient} = useStore();

    function changeStep() {
        if (step === 'step-type') {
            setStep('step-status')
        } else if (step === 'step-status') {
            setStep('step-part')
        } else if (step === 'step-part') {
            setChosenRealizationsList([]);
            setIsLoading(true);
            _apiClient.realizationsCollectionFetch({
                part: autocompleteValue['@id']
            }).then(response => {
                setAvailableRealizationsList(response['hydra:member']);
                setIsLoading(false);
            });
            setStep('step-realizations')
        } else {
            setIsLoading(true);
            handleAssign({chosenRealizationsList});
        }
    }

    function detectField() {
        return type === 'hardware' ? 'partNumber' : 'name'
    }

    function autocompletePlaceholder() {
        return `Find part by ${type === 'hardware' ? 'part number' : 'name'}`
    }

    let additionalQuery = {not_empty_realization_status: status};
    if (status === 'spare') {
        if (system !== null) {
            additionalQuery['system'] = system;
        }
        if (project !== null) {
            additionalQuery['project'] = project;
        }
    }

    return (<React.Fragment>
        {step === 'step-type' && <div className={'chooses-content'}>
            <div className={type === 'hardware' ? 'cards active' : 'cards'} onClick={() => {
                setType('hardware')
                setStep('step-status')
            }}>
                <div className="cards-ico">
                    <Icon path={mdiChip}/>
                </div>
                <p>Hardware</p>
            </div>
            <div className={type === 'software' ? 'cards active' : 'cards'} onClick={() => {
                setType('software')
                setStep('step-status')
            }}>
                <div className="cards-ico">
                    <Icon path={mdiZipDisk}/>
                </div>
                <p>Software</p>
            </div>
        </div>}
        {step === 'step-status' && <div className={'chooses-content'}>
            <div className={status === 'spare' ? 'cards active' : 'cards'} onClick={() => {
                setStatus('spare')
                setStep('step-part')
            }}>
                <div className="cards-ico">
                    <Icon path={mdiRefresh}/>
                </div>
                <p>Spare</p>
            </div>
            <div className={status === 'stock' ? 'cards active' : 'cards'} onClick={() => {
                setStatus('stock')
                setStep('step-part')
            }}>
                <div className="cards-ico">
                    <Icon path={mdiCubeOutline}/>
                </div>
                <p>Stock</p>
            </div>
        </div>}
        {step === 'step-part' && <form>
            <AutocompleteInput<PartFullModel>
                input={{}}
                canEdit={true}
                placeholder={autocompletePlaceholder()}
                additionalQuery={additionalQuery}
                onComplete={(item) => {
                    setAutocompleteValue(item);
                }} fieldName={detectField()} scope={"parts"}/>
        </form>}

        {step === 'step-realizations' &&
        <form><CheckboxToggleList
            data={availableRealizationsList.filter(real => real.partStatus === status)}
            chosen={chosenRealizationsList}
            setChosen={setChosenRealizationsList}
            renderLabelFn={(item) => item.serialNumber}
        />
        </form>}
        {(step === 'step-part' || step === 'step-realizations') && <div className="relative-buttons">
            <button className={'tertiaryBtn'}
                    disabled={(isLoading || (step === 'step-realizations' && chosenRealizationsList.length === 0))}
                    onClick={changeStep}>{step === 'step-realizations' ? 'Assign' : 'Next >'}</button>
        </div>}
    </React.Fragment>)
};

export default TicketAssignPartForm;
