import React from 'react';
import {FinalAutocomplete, FinalButtonSet} from '../../components/ui/form';
import {useStore} from "../../context";
import {Field, FieldRenderProps, Form} from "react-final-form";
import {observer} from "mobx-react";
import {PartFullModel} from "../../utils/models";

const PartAutocompleteAdapter = ({meta, input, ...rest}: FieldRenderProps<PartFullModel, any>) => {
    const {customerStore} = useStore();
    const isHardware = customerStore.partStore.partType === 'hardware';

    return <FinalAutocomplete<PartFullModel>
        fieldName={isHardware ? "partNumber" : "name"} scope={"parts"}
        label={isHardware ? "Part Number" : "Name"}
        additionalQuery={{isStock: true, type: customerStore.partStore.partType}}
        canEdit={true}
        onComplete={(item) => {
            if (typeof item === 'object') {
                customerStore.assignPartFromStock(item)
            }
            if (typeof item === 'string') {
                customerStore.createDraftPart(item)
            }
        }
        }
        input={input} meta={meta}  {...rest}/>
};

const CreatePartStep2Form = observer(() => {
    const {customerStore} = useStore();
    const isHardware = customerStore.partStore.partType === 'hardware';
    const draft = customerStore.draftPart;
    return <Form
        onSubmit={customerStore.goToNextAddPartStep}
        render={({handleSubmit, pristine, submitting, form}) => {
        return (<form onSubmit={handleSubmit}>
            <Field<PartFullModel>
                name={isHardware ? "partNumber" : "name"}
                label={isHardware ? "Part Number" : "Name"}
                component={PartAutocompleteAdapter}
                required
            />
            {draft && <div className={'created-part-item'}>
                <p>Name: <b>{draft.name}</b></p>
                {draft.partNumber && <p>Part number: <b>{draft.partNumber}</b></p>}
                <p>Type: <b>{draft.type}</b></p>
            </div>}
            <FinalButtonSet pristine={pristine} submitting={submitting} label={"Add part"} reset={form.reset}/>
        </form>)
    }}>
    </Form>
});


export default CreatePartStep2Form;
