import {ActivityReadModelOutput} from "../../generated";

export const sortActivities = (activities: ActivityReadModelOutput[]): ActivityReadModelOutput[] => {
    if (!activities) {
        return [];
    }
    const sortByTime = (a: ActivityReadModelOutput, b: ActivityReadModelOutput): number => {
        return -(new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
    }
    return activities.slice().sort(sortByTime);
}