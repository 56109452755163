import React from 'react';
import {Field, Form} from 'react-final-form'

import {mdiAccountOutline, mdiLockOutline} from '@mdi/js';

import {Input} from './../../components/ui/form';
import {Button} from './../../components/ui';

const LoginForm = ({onSubmit}) => {

    return (
        <Form onSubmit={onSubmit}>
            {({handleSubmit, pristine, submitting, submitError}) => (
                <form onSubmit={handleSubmit} className={'login-form'}>
                    <p className="login-form__pre-text">Login to your account</p>
                    <Field type='text' autoComplete='username' name='username' placeholder='Username' label=''
                           withIco={true} ico={mdiAccountOutline} component={Input}/>
                    <Field type='password' autoComplete='current-password' name='password' placeholder='Password'
                           label='' withIco={true} ico={mdiLockOutline} component={Input}/>
                    {submitError && <span className="error">{submitError}</span>}
                    <Button type='submit' className={'roundButton'} disabled={pristine || submitting}>Login</Button>
                </form>
            )}
        </Form>
    );
};

export default LoginForm;
