import React, {useRef, useState} from 'react';
import classNames from 'classnames';
import {Button, Input, MultiSelect} from '../ui';
import DateRangeInput from './../../components/ui/form/DateRangeInput';
import RangeInput from './../../components/ui/form/RangeInput';
import {Field, Form} from 'react-final-form'
import {ActiveFiltersType} from "../../store/interfaces/GridResourceCollectionInterface";
import {CustomerTypeEnum} from "../../store/mobx/CustomerStore";
import {observer} from "mobx-react";
import {useLocation} from "react-router";
import {useStore} from "../../context";
import {
    formatPartRealizationStatus,
    formatPartTypeListStatus,
    partRealizationStatusList,
    partTypeList
} from "../../store/enum/partEnumeration";
import {DataListStoreItemType} from "../../utils/form/useDataListFetch";
import {
    formatTicketStatus,
    formatTicketType,
    ticketStatusList,
    ticketTypeList
} from "../../store/enum/ticketEnumeration";
import {
    contractStatusList,
    contractTypeList,
    formatContractStatus,
    formatContractType
} from "../../store/enum/contractEnumeration";
import {formatQuoteStatus, quoteStatusList} from "../../store/enum/opportunityEnumeration";
import SwitcherBox from "../ui/form/Switcher";
import {Icon} from '@mdi/react';
import {mdiPlus, mdiRefresh} from "@mdi/js";
import {useOnClickOutside} from "../../utils/hooks/useOnClickOutside";
import {FilterStore} from "../../store/mobx/FilterStore";

type FiltersFormProps = {
    initialValues,
    filters: ActiveFiltersType[]
    onSubmit,
}
type FilterItemProps = {
    filter: ActiveFiltersType,
    index: number
}
export const FilterItem = observer(({filter, index}: FilterItemProps) => {
    const {pathname} = useLocation();
    const [_data, setData] = useState({});
    const {_apiClient} = useStore()
    let component: any = Input;
    let onClickCallback;
    let options: any;
    let formatOptions: any;
    const inputProps = {};
    const load = async (item: DataListStoreItemType, query = {}) => {
        if (!_data[item]) {
            const response = await _apiClient.loadDataList(item, query);
            setData({..._data, [item]: response});
        }
    };
    const getData = (item: DataListStoreItemType) => {
        if (_data[item]) {
            return _data[item];
        } else {
            return [];
        }
    };
    if (filter.key === 'updatedAt'
        || filter.key === 'createdAt'
        || filter.key === 'closeAt'
        || filter.key.includes('startDate')
        || filter.key.includes('endDate')
        || filter.key === 'lastActivityTime'
    ) {
        component = DateRangeInput;
        if (filter.key === 'closeAt') {
            inputProps['futureShortCuts'] = true;
        }
    }

    if ((pathname === '/tickets' || pathname === '/') && filter.key === 'status') {
        component = MultiSelect;
        options = ticketStatusList;
        formatOptions = formatTicketStatus
    }

    if ((pathname === '/tickets' || pathname === '/') && filter.key === 'type') {
        component = MultiSelect;
        options = ticketTypeList;
        formatOptions = formatTicketType
    }

    if (pathname === '/opportunities' && filter.key === 'quotes.type') {
        component = MultiSelect;
        options = contractTypeList;
        formatOptions = formatContractType
    }

    if (pathname === '/opportunities' && filter.key === 'quotes.status') {
        component = MultiSelect;
        options = quoteStatusList;
        formatOptions = formatQuoteStatus
    }

    if (pathname === '/customers' && filter.key === 'type') {
        component = MultiSelect;
        options = CustomerTypeEnum;
    }

    if (pathname === '/systems' && filter.key === 'manufacturer') {
        component = MultiSelect;
        onClickCallback = () => load('manufacturers');
        load('manufacturers').then();
        options = getData('manufacturers');
    }

    if (pathname === '/contracts' && filter.key === 'salesPerson.id') {
        component = MultiSelect;
        options = getData('sales_agents+admins');
        onClickCallback = () => load('sales_agents+admins');
        load('sales_agents+admins').then();
    }

    if (pathname === '/sales' && filter.key === 'agent') {
        component = MultiSelect;
        options = getData('sales_agents+admins');
        onClickCallback = () => load('sales_agents+admins');
        load('sales_agents+admins').then();
    }

    if (pathname === '/products' && filter.key === 'category') {
        component = MultiSelect;
        onClickCallback = () => load('part_categories');
        load('part_categories').then();
        options = getData('part_categories');
    }

    if (pathname === '/products' && filter.key === 'type') {
        component = MultiSelect;
        options = partTypeList;
        formatOptions = formatPartTypeListStatus
    }

    if (filter.key === 'partStatus') {
        component = MultiSelect;
        options = partRealizationStatusList;
        formatOptions = formatPartRealizationStatus
    }

    if (pathname === '/purchases' && filter.key === 'supplier.id') {
        component = MultiSelect;
        options = getData('suppliers');
        onClickCallback = () => load('suppliers');
        load('suppliers').then();
    }

    // CONTRACT PROFITS
    if (filter.key === 'salesAgent') {
        component = MultiSelect;
        onClickCallback = () => load('sales_agents+admins');
        load('sales_agents+admins').then();
        options = getData('sales_agents+admins');
    }

    if (filter.key === 'isInvoiced') {
        component = SwitcherBox;
        options = [
            {
                label: 'Yes',
                value: 'true'
            },
            {
                label: 'No',
                value: 'false'
            }
        ]
    }

    if (pathname === '/contract_profits' && filter.key === 'status') {
        component = MultiSelect;
        options = contractStatusList;
        formatOptions = formatContractStatus
    }

    if (filter.key === 'customer') {
        component = MultiSelect;
        onClickCallback = () => load('customers');
        load('customers').then();
        options = getData('customers');
    }

    if (filter.key === 'invited') {
        component = MultiSelect;
        onClickCallback = () => load('customers');
        load('sales_agents+admins').then();
        options = getData('sales_agents+admins');
    }

    if (filter.key === 'customerName') {
        component = MultiSelect;
        onClickCallback = () => load('customerNames');
        load('customerNames').then();
        options = getData('customerNames');
    }

    if (filter.key === 'partner') {
        component = MultiSelect;
        onClickCallback = () => load('partners');
        load('partners').then();
        options = getData('partners');
    }

    if (filter.key === 'rate') {
        return (
            <div key={filter.key} onClick={onClickCallback} className="ls-form-wrapper">
                <Field
                    {...filter}
                    name={filter.key}
                    label={filter.label}
                    placeholder={' '}
                    key={index}
                    component={RangeInput}
                    size={filter.value ? filter.value.length : 0}/>
            </div>
        );
    }

    return (<div key={index} onClick={onClickCallback} className="ls-form-wrapper">
        <Field
            name={filter.key}
            {...filter}
            key={index}
            label={filter.label}
            placeholder={' '}
            {...inputProps}
            options={options}
            formatOptions={formatOptions}
            component={component}
            size={filter.value ? filter.value.length : 0}
        />
    </div>);
});
const FiltersForm = observer(({initialValues, filters, onSubmit}: FiltersFormProps) => {
    const {filterStore} = useStore();

    return (
        <Form onSubmit={onSubmit}
              initialValues={initialValues}>
            {({handleSubmit, pristine, submitting, values, form}) => {
                return (
                    <form className={'filters-list'} onSubmit={handleSubmit}>
                        {filters.length && <div className="filters-wrapper">
                            {filters.map((item, index) => <FilterItem key={item.key} index={index} filter={item}/>)}
                        </div>}

                        <div className="buttons">

                            {((!pristine && filterStore.contextPlace === 'filters_form') ||
                                (filterStore.isUnsavedActiveFilters)) &&
                            <SaveAndUpdateSegments type={'save'} values={values} store={filterStore}/>}
                            {filterStore.contextPlace === 'update_segment' &&
                            <SaveAndUpdateSegments type={'update'} values={values} store={filterStore}/>}


                            {filterStore.contextPlace === 'filters_form' && <div className="buttonGroup">
                                <Button className={'reset'} type='button' onClick={form.reset}>Reset filters</Button>
                                <Button className={'submit'} disabled={pristine || submitting} color='dark'
                                        type="submit">Apply
                                    filters</Button>
                            </div>}
                        </div>
                    </form>
                );
            }}
        </Form>
    );
});

type SaveAndUpdateSegmentsType = {
    type: 'save' | 'update',
    store: FilterStore
    values
}
const SaveAndUpdateSegments = ({type, store, values}: SaveAndUpdateSegmentsType) => {
    const [saveSegmentIsActive, setSaveSegmentIsActive] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const {ref} = useOnClickOutside(() => setSaveSegmentIsActive(false));

    const saveSegmentClasses = classNames({
        'save-segment': true,
        'secondaryBtn': !saveSegmentIsActive,
        'open': saveSegmentIsActive
    });

    const filtersSegmentsIco = store.contextPlace === 'filters_form' ? mdiPlus : mdiRefresh;

    if (type === 'update') {
        return (
            <Button className={'submit'}
                    type="submit">
                Update
            </Button>
        )
    }

    return (
        <div className={saveSegmentClasses}
             ref={ref}
             onClick={() => !saveSegmentIsActive && setSaveSegmentIsActive(true)}>
            {saveSegmentIsActive && <input autoFocus ref={ref => inputRef.current = ref} name={'segmentName'}/>}

            {saveSegmentIsActive &&
            <Button className={'filter-ico'}
                    type="submit"
                    onClick={() => {
                        store.addNewFilterSegment(inputRef.current.value, values)
                    }}>
                <Icon path={filtersSegmentsIco}/>
            </Button>}

            {!saveSegmentIsActive && <span> Save segment </span>}
        </div>
    )
};

export default FiltersForm;
