import React from 'react';
import {FinalButtonSet, Input, Select} from '../../components/ui/form';
import {useStore} from "../../context";
import {Field, Form} from "react-final-form";
import {observer} from "mobx-react";
import {CreatePartForMasterInput} from "../../utils/models";
import useDataListFetch from "../../utils/form/useDataListFetch";

const CreatePartStep3Form = observer(() => {
    const {customerStore} = useStore();
    const manufacturerDataList = useDataListFetch('manufacturers');

    return <Form
        onSubmit={customerStore.addDraftPart}
        initialValues={customerStore.draftPart}
        render={({handleSubmit, pristine, submitting, form}) => {
        return (<form onSubmit={handleSubmit}>
            <Field<CreatePartForMasterInput>
                name={"name"}
                label={"Name"}
                component={Input}
                required
            />
            <Field<CreatePartForMasterInput>
                name={"partNumber"}
                label={"Part Number"}
                component={Input}
                required
            />
            <Field<CreatePartForMasterInput>
                name={"modelNumber"}
                label={"Model Number"}
                component={Input}
                required
            />
            <Field<CreatePartForMasterInput>
                name="manufacturer"
                label="Manufacturer"
                component={Select}
                required
                {...manufacturerDataList}
            />
            <FinalButtonSet pristine={pristine} submitting={submitting} label={"Add part"} reset={form.reset}/>
        </form>)
    }}>
    </Form>
});


export default CreatePartStep3Form;
