import React from 'react';
import ResourceGridView from './../../components/grid/ResourceGridView';
import {useStore} from "../../context";
import StandalonePopover from "../../components/ui/StandalonePopover";
import ResourceUpdate from "./ResourceUpdate";
import ResourceCreate from "./ResourceCreate";
import {observer} from "mobx-react";

const ResourceGrid = observer(() => {
    const {genericResourceStore, authStore} = useStore();

    const canEdit = authStore.isGranted('ROLE_ADMIN') || authStore.isGranted('ROLE_ALL_ACCOUNT');

    genericResourceStore.configure('/api/suppliers', 'Supplier')


    return (
        <div className='dashboard'>
            {genericResourceStore.place === 'create' &&
            <StandalonePopover columnCount={2} title={'Create Supplier'} closePopover={() => genericResourceStore.changePlace('grid')}>
                <ResourceCreate sharedGridResourceCollectionStore={genericResourceStore} canEdit={canEdit}/>
            </StandalonePopover>}
            {genericResourceStore.place === 'update' &&
            <StandalonePopover columnCount={2} title={`${genericResourceStore.label} #${genericResourceStore.currentResourceId} update`}
                               closePopover={() => genericResourceStore.changePlace('grid')}>
                <ResourceUpdate canRemove={canEdit} sharedGridResourceCollectionStore={genericResourceStore}
                                canEdit={canEdit}/>
            </StandalonePopover>}
            <ResourceGridView<any>
                columns={[
                    {
                        name: 'Invoice',
                        value: (item) => (
                            <div>
                                <div className="title">{item.name}</div>
                                <div className="other-info">
                                    {item['@id'].split('/').reverse()[0]}
                                    {item.contactName ? <span className={'dot'}>•</span> : ''}
                                    {item.contactName}
                                </div>
                            </div>
                        ),
                    },
                    {
                        name: 'address',
                        value: (item) => (
                            <div>{item.addresses && item.addresses.length ? item.addresses[0].searchText :
                                <span className={'not-available'}>N/A</span>}</div>
                        ),
                    },
                    {
                        name: 'Email',
                        value: (item) => (
                            <div>{item.email ? item.email : <span className={'not-available'}>N/A</span>}</div>
                        ),
                    },
                    {
                        name: 'Phone',
                        value: (item) => (
                            <div>{item.contactNumber ? item.contactNumber :
                                <span className={'not-available'}>N/A</span>}</div>
                        ),
                    },
                ]}
                trProps={(item) => {
                    if (!item.approved) {
                        return {className: 'not-approved'};
                    }
                }}
                filterKeys={{
                    name: 'Supplier Name',
                    contactNumber: 'Phone',
                    email: 'Email',
                    contactName: 'Contact Person',
                }}
                onRowClick={(item) => {
                    genericResourceStore.changePlace('update', item)
                }}
                onCreate={() => genericResourceStore.changePlace('create')}


                gridResource={genericResourceStore}
                tableClass={'suppliers'}
            />
        </div>
    );
})


export default ResourceGrid;
