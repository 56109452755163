import React, {useState} from 'react';
import {PartStore} from "../../store/mobx/PartStore";
import {CheckboxToggleList} from "../../components/ui";
import {Iri, PartRealizationShortModel} from "../../utils/models";
import {PartRealizationStatusType} from "../../store/enum/partEnumeration";

type RealizationAssignFormProps = {
    realizationsList: Array<PartRealizationShortModel>,
    newStatus: PartRealizationStatusType,
    oldStatus: PartRealizationStatusType,
    partStore: PartStore
    closePopover
}

const RealizationAssignForm = ({realizationsList, oldStatus, newStatus, partStore, closePopover}: RealizationAssignFormProps) => {
    const [chosenRealizationsList, setChosenRealizationsList] = useState<Iri[]>([]);

    return (<form>
        {newStatus && <div>
            You are going to take following parts from {oldStatus} to {newStatus}<br/><br/>
        </div>}
        <CheckboxToggleList data={realizationsList} chosen={chosenRealizationsList}
                            setChosen={setChosenRealizationsList} renderLabelFn={(data => data.serialNumber)}/>
        <div className={'relative-buttons'}>
            <button className={'secondaryBtn'} onClick={closePopover}>Cancel</button>
            <button className={'tertiaryBtn'}
                    disabled={chosenRealizationsList.length === 0}
                    onClick={async (e) => {
                        e.preventDefault();
                        await partStore.changeRealizationsStatus(chosenRealizationsList, oldStatus, newStatus)
                        closePopover();
                    }}>Save
            </button>
        </div>
    </form>);
};

export default RealizationAssignForm;
