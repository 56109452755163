import {CollectionResponseType, GridResourceCollectionInterface} from "../interfaces/GridResourceCollectionInterface";
import ApiClient from "../ApiClient";
import {action, observable} from "mobx";
import moment from "moment";
import {formatCurrency} from "../../containers/opportunities/OpportunityHelper";
import ReportDownloader from "../service/ReportDownloader";
import {idFromIri} from "../../utils/iri";
import {IFullSaleModel, IShortSaleModel} from "../../utils/models";

export class SalesStore implements GridResourceCollectionInterface<IShortSaleModel> {
    @observable collectionResponse: CollectionResponseType<IShortSaleModel> | null;
    @observable currentSale: IFullSaleModel | null;
    @observable isLoading: boolean = false;
    @observable isLoadingItem: boolean = false;
    getEntityName: 'Sale';
    constructor(private apiClient: ApiClient, private reportDownloader: ReportDownloader) {
    }

    @action toggleLoading = (): void => {
        this.isLoading = !this.isLoading;
    };

    @action fetchCollection = (query): void => {
        this.isLoading = true;
        this.apiClient.salesCollectionFetch({params: query}).then(
            action("SalesStore fetchCollection ok", response => {
                this.collectionResponse = response;
                this.isLoading = false;
            })
        ).catch(action("SalesStore fetchCollection failed", e => {
            this.isLoading = false;
        }));
    }

    @action fetchItem = (id): void => {
        this.isLoadingItem = true;
        this.apiClient.salesItemFetch(id).then(
            action("SalesStore fetchItem ok", response => {
                this.currentSale = response;
                this.isLoadingItem = false;
            })
        ).catch(action("SalesStore fetchItem failed", e => {
            this.isLoadingItem = false;
        }));
    };

    @action updateSales = (value, id) => {
        this.isLoading = true;
        this.apiClient.salesUpdate(value, id).then(
            action("update SalesStore ok", response => {
                this.currentSale = {
                    ...this.currentSale,
                    isInvoiced: response.isInvoiced,
                    invoicedAt: response.invoicedAt,
                    invoiceNumber: response.invoiceNumber
                };

                this.isLoading = false;
            })
        ).catch(action("update SalesStore DeliveryCost failed", e => {
            this.isLoading = false;
        }));

    }

    @action fetchCollectionReport(query, sendReportToJournalClb): void {
        this.reportDownloader.download('Sale', this, this.apiClient.salesCollectionFetch(query), (item) => {
            const createdAt = moment(item.createdAt);
            const contract = item.contracts.length > 0 ? item.contracts[0] : null;
            return {
                'id': idFromIri(item['@id']).toString(),
                'opportunity title': item.title,
                'customer': item.customer ? item.customer.name : '',
                'source': item.source ? item.source : '',
                'value': item.totalValue ? formatCurrency(parseFloat(item.totalValue)) : '0',
                'invoice number': item.invoiceNumber,
                'invoice status': item.isInvoiced ? 'invoiced' : 'not invoiced',
                'total value': item.totalValue ? formatCurrency(item.totalValue) : null,
                'created at': createdAt.format('DD MMM \'YY HH:mm'),
                'type': item.type,
                'sales agent': item.agent ? item.agent.username : '',
                'contract': contract ? idFromIri(contract["@id"]) : '',
                'systems': contract?.systemModelNumbers,
                'part numbers': contract?.partNumbers
            }
        })
    }
}
