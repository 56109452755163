import React, {useEffect} from "react";
import {useQuery} from "./utils/query";


export const Impersonate = function () {
    const {jwt} = useQuery();

    useEffect(() => {
        if (jwt) {
            localStorage.clear();
            localStorage.setItem('token', jwt as string);
            window.location.pathname = '/'
        }
    }, [jwt])
    return (<p>Impersonating...</p>);
}