import React, {useState} from "react";
import {Icon} from "@mdi/react";
import {mdiDotsHorizontal, mdiPlus} from "@mdi/js";
import {useOnClickOutside} from "../../utils/hooks/useOnClickOutside";

type FloatButtonsType = {
    children,
    type: 'normal' | 'contract',
    icon?
}

const FloatButtons = ({children, type = 'normal', icon = mdiDotsHorizontal}: FloatButtonsType) => {

    const {ref} = useOnClickOutside(() => setShowContextMenu(false));

    const [showContextMenu, setShowContextMenu] = useState(false);
    return (
        <div ref={ref} onClick={() => setShowContextMenu(!showContextMenu)}
             className={[`context-menu`, showContextMenu ? 'active' : '', type === 'contract' ? 'contract' : ''].join(' ')}>
            {!showContextMenu ? <Icon path={icon}/> : <Icon path={mdiPlus}/>}
            {showContextMenu && children}
        </div>
    )
};

export default FloatButtons
