import React from 'react';
import {Field} from 'react-final-form'
import {Input} from "./index";

const RangeInput = (field) => {
    return (
        <div className="ls-form ls-range">
            <label>{field.label}</label>
            <div className="ls-range-inputs">
                <Field
                    name={`${field.input.name}.gte`}
                    className="right-input"
                    placeholder={' '}
                    type="text"
                    component={Input}
                    size={field.input.value['gte'] ? field.input.value['gte'].length : 1}
                />
                <span>-</span>
                <Field
                    name={`${field.input.name}.lte`}
                    className="left-input"
                    placeholder={' '}
                    type="text"
                    component={Input}
                    size={field.input.value['lte'] ? field.input.value['lte'].length : 1}
                />
            </div>
        </div>
    );
};

export default RangeInput;
