import remotedev from "mobx-remotedev";
import ApiClient from "../ApiClient";
import {action, computed, observable, runInAction} from "mobx";
import UiBus from "../service/UiBus";
import {idFromEntity, idFromIri} from "../../utils/iri";
import {SubmissionErrors} from "final-form";
import {handleFormSubmit} from "../../utils/form/handleFormSubmit";
import {RouterStore} from "mobx-react-router";
import {ItemResourceInterface} from "../interfaces/ItemResourceInterface";
import {AuthStore} from "./AuthStore";
import OpportunityPermissionResolver from "../service/OpportunityPermissionResolver";
import {formatQuoteStatus, QuoteStatusType} from "../enum/opportunityEnumeration";
import {
    IAttachmentModel,
    IItemOpportunityModel,
    IQuoteModel,
    IQuoteWriteModel,
    Iri,
    ITermsAndConditionsModel,
    QuotePartInfoModel,
    QuoteSystemInfoRead
} from "../../utils/models";
import {FileAttachmentsInterface} from "../interfaces/FileAttachmentsInterface";
import AttachmentManager from "../service/AttachmentManager";
import {CollectionResponseType, GridResourceCollectionInterface} from "../interfaces/GridResourceCollectionInterface";
import {ActivitiesLogInterface} from "../../containers/activity_log/ActivitiesLogInterface";
import {fromPromise, IPromiseBasedObservable} from "mobx-utils";
import {ActivityReadModelOutput} from "../../generated";

type QuotePlace = 'create' | 'update';
type QuoteContextPlace = 'attachments' | 'decline' | 'activity_log'
type QuoteConvertResponse = { startDate: string }

@remotedev({global: true})
export class OpportunityQuoteStore implements GridResourceCollectionInterface<IQuoteModel>, ItemResourceInterface<IQuoteModel>, FileAttachmentsInterface, ActivitiesLogInterface {
    @observable isLoading: boolean = false;
    @observable currentOpportunity: IItemOpportunityModel | null;
    @observable currentQuote: IQuoteModel | null;
    @observable currentPlace: QuotePlace | null;
    @observable currentContextPlace: QuoteContextPlace | null;
    @observable collectionResponse: CollectionResponseType<IQuoteModel> | null;
    @observable private _termsAndConditions: CollectionResponseType<ITermsAndConditionsModel> | null;
    private permissionResolver: OpportunityPermissionResolver;
    private attachmentManager: AttachmentManager;

    @observable public activities: IPromiseBasedObservable<ActivityReadModelOutput[]>|null = null;

    @action fetchActivities(): void {
        this.activities = fromPromise(this.apiClient.quoteItemFetch(this.id).then((r) => r.activities));
    }
    constructor(private apiClient: ApiClient, private authStore: AuthStore, private uiBus: UiBus, private routing: RouterStore) {
        this.permissionResolver = new OpportunityPermissionResolver(authStore);
        this.attachmentManager = new AttachmentManager(apiClient, this, uiBus);
    }

    getEntityName: 'Quote';

    @computed get termsAndConditions() {
        if (!this._termsAndConditions) {
            return [];
        }
        return this._termsAndConditions['hydra:member'];
    }

    @computed
    public get normalized() {

        if (!this.currentQuote) {
            return null;
        }

        const convertQuotePart = (type): Partial<QuotePartInfoModel>[] => {
            return this.currentQuote.quotePartInfos.slice().map(quotePartInfo => {
                return {
                    ...quotePartInfo,
                    quoteRealizationInfos: quotePartInfo.quoteRealizationInfos.slice().map(realizationItem => {
                        return {
                            ...realizationItem, ...{
                                stockRealization: realizationItem.stockRealization
                                    ? realizationItem.stockRealization['@id']
                                    : null
                            }
                        }
                    })
                }
            }).filter(quotePartInfo => quotePartInfo.part.type === type)
        };

        const convertQuoteSystem = (): Partial<QuoteSystemInfoRead>[] => {
            return this.currentQuote.quoteSystemInfos.slice().map(quoteSys => {
                return {
                    ...quoteSys,
                    quoteSystemRealizationInfo: quoteSys.stockRealization
                        ? quoteSys.stockRealization['@id']
                        : null
                }
            })
        };

        return {
            type: this.currentQuote.type,
            purchaseOrder: this.currentQuote.purchaseOrder,
            notes: this.currentQuote.notes,
            termsAndConditions: this.currentQuote.termsAndConditions,
            slaTime: this.currentQuote.slaTime,
            duration: this.currentQuote.duration,
            description: this.currentQuote.description,
            subcontractor: this.currentQuote.subcontractor,
            totalValue: this.currentQuote.totalValue,
            ticketsNumber: this.currentQuote.ticketsNumber,
            hoursNumber: this.currentQuote.hoursNumber,
            period: this.currentQuote.period,
            rate: this.currentQuote.rate,
            timeMinStep: String(this.currentQuote.timeMinStep),
            quoteSystemInfos: convertQuoteSystem().slice(),
            quoteProjectInfos: this.currentQuote.quoteProjectInfos.slice(),
            quotePartSoftwareInfos: convertQuotePart('software').slice(),
            quotePartHardwareInfos: convertQuotePart('hardware').slice(),
        }
    }

    @computed get iri() {
        return this.currentQuote ? this.currentQuote['@id'] : null;
    }

    @computed get canEdit(): boolean {
        return this.permissionResolver.canEdit(this.currentOpportunity);
    }

    @computed
    public get id(): number {
        return this.currentQuote ? idFromEntity(this.currentQuote) : null;
    }

    @computed get fetchAttachments(): IAttachmentModel[] {
        if (!this.currentQuote || !this.currentQuote.attachments) {
            return [];
        }
        return this.currentQuote ? this.currentQuote.attachments : [];
    }

    @action public resetPlace = (): void => {
        this.currentQuote = null;
        this.currentOpportunity = null;
        this.currentPlace = null;
    };

    @action public clearContextPlace = (): void => {
        this.currentContextPlace = null;
    };

    @action public goDeclineQuote = (): void => {
        this.currentContextPlace = 'decline';
    };

    @action public goExportQuote = async () => {
        this.toggleLoading();
        await this.apiClient.quoteExport(this.id);
        this.toggleLoading();
    };

    @action public goActivityLog = (): void => {
        this.currentContextPlace = 'activity_log';
    };

    @action public goAttachments = (): void => {
        this.currentContextPlace = 'attachments';
    };

    @action goUpdate = (item: IQuoteModel): void => {
        this.currentPlace = 'update';
        this.routing.history.push({pathname: `/opportunities/${idFromIri(item.opportunity)}/quotes/${idFromEntity(item)}`})
    };

    @action convert = async (e) => {
        return this.uiBus.convertQuote("Covert quote?", e).then((response: QuoteConvertResponse) => {
            this.toggleLoading();
            return handleFormSubmit(this.apiClient.quoteConvert(this.id, response.startDate)).then(action("convert ok", (res) => {
                if (res.errors) {
                    this.uiBus.notify(res.errors[Object.keys(res.errors)[0]], "danger");
                } else {
                    this.uiBus.notify(`Quote #${this.id} converted`, "info");
                    this.currentQuote.status = "converted";
                }
                this.toggleLoading();
            }))
        })
    };

    @action clone = async () => {
        this.toggleLoading();
        return handleFormSubmit(this.apiClient.cloneQuote(this.iri).then(action("clone ok", (res) => {
            this.toggleLoading();
            this.goUpdate(res);
        })));
    }

    @action createActivities = async (values): Promise<SubmissionErrors | null> => {
        const request = this.apiClient.addOpportunityQuoteComment(this.iri, values);
        const {errors} = await handleFormSubmit(request);
        if (!errors) {
            this.uiBus.notify(`Added comment to quote #${idFromEntity(this.currentQuote)}`, 'success');
            this.fetchItem(idFromEntity(this.currentQuote));
        }
        return errors;
    };

    @action markAsSent = (id) => {
        return this.apiClient.quoteUpdate(id, {status: "sent"}).then(() => {
            this.uiBus.notify(`Quote #${id}: status changed: ${formatQuoteStatus("sent")}`, "info");
        })
    };

    @action delete = (e) => {
        return this.uiBus.confirm("Remove quote?", e).then(() => {
            this.toggleLoading();
            return this.apiClient.quoteRemove(this.id).then((res) => {
                this.toggleLoading();
                this.uiBus.notify("Quote removed", "info");
                return this.fetchCollection({opportunity: this.currentOpportunity['@id']});
            }).catch(e => {
                this.toggleLoading()
                this.uiBus.notify("Something went wrong", "warning");
                throw e;
            });
        })
    };

    @action goCreate = (): void => {
        this.currentPlace = 'create';
        this.currentQuote = null;
    };

    @action toggleLoading = (): void => {
        this.isLoading = !this.isLoading;
    };

    @action public markQuoteAsSent = () => {
        const status: QuoteStatusType = 'sent';
        return this.updateQuote({status: status}).then(action("markQuoteAsSent ok", () => {
            this.currentQuote.status = status;
        }));
    };

    @action createQuote = async (opportunity: Iri, values): Promise<SubmissionErrors | null> => {
        const request = this.apiClient.quoteCreate({
            ...{
                opportunity: opportunity,
            }, ...values
        });
        const {errors, response} = await handleFormSubmit(request);
        if (!errors) {
            this.uiBus.notify(`Quote created`, 'success');
            this.currentQuote = response;
            this.goUpdate(response)
        }
        return errors;
    };

    @action declineQuote = async (values): Promise<SubmissionErrors | null> => {
        const quoteId = idFromEntity(this.currentQuote);
        const request = this.apiClient.quoteDecline(quoteId, values);
        const {errors} = await handleFormSubmit(request);
        return runInAction("declineQuote ok", () => {
            if (!errors) {
                this.clearContextPlace();
                this.uiBus.notify(`Quote #${quoteId} declined`, 'success');
                this.currentQuote.status = "declined";
            }
            return errors;
        })
    }

    @action updateQuote = async (values): Promise<SubmissionErrors | null> => {
        const hydratedValues: Partial<IQuoteWriteModel> = {...values};
        if (values.quotePartHardwareInfos || values.quotePartSoftwareInfos) {
            hydratedValues.quotePartInfos = [];
        }

        if (values.quotePartHardwareInfos) {
            values.quotePartHardwareInfos.every(item => hydratedValues.quotePartInfos.push({
                ...item,
                quantity: Number(item.quantity),
                price: String(item.price),
                part: item.part['@id']
            }));
        }
        if (values.quotePartSoftwareInfos) {
            values.quotePartSoftwareInfos.every(item => hydratedValues.quotePartInfos.push({
                ...item,
                quantity: Number(item.quantity),
                price: String(item.price),
                part: item.part['@id'] ? item.part['@id'] : item.part
            }));
        }
        if (values.quoteSystemInfos) {
            hydratedValues.quoteSystemInfos = hydratedValues.quoteSystemInfos.map(item => {
                let sysAsProduct = null;
                if (item.systemAsProduct) {
                    sysAsProduct = item.systemAsProduct['@id'] ? item.systemAsProduct['@id'] : item.systemAsProduct
                }
                let sysAsExample = null;
                if (item.systemAsExample) {
                    sysAsExample = item.systemAsExample['@id'] ? item.systemAsExample['@id'] : item.systemAsExample
                }

                return {
                    ...item,
                    quantity: Number(item.quantity),
                    price: String(item.price),
                    systemAsProduct: sysAsProduct,
                    systemAsExample: sysAsExample,
                    quoteSystemRealizationInfo: item.stockRealization ? item.stockRealization['@id'] : null
                }
            });
        }

        if (values.duration) {
            hydratedValues.duration = Number(values.duration)
        }

        hydratedValues.totalValue = String(values.totalValue);

        if (values.ticketsNumber) {
            hydratedValues.ticketsNumber = Number(values.ticketsNumber);
        }

        if (values.hoursNumber) {
            hydratedValues.hoursNumber = Number(values.hoursNumber);
        }

        if (values.timeMinStep) {
            hydratedValues.timeMinStep = Number(values.timeMinStep);
        }

        if (values.primenetsCost) {
            hydratedValues.primenetsCost = Number(values.primenetsCost).toFixed(2);
        }

        hydratedValues.rate = String(values.rate)

        if (this.currentQuote.rate && !values.rate) {
            hydratedValues.rate = null;
        }

        if (values.slaTime && values.slaTime['@id']) {
            hydratedValues.slaTime = values.slaTime['@id'];
        }

        const quoteId = idFromEntity(this.currentQuote);
        const request = this.apiClient.quoteUpdate(quoteId, hydratedValues);
        const {errors, response} = await handleFormSubmit(request);
        return runInAction("updateQuote ok", () => {
            if (!errors) {
                this.fetchItem(idFromEntity(response)).then(() => {
                    this.uiBus.notify(`Quote #${quoteId} updated`, 'success');
                })
            }
            return errors;
        })

    };

    @action fetchCollection = (query): void => {
        this.isLoading = true;
        this.apiClient.quoteCollectionFetch(query).then(
            action("fetchCollection ok", response => {
                this.collectionResponse = response;
                this.isLoading = false;
            })
        ).catch(action("fetchCollection failed", e => {
            this.isLoading = false;
            this.uiBus.notify("Something went wrong", "warning");
        }));
    };

    @action fetchTermsAndConditions = (): void => {
        this.apiClient.termsAndConditionsCollectionFetch({id: this.currentQuote.termsAndConditions}).then(
            action("fetchTermsAndConditions ok", response => {
                this._termsAndConditions = response;
            })
        )
    };

    @action populateQuote = (quote: IQuoteModel) => {
        this.currentQuote = quote;
    };

    @action fetchItem = (id) => {
        this.isLoading = true;
        return this.apiClient.quoteItemFetch(id).then(
            action("fetchItem ok", response => {
                this.currentQuote = response;
                return this.apiClient.opportunityItemFetch(idFromIri(response.opportunity)).then(action("opportunityItemFetch ok", ((response) => {
                    this.currentOpportunity = response;
                    this.isLoading = false;
                })));
            })
        ).catch(action("fetchItem failed", e => {
            this.isLoading = false;
            this.uiBus.notify("Something went wrong", "warning");
        }));
    };

    @action public saveAttachments = (files: FormData): void => {
        this.attachmentManager.persist(this.apiClient.quoteAttachmentsUpdate(idFromEntity(this.currentQuote), files)).then(action("saveAttachments ok", (res) => {
            this.currentQuote.attachments = res;
        }))
    };

    canRemoveAttachment(attachment: IAttachmentModel): boolean {
        return this.canEdit;
    }

    @action public removeAttachment = (item: IAttachmentModel): void => {
        this.attachmentManager.remove(item).then(action("removeAttachment ok", () => {
            this.currentQuote.attachments = this.currentQuote.attachments.filter(a => a['@id'] !== item['@id']);
        }));
    };

    @computed get idForActivityLogParent(): string {
        return this.currentQuote ? this.currentQuote["@id"] : '';
    }

}
