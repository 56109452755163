import FileUploader from "../../components/ui/widgets/FileUploader";
import {Icon} from "@mdi/react";
import {
    mdiArrowLeftBoldCircle,
    mdiCreditCardPlusOutline,
    mdiCubeSend,
    mdiDatabasePlus,
    mdiDotsHorizontal,
    mdiFileRemove,
    mdiFileRestore,
    mdiImagePlus,
    mdiPlus,
    mdiPlusNetwork,
    mdiServerPlus,
    mdiTrashCan
} from "@mdi/js";
import React, {useState} from "react";
import {observer} from "mobx-react";
import {useOnClickOutside} from "../../utils/hooks/useOnClickOutside";
import {useStore} from "../../context";
import RequesterCreateForm from "./RequesterCreateForm";
import ExpenseSelect from "../settings/expenses/ExpenseSelect";
import StandalonePopover from "../../components/ui/StandalonePopover";
import ProjectDetailsForm from "./ProjectDetailsForm";
import ContractDetailsForm from "./Сontract/ContractDetailsForm";
import ContractSelectTypeForm from "./Сontract/ContractSelectTypeForm";
import CreateTicketForm from "./CreateTicketForm";
import PartTypeSelectForm from "../parts/PartTypeSelectForm";
import CreatePartStep2Form from "./CreatePartStep2Form";
import {useHistory} from "react-router";
import {idFromIri} from "../../utils/iri";
import CreatePartStep3Form from "./CreatePartStep3Form";
import ContractReassignForm from "./Сontract/ContractReassignForm";
import SystemCreateForm from "../systems/SystemCreateForm";

const CustomerPageButtonList = observer(() => {
    const [showContextMenu, setShowContextMenu] = useState(false);

    const {ref} = useOnClickOutside(() => setShowContextMenu(false));
    const {customerStore} = useStore();
    const history = useHistory();

    return <>
        {customerStore.contextPlace === 'attachments' &&
        <StandalonePopover title={"Attachments"} size={"bd"} closePopover={customerStore.clearContextPlace}>
            <FileUploader attachmentStore={customerStore.attachmentStore}/>
        </StandalonePopover>
        }
        {customerStore.contextPlace === 'add_requester' &&
        <StandalonePopover title={"Add requester"} closePopover={customerStore.clearContextPlace}>
            <RequesterCreateForm/>
        </StandalonePopover>
        }
        {customerStore.contextPlace === 'expenses' &&
        <StandalonePopover title={"Add expense"} closePopover={customerStore.clearContextPlace}>
            <ExpenseSelect onFormSubmit={customerStore.addContractExpense} canEdit={customerStore.canEdit}/>
        </StandalonePopover>}
        {customerStore.contextPlace === 'create_system' &&
        <StandalonePopover title={"Add system"} columnCount={2} classNames={'scrollable'}
                           closePopover={customerStore.clearContextPlace}>
            <SystemCreateForm onSubmit={customerStore.addSystem}
                        initialValues={{slaTime: customerStore.contractStore.currentContract.slaTime}}/>
        </StandalonePopover>}
        {customerStore.contextPlace === 'create_contract_select_type' &&
        <StandalonePopover columnCount={1} title={"Add contract | Select type"}
                           closePopover={customerStore.clearContextPlace}>
            <ContractSelectTypeForm/>
        </StandalonePopover>}
        {customerStore.contextPlace === 'create_contract' &&
        <StandalonePopover size={'ld'} columnCount={3}
                           title={`Add contract | ${customerStore.contractStore.contractType}`}
                           closePopover={customerStore.clearContextPlace}>
            <ContractDetailsForm mode={"RELATIVE"}/>
        </StandalonePopover>}
        {customerStore.contextPlace === 'contract_reassign' &&
        <StandalonePopover title={`Reassign contract to `}
                           closePopover={customerStore.clearContextPlace}>
            <ContractReassignForm onSubmit={customerStore.reassignContract}/>
        </StandalonePopover>}
        {customerStore.contextPlace === 'part_type_select' &&
        <StandalonePopover size={"md"} title={`Add Part | Step 1`}
                           closePopover={customerStore.clearContextPlace}>
            <PartTypeSelectForm partStore={customerStore.partStore}/>
        </StandalonePopover>}
        {customerStore.contextPlace === 'part_second_step' &&
        <StandalonePopover size={"md"} title={`Add Part (${customerStore.partStore.partType}) | Step 2`}
                           closePopover={customerStore.clearContextPlace}>
            <CreatePartStep2Form/>
        </StandalonePopover>}
        {customerStore.contextPlace === 'part_third_step' &&
        <StandalonePopover size={"md"} title={`Add Part (${customerStore.partStore.partType}) | Step 3`}
                           closePopover={customerStore.clearContextPlace}>
            <CreatePartStep3Form/>
        </StandalonePopover>}
        {customerStore.contextPlace === 'create_ticket' &&
        <StandalonePopover title={`Create ticket`}
                           closePopover={customerStore.clearContextPlace}>
            <CreateTicketForm/>
        </StandalonePopover>}
        {(customerStore.contextPlace === 'create_project' || customerStore.contextPlace === 'create_project_with_network_support') &&
        <StandalonePopover classNames={'scrollable'}
            title={customerStore.contextPlace === 'create_project' ? "Add project" : "Add network support"}
            closePopover={customerStore.clearContextPlace}>
            <ProjectDetailsForm customerStore={customerStore} mode={"RELATIVE"}/>
        </StandalonePopover>}
        {customerStore.renderContextMenu && !customerStore.isLoading &&
        <div ref={ref} onClick={() => {
            setShowContextMenu(!showContextMenu)
        }} className={!showContextMenu ? 'context-menu' : 'context-menu active'}>
            {!showContextMenu ? <Icon path={mdiDotsHorizontal}/> : <Icon path={mdiPlus}/>}
            {showContextMenu && <div className={'context-menu-list'}>
                {customerStore.canAddContract &&
                <div className="context-menu__item"
                     onClick={() => customerStore.changeContextPlace('create_contract_select_type')}>
                    <Icon path={mdiCreditCardPlusOutline}/>
                    Add contract
                </div>}
                {customerStore.canShowExpense &&
                <div className="context-menu__item" onClick={() => {
                    customerStore.changeContextPlace('expenses');
                }}>
                    <Icon path={mdiCreditCardPlusOutline}/>
                    Add expense
                </div>}

                {customerStore.canAddTicket &&
                <div className="context-menu__item" onClick={() => {
                    customerStore.changeContextPlace('create_ticket');
                }}>
                    <Icon path={mdiPlus}/>
                    Add ticket
                </div>}

                {customerStore.canUnassign &&
                <div className="context-menu__item" onClick={customerStore.unassignSystem}>
                    <Icon path={mdiFileRemove}/>
                    Unassign system
                </div>}

                {customerStore.canAddSystem &&
                <div className="context-menu__item" onClick={() => customerStore.changeContextPlace('create_system')}>
                    <Icon path={mdiServerPlus}/>
                    Add system
                </div>}
                {customerStore.canAddProject &&
                <div className="context-menu__item" onClick={() => {
                    customerStore.changeContextPlace('create_project_with_network_support')
                }}>
                    <Icon path={mdiDatabasePlus}/>
                    Add network support
                </div>}
                {customerStore.canAddProject && customerStore.contractStore.currentContract.type !== 'maintenance' &&
                <div className="context-menu__item" onClick={() => {
                    customerStore.changeContextPlace('create_project')
                }}>
                    <Icon path={mdiDatabasePlus}/>
                    Add project
                </div>}
                {customerStore.canAddPart && <div className="context-menu__item" onClick={() => {
                    customerStore.changeContextPlace('part_type_select');
                }}>
                    <Icon path={mdiCubeSend}/>
                    Add Part
                </div>}
                {customerStore.canRemovePart &&
                <div className="context-menu__item" onClick={customerStore.removePart}>
                    <Icon path={mdiTrashCan}/>
                    Remove Part
                </div>}
                {customerStore.activeTab !== 'project' && <div className="context-menu__item" onClick={() => {
                    customerStore.changeContextPlace('attachments');
                }}>
                    <Icon path={mdiImagePlus}/>
                    Add file
                </div>}
                {customerStore.canAddRequester &&
                <div className="context-menu__item" onClick={() => customerStore.changeContextPlace('add_requester')}>
                    <Icon path={mdiPlusNetwork}/>
                    Add requester
                </div>}
                {customerStore.canRenewContract &&
                (customerStore.contractStore.currentContract.renewalOpp
                        ? <div className="context-menu__item" onClick={() => {
                            history.push({pathname: `/opportunities/${idFromIri(customerStore.contractStore.currentContract.renewalOpp)}`})
                        }}>
                            <Icon path={mdiFileRestore}/>
                            Go to renewal quote
                        </div>
                        : <div className="context-menu__item" onClick={customerStore.contractStore.renewContract}>
                            <Icon path={mdiFileRestore}/>
                            Add renewal quote
                        </div>
                )}
                {customerStore.canDisassembleContract &&
                <div className="context-menu__item" onClick={customerStore.contractStore.disassembleContract}>
                    <Icon path={mdiFileRemove}/>
                    Disassemble contract
                </div>}
                {customerStore.canReassignContract &&
                <div className="context-menu__item" onClick={() => customerStore.changeContextPlace('contract_reassign')}>
                    <Icon path={mdiArrowLeftBoldCircle}/>
                    Reassign contract
                </div>}
            </div>}
        </div>}
    </>
})

export default CustomerPageButtonList;
