import {useEffect, useState} from "react";
import useApiClient from "./useApiClient";

export type DataListStoreItemType =
    | 'engineers+admins'
    | 'engineers+sales_agents+admins'
    | 'admins'
    | 'systems'
    | 'systems_with_iri'
    | 'sla_times'
    | 'expense_types'
    | 'opportunity_types'
    | 'user_specializations'
    | 'terms_and_conditions'
    | 'services'
    | 'model_numbers'
    | 'cancellation_reasons'
    | 'courier_companies'
    | 'partners'
    | 'ticket_categories'
    | 'manufacturers'
    | 'sales_agents+admins'
    | 'customers'
    | 'part_categories'
    | 'part_families'
    | 'part_series'
    | 'projects'
    | 'customerNames'
    | 'suppliers'
    | 'invited';

const useDataListFetch = (type: DataListStoreItemType, deps = {}, clearable: boolean = false) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const {client} = useApiClient();
    useEffect(() => {
        let unmounted = false;
        const axiosClient = client.client;
        const source = axiosClient.CancelToken.source();
        const request = client.loadDataList(type, deps, {cancelToken: source.token})
        request
            .then(a => {
                if (!unmounted) {
                    setData(a || []);
                    setLoading(false);
                }
            }).catch((e) => {
            if (!unmounted) {
                setLoading(false);
                if (axiosClient.isCancel(e)) {
                    /*eslint no-console: "off"*/
                    console.log(`request cancelled:${e.message}`);
                } else {
                    console.log("another error happened:" + e.message);
                }
            }
        });
        return function () {
            unmounted = true;
            source.cancel("Cancelling in cleanup");
        };
    }, [JSON.stringify(deps)]);

    return {options: data, isLoading: loading, isSearchable: true, isClearable: clearable};
};

export default useDataListFetch;
