import React from 'react';
import FiltersForm from './FiltersForm';
import {activeFilters, flatActiveFilters} from "../../utils/filters";
import {useQuery} from "../../utils/query";
import {useStore} from "../../context";
import {observer} from "mobx-react";

type FilterProps = {
    filterKeys,
}

const Filters = observer(({filterKeys}: FilterProps) => {
    const query = useQuery();
    const {filterStore} = useStore();
    return (
        <div className={'filters-form'}>
            <FiltersForm
                filters={activeFilters(filterKeys, false, query)}
                onSubmit={filterStore.onSubmitFilterForm}
                initialValues={flatActiveFilters(filterKeys, false, query)}
            />
        </div>
    );
});

export default Filters;