import React from 'react';

import {RadioGroup} from '../../components/ui/form';
import {Button} from '../../components/ui';

import {Field, Form} from "react-final-form";
import {observer} from "mobx-react";
import {PartStore} from "../../store/mobx/PartStore";
import {formatPartTypeListStatus, partTypeList} from "../../store/enum/partEnumeration";

type PartTypeSelectFormProps = {
    partStore: PartStore
}
const PartTypeSelectForm = observer(({partStore}: PartTypeSelectFormProps) => {

    return (
        <Form onSubmit={partStore.partTypeSelectFirstStep} initialValues={{
            type: 'hardware'
        }} render={({handleSubmit}) =>
            <form onSubmit={handleSubmit}>
                <Field
                    name="type"
                    label="Type"
                    component={RadioGroup}
                    options={partTypeList}
                    formatOptions={formatPartTypeListStatus}
                />
                <div className="relative-buttons">
                    <Button className={'roundButton tertiaryBtn'} type="submit"> Next</Button>
                </div>

            </form>
        }/>
    );
});


export default PartTypeSelectForm;
