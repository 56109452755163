import {action, computed, observable, runInAction} from "mobx";
import remotedev from 'mobx-remotedev';
import ApiClient from "../ApiClient";
import {CollectionResponseType, GridResourceCollectionInterface} from "../interfaces/GridResourceCollectionInterface";
import {handleFormSubmit} from "../../utils/form/handleFormSubmit";

import UiBus from "../service/UiBus";

type PlaceType = 'grid' | 'update' | 'create';

@remotedev({global: true})
export class GenericResourceStore implements GridResourceCollectionInterface<any> {
    @observable collectionResponse: CollectionResponseType<any> | null;
    @observable isLoading: boolean = false;
    @observable currentResource = null;
    @observable place: PlaceType = 'grid';
    private _uri: string;

    constructor(private apiClient: ApiClient, private uiBus: UiBus) {}

    getEntityName: 'Generic';
    private _label: string;

    public get label(): string {
        return this._label;
    }

    @computed get currentResourceId() {
        if (this.currentResource) {
            return this.currentResource['@id'].split('/').reverse()[0]
        }
        return null;
    }

    public configure(uri: string, resourceLabel: string) {
        this._uri = uri;

        this._label = resourceLabel;
    }

    @action changePlace = (place: PlaceType, currentResource?): void => {
        this.place = place;
        if (currentResource) {
            this.currentResource = currentResource;
        }
    };

    @action toggleLoading = (): void => {
        this.isLoading = !this.isLoading;
    };
    @action createResource = async (values) => {
        this.isLoading = true;
        const request = this.apiClient.genericResourceCreate(this._uri, values);
        const {errors, response} = await handleFormSubmit(request);
        runInAction("genericResourceCollection handleCreate ok", () => {
            if (!errors) {
                this.currentResource = response;
                this.changePlace('grid');
                this.fetchCollection({});
            }
            this.isLoading = false;
        })
        if (errors) {
            return errors;
        }

    }

    @action updateResource = async (values) => {
        this.isLoading = true;
        const request = this.apiClient.genericResourceUpdate(this._uri, this.currentResourceId, values);
        const {errors, response} = await handleFormSubmit(request);
        runInAction("genericResourceCollection handleCreate ok", () => {
            if (!errors) {
                this.currentResource = response;
                this.changePlace('grid');
                this.fetchCollection({});
            }
            this.isLoading = false;
        })
        if (errors) {
            this.uiBus.notify("Something went wrong", "warning");
            return errors;
        }

    }

    @action removeResource = () => {
        this.isLoading = true;
        this.apiClient.genericResourceDelete(this._uri, this.currentResourceId).then(action("genericResource delete ok", () => {
            this.currentResource = null;
            this.changePlace('grid');
            this.uiBus.notify("Resource was successfully deleted.")
            this.fetchCollection({});
        })).catch(action("genericResource delete failed", e => {
            this.isLoading = false;
            this.uiBus.notify("Something went wrong", "warning");
        }));

    }

    @action fetchCollection = (query): void => {
        this.isLoading = true;
        this.apiClient.genericResourceCollectionFetch(this._uri, {params: query}).then(
            action("genericResourceCollection ok", response => {
                this.collectionResponse = response;
                this.isLoading = false;
            })
        ).catch(action("genericResourceCollection failed", e => {
            this.isLoading = false;
            this.uiBus.notify("Something went wrong", "warning");
        }));
    }

    @action fetchItem = (id): void => {
        this.isLoading = true;
        this.apiClient.genericResourceItemFetch(this._uri, id).then(
            action("genericResource fetchItem ok", response => {
                this.currentResource = response;
                this.isLoading = false;
            })
        ).catch(action("genericResource fetchItem failed", e => {
            this.isLoading = false;
            this.uiBus.notify("Something went wrong", "warning");
        }));
    }
}
