import * as React from 'react';

type EntityInfoProps = {
    info: { label, value }[]
}
const EntityInfo = ({info}: EntityInfoProps) => {

    const renderEntityRow = (label, value, index) => {
        return (
            <div key={index} className='entity-info__row'>
                <label>{label}: </label> <p>{value ? value : <span className='not-set'>N/A</span>}</p>
            </div>
        );
    };

    return (
        <div className='entity-info'>
            {info.map(
                (item, index) => renderEntityRow(item.label, item.value, index),
            )}
        </div>
    );

}

export default EntityInfo;