import {action, computed, observable, runInAction} from "mobx";
import remotedev from 'mobx-remotedev';
import ApiClient from "../ApiClient";
import {handleFormSubmit} from "../../utils/form/handleFormSubmit";
import {parseJwt} from "../../utils/tokenUtils";
import {SubmissionErrors} from "final-form";
import {Iri} from "../../utils/models";

export type LoginTokenType = { code, message: string, token: string, data: { twoFactorPasses: boolean, roles: string[], username: string } }
export type RoleType =
    'ROLE_ENGINEER'
    | 'ROLE_ADMIN'
    | 'ROLE_SALE'
    | 'ROLE_CUSTOMER'
    | 'ROLE_PARTNER'
    | 'ROLE_ADD_USER_NOTIFICATION'
    | 'ROLE_SHOW_CONTRACT_VALUE'
    | 'ROLE_CAN_ADD_SYSTEM'
    | 'ROLE_ALL_ACCOUNT';


@remotedev({global: true})
export class AuthStore {
    @observable userId: number | null;
    @observable twoFactorPasses: boolean = false;
    @observable twoFactorSecret: string | null;
    @observable twoFactorCodeUrl: string | null;
    @observable twoFactorRoute: string | null;
    @observable roles: RoleType[] = [];
    @observable username: string | null;

    constructor(private apiClient: ApiClient, private token: string | null) {
        if (token) {
            this.parseAndFillJwt(token);
        }
    }
    @computed get isAuthenticated(): boolean {
        return this.userId !== null && this.twoFactorPasses;
    }

    @computed get isCustomerOrPartner(): boolean {
        return !this.roles.includes('ROLE_ENGINEER') && !this.roles.includes('ROLE_ADMIN') && !this.roles.includes('ROLE_SALE') && !this.roles.includes('ROLE_ALL_ACCOUNT');
    }

    @computed get userIri(): Iri {
        return `/api/users/${this.userId}`;
    }

    @computed get isAdmin(): boolean {
        return this.roles.includes('ROLE_ADMIN');
    }

    @computed get isSalesAgent(): boolean {
        return this.roles.includes('ROLE_SALE');
    }

    @action login = async ({username, password}): Promise<SubmissionErrors | null> => {
        const {errors, response} = await handleFormSubmit(this.apiClient.login(username, password));
        return runInAction("login ok", () => {
            if (!errors) {
                this.parseAndFillJwt(response.token);
                localStorage.setItem('token', response.token);
            }
            return errors;
        })
    }

    public isGranted = (role: RoleType): boolean => {
        return Object.keys(this.roles).find(authItemI => this.roles[authItemI] === role) !== undefined;
    };

    @action public populateUser = ({username, roles}) => {
        this.roles = roles;
        this.username = username;
    };

    @action logout() {
        localStorage.removeItem('token');
        this.userId = null;
    }

    hasRole = (role: RoleType): boolean => {
        if (!this.isAuthenticated) {
            return false;
        }
        return this.roles.includes(role);
    };

    @action twoFactorStep = async (values): Promise<SubmissionErrors | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.completeTwoFactor(values, this.twoFactorRoute, this.twoFactorSecret));

        return runInAction("twoFactorStep ok", () => {
            if (errors) {
                return errors;
            }
            localStorage.setItem('token', response.token);
            this.parseAndFillJwt(response.token)
        })
    }

    @action private parseAndFillJwt = (token: string) => {
        const tokenData = parseJwt(token);
        this.roles = Object.values(tokenData.roles);
        this.username = tokenData.username;
        if (tokenData['2factor_passes'] === false) {
            this.twoFactorPasses = false;
            this.userId = tokenData['user_id'];
            this.twoFactorSecret = tokenData['2factor_secret'];
            this.twoFactorCodeUrl = tokenData['2factor_qr_code_url'];
            this.twoFactorRoute = tokenData['2factor_route'];
        } else {
            this.twoFactorPasses = true;
            this.userId = tokenData['user_id'];
        }
    }
}
