import {Icon} from "@mdi/react";
import {
    mdiArrowRightCircleOutline,
    mdiCloseBoxOutline, mdiContentCopy,
    mdiDotsHorizontal,
    mdiFileExcelOutline,
    mdiPencil,
    mdiReload,
    mdiTrashCanOutline
} from "@mdi/js";
import StandalonePopover from "../../../components/ui/StandalonePopover";
import React, {useState} from "react";
import {observer} from "mobx-react";
import {useStore} from "../../../context";
import {idFromIri} from "../../../utils/iri";
import QuoteStatusColor from "./QuoteStatusColor";
import {IQuoteModel} from "../../../utils/models";

type QuotesItemMenuProps = {
    item: IQuoteModel,
    clear,
    mode: 'LIST' | 'SINGLE'
}

const QuotesItemMenu = observer(({item, clear, mode}: QuotesItemMenuProps) => {
    const {opportunityQuoteStore} = useStore();
    return (
        <React.Fragment>
            {mode === 'LIST' && <span onClick={() => {
                clear();
                opportunityQuoteStore.goUpdate(opportunityQuoteStore.currentQuote);
            }
            }><Icon path={mdiPencil}/> Edit</span>}
            {item.status !== 'converted' && <span onClick={(e) => {
                opportunityQuoteStore.convert(e).then(clear);
            }}><Icon path={mdiReload}/> Convert</span>}
            {<span onClick={(e) => {
                opportunityQuoteStore.clone().then(clear);
            }}><Icon path={mdiContentCopy}/> Clone</span>}
            <span onClick={() => {
                opportunityQuoteStore.goExportQuote().then(clear);
            }}><Icon path={mdiFileExcelOutline}/>Export to Excel</span>
            {item.status !== 'sent' && <span onClick={() => {
                opportunityQuoteStore.markQuoteAsSent().then(clear);
            }}><Icon path={mdiArrowRightCircleOutline}/>Set Sent status</span>}
            {item.status !== 'declined' && <span onClick={() => {
                clear();
                opportunityQuoteStore.goDeclineQuote();
            }}><Icon path={mdiCloseBoxOutline}/>Set Declined status</span>}

            {mode === 'LIST' && <span onClick={(e) => {
                opportunityQuoteStore.delete(e).then(clear);
            }}><Icon path={mdiTrashCanOutline}/> Delete</span>}
        </React.Fragment>
    )
});
type QuoteItemHeaderProps = {
    item: IQuoteModel,
    mode: 'LIST' | 'SINGLE'
}

const QuoteItemHeader = observer(({item, mode}: QuoteItemHeaderProps) => {
    const [contextMenuCoordinates, setContextMenuCoordinates] = useState<{ x, y } | null>(null);
    const {opportunityQuoteStore, opportunityStore} = useStore();
    const quoteId = () => {
        return idFromIri(item["@id"])
        // return `${idFromEntity(currentOpportunity)}.${index + 1}`;
    };
    const clearQuote = () => {
        setContextMenuCoordinates(null)
    }
    return (
        <React.Fragment>
            <div className="dots"
                 onClick={(e) => {
                     opportunityQuoteStore.populateQuote(item);
                     setContextMenuCoordinates({x: e.clientX, y: e.clientY});
                 }}>
                <Icon path={mdiDotsHorizontal}/>
            </div>

            {contextMenuCoordinates &&
            <StandalonePopover classNames={'quote-menu'} title={null}
                               closePopover={clearQuote}
                               position={contextMenuCoordinates}
                               darken={false} direction={"BOTTOM_LEFT"}>
                <QuotesItemMenu mode={mode} item={item} clear={clearQuote}/></StandalonePopover>}

            <span className={`quotes-item__status ${QuoteStatusColor(item.status)}`}
                  title={item.status === 'declined' ? item.cancellationReasonText : null}>{item.status}</span>
            <div className="quotes-item__quote-id" onClick={() => {
                opportunityStore.canEdit && opportunityQuoteStore.goUpdate(item);
            }}>Quote {quoteId()}</div>
        </React.Fragment>
    )
})
export default QuoteItemHeader;
