import React, {useEffect} from "react";
import {Field, Form} from "react-final-form";
import {Textarea} from "../../components/ui/form";
import UserAvatar from "../../components/ui/UserAvatar";
import {useStore} from "../../context";
import {ActivitiesLogInterface} from "./ActivitiesLogInterface";
import {observer} from "mobx-react";
import {Button} from "../../components/ui";
import UiBus from "../../store/service/UiBus";
import {Intent, Spinner} from "@blueprintjs/core";
import {sortActivities} from "./formatter";
import {ActivityLogMessages} from "./ActivityLogMessages";

type ActivityLogType = {
    activitiesLog: ActivitiesLogInterface,
    canEdit?: boolean
    showTitle?: boolean
}

// type FFProps<T> = FieldRenderProps<T, HTMLInputElement>;

// const InternalSwitcherTabsAdapter = <T extends boolean>(props: FFProps<T>) => {
//     return <div className={'private-tabs'}>
//         <div
//             className={props.input.value === false ? 'private-tab-item active' : 'private-tab-item'}
//             onClick={() => {
//                 props.input.onChange(false);
//                 props.changeTab?.('public')
//             }}
//         >
//             <span>Reply</span>
//         </div>
//         <div
//             className={props.input.value === true ? 'private-tab-item active' : 'private-tab-item'}
//             onClick={() => {
//                 props.input.onChange(true);
//                 props.changeTab?.('internal')
//             }}
//         >
//             <span>Internal note</span>
//         </div>
//     </div>
// }


const ActivityLogWidget = observer(({showTitle = true, activitiesLog, canEdit = true}: ActivityLogType) => {
    // const [activitiesOption, setActivitiesOption] = useState<'public' | 'internal'>('internal')
    const activitiesOption = 'internal';
    // const selectTab = (select: 'internal' | 'public') => {
    //     setActivitiesOption(select)
    // }
    const {authStore} = useStore();

    useEffect(() => {
        activitiesLog?.fetchActivities();
    }, [activitiesLog.idForActivityLogParent]);

    return (
        <div className={'activity-log'}>
            {showTitle && <h2>Activity log</h2>}
            <div className={'message-section'}>
                {canEdit && <Form onSubmit={activitiesLog.createActivities}
                                  initialValues={{isInternal: activitiesOption === 'internal'}}
                                  keepDirtyOnReinitialize={true}>
                    {({handleSubmit, form, submitting, pristine, values}) =>
                        <form className={'add-comment-form'} onSubmit={(e) => {
                            if (!submitting) {
                                const submitted = handleSubmit(e);
                                if (submitted) {
                                    submitted.then(() => form.change('body', ''));
                                }
                            }
                        }}>
                            {/*<Field name={"isInternal"}*/}
                            {/*       changeTab={selectTab}*/}
                            {/*       component={InternalSwitcherTabsAdapter}*/}
                            {/*/>*/}
                            <div className={'activity-log-send'}>
                                <UserAvatar name={authStore.username} full={false}/>
                                <div
                                    style={{fontSize: '22px'}}>{activitiesOption === 'internal' ? 'Create note' : 'Reply to client'}</div>
                            </div>
                            <Field
                                name={'body'}
                                placeholder={values.isInternal ? "Add note" : "Add reply"}
                                style={values.isInternal ? {'background': '#fdf1dd'} : {}}
                                component={Textarea}
                            />
                            <Button type={'submit'} onClick={async (e) => {
                                if (!values.isInternal) {
                                    if (!submitting && !pristine && values['body']?.length > 0) {
                                        e.preventDefault();
                                        return new UiBus().confirm("Are you sure you want to send a reply to the client? This reply can not be retracted once sent", e).then(() => form.submit().then(() => form.change('body', '')));
                                    }
                                }
                            }}>{activitiesOption === 'internal' ? 'Submit' : 'Send'}</Button>
                        </form>
                    }
                </Form>
                }
                <div className="activity-list">
                    {activitiesLog.activities?.state === 'pending' && <Spinner intent={Intent.PRIMARY} size={20}/>}
                    {activitiesLog.activities?.state === 'fulfilled' &&
                    <ActivityLogMessages
                        forceActivityVisibleMode={'public'}
                        isSupportRequest={false}
                        activities={sortActivities(activitiesLog.activities?.value)}/>
                    }
                </div>
            </div>
        </div>)


});

export default ActivityLogWidget;
