import React, {useState} from 'react';
import {Checkbox, DateInput, FinalAutocomplete, FinalButtonSet, Input, Textarea} from '../../../components/ui/form';
import {LinkButton, Select} from '../../../components/ui';

import {FieldArray} from 'react-final-form-arrays'
import {Field, Form} from "react-final-form";
import {PartStore} from "../../../store/mobx/PartStore";
import useDataListFetch from "../../../utils/form/useDataListFetch";
import FileGallery from "../../../components/ui/widgets/FileGallery";
import arrayMutators from "final-form-arrays";

import Icon from '@mdi/react';
import {mdiClose, mdiLink, mdiPlus, mdiServer} from "@mdi/js";
import NumberInput from "../../../components/ui/form/NumberInput";
import RealizationDragAndDrop from "../RealizationDragAndDrop";
import {Iri, PartFullModel, PartRealizationShortModel, SystemShortModel} from "../../../utils/models";
import StandalonePopover from "../../../components/ui/StandalonePopover";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import {stringify} from "querystring";
import AutocompleteInput from "../../../components/ui/form/AutocompleteInput";
import {TextArea} from "@blueprintjs/core";
import {useStore} from "../../../context";

type PartTypeDetailsFormType = {
    mode: 'CREATE' | 'UPDATE',
    partStore: PartStore,
    isGranted: boolean,
    catalogueMode: boolean,
    standalone: boolean
    collapsed?: boolean
}

type LocationFormProps = {
    item: PartRealizationShortModel,
    partStore: PartStore,
    setPopoverIndex
}

const LocationForm = ({item, setPopoverIndex, partStore}: LocationFormProps) => {
    const {realizationStore} = useStore();
    const [value, setValue] = useState(item.location);
    const submit = async () => {
        realizationStore.addInventoryNotes(item['@id'], {notes: value}).then((res) => {
            if (!res.errors) {
                partStore.fetchItem(partStore.currentPart.id);
            }
        });
        setPopoverIndex(null)
    };
    return (
        <>
            <TextArea
                defaultValue={item.location}
                placeholder={"Notes"}
                onChange={(e) => setValue(e.target.value)}
                rows={10}
                name={'location'}/>
            <br/>
            <br/>
            <button disabled={value === item.location} className={'tertiaryBtn'} onClick={submit}>
                Save
            </button>
        </>
    )
};

type AssignFormProps = {
    item: PartRealizationShortModel,
    partStore: PartStore,
    setAssignPopoverIndex
}

const AssignToSystemPopup = ({item, partStore, setAssignPopoverIndex}: AssignFormProps) => {
    const [system, setSystem] = useState<Iri>(null);
    return (<StandalonePopover
        size={"bd"}
        title={"Assign to System"}
        closePopover={() => setAssignPopoverIndex(null)}>
        <div>
            <AutocompleteInput<SystemShortModel>
                onComplete={(value) => setSystem(value['@id'])}
                fieldName={"serialNumber"}
                scope={"systems"}
                canEdit={true}/>
            <br/>
            {<LinkButton className={'tertiaryBtn'}
                         onClick={() => partStore.assignRealizationToSystem(item, system)
                             .then(setAssignPopoverIndex(null))}>Assign</LinkButton>}
        </div>
    </StandalonePopover>);
}

const PartTypeDetailsForm = observer(({mode, partStore, standalone, catalogueMode, isGranted, collapsed = false}: PartTypeDetailsFormType) => {
    const manufacturerDataList = useDataListFetch('manufacturers');
    const categoriesDataList = useDataListFetch('part_categories');
    const [familiesDataListDeps, setFamiliesDataListDeps] = useState(
        partStore.currentPart && partStore.currentPart.manufacturer
            ? {manufacturer: partStore.currentPart.manufacturer}
            : {});
    const familiesDataList = useDataListFetch('part_families', familiesDataListDeps);
    const [seriesDataListDeps, setSeriesDataListDeps] = useState(
        partStore.currentPart && partStore.currentPart.family
        ? {partFamily: partStore.currentPart.family}
        : {}
    );
    const seriesDataList = useDataListFetch('part_series', seriesDataListDeps);
    const [popoverIndex, setPopoverIndex] = useState<number>(null);
    const [assignPopoverIndex, setAssignPopoverIndex] = useState<number>(null);
    const history = useHistory();

    let assignedLink;
    if (collapsed) {
        if (partStore.currentCollapsedPart && partStore.currentCollapsedPart.customerId) {
            const customerId = partStore.currentCollapsedPart.customerId;
            if (partStore.currentCollapsedPart.system) {
                const systemId = partStore.currentCollapsedPart.system.split('/').reverse()[0];
                assignedLink = <a href={`/customers/${customerId}?systemId=${systemId}`}>System #{systemId}</a>;
            }
            if (partStore.currentCollapsedPart.contract) {
                const contractId = partStore.currentCollapsedPart.contract.split('/').reverse()[0];
                assignedLink +=
                    <a href={`/customers/${customerId}?contractId=${contractId}`}>Contract #{contractId}</a>;
            }
            if (partStore.currentCollapsedPart.collapsedSystem) {
                const systemId = partStore.currentCollapsedPart.collapsedSystem.split('/').reverse()[0];
                assignedLink = <a href={`/customers/${customerId}?systemId=${systemId}&isCollapsed=1`}>Collapsed system
                    #{systemId}</a>;
            }
            if (partStore.currentCollapsedPart.collapsedProject) {
                const projectId = partStore.currentCollapsedPart.collapsedProject.split('/').reverse()[0];
                assignedLink +=
                    <a href={`/customers/${customerId}?projectId=${projectId}&isCollapsed=1`}>Collapsed project
                        #{projectId}</a>;
            }
        }
    } else {
        if (partStore.currentPart && partStore.currentPart.customerId) {
            const customerId = partStore.currentPart.customerId;
            if (partStore.currentPart.system) {
                const systemId = partStore.currentPart.system.split('/').reverse()[0];
                assignedLink = <a href={`/customers/${customerId}?systemId=${systemId}`}>System #{systemId}</a>;
            }
            if (partStore.currentPart.project) {
                const projectId = partStore.currentPart.project.split('/').reverse()[0];
                assignedLink = <a href={`/customers/${customerId}?projectId=${projectId}`}>Projects #{projectId}</a>;
            }
            if (partStore.currentPart.contract) {
                const contractId = partStore.currentPart.contract.split('/').reverse()[0];
                assignedLink +=
                    <a href={`/customers/${customerId}?contractId=${contractId}`}>Contract #{contractId}</a>;
            }
        }
    }

    return (
        <Form onSubmit={mode === 'UPDATE' ? partStore.update : partStore.create}
              mutators={{
                  ...arrayMutators
              }}
              initialValues={collapsed ? partStore.currentCollapsedPart : partStore.currentPart}
              render={({handleSubmit, submitting, values, pristine, form: {change, reset, mutators: {push, remove}}}) => {
                  return (<form id={"part-details-form"} onSubmit={handleSubmit}>

                      <Field
                          name="name"
                          label="Name"
                          component={Input}
                          required
                          canEdit={partStore.canEdit && !collapsed}
                      />
                      {partStore.currentPart && partStore.currentPart.customerId &&
                      <div className='ls-form'>
                          <label>Assigned to: </label>
                          <div className={'input-field'}>
                              {assignedLink}
                          </div>
                      </div>}
                      {partStore.currentCollapsedPart && partStore.currentCollapsedPart.customerId &&
                      <div className='ls-form'>
                          <label>Assigned to: </label>
                          <div className={'input-field'}>
                              {assignedLink}
                          </div>
                      </div>}
                      {!catalogueMode && <Field
                          name="configParts"
                          label="Config parts"
                          component={NumberInput}
                          canEdit={partStore.canEdit && !collapsed}
                      />}
                      <React.Fragment>
                          <Field
                              name="modelNumber"
                              label="Model Number"
                              component={Input}
                              canEdit={partStore.canEdit && !collapsed}
                              required={partStore.isSystem}
                          />
                          <Field
                              name="partNumber"
                              label={partStore.isSoftware ? "License number" : "Part number"}
                              component={Input}
                              canEdit={partStore.canEdit && !collapsed}
                              required={!partStore.isSystem}
                          />
                      </React.Fragment>
                      <Field
                          name="manufacturer"
                          label="Manufacturer"
                          component={Select}
                          onChange={(newValue) => {
                              setFamiliesDataListDeps({manufacturer: newValue.value});
                              change("manufacturer", newValue.value);
                              change("family", null);
                              change("series", null);
                          }}
                          canEdit={partStore.canEdit && !collapsed}
                          {...manufacturerDataList}
                      />
                      <Field
                          name="category"
                          label="Category"
                          component={Select}
                          canEdit={partStore.canEdit && !collapsed}
                          {...categoriesDataList}
                      />
                      <Field
                          name="type"
                          label="Type"
                          component={Select}
                          canEdit={partStore.canEdit && !collapsed}
                          options={{'hardware':'hardware', 'system':'system', 'software':'software'}}
                      />
                      {partStore.isSoftware && <div className={"ls-form ls-textinput"}></div>}
                      {catalogueMode && partStore.partType !== 'software' && <Field
                          name="family"
                          label="Family"
                          component={Select}
                          clearable={true}
                          onChange={(newValue) => {
                              setSeriesDataListDeps({partFamily: newValue.value});
                              change("family", newValue.value);
                              change("series", null);
                          }}
                          canEdit={partStore.canEdit && !collapsed}
                          {...familiesDataList}
                      />}
                      {catalogueMode && partStore.partType !== 'software' && <Field
                          name="series"
                          label="Series"
                          clearable={true}
                          component={Select}
                          canEdit={partStore.canEdit && !collapsed}
                          {...seriesDataList}
                      />}
                      {catalogueMode && !partStore.isSoftware && <Field
                          name="endOfLife"
                          label="End Of Life"
                          component={DateInput}
                          canEdit={partStore.canEdit}
                      />}
                      {catalogueMode && !partStore.isSoftware && <Field
                          name="endOfService"
                          label="End Of Service"
                          component={DateInput}
                          canEdit={partStore.canEdit}
                      />}
                      {catalogueMode && partStore.isSystem && <Field
                          name="endOfSale"
                          label="End Of Sale"
                          component={DateInput}
                          canEdit={partStore.canEdit}
                      />}
                      {catalogueMode && <React.Fragment>
                          <div className={"ls-form ls-textinput"}>
                              <label>Price Range</label>
                              <Field
                                  name={`minPrice`}
                                  component={NumberInput}
                                  canEdit={partStore.canEdit && !collapsed}
                                  placeholder={`min`}
                              />
                              <div className={"range-divider"}>-</div>
                              <Field
                                  name={`maxPrice`}
                                  canEdit={partStore.canEdit && !collapsed}
                                  component={NumberInput}
                                  placeholder={`max`}
                              />
                          </div>
                      </React.Fragment>}
                      <Field
                          name="description"
                          label="Description"
                          component={Textarea}
                          canEdit={partStore.canEdit && !collapsed}
                      />
                      {partStore.fetchAttachments.length > 0 && (
                          <div className='custom-field'>
                              <label>Attachments: </label>
                              <FileGallery attachmentInterface={partStore}/>
                          </div>
                      )}
                      {!partStore.isSystem && <Field
                          name='platformSupported'
                          label='Platforms supported'
                          canEdit={partStore.canEdit && !collapsed}
                          component={Textarea}
                      />}
                      {partStore.isHardware && <Field
                          name='typeAndSpeed'
                          label='Type & speed'
                          canEdit={partStore.canEdit && !collapsed}
                          component={Textarea}
                      />}

                      {mode === 'UPDATE'
                      && standalone
                      && !catalogueMode
                      && partStore.currentPart
                      && partStore.currentPart.partRealizations
                      && partStore.currentPart.partRealizations.length > 0 &&
                      <FieldArray name="partRealizations">
                          {({fields}) => (
                              <div className='ls-form with-table'>
                                  <label>Serials</label>
                                  <div className={'part-realizations'}>
                                      <div className="part-realizations__row">
                                          <div></div>
                                          <div>Part Number</div>
                                          <div>Notes</div>
                                          <div></div>
                                          <div></div>
                                      </div>
                                      {fields.map((name, index) => {
                                          const realization: PartRealizationShortModel = values['partRealizations'][index];
                                          const purchaseId = realization.purchaseItem.purchase.split('/').reverse()[0];
                                          return (
                                              <div key={realization["@id"]}
                                                   className={'part-realizations__row'}
                                                   onClick={() => {
                                                       setPopoverIndex(index)
                                                   }}>
                                                  <div className={'link'}
                                                       onClick={(e) => {
                                                           e.stopPropagation();
                                                           history.push({
                                                               pathname: `/purchases`,
                                                               search: stringify({
                                                                   id: purchaseId,
                                                               })
                                                           });
                                                       }}>
                                                      <Icon path={mdiLink}/></div>
                                                  <div className={'serial-number'}>
                                                      {realization.serialNumber} {realization.partStatus === 'faulty' ? '[Faulty]' : null}
                                                  </div>
                                                  <div className={'part-realizations__notes'}>
                                                      <p>{realization.location && realization.location}</p>
                                                  </div>
                                                  {realization.partStatus === 'stock' &&
                                                  <div className={'assign'}
                                                       onClick={(e) => {
                                                           e.stopPropagation();
                                                           setAssignPopoverIndex(index);
                                                       }}>
                                                      <Icon path={mdiServer}/>
                                                  </div>}
                                                  {assignPopoverIndex === index &&
                                                  <AssignToSystemPopup
                                                      item={realization}
                                                      partStore={partStore}
                                                      setAssignPopoverIndex={setAssignPopoverIndex}
                                                  />}
                                                  {realization.partStatus !== 'faulty' &&
                                                  <div className={"remove"}
                                                       onClick={(e) => {
                                                           e.stopPropagation();
                                                           partStore.markRealizationAsFaulty(realization["@id"], e)
                                                       }}>
                                                      <Icon path={mdiClose}/></div>}
                                                  {popoverIndex === index &&
                                                  <StandalonePopover darken={false}
                                                                     title={'Notes'}
                                                                     size={"md"}
                                                                     closePopover={() => setPopoverIndex(null)}>

                                                      <LocationForm partStore={partStore}
                                                                    setPopoverIndex={setPopoverIndex}
                                                                    item={realization}/>
                                                  </StandalonePopover>}

                                              </div>
                                          )
                                      })}

                                  </div>
                              </div>
                          )}
                      </FieldArray>}

                      {!partStore.isSoftware && <Field
                          name='partAcNumber'
                          canEdit={partStore.canEdit && !collapsed}
                          fieldName={"partNumber"}
                          scope={"parts"}
                          label='Compatibles'
                          placeholder='Type part number to autocomplete compatibles'
                          clearOnComplete={true}
                          component={FinalAutocomplete}
                          onComplete={(item: PartFullModel | string) => {
                              if (typeof item === 'object') {
                                  push('compatible', item.partNumber)
                              }
                          }}
                      />}
                      {!partStore.isSoftware && <FieldArray<PartFullModel> name="compatible">

                          {({fields}) => (
                              <div className={'parts-number'}>
                                  {fields.map((name, index) => {
                                      return <div key={values['compatible'][index]}
                                                  className={'parts-number__item'}>{values['compatible'][index]}<span
                                          onClick={() => remove('compatible', index)}> <Icon path={mdiPlus}/></span>
                                      </div>
                                  })}
                              </div>

                          )}

                      </FieldArray>}

                      {catalogueMode && <Field
                          name="notToSync"
                          label="Not To Sync With Website"
                          component={Checkbox}
                          canEdit={partStore.canEdit && !collapsed}
                      />}

                      {mode === 'UPDATE' && !standalone && isGranted && <RealizationDragAndDrop partStore={partStore}/>}

                      <FinalButtonSet submitting={submitting} pristine={pristine}
                                      mode={mode === 'CREATE' ? 'RELATIVE' : 'FIXED'} reset={reset}/>
                  </form>)
              }
              }/>
    );
});


export default PartTypeDetailsForm;
