import React from 'react';

import ResourceForm from "./SystemCreateForm";
import {useStore} from "../../context";
import {observer} from "mobx-react";

const SystemCreate = observer(() => {
    const {systemStore} = useStore();
    return (
        <ResourceForm
            onSubmit={systemStore.create}
            initialValues={{}}
        />
    );
});


export default SystemCreate;
