import React from 'react';

import {Field, Form} from "react-final-form";
import {FinalButtonSet} from "../../components/ui/form";
import {observer} from "mobx-react";
import {useStore} from "../../context";
import useDataListFetch from "../../utils/form/useDataListFetch";
import SelectButtons from "../../components/ui/form/SelectButtons";


const OpportunityInvitedSelectForm = observer(() => {
    const {opportunityStore} = useStore();
    const usersDL = useDataListFetch('sales_agents+admins')

    return (
        <Form
            onSubmit={opportunityStore.updateAttribute}
            initialValues={{invited: opportunityStore.currentOpportunity.invited
                    .map(invited => typeof invited === 'object' ? invited['@id'] : invited)}}
        >
            {({handleSubmit, pristine, submitting, form}) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        name='invited'
                        {...usersDL}
                        component={SelectButtons}
                        isMulti={true}
                    />
                    <FinalButtonSet pristine={pristine} submitting={submitting} mode={"RELATIVE"}/>
                </form>
            )}
        </Form>
    );
})


export default OpportunityInvitedSelectForm;
