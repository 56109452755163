import React from "react";
import {Field, Form} from "react-final-form";
import {useStore} from "../../context";
import {observer} from "mobx-react";
import {Button, Select} from "../../components/ui";
import useDataListFetch from "../../utils/form/useDataListFetch";

export const SupportRequestSelectCustomerForm = observer(() => {
    const {supportRequestStore} = useStore();
    const customersList = useDataListFetch('customers');

    return (<Form
        onSubmit={supportRequestStore.assignCustomer}
    >
        {({handleSubmit, pristine}) => {
            return (<form onSubmit={handleSubmit}>
                <Field
                    name={'customer'}
                    component={Select}
                    {...customersList}
                    required
                />

                <Button disabled={pristine} className={'roundButton tertiaryBtn '} type='submit'>Save</Button>
            </form>)
        }}
    </Form>);
});