import React, {useEffect, useState} from 'react';
import {QuoteSystemInfoRead} from "../../../utils/models";
import {Button, TextArea} from "@blueprintjs/core";
import {ContractTypeListType} from "../../../store/enum/contractEnumeration";
import {FieldArrayRenderProps} from "react-final-form-arrays";

type QuoteSystemRealizationsFormProps = {
    item: Partial<QuoteSystemInfoRead>,
    quoteType: ContractTypeListType,
    index: number,
    closePopover?: () => void,
    fieldArrayProps: FieldArrayRenderProps<Partial<QuoteSystemInfoRead>, any>
}

const QuoteSystemRealizationsForm = ({
                                         item,
                                         quoteType,
                                         index,
                                         fieldArrayProps,
                                         closePopover
                                     }: QuoteSystemRealizationsFormProps) => {
    const [stockRealizationValues, setStockRealizationValues] = useState(null);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if (item.stockRealization) {
            setStockRealizationValues({
                ...stockRealizationValues,
                stockRealization: item.stockRealization['@id']
            });
        }else if (typeof fieldArrayProps.fields.value[index].stockRealization === 'string') {
            setStockRealizationValues({
                ...stockRealizationValues,
                stockRealization: fieldArrayProps.fields.value[index].stockRealization
            });
        }
    }, [item.stockRealization]);

    const handleSystemAddressChange = (e) => {
        setIsChanged(true)
        setStockRealizationValues({
            ...stockRealizationValues,
            address: e.target.value
        })
    };

    const handleStockRealizationChange = (e) => {
        setIsChanged(true)
        setStockRealizationValues({
            ...stockRealizationValues,
            stockRealization: e.target.value
        })
    };

    const handleStockRealizationSubmit = () => {
        fieldArrayProps.fields.update(index, {
            ...item,
            ...stockRealizationValues
        });
        closePopover()
    }

    return (
        <div className={'serials-block'}>
            {quoteType === "pay_as_you_go" && item.systemAsProduct && item.systemAsProduct.stockRealizations.length >= 1
            && <div>
                {item.systemAsProduct.stockRealizations.map(stockReal => {
                    return <label className={'serials-block__radios'} key={stockReal.serialNumber}>
                        <input
                            type={'radio'}
                            name={"serialNumber"}
                            checked={stockRealizationValues?.stockRealization === stockReal['@id']}
                            value={stockReal['@id']}
                            onChange={(e) => handleStockRealizationChange(e)}
                        />
                        {stockReal.serialNumber}
                    </label>
                })}
            </div>}
            {<TextArea
                defaultValue={item.address}
                placeholder={"Address"}
                onChange={handleSystemAddressChange}
                name={'address'}/>}

            {isChanged &&  <Button className={'secondaryBtn'} onClick={handleStockRealizationSubmit}>Submit</Button>}
        </div>
    )
};

export default QuoteSystemRealizationsForm;
