import React from 'react';

import ResourceForm from "./SlaForm";
import {GenericResourceStore} from "../../../store/mobx/GenericResourceStore";

type ResourceCreateProps = {
    sharedGridResourceCollectionStore: GenericResourceStore
    canEdit: boolean,
}
const ResourceCreate = ({sharedGridResourceCollectionStore, canEdit}: ResourceCreateProps) => {
    return (
        <React.Fragment>
            <ResourceForm
                initialValues={{}}
                disabled={!canEdit}
                onSubmit={(values) => {
                    values.hours = Number(values.hours);
                    sharedGridResourceCollectionStore.createResource(values)
                }}
            />
        </React.Fragment>
    );
};


export default ResourceCreate;
