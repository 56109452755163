import React, {useEffect, useState} from 'react';
import Icon from "@mdi/react";
import {mdiHarddisk} from "@mdi/js";
import {PartStore} from "../../store/mobx/PartStore";
import {observer} from "mobx-react";
import StandalonePopover from "../../components/ui/StandalonePopover";
import RealizationAssignForm from "./RealizationAssignForm";
import {PartRealizationStatusType} from "../../store/enum/partEnumeration";

type RealizationDragAndDropProps = {
    partStore: PartStore
}

const RealizationDragAndDrop = observer(({partStore}: RealizationDragAndDropProps) => {
    useEffect(() => {
        partStore.fetchStockPart(partStore.currentPart)
    }, [partStore.currentPart]);

    const [draggableType, setDraggableType] = useState<PartRealizationStatusType>(null);
    const [dragInType, setDragInType] = useState(null);
    const [dragLeft, setDragLeft] = useState(0);
    const [dragTop, setDragTop] = useState(0);
    const [showPopover, setShowPopover] = useState(false);
    const [popoverRealizations, setPopoverRealizations] = useState([]);
    const [popoverNewStatus, setPopoverNewStatus] = useState(null);
    const [popoverOldStatus, setPopoverOldStatus] = useState(null);

    function dragStart(e, type) {
        setDraggableType(type);
        moveAt(e)
    }

    function dragStop() {
        if (draggableType !== null) {
            if (dragInType !== null) {
                showPopoverByType(draggableType);
                setPopoverNewStatus(dragInType);
                setPopoverOldStatus(draggableType);
                setDragInType(null);
            }
            setDraggableType(null);
        }
        setDragLeft(0);
        setDragTop(0);
    }

    function showPopoverByType(type: PartRealizationStatusType) {
        setShowPopover(true);
        if (type === 'assigned') {
            setPopoverRealizations(partStore.assignedRealizationsList);
        }
        if (type === 'spare') {
            setPopoverRealizations(partStore.spareRealizationsList);
        }
        if (type === 'stock') {
            setPopoverRealizations(partStore.stockRealizationsList);
        }
    }

    function moveAt(event) {
        setDragLeft(event.pageX - 12);
        setDragTop(event.pageY - 12);
    }

    function getDraggableStyle() {
        return {
            position: 'fixed' as 'fixed',
            left: dragLeft,
            top: dragTop
        }
    }

    function detectIfIn(type: PartRealizationStatusType) {
        if (draggableType !== null && draggableType !== type) {
            setDragInType(type);
        }
    }

    function detectOut(e, type: PartRealizationStatusType) {
        if (e.relatedTarget.className !== 'draggable-ico' && dragInType === type) {
            setDragInType(null)
        }
    }

    function getClassNameByType(type: PartRealizationStatusType) {
        let className = 'drag-item ';
        if (draggableType !== null) {
            if (draggableType === type) {
                className += 'dragout ';
            } else {
                className += 'dragwhereto ';
                if (dragInType === type) {
                    className += 'dragin '
                }
            }
        }
        if (type === 'stock' && partStore.stockRealizationsList.length === 0) {
            className += 'empty '
        }
        if (type === 'assigned' && partStore.assignedRealizationsList.length === 0) {
            className += 'empty '
        }
        if (type === 'spare' && partStore.spareRealizationsList.length === 0) {
            className += 'empty '
        }
        return className;
    }

    function handleClosePopover() {
        setShowPopover(false);
        setPopoverNewStatus(null);
        setPopoverRealizations([])
    }

    return (<div>
            <div className="drag" onMouseMove={moveAt} onMouseUp={dragStop}>
                {partStore.currentPart && <div className={getClassNameByType('assigned')}
                                               onMouseEnter={() => detectIfIn('assigned')}
                                               onMouseLeave={(e) => detectOut(e, 'assigned')}>
                    <div className="ico">
                <span
                    style={draggableType === 'assigned' ? getDraggableStyle() : {}}
                    className={'draggable-ico'}
                    onMouseDown={(e) => {
                        if (partStore.assignedRealizationsList.length > 0) {
                            dragStart(e, 'assigned')
                        }
                    }}
                ><Icon path={mdiHarddisk}/></span>
                    </div>
                    <div className="info" onClick={() => showPopoverByType('assigned')}>
                        <p className="drag-item__title">Assigned:</p>
                        <p className="drag-item__count">{partStore.assignedRealizationsList.length}</p>
                    </div>
                </div>}
                {partStore.currentPart && <div className={getClassNameByType('spare')}
                                               onMouseOver={() => detectIfIn('spare')}
                                               onMouseLeave={(e) => detectOut(e, 'spare')}>
                    <div className="ico">
                <span
                    style={draggableType === 'spare' ? getDraggableStyle() : {}}
                    className={'draggable-ico'}
                    onMouseDown={(e) => {
                        if (partStore.spareRealizationsList.length > 0) {
                            dragStart(e, 'spare')
                        }
                    }}
                >
                    <Icon path={mdiHarddisk}/>
                </span>
                    </div>
                    <div className="info" onClick={() => showPopoverByType('spare')}>
                        <p className="drag-item__title">Spare parts:</p>
                        <p className="drag-item__count">{partStore.spareRealizationsList.length}</p>
                    </div>
                </div>}
                {partStore.stockPart && <div className={getClassNameByType('stock')}
                                             onMouseEnter={() => detectIfIn('stock')}
                                             onMouseLeave={(e) => detectOut(e, 'stock')}>
                    <div className="ico">
                <span
                    style={draggableType === 'stock' ? getDraggableStyle() : {}}
                    className={'draggable-ico'}
                    onMouseDown={(e) => {
                        if (partStore.stockRealizationsList.length) {
                            dragStart(e, 'stock')
                        }
                    }}
                >
                    <Icon path={mdiHarddisk}/>
                </span>
                    </div>
                    <div className="info" onClick={() => showPopoverByType('stock')}>
                        <p className="drag-item__title">Stock parts:</p>
                        <p className="drag-item__count">{partStore.stockRealizationsList.length}</p>
                    </div>
                </div>}
            </div>

            {showPopover && <StandalonePopover classNames={'assign-part'} title={"Assign part"}
                                               closePopover={handleClosePopover}>
                <RealizationAssignForm
                    realizationsList={popoverRealizations}
                    newStatus={popoverNewStatus}
                    oldStatus={popoverOldStatus}
                    closePopover={handleClosePopover}
                    partStore={partStore}
                />
            </StandalonePopover>}
        </div>
    )
});

export default RealizationDragAndDrop;
