import React, {useState} from "react";
import {Field, Form} from 'react-final-form'
import {Input, Select, Textarea} from "../form";
import {Button, LinkButton} from "../index";
import {FormattedRelative} from "react-intl";

import {Icon} from '@mdi/react';
import {mdiBeakerRemoveOutline, mdiPlus, mdiSquareEditOutline} from "@mdi/js";
import {WithExpensesInterface} from "../../../store/interfaces/WithExpensesInterface";
import {useOnClickOutside} from "../../../utils/hooks/useOnClickOutside";
import ExpensesBlock from "../tile/expenses";
import useDataListFetch from "../../../utils/form/useDataListFetch";
import {observer} from "mobx-react";
import {ExpenseUpdateInput} from "../../../utils/models";

type ExpensesDetailsProps = {
    withExpenses: WithExpensesInterface
}

const ExpensesDetailsWidget = observer(({withExpenses}: ExpensesDetailsProps) => {
    const [canEditExpense, setCanEditExpense] = useState(null);
    const expenseTypesDL = useDataListFetch('expense_types');
    const [showExpensesList, setShowExpensesList] = useState(false);

    const {ref} = useOnClickOutside(() => setShowExpensesList(false));
    const total = withExpenses.expensesTotal;

    return (
        <div ref={ref}
             className={showExpensesList ? 'tile-item expenses showed' : 'tile-item expenses'}
             onClick={() => {
                 setShowExpensesList(!showExpensesList);
                 if (withExpenses.expenses.length) {
                     setShowExpensesList(!showExpensesList);
                 } else {
                     withExpenses.goAddExpense();
                 }
             }}>
            <ExpensesBlock total={total}/>
            {showExpensesList && withExpenses.expenses.map((expense, index) => {
                return (
                    <div onClick={e => {
                        e.stopPropagation()
                    }}
                         key={expense['@id']}
                         className={'expenses-list'}>
                        <Form<ExpenseUpdateInput>
                            onSubmit={values => {
                                return withExpenses.expenseUpdate(expense["@id"], values).then(() => {
                                    setCanEditExpense(null);
                                    setShowExpensesList(false);
                                });
                            }}
                            initialValues={expense}
                            render={({handleSubmit, pristine, submitting}) => {
                                return (
                                    <form className={'expenses-drop'} onSubmit={handleSubmit}>
                                        <div
                                            key={expenseTypesDL.options[expense["@id"]]}
                                            className={'expenses-item'}>
                                            <div className="expenses-item-heading">

                                                {canEditExpense === index
                                                    ? <Field
                                                        label={null}
                                                        name={`type`}
                                                        placeholder='Select type'
                                                        component={Select}
                                                        {...expenseTypesDL}/>
                                                    : <p className={'title'}>{expenseTypesDL.options[expense.type]}</p>}
                                                <span className="expenses-item-date">
                                                    {canEditExpense === index
                                                        ? null
                                                        : <FormattedRelative
                                                            value={expense?.createdAt}/>}
                                                </span>
                                                {canEditExpense === index
                                                    ? <Field
                                                        label={null}
                                                        name={`cost`}
                                                        placeholder='Cost'
                                                        step={100}
                                                        className={'cost'}
                                                        component={Input}/>
                                                    : <p className={'cost'}><span
                                                        className={'money'}>£</span>{expense.cost}
                                                    </p>}
                                            </div>

                                            <div className="expenses-item__notes">
                                                {canEditExpense === index
                                                    ? <Field
                                                        label={null}
                                                        name={`notes`}
                                                        placeholder='Notes'
                                                        component={Textarea}/>
                                                    : <p className={'notes'}>{expense.notes}</p>}
                                            </div>
                                            <LinkButton
                                                className={'edit'}
                                                onClick={() => {
                                                    setCanEditExpense(index)
                                                    if (canEditExpense === index) {
                                                        setCanEditExpense(null)
                                                    }
                                                }}>
                                                {canEditExpense !== index
                                                    ? <Icon path={mdiSquareEditOutline}/>
                                                    : <Icon className={'plus'} path={mdiPlus}/>}
                                            </LinkButton>
                                            <LinkButton className={'remove'} onClick={() => {
                                                withExpenses.removeExpense(expense['@id']);
                                            }}><Icon path={mdiBeakerRemoveOutline}/></LinkButton>
                                        </div>

                                        {canEditExpense === index && <div className='expenses-button'>
                                            <Button
                                                className={'tertiaryBtn'}
                                                color='blue'
                                                type='submit'
                                                disabled={pristine || submitting}>
                                                Submit
                                            </Button>
                                        </div>}
                                    </form>

                                )
                            }}/>
                    </div>);
            })}</div>
    )
});

export default ExpensesDetailsWidget;
