import {action, computed, observable, runInAction} from "mobx";
import remotedev from 'mobx-remotedev';
import ApiClient from "../ApiClient";
import {CollectionResponseType, GridResourceCollectionInterface} from "../interfaces/GridResourceCollectionInterface";
import {handleFormSubmit, hydrateInitialValues} from "../../utils/form/handleFormSubmit";
import moment from "moment";
import ReportDownloader from "../service/ReportDownloader";
import {FileAttachmentsInterface} from "../interfaces/FileAttachmentsInterface";
import {RouterStore} from "mobx-react-router";
import {idFromEntity, uuidFromIri} from "../../utils/iri";
import UiBus from "../service/UiBus";
import AttachmentManager from "../service/AttachmentManager";
import {SubmissionErrors} from "final-form";
import {ActivitiesLogInterface} from "../../containers/activity_log/ActivitiesLogInterface";
import {WithExpensesInterface} from "../interfaces/WithExpensesInterface";
import {AuthStore} from "./AuthStore";
import {
    ExpenseUpdateInput,
    IAlertModel,
    IAttachmentModel,
    IExpenseModel,
    Iri,
    PartRealizationShortModel,
    TicketFullModel,
    TicketShortModel
} from "../../utils/models";
import {formatTicketStatus} from "../enum/ticketEnumeration";
import {FilterStore} from "./FilterStore";
import {OpportunityStore} from "./OpportunityStore";
import {v4 as uuidv4} from 'uuid';
import {fromPromise, IPromiseBasedObservable} from "mobx-utils";
import {ActivityReadModelOutput} from "../../generated";
import {assert} from "ts-essentials";


type TicketStoreContextPlace =
    'expenses'
    | 'attachment'
    | 'invite'
    | 'assign'
    | 'spent_time'
    | 'set_status'
    | 'assign_parts'
    | 'alert';
type TicketStorePlace = 'grid' | 'update' | 'create';

@remotedev({global: true})
export class TicketStore implements GridResourceCollectionInterface<TicketShortModel>, FileAttachmentsInterface, ActivitiesLogInterface, WithExpensesInterface {
    @observable currentTicket: TicketFullModel | null;
    @observable collectionResponse: CollectionResponseType<TicketShortModel> | null;
    @observable isLoading: boolean = false;
    @observable assignedPartsLoading: boolean = false;
    @observable assignedParts: PartRealizationShortModel[] = [];
    @observable contextPlace: TicketStoreContextPlace | null;
    @observable place: TicketStorePlace = 'grid';
    private attachmentManager: AttachmentManager;

    constructor(private apiClient: ApiClient, private authStore: AuthStore, private reportDownloader: ReportDownloader, private routing: RouterStore, private uiBus: UiBus, private filterStore: FilterStore) {
        this.attachmentManager = new AttachmentManager(apiClient, this, uiBus);
    }

    @computed get idForActivityLogParent(): string {
        return this.currentTicket ? this.currentTicket.id.toString() : '';
    }

    @observable public activities: IPromiseBasedObservable<ActivityReadModelOutput[]> | null = null;

    @action fetchActivities(): void {
        this.activities = fromPromise(this.apiClient.ticketFetch(this.id).then((r) => r.activities));
    }


    @computed get fetchAttachments(): IAttachmentModel[] {
        return this.currentTicket ? this.currentTicket.attachments : [];
    }

    @computed get expensesTotal(): string {
        if (!this.currentTicket) {
            return 'Not set';
        }
        return this.currentTicket ? this.currentTicket.expenses.reduce((prev, curr) => prev + Number(curr.cost), 0).toFixed(2) : ''
    }

    @computed get expenses(): IExpenseModel[] {
        return this.currentTicket ? this.currentTicket.expenses : [];
    }

    @computed get canEdit() {
        return this.currentTicket && this.currentTicket.status !== 'complete' && !this.authStore.isCustomerOrPartner;
    }

    public get id(): string {
        return uuidFromIri(this.currentTicket['@id']);
    }

    public get resolvedStatus(): string {
        if (this.currentTicket && this.currentTicket.status) {
            return formatTicketStatus(this.currentTicket.status);
        } else {
            return 'N/A';
        }
    }

    public get status(): string {
        return this.currentTicket.status;
    }

    public get title(): string {
        return this.currentTicket.title;
    }

    public get timeLeft(): Date | null {
        return (this.currentTicket.deadline ? new Date(this.currentTicket.deadline) : null)
    }

    @computed
    public get showTimer(): boolean {
        assert(this.currentTicket);
        return this.currentTicket.deadline && !this.currentTicket.firstReplyAt;
    }

    public get isThereTimeLeft(): Boolean | null {
        assert(this.currentTicket);
        if (!this.currentTicket.deadline) {
            return null;
        }
        return moment(this.currentTicket.deadline).diff(moment.now()) > 0;
    }

    public get showSlaSpent(): Boolean {
        assert(this.currentTicket);
        return this.currentTicket.firstReplyAt !== null;
    }

    public get formattedSlaSpent(): string {
        assert(this.currentTicket);
        return TicketStore.sla(this.currentTicket.slaStart, this.currentTicket.firstReplyAt);
    }

    private static sla(slaStart: string, firstReplyAt: string): string {
        const startDate = moment(slaStart);
        const convertedDate = moment(firstReplyAt);
        const diffTime = moment.duration(convertedDate.diff(startDate));
        const secs = TicketStore.formatAsTimer(diffTime.seconds());
        const mins = TicketStore.formatAsTimer(diffTime.minutes());
        const hours = TicketStore.formatAsTimer(diffTime.hours());
        const days = TicketStore.formatAsTimer(diffTime.days());
        return `${days}:${hours}:${mins}:${secs}`;
    }

    private static formatAsTimer(val: number): string {
        if (val < 10) {
            return `0${val}`;
        }
        return String(val);
    }

    @computed
    public get iri(): Iri {
        return this.currentTicket ? this.currentTicket['@id'] : null;
    }

    @action changeContextPlace = (place: TicketStoreContextPlace) => {
        this.contextPlace = place;
    }

    @action goCreate = () => {
        this.place = 'create';
    }

    @action goUpdate = (id: number) => {
        this.place = 'update';
        this.routing.push({pathname: `/tickets/${id}`})
    }

    @action clearContextPlace = () => {
        this.contextPlace = null;
    }

    @action clearPlace = () => {
        this.place = "grid";
        this.currentTicket = null;
    }

    @action toggleLoading = (): void => {
        this.isLoading = !this.isLoading;
    };

    @action saveAttachments = (files: FormData): void => {
        this.attachmentManager.persist(this.apiClient.ticketAttachmentsUpdate(this.currentTicket.id, files)).then(action("saveAttachments ok", (res) => {
            this.currentTicket.attachments = res;
            this.clearContextPlace();
        }))
    }

    @action removeAttachment = (item: IAttachmentModel): void => {
        this.attachmentManager.remove(item).then(action("removeAttachment ok", () => {
            this.currentTicket.attachments = this.currentTicket.attachments.filter(a => a['@id'] !== item['@id']);
        }));
    }

    @action goAddExpense = () => {
        this.changeContextPlace('expenses');
    };

    @action removeAlert = (e) => {
        this.uiBus.confirm("Remove alert?", e).then(() => {
            this.apiClient.alertRemove(idFromEntity(this.currentTicket.alert)).then(action("removeAlert ok", () => {
                this.clearContextPlace();
                this.uiBus.notify(`Alert removed`, 'info');
                this.loadCurrentTicket(this.id);
            }));
        })
    };

    @action mutateAlert = async (values): Promise<SubmissionErrors | null> => {
        let req;
        if (values['dateAndTime']) {
            if (values['dateAndTime']['date']) {
                values['dateAndTime']['date'] = OpportunityStore.formatEmbeddedDate(values['dateAndTime']['date']);
            }
            values['dateAndTime']['hours'] = OpportunityStore.formatEmbeddedHours(values['dateAndTime']['hours']);
            values['dateAndTime']['minutes'] = OpportunityStore.formatEmbeddedMinutes(values['dateAndTime']['minutes']);
            values['dateAndTime']['offsetTimezone'] = OpportunityStore.getEmbeddedTimezone();
            values['dateAndTime']['offsetTimezoneDirection'] = OpportunityStore.getEmbeddedTimezoneDirection();
        }
        if (!this.currentTicket.alert) {
            req = this.apiClient.createAlertForTicket({...values, ...{ticket: this.iri}});
        } else {
            req = this.apiClient.alertUpdate(idFromEntity(this.currentTicket.alert), values);
        }
        const {errors} = await handleFormSubmit<IAlertModel>(req);
        if (!errors) {
            this.clearContextPlace();
            this.uiBus.notify(`Alert is set`, 'success');
            this.loadCurrentTicket(idFromEntity(this.currentTicket));
        }
        return errors;
    };


    @action fetchCollection = (query): void => {
        this.isLoading = true;
        this.apiClient.ticketCollectionFetch({params: query}).then(
            action("ticketCollectionFetch ok", response => {
                this.collectionResponse = response;
                this.isLoading = false;
            })
        ).catch(action("fetchTickets failed", e => {
            this.isLoading = false;
        }));
    };

    @action fetchCollectionReport = (query) => {
        this.reportDownloader.download<TicketShortModel>('Ticket', this, this.apiClient.ticketCollectionFetch({
            params: {...query, _perPage: 100},
        }), (item) => {
            const createdAt = moment(item.createdAt);
            const spentTimeStr = `${item.spentHours ? `${item.spentHours}h` : '00h'}:${item.spentMinutes ? `${item.spentMinutes}m` : '00m'} `;
            const modelNumber = item.project ? item.project.name : (item.system ? item.system.modelNumber : '');
            return {
                'id': item['@id'].split('/').reverse()[0],
                'date and time': createdAt.isValid()
                    ? createdAt.format('DD MMM \'YY HH:mm')
                    : '',
                'company': item.contract && item.contract.customer
                    ? item.contract.customer.name
                    : '',
                'title': item.title,
                'status': formatTicketStatus(item.status),
                'contract number': item.contract ? item.contract.contractNumber : '',
                'model number': modelNumber,
                'serial number': item.system ? item.system.serialNumber : '',
                'category': item.category ? item.category.name : '',
                'engineer': item.engineer ? item.engineer.username : '',
                'time spent': spentTimeStr,
                'SLA':item.firstReplyAt ? TicketStore.sla(item.slaStart, item.firstReplyAt) : 'not set yet'
            }
        }, undefined, (items) => {
            const totalFooterValues = {
                'id': '',
                'date and time': '',
                'company': '',
                'title': '',
                'status': '',
                'contract number': '',
                'model number': '',
                'serial number': '',
                'category': '',
                'engineer': '',
                'time spent': '',
                'SLA': ''
            }
            const totalMnt = items.reduce((acc, curVal) => Number(acc) + Number(curVal['spentHours']) * 60 + Number(curVal['spentMinutes']), 0)
            return TicketStore.recountTotalTime(totalFooterValues, totalMnt)
        });

    };

    public static recountTotalTime(totalFooterValues, totalMnt) {
        const spentHours = Math.floor(totalMnt / 60);
        const spentMinutes = totalMnt - (spentHours * 60);
        totalFooterValues['time spent'] = `${spentHours ? `${spentHours}h` : '00h'}:${spentMinutes ? `${spentMinutes}m` : '00m'} `;
        return totalFooterValues;
    }

    @action createTicket = async (data): Promise<SubmissionErrors | null> => {
        const dataWithId = {...data, ...{id: uuidv4()}}
        const {
            response,
            errors
        } = await handleFormSubmit(data.system ? this.apiClient.ticketCreateForSystem(dataWithId) : this.apiClient.ticketCreateForProject(dataWithId));
        return runInAction("createTicket ok", async () => {
            if (!errors) {
                this.currentTicket = await this.apiClient.ticketFetch(response.id);
                this.uiBus.notify(`Ticket #${this.currentTicket.id} created`, "success");
                this.goUpdate(response.id);
            }
            return errors;
        })
    };

    @action addExpense = async (data) => {
        const {response, errors} = await handleFormSubmit(this.apiClient.expenseCreateForTicket({
            ...data,
            ticket: this.currentTicket['@id']
        }));
        if (errors) {
            return errors;
        } else {
            runInAction("addExpense ok", () => {
                this.currentTicket.expenses.unshift(response);
                this.clearContextPlace();
                this.uiBus.notify(`Ticket #${idFromEntity(this.currentTicket)} updated`, "success");
            });

        }
    };

    @action assignUser = async ({engineer}): Promise<SubmissionErrors | null> => {

        const {
            errors,
            response
        } = await handleFormSubmit(this.apiClient.ticketUpdate(this.currentTicket.id, {engineer}));
        return runInAction("assignUser", () => {
            if (!errors) {
                this.currentTicket = response;
                this.clearContextPlace();
                this.uiBus.notify(`Ticket #${idFromEntity(this.currentTicket)} updated`, "success");
            }
            return errors;
        });

    };

    @action invite = async (values): Promise<SubmissionErrors | null> => {
        const {errors, response} = await handleFormSubmit(this.apiClient.ticketUpdate(this.currentTicket.id, values));

        return runInAction("invite", () => {
            if (!errors) {
                this.currentTicket.invited = response.invited;
                this.uiBus.notify(`Ticket #${idFromEntity(this.currentTicket)} updated`, "success");
                this.clearContextPlace();
            }
            return errors;
        });

    };

    @action addSpentTime = async (values): Promise<SubmissionErrors | null> => {
        const hours = Math.floor(values['hours']);
        const minutes = Math.round((values['hours'] - hours) * 100 + hours * 60);
        const {errors} = await handleFormSubmit(this.apiClient.spentTimeCreate({
            type: values['type'],
            minutes: minutes,
            ticket: this.currentTicket['@id']
        }));
        return runInAction("addSpentTime ok", () => {
            if (!errors) {
                this.loadCurrentTicket(this.currentTicket.id)
                this.uiBus.notify(`Ticket #${idFromEntity(this.currentTicket)} updated`, "success");
                this.clearContextPlace();
            }
            return errors;

        });
    };

    @action updateTicket = async (data): Promise<SubmissionErrors | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.ticketUpdate(this.currentTicket.id,
            hydrateInitialValues(this.currentTicket, {
                ...data,
                category: typeof data.category === 'object'
                    ? data.category['@id']
                    : data.category
            })
        ));
        return runInAction("updateTicket ok", () => {
            if (!errors) {
                this.currentTicket = response;
                this.uiBus.notify(`Ticket #${idFromEntity(this.currentTicket)} updated`, "success");
            }
            return errors;
        });
    };

    @action updateTicketStatus = async ({status}): Promise<SubmissionErrors | null> => {
        const {response, errors} = await handleFormSubmit(this.apiClient.ticketUpdate(this.currentTicket.id,
            {status}
        ));
        return runInAction("updateTicketStatus ok", () => {
            if (!errors) {
                this.currentTicket = response;
                this.clearContextPlace();
                this.uiBus.notify(`Ticket #${idFromEntity(this.currentTicket)} updated`, "success");
            }
            return errors;
        });
    };

    @action assignParts = async (parts: Iri[]) => {
        this.assignedPartsLoading = true;
        for (let realId of parts) {
            await this.apiClient.realizationAssignToTicket(realId, this.currentTicket['@id']);
        }
        await this.fetchAssignedParts();
    };

    @action removeAssignedPart(item: PartRealizationShortModel, e) {
        this.uiBus.confirm(`Remove assigned part?`, e).then(action("removeAssignedPart start", () => {
            this.assignedPartsLoading = true;

            this.apiClient.realizationRemoveFromTicket(item['@id']).then(() => {
                this.fetchAssignedParts();
            })
        }))
    }

    @action createActivities = async (values): Promise<SubmissionErrors | null> => {
        const request = this.apiClient.addTicketComment(this.id, values);
        const {errors} = await handleFormSubmit(request);
        if (!errors) {
            this.uiBus.notify(`Added comment to ticket #${this.currentTicket.id}`, 'success');
            // this.loadCurrentTicket(this.id);
            this.fetchActivities();
        }
        return errors;
    };

    canRemoveAttachment(attachment: IAttachmentModel): boolean {
        return this.canEdit;
    }

    @action loadCurrentTicket = (id) => {
        this.isLoading = true;
        return this.apiClient.ticketFetch(id).then(
            action("loadCurrentTicket ok", response => {
                this.currentTicket = response;
                this.fetchAssignedParts()
                this.isLoading = false;
            })
        ).catch(action("loadCurrentTicket failed", e => {
            this.isLoading = false;
        }));
    };

    @action
    private fetchAssignedParts() {
        this.assignedPartsLoading = true;
        this.apiClient.realizationsCollectionFetch({ticket: this.currentTicket.id}).then(action("fetchAssignedParts ok", (response) => {
            this.assignedParts = response['hydra:member'];
            this.assignedPartsLoading = false;
        }));
    }

    @action expenseUpdate = async (iri: Iri, values: ExpenseUpdateInput): Promise<SubmissionErrors | null> => {

        const {errors} = await handleFormSubmit(this.apiClient.expenseUpdate(iri, values));
        if (errors) {
            return errors;
        } else {
            runInAction("expenseUpdate ok", () => {
                this.loadCurrentTicket(this.currentTicket.id).then(() => {
                    this.uiBus.notify(`Ticket #${idFromEntity(this.currentTicket)} updated`, "success");
                })
            });
        }
    }
    @action removeExpense = async (iri: Iri) => {
        const {errors} = await handleFormSubmit(this.apiClient.expenseDelete(iri));
        if (errors) {
            return errors;
        } else {
            runInAction("expenseUpdate ok", () => {
                this.loadCurrentTicket(this.currentTicket.id).then(() => {
                    this.uiBus.notify(`Ticket #${idFromEntity(this.currentTicket)} updated`, "success");
                })
            });
        }
    }

}
