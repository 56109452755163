import React from 'react';
import {CheckboxToggleList} from "../../../components/ui";
import {QuotePartInfoModel, StockRealizationModel} from "../../../utils/models";

type QuoteRealizationsFormProps = {
    item: Partial<QuotePartInfoModel>,
    handleStockRealizationsChange
}

const QuoteRealizationsForm = ({item, handleStockRealizationsChange}: QuoteRealizationsFormProps) => {

    return (<div className={'serials-block'}>
        <CheckboxToggleList data={item.part.stockRealizations}
                            chosen={item.quoteRealizationInfos.map((item => item.stockRealization))}
                            setChosen={handleStockRealizationsChange}
                            renderLabelFn={(data: StockRealizationModel) => data.serialNumber}/>
    </div>)
};

export default QuoteRealizationsForm;
