import React, {useEffect} from 'react';

import {observer} from "mobx-react";
import {useStore} from "../../context";
import {useLocation, useParams} from "react-router";
import {Loader} from "../../components/ui";
import {Link} from "react-router-dom";
import {Icon} from "@mdi/react";
import {mdiChevronLeft} from "@mdi/js";
import OpportunityDetailsButtonList from "./OpportunityDetailsButtonList";
import ActivityLogWidget from "../activity_log/ActivityLogWidget";
import OpportunityDetailsForm from "./OpportunityDetailsForm";
import OpportunityDetailsInfoPanel from "./OpportunityDetailsInfoPanel";
import OpportunityQuoteDetailsForm from "./Quote/OpportunityQuoteDetailsForm";
import StandalonePopover from "../../components/ui/StandalonePopover";
import QuoteListPanel from "./Quote/QuoteListPanel";
import {Media} from 'react-breakpoints'
import FloatButtons from "../../components/ui/FloatButtons";


const OpportunityDetailsLeftPanel = observer(() => {
    const {opportunityStore} = useStore();

    return (<div className='left-panel'>
        <Link to={"/opportunities"} className="back"><Icon path={mdiChevronLeft}/>Back to opportunities</Link>
        {opportunityStore.currentOpportunity && <OpportunityDetailsButtonList/>}
    </div>);
});

const OpportunityDetails = observer(() => {
    const {opportunityStore, opportunityQuoteStore} = useStore();
    const params = useParams();
    const {search} = useLocation();

    useEffect(() => {
        opportunityStore.fetchItem(params['opportunityId']).then()
    }, [params, search]);
    if (opportunityStore.isLoading) {
        return <Loader/>
    }

    return <div className='center-form-container opportunity'>

        {opportunityQuoteStore.currentPlace === 'create' &&
        <StandalonePopover classNames={'create-quotes'} title={'Create quote'}
                           closePopover={opportunityQuoteStore.resetPlace}
                           size={'ld'}>
            <OpportunityQuoteDetailsForm
                closePopover={opportunityQuoteStore.resetPlace}
            />
        </StandalonePopover>}


        <Media>
            {({breakpoints, currentBreakpoint}) => {
                return breakpoints[currentBreakpoint] > breakpoints.tablet && <OpportunityDetailsLeftPanel/>
            }}
        </Media>

        <div className='form-wrapper'>

            <Link to={"/opportunities"} className="back hidden"><Icon path={mdiChevronLeft}/>Back to
                opportunities</Link>

            {opportunityStore.currentOpportunity && <OpportunityDetailsInfoPanel/>}
            <OpportunityDetailsForm/>

            <Media>
                {({breakpoints, currentBreakpoint}) => {
                    return breakpoints[currentBreakpoint] < breakpoints.tablet &&
                        <div className='right-panel'> {<QuoteListPanel/>}</div>

                }}
            </Media>

            <ActivityLogWidget activitiesLog={opportunityStore} canEdit={opportunityStore.canEdit}/>
        </div>


        <Media>
            {({breakpoints, currentBreakpoint}) => {
                return breakpoints[currentBreakpoint] > breakpoints.mobileLandscape &&
                    <div className='right-panel'> {<QuoteListPanel/>} </div>
            }}
        </Media>

        {opportunityStore.currentOpportunity && opportunityStore.currentOpportunity.status !== 'successful' &&
        <div className={'float-buttons'}>
            <Media>
                {({breakpoints, currentBreakpoint}) =>

                    breakpoints[currentBreakpoint] < breakpoints.desktop &&
                    <FloatButtons type={"normal"}><OpportunityDetailsButtonList/></FloatButtons>
                }
            </Media>
        </div>
        }
    </div>
});


export default OpportunityDetails;
