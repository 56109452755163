import {Field, Form} from "react-final-form";
import {FinalButtonSet, Select} from "../../../components/ui/form";
import React from "react";
import {observer} from "mobx-react";
import useDataListFetch from "../../../utils/form/useDataListFetch";

type ContractReassignFormProps = {onSubmit}

const ContractReassignForm = observer(({onSubmit}: ContractReassignFormProps) => {
    const customersList = useDataListFetch('customers');

    return (
        <Form
            onSubmit={onSubmit}
            render={({handleSubmit, pristine, submitting, form}) => {
                return (<form onSubmit={handleSubmit}>
                    <Field
                        name={'customer'}
                        component={Select}
                        {...customersList}
                        required
                    />

                    <FinalButtonSet pristine={pristine} submitting={submitting}
                                    label={"Reassign"} reset={form.reset}/>
                </form>)
            }}>
        </Form>
    );
})

export default ContractReassignForm;
