import React from 'react';
import {Input, Textarea} from './../../components/ui';
import {Field, Form} from "react-final-form";
import {FinalButtonSet} from "../../components/ui/form";

type ResourceFormProps = {
    initialValues, disabled: boolean, onSubmit
}
const ResourceForm = ({initialValues = {}, disabled = false, onSubmit}: ResourceFormProps) => {

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues} render={({handleSubmit, pristine, submitting}) =>
            <form onSubmit={handleSubmit}>
                <Field
                    disabled={disabled}
                    name='name'
                    label='Name'
                    component={Input}
                />

                <Field
                    disabled={disabled}
                    name='email'
                    label='Email'
                    component={Input}
                />

                <Field
                    disabled={disabled}
                    name={'addresses[0].searchText'}
                    label='Address'
                    component={Input}
                />

                <Field
                    disabled={disabled}
                    name='contactName'
                    label="Contact's name"
                    component={Input}
                />
                <Field
                    disabled={disabled}
                    name='contactNumber'
                    label="Contact's number"
                    component={Input}
                />
                <Field
                    disabled={disabled}
                    name='notes'
                    label='Notes'
                    component={Textarea}
                />

                {!disabled && <FinalButtonSet submitting={submitting} pristine={pristine} mode={"RELATIVE"}/>}
            </form>}/>

    );
};


export default ResourceForm;
