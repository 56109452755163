import React from 'react';
import ResourceGridView from './../../components/grid/ResourceGridView';
import {useStore} from "../../context";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import {stringify} from "querystring";
import {idFromEntity} from "../../utils/iri";
import {formatShortContractName} from "../../store/formatters";
import {IProjectModel} from "../../utils/models";

const ProjectGrid = observer(() => {
    const {projectStore} = useStore();
    const history = useHistory();

    return (
        <div className='dashboard'>
            <ResourceGridView<IProjectModel>
                filterKeys={{
                    name: 'Project name'
                }}
                gridResource={projectStore}
                tableClass={'projects'}

                columns={
                    [
                        'id',
                        {
                            name: 'Project name',
                            value: (item) => item.name,
                        },
                        {
                            name: 'SLA time',
                            value: (item) => (
                                item.slaTime && item.slaTime.label ? item.slaTime.label :
                                    <span className={'not-available'}>N/A</span>
                            )
                        },
                        {
                            name: 'Contract',
                            value: (item) => formatShortContractName(item.contract)
                        },
                        {
                            name: 'Customer',
                            value: (item) => item.contract.customer.name,
                        },

                    ]
                }
                onRowClick={(item) => {
                    history.push({
                        pathname: `/customers/${item.contract.customer.id}`,
                        search: stringify({projectId: idFromEntity(item)})
                    });
                }}
            />
        </div>
    );
});


export default ProjectGrid;
