import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {useStore} from "../../context";
import {Field, Form} from "react-final-form";
import {FinalButtonSet, Textarea} from "../../components/ui/form";
import ViolationMessage from "../../components/ui/ViolationMessge";
import VendorSelect from 'react-select';
import {Button} from "../../components/ui";
import {IUserModel} from "../../utils/models";

const UsersToNotifyAdapter = ({input, meta, label, options}) => {
    const mappedOpt = [];
    options.forEach((val) => {
        const label = val['specialization']['label'];
        const options = {
            value: val['@id'],
            label: val['username']
        };
        const resI = mappedOpt.findIndex(item => item.label === label);
        if (resI === -1) {
            mappedOpt.push({
                label: label,
                options: [options]
            })
        } else {
            mappedOpt[resI]['options'].push(options);
        }

    })
    return (<div className="ls-form ls-select multiselect">
        <label className={'required'}>{label}</label>
        <VendorSelect
            name={input.name}
            isMulti={true}
            options={mappedOpt}
            value={input.value}
            onChange={(value) => {
                input.onChange(value)
            }}
            isLoading={mappedOpt.length === 0}
            menuPlacement="auto"
        />
        {(meta.error || meta.submitError) && (<ViolationMessage message={meta.error || meta.submitError}/>)}
    </div>)
}
const NotificationCreate = observer(() => {
    const {notificationStore, _apiClient} = useStore();
    const [notificationsUsers, setNotificationsUsers] = useState<IUserModel[]>([]);
    const [selectedUsersType, setSelectedUsersType] = useState(null);

    useEffect(() => {
        _apiClient.notificationsUsersListFetch().then(response => {
            setNotificationsUsers(response['hydra:member']);
        })
    }, []);
    return (
        <Form
            onSubmit={(values) => {
                const users = values['users'].map(item => item.value);
                return notificationStore.create({users, message: values.message});
            }}
        >
            {({handleSubmit, pristine, submitting, form}) => (

                <form onSubmit={handleSubmit}>
                    <Field
                        label='Notification for'
                        name='users'
                        options={notificationsUsers}
                        component={UsersToNotifyAdapter}
                    />
                    <div className={'notification-select-btns'}>
                        <Button
                            type={"button"}
                            color={selectedUsersType === 'all' ? 'blue' : ''}
                            onClick={() => {
                                form.change("users", notificationsUsers.map((item) => {
                                    return {value: item['@id'], label: item.username}
                                }));
                                setSelectedUsersType('all')
                            }}>Select all users</Button>
                        <Button
                            type={"button"}
                            color={selectedUsersType === 'engineers' ? 'blue' : ''}
                            onClick={() => {
                                form.change("users", notificationsUsers.filter(item => item.specialization.type === 'engineer').map((item) => {
                                    return {value: item['@id'], label: item.username}
                                }));
                                setSelectedUsersType('engineers')
                            }}>Select all engineers</Button>

                        <Button
                            type={"button"}
                            color={selectedUsersType === 'salesAgents' ? 'blue' : ''}
                            onClick={() => {
                                form.change("users", notificationsUsers.filter(item => item.specialization.type === 'sales-agent').map((item) => {
                                    return {value: item['@id'], label: item.username}
                                }));
                                setSelectedUsersType('salesAgents')
                            }}>Select all sales</Button>
                    </div>

                    <Field
                        label='Message'
                        name='message'
                        required
                        component={Textarea}
                    />
                    <FinalButtonSet submitting={submitting} pristine={pristine} mode={"FIXED"}/>

                </form>
            )}
        </Form>


    );
});

export default NotificationCreate
