import {QuoteStatusType} from "../../../store/enum/opportunityEnumeration";

const QuoteStatusColor = (status: QuoteStatusType) => {
    switch (status) {
        case 'new':
            return 'bg-grey';
        case 'sent':
            return 'bg-cyan';
        case 'converted':
            return 'bg-green';
        case 'declined':
            return 'bg-red';
    }
};

export default QuoteStatusColor;
